import axios from 'axios';
import { convertContactsToAddressPairs } from '@/plugins/util';
import Vue from 'vue';
import { createObjectURL, blobToBase64String } from 'blob-util';

export const initialState = () => ({
  account: {},
  emailSyncState: 'running',
  reloadTimeout: null,
  loadingEmailMessages: false,
  emailMessages: [],
  emailThreadDetail: {},
  emailDraft: null,
  emailFiles: [],
  emailData: {
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    body: '',
    files: [],
  },
  isDraft: false,
  isDocumentAttachedToEmailMessage: {},
  isDocumentUploading: {},
  emailFileDownloadUrl: {},
  emailFileDownloadBase64: {},
  isEmailFileDownloading: {},
  templates: [],
  signatures: [],
  signaturesLoaded: false,
  templatesLoaded: false,
  emailMessageBox: {
    visible: false,
    messageType: null,
  },
  autoresponderTemplate: {
    html: '',
  },
});

export const mutations = {
  SET_ACCOUNT(state, account) {
    state.account = account;
  },

  SET_EMAIL_SYNC(state, newValue) {
    state.emailSyncState = newValue;
  },

  SET_EMAIL_MESSAGES(state, newValue) {
    state.emailMessages = newValue;
  },

  SET_THREAD_DETAIL(state, newValue) {
    state.emailThreadDetail = newValue;
  },

  SET_LOADING(state, newValue) {
    state.loadingEmailMessages = newValue;
  },

  SET_EMAIL_DRAFT(state, draft) {
    state.emailDraft = draft;
  },

  SET_EMAIL_FILES(state, files) {
    state.emailFiles = files;
  },
  SET_ALL_EMAILDATA(state, thread) {
    state.emailData.to = convertContactsToAddressPairs(thread.from);
    state.emailData.cc = convertContactsToAddressPairs(thread.cc);
    state.emailData.bcc = convertContactsToAddressPairs(thread.bcc);
    state.emailData.subject = thread.subject;
  },

  SET_MAIL_DATA_BODY(state, body) {
    state.emailData.body = body;
  },

  SET_EMAILDATA_TO(state, value) {
    state.emailData.to = convertContactsToAddressPairs(value);
  },

  SET_EMAILDATA_CC(state, value) {
    state.emailData.cc = convertContactsToAddressPairs(value);
  },

  SET_EMAILDATA_BCC(state, value) {
    state.emailData.bcc = convertContactsToAddressPairs(value);
  },

  SET_EMAILDATA(state, { field, value }) {
    state.emailData[field] = value;
  },

  SET_EMAIL_DRAFT_DATA(state, { field, value }) {
    state.emailDraft[field] = value;
  },

  SET_DOCUMENT_ATTACHMENT(state, { document, value }) {
    Vue.set(state.isDocumentAttachedToEmailMessage, document.ID, value);
  },

  RESET_DOCUMENT_ATTACHMENT(state) {
    Vue.set(state, 'isDocumentAttachedToEmailMessage', {});
  },

  SET_DOCUMENT_UPLOADING(state, { document, value }) {
    Vue.set(state.isDocumentUploading, document.ID, value);
  },

  SET_EMAIL_FILE_DOWNLOAD_URL(state, map) {
    state.emailFileDownloadUrl = { ...state.emailFileDownloadUrl, ...map };
  },

  SET_EMAIL_FILE_DOWNLOAD_BASE64(state, map) {
    state.emailFileDownloadBase64 = { ...state.emailFileDownloadBase64, ...map };
  },

  SET_EMAIL_FILE_DOWNLOADING(state, map) {
    state.isEmailFileDownloading = { ...state.isEmailFileDownloading, ...map };
  },

  SET_TEMPLATES(state, value) {
    state.templates = value;
  },

  SET_SIGNATURES(state, value) {
    state.signatures = value;
  },

  SET_EMAIL_MESSAGE_BOX(state, messageBox) {
    state.emailMessageBox.visible = messageBox.visible;
    state.emailMessageBox.messageType = messageBox.messageType;
  },

  SET_SIGNATURES_LOADED(state, loaded) {
    state.signaturesLoaded = loaded;
  },

  SET_TEMPLATES_LOADED(state, loaded) {
    state.templatesLoaded = loaded;
  },

  SET_AUTORESPONDER_TEMPLATE(state, template) {
    state.autoresponderTemplate = template;
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_TIMEOUT(state, timeout) {
    state.reloadTimeout = timeout;
  },
  CLEAR_TIMEOUT(state) {
    if (state.reloadTimeout) {
      clearTimeout(state.reloadTimeout);
      state.reloadTimeout = null;
    }
  },
};

export const actions = {
  async saveEmailToken({ commit, dispatch }, data) {
    const response = await axios.post('/v2/email/token', data);
    const responseData = response.data;

    if (responseData.provider === 'imap') {
      // For IMAP it can take up to 10 minutes to sync the email folders
      const timeout = setTimeout(() => {
        dispatch('auth/reloadCurrentBranch', null, { root: true });
        commit('CLEAR_TIMEOUT');
      }, 600000);
      commit('SET_TIMEOUT', timeout);
    } else {
      dispatch('auth/reloadCurrentBranch', null, { root: true });
    }

    return responseData;
  },

  revokeEmail() {
    return axios.post('/v2/email/revoke');
  },

  getEmailMessagesByThreadId({ commit }, threadId) {
    return axios.get(`/v2/email/threads/${threadId}/messages`).then((res) => {
      commit('SET_EMAIL_MESSAGES', res.data.data);
    });
  },

  getEmailThreadById({ commit }, threadId) {
    return axios.get(`/v2/email/threads/${threadId}`).then((res) => {
      commit('SET_THREAD_DETAIL', res.data.data);
    });
  },

  resetEmailThreadDetail({ commit }) {
    commit('RESET');
  },

  async sendNewEmailMessage(_, data) {
    return axios.post('/v2/email/internal/send', data);
  },

  async createDraft({ commit }, draft) {
    const { data } = await axios.post('/v2/email/drafts', draft);
    commit('emailThreadList/SET_HAS_DRAFT_TO_THREAD', { threadId: data.threadId, hasDrafts: true }, { root: true });
  },

  async updateDraft(storeUtils, { draftId, draft }) {
    return axios.put(`/v2/email/drafts/${draftId}`, draft);
  },

  async deleteDraft({ commit }, { draftId, threadId }) {
    await axios.delete(`/v2/email/drafts/${draftId}`);

    commit('emailThreadList/SET_HAS_DRAFT_TO_THREAD', { threadId, hasDrafts: false }, { root: true });
    commit('SET_EMAIL_DRAFT', {});
  },

  async getLastDraftOfThread({ commit }, threadId) {
    const { data: drafts } = await axios.get(`/v2/email/threads/${threadId}/drafts`);

    if (drafts.length > 0) {
      commit('SET_EMAIL_DRAFT', drafts[0]);
    }
  },

  async getEmailFileBase64(storeUtils, { file, messageId }) {
    const response = await axios.get(`/v2/email/files/${file.id}/download/${messageId}`, { responseType: 'blob' });
    const url = createObjectURL(response.data);
    const base64 = `data:${file.contentType};base64,${await blobToBase64String(response.data)}`;

    return {
      url,
      base64,
    };
  },

  async markEmailAsRead({ commit }, threadId) {
    await axios.put(`/v2/email/threads/${threadId}/unread`, { unread: false });
    commit('emailThreadList/MARK_THREAD_READ', threadId, { root: true });
  },


  storeTemplate({ commit }, templateData) {
    commit('SET_TEMPLATES_LOADED', false);

    if (templateData.id === null) {
      return axios.post('/v2/email/internal/template', {
        html: templateData.html,
        name: templateData.name,
        scope: templateData.scope,
      });
    }
    return axios.put(`/v2/email/internal/template/${templateData.id}`, {
      html: templateData.html,
      name: templateData.name,
      scope: templateData.scope,
    });
  },

  async createAutoresponderTemplate({ commit }, template) {
    const { data: { data: autoresponderTemplate } } = await axios.post('/v2/email/internal/template', {
      ...template,
      name: 'Autoresponder',
      scope: 'user',
      isAutoresponder: true,
    });
    commit('SET_AUTORESPONDER_TEMPLATE', autoresponderTemplate);
  },

  async updateAutoresponderTemplate({ commit, dispatch }, template) {
    const templateExists = Number.isInteger(template.ID);
    if (!templateExists) {
      dispatch('createAutoresponderTemplate', template);
    } else {
      const { data: { data: autoresponderTemplate } } = await axios.put(`/v2/email/internal/template/${template.ID}`, template);
      commit('SET_AUTORESPONDER_TEMPLATE', autoresponderTemplate);
    }
  },

  async getAutoresponderTemplate({ commit }) {
    const { data: { data: autoresponderTemplate } } = await axios.get('/v2/email/internal/template/autoresponder');
    commit('SET_AUTORESPONDER_TEMPLATE', autoresponderTemplate);
  },

  loadTemplates({ commit, state }) {
    if (state.templatesLoaded) {
      return state.templates;
    }

    return axios.get('/v2/email/internal/template')
      .then((data) => {
        commit('SET_TEMPLATES', data.data.data);
        commit('SET_TEMPLATES_LOADED', true);
      });
  },

  loadTemplate(storeUtils, id) {
    return axios.get(`/v2/email/internal/template/${id}`);
  },

  async deleteAutoresponderTemplateById({ commit }, id) {
    await axios.delete(`/v2/email/internal/template/${id}`);
    commit('SET_AUTORESPONDER_TEMPLATE', { html: '' });
  },

  deleteTemplates(storeUtils, data) {
    return axios.put('/v2/email/internal/template/deleteMultiple', {
      templateIds: data.templateIds,
    });
  },

  storeSignature({ commit }, signatureData) {
    commit('SET_SIGNATURES_LOADED', false);

    if (signatureData.id === null) {
      return axios.post('/v2/email/internal/signature', {
        html: signatureData.html,
        name: signatureData.name,
      });
    }
    return axios.put(`/v2/email/internal/signature/${signatureData.id}`, {
      html: signatureData.html,
      name: signatureData.name,
    });
  },

  loadSignatures({ commit, state }) {
    if (state.signaturesLoaded) {
      return state.signatures;
    }

    return axios.get('/v2/email/internal/signature')
      .then((data) => {
        commit('SET_SIGNATURES', data.data.data);
        commit('SET_SIGNATURES_LOADED', true);
      });
  },

  loadSignature(storeUtils, id) {
    return axios.get(`/v2/email/internal/signature/${id}`);
  },

  deleteSignatures(storeUtils, data) {
    return axios.put('/v2/email/internal/signature/deleteMultiple', {
      signatureIds: data.signatureIds,
    });
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
