<template>
  <q-btn
    v-bind="attrs"
    @click="onClickWrapper"
  >
    <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope || {}" />
    </template>
  </q-btn>
</template>

<script>
/**
 * Drop in replacement for q-btn that auto switches loading attr state.
 *
 * Just replace q-btn with a-btn and the btn switches to loading state if
 * the onClick handler is running (handler needs to be async though).
 *
 * Also provides new prop debounce wich disables the btn for a given time in
 * milliseconds after execution. Comes in handy for e. g. reload buttons.
 */

export default {
  props: {
    debounce: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
    disable: false,
  }),
  computed: {
    attrs() {
      const attrs = { ...this.$attrs };

      if (this.loading) attrs.loading = true;
      if (this.disable) attrs.disable = true;

      return attrs;
    },
  },
  methods: {
    async onClickWrapper() {
      this.loading = true;
      try {
        await this.$listeners.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;

      if (this.debounce) {
        this.disable = true;
        setTimeout(() => {
          this.disable = false;
        }, this.debounce);
      }
    },
  },
};
</script>
