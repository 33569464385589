import axios from 'axios';

const initialState = () => ({
  vehicle: {},
  loading: false,
  dealerInfo: {},
  dealerInfoLoading: true,
});

export const mutations = {
  SET_VEHICLE(state, newValue) {
    state.vehicle = newValue;
  },
  RESET_VEHICLE(state) {
    state.vehicle = {};
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_DEALER_INFO(state, newValue) {
    state.dealerInfo = newValue;
  },
  RESET_DEALER_INFO(state) {
    state.dealerInfo = {};
    state.dealerInfoLoading = true;
  },
  SET_DEALER_LOADING(state, newValue) {
    state.dealerInfoLoading = newValue;
  },
};


export const actions = {
  setVehicle({ commit }, vehicle) {
    commit('SET_VEHICLE', vehicle);
  },

  resetVehicle({ commit }) {
    commit('RESET_VEHICLE');
  },


  async getVehicle({ commit }, uid) {
    commit('SET_LOADING', true);

    const { data } = await axios.get(`v2/eln/market/${uid}`);
    commit('SET_VEHICLE', data);

    commit('SET_LOADING', false);
  },

  async getDealerInfo({ commit }, dealerId) {
    commit('SET_DEALER_INFO', {});

    commit('SET_DEALER_LOADING', true);

    const { data } = await axios.get(`v2/eln/reference/dealer/${dealerId}`);
    commit('SET_DEALER_INFO', data);

    commit('SET_DEALER_LOADING', false);
    return data;
  },

  resetDealerInfo({ commit }) {
    commit('RESET_VEHICLE');
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
