import axios from 'axios';
import { toOptions, orderBy } from '@/plugins/util';
import groupBy from 'lodash.groupby';

export const initialState = {
  vehicleRef: {},
  equipment: [],
  groupedEquipment: {
    Extras: [],
  },
  vehicleRefLoaded: false,
  vehicleRefLoading: false,
  makeList: [],
  makeListLoaded: false,
  modelList: [],
  imageBackgrounds: [],
};

export const mutations = {
  SET_REF(state, newValue) {
    state.vehicleRef = newValue;
    state.vehicleRefLoaded = true;
  },
  SET_REF_LOADING(state, newValue) {
    state.vehicleRefLoading = newValue;
  },
  SET_EQUIP(state, newValue) {
    state.equipment = newValue;
    state.groupedEquipment = groupBy(newValue, 'category');
  },
  SET_MAKES(state, newValue) {
    state.makeList = newValue;
    state.makeListLoaded = true;
  },
  SET_MODELS(state, newValue) {
    state.modelList = newValue;
  },
  SET_IMAGE_BACKGROUNDS(state, newValue) {
    state.imageBackgrounds = newValue;
  },
};

export const getters = {
  makeListToOptions: state => toOptions(state.makeList, 'name', 'ID'),
  modelListToOptions: state => toOptions(state.modelList, 'name', 'ID'),
  vehicleTypeListToOptions: state => toOptions(state.vehicleRef.vehicleTypeList, 'app', 'ecd'),
  colorsToOptions: state => toOptions(state.vehicleRef.colors, 'app', 'ecd'),
  interiorTypeListToOptions: state => toOptions(state.vehicleRef.interiorTypeList, 'localized', 'ecd'),
  climatisationTypeListToOptions: state => toOptions(state.vehicleRef.climatisationTypeList, 'localized', 'ecd'),
  slidingDoorListToOptions: state => toOptions(state.vehicleRef.slidingDoorList, 'localized', 'ecd'),
  airbagTypeListToOptions: state => toOptions(state.vehicleRef.airbagTypeList, 'localized', 'ecd'),
  conditionTypeListToOptions: state => toOptions(state.vehicleRef.conditionTypeList, 'localized', 'ecd'),
  deliveryPeriodListToOptions: state => toOptions(state.vehicleRef.deliveryPeriodList, 'localized', 'ecd'),
  countryListToOptions: state => toOptions(state.vehicleRef.countryList, 'name', 'ecd'),
  equipmentWithoutTyres(state) {
    return { ...state.groupedEquipment, Extras: state.groupedEquipment.Extras.filter(extra => !extra.name.match(/[rR]eifen/)) };
  },
  tyreEquipment: (state) => {
    const tyres = state.groupedEquipment.Extras.filter(extra => extra.name.match(/[rR]eifen|[rR]ad/));
    return orderBy(tyres, 'name', 'asc');
  },
  equipmentListToOptions: state => toOptions(state.equipment, 'name', 'ID'),
  driveTypeListToOptions: state => toOptions(state.vehicleRef.driveTypeList, 'localized', 'ecd'),
  transmissionTypeListToOptions: state => toOptions(state.vehicleRef.transmissionTypeList, 'localized', 'ecd'),
  fuelMappingToOptions: state => toOptions(state.vehicleRef.fuelMapping, 'localized', 'ecd'),
  fuelDetailTypeListToOptions: state => (state.vehicleRef.fuelDetailList || []).map(fuelList => toOptions(fuelList.details, 'localized', 'ecd')).flat(),
  efficiencyClassListToOptions: state => toOptions(state.vehicleRef.efficiencyClassList, 'gwh', 'ecd'),
  co2ClassListToOptions: state => toOptions(state.vehicleRef.co2ClassList, 'gwh', 'ecd'),
  emissionClassListToOptions: state => toOptions(state.vehicleRef.emissionClassList, 'localized', 'ecd'),
  pollutionBadgeListToOptions: state => toOptions(state.vehicleRef.pollutionBadgeList, 'localized', 'ecd'),


  ToOptions: state => toOptions(state.vehicleRef.conditionTypeList, 'localized', 'ecd'),

};

let refPromise;

export const actions = {

  getVehicleRef({ commit, state } = {}) {
    if (state.vehicleRefLoaded) {
      return Promise.resolve(state.vehicleRef);
    }

    if (!state.vehicleRefLoading) {
      commit('SET_REF_LOADING', true);
      refPromise = axios.get('v2/reference/vehicle').then((res) => {
        commit('SET_REF_LOADING', false);
        commit('SET_REF', res.data.data);
        commit('SET_EQUIP', res.data.data.equipment);

        return res.data.data;
      });
    }

    return refPromise;
  },

  async getAllMakes({ commit, state } = {}) {
    return !state.makeListLoaded && axios.get('v1/vehicle/makes/list', { errorNotification: false })
      .then((res) => {
        commit('SET_MAKES', res.data.data);
      });
  },

  clearModelsForMake({ commit }) {
    commit('SET_MODELS', []);
  },

  getModelsForMake({ commit }, makeId) {
    return axios.get(`/v1/vehicle/models/${makeId}`).then((res) => {
      commit('SET_MODELS', res.data.data);
    });
  },

  getImageBackgrounds({ commit }) {
    return axios.get('v2/media/backgroundimage').then((res) => {
      commit('SET_IMAGE_BACKGROUNDS', res.data.data);
    });
  },

};

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
  getters,
};
