import vue from 'vue';
import axios from 'axios';
import DefaultLayout from '@/layouts/Default.vue';
import MinimalLayout from '@/layouts/Minimal.vue';

export default [
  {
    path: '/',
    component: DefaultLayout,
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/dashboard',
        name: 'newHome',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/inventory/:type', props: true, name: 'VehicleInventoryList', component: () => import('@/views/vehicle/VehicleInventory'),
      },
      {
        path: '/vehicle/:sellingId', name: 'vehicleDetail', props: true, component: () => import('@/views/vehicle/VehicleDetail'),
      },
      {
        path: '/inserate', name: 'inserateOverview', component: () => import('@/views/inserate/InserateOverview'),
      },
      {
        path: '/customer', name: 'customerOverview', component: () => import('@/views/customer/CustomerOverview'),
      },
      {
        path: '/customer/:id', name: 'customerDetail', props: true, component: () => import('@/views/customer/CustomerDetail'),
      },
      {
        path: '/contact', name: 'contactOverview', component: () => import('@/views/contact/ContactOverview'),
      },
      {
        path: '/contact/:id', name: 'contactDetail', props: true, component: () => import('@/views/contact/ContactDetail'),
      },
      {
        path: '/organization/:id', name: 'organizationDetail', props: true, component: () => import('@/views/organization/OrganizationDetail'),
      },
      {
        path: '/opportunity', name: 'opportunityOverview', component: () => import('@/views/opportunity/OpportunityOverview'),
      },
      {
        path: '/opportunity/:id', name: 'opportunityDetail', props: true, component: () => import('@/views/opportunity/OpportunityDetail'),
      },
      {
        path: '/opportunitymail/:opportunityId',
        name: 'opportunityMailDetail',
        props: true,
        component: () => import('@/views/opportunity/OpportunityMailDetail'),
      },
      {
        path: '/opportunity/create', name: 'opportunityDetailCreate', props: true, component: () => import('@/views/opportunity/OpportunityDetail'),
      },
      {
        path: '/document', name: 'documentsOverview', component: () => import('@/views/document/DocumentOverview'),
      },
      {
        path: '/document/:id', name: 'documentDetail', props: true, component: () => import('@/components/document/dashboard/DocumentPdfDetail'),
      },
      {
        path: '/document/template/create', props: true, name: 'templateCreate', component: () => import('@/views/document/CreateDocumentTemplate'),
      },
      {
        path: '/document/template/:id', name: 'template', props: true, component: () => import('@/views/document/DocumentTemplate'),
      },
      {
        path: '/document/create/:templateId', name: 'documentCreate', props: true, component: () => import('@/views/document/CreateDocument'),
      },
      {
        path: '/email/authresult', name: 'authresult', component: () => import('@/views/email/EmailAuthResult'),
      },
      {
        path: '/email/:folder', props: true, name: 'emailThreadOverview', component: () => import('@/views/email/EmailThreadOverview'),
      },
      {
        path: '/email/new', name: 'newEmailThreadDetail', props: { isNew: true }, component: () => import('@/views/email/EmailThreadDetail'),
      },
      {
        path: '/email/detail/:encodedThreadId', name: 'emailThreadDetail', props: true, component: () => import('@/views/email/EmailThreadDetail'),
      },
      {
        path: '/invoice', name: 'invoiceOverview', component: () => import('@/views/invoice/InvoiceOverview'),
      },
      {
        path: '/invoice/:id', name: 'invoiceDetail', props: true, component: () => import('@/components/invoice/dashboard/InvoicePdfDetail'),
      },
      {
        path: '/invoice/template/create', props: true, name: 'invoiceTemplateCreate', component: () => import('@/views/invoice/CreateInvoiceTemplate'),
      },
      {
        path: '/invoice/template/:id', name: 'invoiceTemplate', props: true, component: () => import('@/views/invoice/InvoiceTemplate'),
      },
      {
        path: '/invoice/create/:templateId', name: 'invoiceCreate', props: true, component: () => import('@/views/invoice/CreateInvoice'),
      },
      {
        path: '/invoices', name: 'invoicesList', component: () => import('@/views/invoices/InvoicesList.vue'),
      },
      {
        path: '/invoices/draft/:invoiceId',
        name: 'invoicesDraft',
        props: true,
        component: () => import('@/views/invoices/InvoicesDraftDetail.vue'),
      },
      {
        path: '/invoices/:invoiceId',
        name: 'invoicesPreview',
        props: true,
        component: () => import('@/views/invoices/InvoicesPreview.vue'),
      },
      {
        path: '/settings',
        name: 'settings',
        meta: { isBranchOwner: true },
        component: () => import('@/views/settings/Settings'),
      },
      {
        path: '/profile-settings', name: 'profile-settings', component: () => import('@/views/settings/ProfileSettings'),
      },
      {
        path: '/upgrade', name: 'upgradeAccountView', component: () => import('@/views/payment/UpgradeAccountView'),
      },
      {
        path: '/subscribe', name: 'subscribeView', component: () => import('@/views/payment/Subscribe'),
      },
      {
        path: '/maxvin', name: 'maxVin', component: () => import('@/views/payment/MaxVin'),
      },
      {
        path: '/import/:branchId', name: 'inserateImporter', props: true, component: () => import('@/views/import/Importer'),
      },
      {
        path: '/import-logs', name: 'importLogDetail', props: true, component: () => import('@/views/import/ImportLogDetail'),
      },
      {
        path: '/user', name: 'userOverview', meta: { isBranchOwner: true }, component: () => import('@/views/user/UserOverview'),
      },
      {
        path: '/user/:userId', name: 'userDetail', props: true, meta: { isBranchOwner: true }, component: () => import('@/views/user/UserDetail'),
      },
      {
        path: '/branch', name: 'branchOverview', meta: { isDealerOwner: true }, component: () => import('@/views/branch/BranchOverview'),
      },
      {
        path: '/marketplace',
        component: () => import('@/views/marketplace/MarketplaceOverview'),
        children: [
          {
            path: '', name: 'marketplaceAllOverview', component: () => import('@/components/marketplace/overview/MarketplaceAllOverview'),
          },
          {
            path: 'fav', name: 'marketplaceFavOverview', component: () => import('@/components/marketplace/overview/MarketplaceFavOverview'),
          },
          {
            path: 'bid', name: 'marketplaceBidOverview', component: () => import('@/components/marketplace/overview/MarketplaceBidOverview'),
          },
          {
            path: 'bought', name: 'marketplaceBoughtOverview', component: () => import('@/components/marketplace/overview/MarketplaceBoughtOverview'),
          },
        ],
      },
      {
        path: '/marketplace/carsale24/:vehicleId',
        name: 'marketplaceCarsale24Detail',
        props: true,
        component: () => import('@/views/marketplace/MarketplaceCarsale24Detail'),
      },
      {
        path: '/eln-marketplace',
        name: 'elnMarketPlace',
        component: () => import('@/views/eln-market/ElnMarketOverview'),
        beforeEnter: (to, from, next) => {
          if (import.meta.env.VUE_APP_WHITELABEL !== 'eln') next('/marketplace');
          next();
        },
      },
      {
        path: '/eln-marketplace/vehicle/:type',
        name: 'elnVehicles',
        props: true,
        component: () => import('@/views/eln-market/ElnVehicleList'),
        beforeEnter: (to, from, next) => {
          if (import.meta.env.VUE_APP_WHITELABEL !== 'eln') next('/marketplace');
          next();
        },
      },
      {
        path: '/eln-marketplace/:vehicleId',
        name: 'elnMarketPlaceDetails',
        props: true,
        component: () => import('@/views/eln-market/ElnMarketDetail'),
        beforeEnter: (to, from, next) => {
          if (import.meta.env.VUE_APP_WHITELABEL !== 'eln') next('/marketplace');
          next();
        },
      },


    ],
  },
  {
    path: '/admin',
    component: DefaultLayout,
    meta: { checkAuth: true, isAdmin: true },
    children: [
      {
        path: 'dealer', name: 'adminDealerOverview', component: () => import('@/views/admin/DealerOverview'),
      },
      {
        path: 'dealer/:dealerId', name: 'adminDealerDetail', props: true, component: () => import('@/views/admin/DealerDetail'),
      },
      {
        path: 'dashboard', name: 'adminDashboard', props: true, component: () => import('@/views/admin/AdminDashboard'),
      },
      {
        path: 'system', name: 'systemDashboard', props: true, component: () => import('@/views/admin/SystemDashboard'),
      },
      {
        path: 'stripe', name: 'stripeInvoices', props: true, component: () => import('@/views/admin/StripeInvoices'),
      },
    ],
  },
  {
    path: '/login',
    component: MinimalLayout,
    children: [
      { path: '', name: 'login', component: () => import('@/views/auth/Login') },
    ],
  },
  {
    path: '/forgot-password',
    component: MinimalLayout,
    children: [
      { path: '', name: 'forgotPassword', component: () => import('@/views/auth/ForgotPassword') },
    ],
  },
  {
    path: '/resetPassword',
    component: MinimalLayout,
    children: [
      { path: '', name: 'resetPassword', component: () => import('@/views/auth/ResetPassword') },
    ],
  },
  {
    path: '/createpassword',
    component: MinimalLayout,
    children: [
      { path: '', name: 'createpassword', component: () => import('@/views/auth/CreatePassword') },
    ],
  },
  {
    path: '/oauth/result',
    component: MinimalLayout,
    children: [
      { path: '', name: 'oauthresult', component: () => import('@/views/auth/OAuthResult') },
    ],
  },
  {
    path: '/reset2FA',
    redirect: (to) => {
      const { resetToken } = to.query;

      axios.get(`/v2/security/otp/reset?resetToken=${resetToken}`, { errorNotification: false }).then(
        () => {
          vue.prototype.$notifyUserInteraction.success('Die 2-Faktor-Authentifizierung wurde erfolgreich zurückgesetzt!');
        },
        (error) => {
          console.error(error);
          vue.prototype.$notifyUserInteraction.error('Es ist ein Fehler beim Zurücksetzen der 2-Faktor-Authentifizierung aufgetreten!');
        },
      );

      return { name: 'login', query: {} };
    },
  },
  {
    path: '/payment',
    component: MinimalLayout,
    children: [
      { path: '', name: 'payment', component: () => import('@/views/payment/Payment') },
    ],
  },
  {
    path: '/notactive',
    component: MinimalLayout,
    children: [
      { path: '', name: 'notactive', component: () => import('@/views/payment/NotActive') },
    ],
  },
  {
    path: '/callback',
    component: MinimalLayout,
    children: [
      { path: '', name: 'callback', component: () => import('@/views/auth/Callback') },
    ],
  },
  {
    path: '/eln/job/:uuid',
    component: MinimalLayout,
    children: [
      { path: '', name: 'eln-job', component: () => import('@/views/eln/Job') },
    ],
  },
  {
    path: '/print/invoices/:token',
    name: 'invoicesPrintView',
    component: () => import('@/views/invoices/InvoicesPrintView.vue'),
  },
];
