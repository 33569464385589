import Vue from 'vue';
import axios from 'axios';
import { clean } from '@/plugins/util';

const initialState = () => ({
  list: [],
  loading: false,
  showSearchDetails: false,
  queryParams: {
    limit: 25,
    offset: 0,
  },
  pagination: {
    filteredCount: 0,
    totalCount: 0,
  },
  search: {
    modelSearch: [
      {
        makeKey: null,
        modelKey: null,
        versionText: null,
      },
    ],
    firstRegistrationDate: {
      from: null,
      until: null,
    },
    salesPrice: {
      from: null,
      until: null,
    },
    mileage: {
      from: null,
      until: null,
    },
    taxReportable: null,
    minInternalImageCount: 0,
    deliverableImmediately: null,
    deliverableOnShortNotice: null,
    transmissionTypeKey: [],
    envkvPetrolTypeKey: [],
    bodyStyleKey: [],
    enginePowerKw: {
      from: null,
      until: null,
    },
    exterior: [{
      colorKey: null,
      paintKey: null,
    }],
    doorCount: null,
    seatCount: null,
    interiorColorKey: [],
    upholsteryTypeKey: [],
    searchflags: [],
    distributorId: [],
    deliverycategoryKey: [],
  },
  order: {
    name: 'dateIndexed',
    dir: 'DESC',
  },
});

export const mutations = {
  SET_LIST(state, newValue) {
    state.list = newValue;
  },
  SET_PAGINATION(state, newValue) {
    state.pagination = newValue;
  },
  SET_QUERY_PARAMS(state, { limit = 25, offset = 0 }) {
    state.queryParams = { limit, offset };
  },
  RESET_LIST(state) {
    state.list = [];
  },
  RESET_FILTER(state) {
    state.search = initialState().search;
  },
  RESET_FILTER_PROP(state, prop) {
    state.search[prop] = null;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_SHOW_SEARCH_DETAILS(state, newValue) {
    state.showSearchDetails = newValue;
  },
  UPDATE_VEHICLE(state, [uid, update]) {
    const vehicle = state.list.find(vehicle => vehicle.uid === uid);
    if (!vehicle) return;
    Object.entries(update).forEach(([key, value]) => {
      Vue.set(vehicle, key, value);
    });
  },
};


export const actions = {
  async getList({ state, commit }) {
    commit('SET_LOADING', true);

    const { data } = await axios.get('v2/eln/market', {
      params: {
        search: clean(JSON.parse(JSON.stringify(state.search))),
        offset: state.queryParams.offset,
      },
    });

    commit('SET_LIST', data.data);
    commit('SET_PAGINATION', { totalCount: data.totalCount, filteredCount: data.filteredCount });

    commit('SET_LOADING', false);
  },

  resetFilter({ commit }) {
    commit('RESET_FILTER');
  },

  resetFilterProp({ commit }, prop) {
    commit('RESET_FILTER_PROP', prop);
  },

  setShowSearchDetails({ commit, state }) {
    commit('SET_SHOW_SEARCH_DETAILS', !state.showSearchDetails);
  },

  setQueryParams({ commit }, val) {
    commit('SET_QUERY_PARAMS', val);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
