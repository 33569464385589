import Vue from 'vue';

import 'quasar-framework/dist/quasar.ie.polyfills';
import lang from 'quasar-framework/i18n/de';

const animateModules = import.meta.glob('./node_modules/quasar-extras/animate/**/*.css');
Object.values(animateModules).forEach(v => v());

import 'quasar-extras/roboto-font';
import 'quasar-extras/material-icons';
import 'quasar-extras/fontawesome';
import Quasar, * as All from 'quasar';
import Vuelidate from 'vuelidate';

import { appConfig } from './appConfig';
import axios from './axios';
import store from '@/store';
import socket from './socket';
import notification from './notification';
import requestErrorHandler from './requestErrorHandler';
import creoleParser from './creoleParser';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vueDropzone from './dropzone';
import confirmation from './confirmation';
import sanitizeHtml from './sanitizeHtml';
import translations from './translations';
import copyToClipboard from './copyToClipboard';
import VueObserveVisibility from 'vue-observe-visibility';
import modal from './modal';

import AAvatar from '@/components/base/AAvatar';
import AInput from '@/components/base/a-input/AInput';
import AEditor from '@/components/base/AEditor';
import ADatetime from '@/components/base/ADatetime';
import AUploader from '@/components/base/AUploader';
import AModal from '@/components/base/AModal';
import AImage from '@/components/base/AImage';
import AGallery from '@/components/base/AGallery';
import AAutocompleteInput from '@/components/base/AAutocompleteInput';
import APopupEdit from '@/components/base/APopupEdit';
import AInfiniteScroll from '@/components/base/AInfiniteScroll';
import ACards from '@/components/base/ACards';
import ABtn from '@/components/base/ABtn';

import VueStyle2 from 'vue-style2';
import AMailChipsInput from '@/components/base/AMailChipsInput';
import AEmail from '@/components/base/AEmail';

socket(store);

Vue.use(Vuelidate);
Vue.use(Quasar, {
  i18n: lang,
  components: All,
  directives: All,
  plugins: {
    ...All,
    modal,
  },
});

Vue.use(axios);
Vue.use(appConfig);
Vue.use(notification);
Vue.use(requestErrorHandler);
Vue.use(creoleParser);
Vue.use(Viewer);
Vue.use(vueDropzone);
Vue.use(confirmation);
Vue.use(sanitizeHtml);
Vue.use(translations);
Vue.use(copyToClipboard);
Vue.use(VueObserveVisibility);

Vue.config.ignoredElements = ['trix-editor'];

Vue.component('a-avatar', AAvatar);
Vue.component('a-input', AInput);
Vue.component('a-editor', AEditor);
Vue.component('a-datetime', ADatetime);
Vue.component('a-uploader', AUploader);
Vue.component('a-modal', AModal);
Vue.component('a-image', AImage);
Vue.component('a-gallery', AGallery);
Vue.component('a-autocomplete-input', AAutocompleteInput);
Vue.component('a-popup-edit', APopupEdit);
Vue.component('a-infinite-scroll', AInfiniteScroll);
Vue.component('a-cards', ACards);
Vue.component('a-btn', ABtn);
Vue.component('warning-dialog', AUploader);
Vue.component('a-mail-chips-input', AMailChipsInput);
Vue.component('a-email', AEmail);

// TODO: styles for document editor (gridstack) are imported only on subcomponent
// TODO: improve the loading of the styles
Vue.use(VueStyle2);
