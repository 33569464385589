<template>
  <simple-modal
    :open.sync="open"
    title="Konflikt aufgetreten"
    no-backdrop-dismiss
    content-css="width: 866px"
  >

    <template slot="body">
      <div class="row q-mb-md q-pb-sm">
        Das Fahrzeug wurde zwischenzeitlich verändert. Folgende Werte haben sich geändert:
      </div>
      <div class="row q-mb-md q-body-2">
        <div class="col-4"></div>
        <div class="col-3 column items-center">Gespeicherte Werte</div>
        <div class="col-2"></div>
        <div class="col-3 column items-center">Ihre Werte</div>
      </div>
      <div
        class="row items-center q-mt-sm"
        v-for="(row, key) in diff"
        :key="key"
      >
        <div class="col-4">{{key | mapKey}}</div>
        <div class="col-3">
          <q-field>
            <q-input
              inverted-light
              color="grey-2"
              class="no-shadow"
              readonly
              :value="null"
            >
              <div
                slot="before"
                class="col-12 q-input-target ellipsis"
              >
                <span v-html="mapValue(row.originValue, key)" />
                <q-tooltip
                  anchor="bottom left"
                  self="top left"
                  :delay="500"
                  v-html="mapValue(row.originValue, key)"
                />
              </div>
            </q-input>
          </q-field>
        </div>
        <div class="col-2 column items-center">
          <q-toggle
            v-model="row.resolve"
            false-value="originValue"
            true-value="currentValue"
            unchecked-icon="arrow_back"
            checked-icon="arrow_forward"
            color="primary"
            keep-color
          />
        </div>
        <div class="col-3">
          <q-field>
            <q-input
              inverted-light
              color="grey-2"
              class="no-shadow"
              readonly
              :value="null"
            >
              <div
                slot="before"
                class="col-12 q-input-target ellipsis"
              >
                <span v-html="mapValue(row.currentValue, key)" />
                <q-tooltip
                  anchor="bottom left"
                  self="top left"
                  :delay="500"
                  v-html="mapValue(row.currentValue, key)"
                />
              </div>
            </q-input>
          </q-field>
        </div>
      </div>
      <div class="q-mb-lg"></div>
    </template>

    <template slot="footer">
      <q-btn
        color="primary"
        class="full-width no-shadow"
        label="Konflikt auflösen"
        @click="resolveConflict"
      />
    </template>
  </simple-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import SimpleModal from '@/components/base/SimpleModal';
import { modal } from '@/plugins/mixins';
import { numberWithPoints } from '@/plugins/util';
import i18n from '@/plugins/translations';

export default {
  name: 'VehicleDiffModal',

  props: [
    'originVehicle',
    'originalVehicle',
    'currentVehicle',
  ],

  components: {
    SimpleModal,
  },

  mixins: [

    /*
     * Provides
     *   properties:
     *     open (allows v-sync)
     *   methods:
     *     show
     *     hide
     *   events:
     *     @show
     *     @hide
     */
    modal,
  ],

  i18n,

  filters: {
    mapKey(key) {
      return i18n.de[key] || key;
    },
  },

  data: () => ({
    diff: {},
  }),

  computed: {
    ...mapGetters('vehicleMeta', {
      values_makeId: 'makeListToOptions',
      values_modelId: 'modelListToOptions',
      values_vehicleTypeId: 'vehicleTypeListToOptions',
      values_driveType: 'driveTypeListToOptions',
      values_transmissionType: 'transmissionTypeListToOptions',
      values_colorId: 'colorsToOptions',
      values_interiorColorId: 'colorsToOptions',
      values_interiorType: 'interiorTypeListToOptions',
      values_climatisationType: 'climatisationTypeListToOptions',
      values_airbagType: 'airbagTypeListToOptions',
      values_slidingDoor: 'slidingDoorListToOptions',
      values_fuelType: 'fuelMappingToOptions',
      values_fuelDetailType: 'fuelDetailTypeListToOptions',
      values_energyEfficiency: 'efficiencyClassListToOptions',
      values_emissionClass: 'emissionClassListToOptions',
      values_pollutionBadge: 'pollutionBadgeListToOptions',
      values_conditionType: 'conditionTypeListToOptions',
      values_deliveryPeriod: 'deliveryPeriodListToOptions',
      values_countryId: 'countryListToOptions',
      values_equipment: 'equipmentListToOptions',
    }),
  },

  mounted() {
    this.createDiff();
  },

  methods: {
    createDiff() {
      const diff = {};

      Object.keys(this.originalVehicle).forEach((key) => {
        /* eslint-disable eqeqeq */

        const originValue = this.originVehicle[key];
        const originalValue = this.originalVehicle[key];
        const currentValue = this.currentVehicle[key];

        if (key === 'updated') {
          return;
        }

        if (key === 'equipment') {
          const originChecked = originValue.filter(x => !originalValue.includes(x));
          const originUnchecked = originalValue.filter(x => !originValue.includes(x));
          const currentChecked = currentValue.filter(x => !originalValue.includes(x));
          const currentUnchecked = originalValue.filter(x => !currentValue.includes(x));

          if (
            !originChecked.length
            && !originUnchecked.length
            && !currentChecked.length
            && !currentUnchecked.length
          ) {
            return;
          }

          diff[key] = {
            type: 'checkableArray',
            originValue: { checked: originChecked, unchecked: originUnchecked },
            currentValue: { checked: currentChecked, unchecked: currentUnchecked },
            resolve: 'originValue',
          };

          return;
        }

        // TODO: Resolve arrays and objects
        if (currentValue instanceof Object) {
          console.log({
            key,
            originValue,
            originalValue,
            currentValue,
          });
          return;
        }

        if (originalValue != originValue || originalValue != currentValue) {
          diff[key] = {
            originValue,
            currentValue,
            resolve: originalValue != currentValue ? 'currentValue' : 'originValue',
          };
        }
      });

      this.diff = diff;
    },
    mapValue(value, key) {
      if (key.startsWith('is') || key.startsWith('has') || [
        'particulateFilter',
      ].includes(key)) {
        return value == 1 ? 'Ja' : 'Nein';
      }

      if (key === 'equipment') {
        const checked = value.checked.map((v) => {
          const m = this.values_equipment.find(e => e.value === v);
          return m && m.label || value;
        });
        const unchecked = value.unchecked.map((v) => {
          const m = this.values_equipment.find(e => e.value === v);
          return m && m.label || value;
        });
        return `${checked.concat(unchecked.map(v => `<s>${v}</s>`)).join(',&nbsp;')}`;
      }

      if (this[`values_${key}`]) {
        const m = this[`values_${key}`].find(e => e.value === value);
        return m && m.label || value;
      }

      if (value == parseFloat(value)) {
        return numberWithPoints(value);
      }

      const momentValue = moment(value);
      if (momentValue.isValid()) {
        return momentValue.format('DD.MM.YYYY');
      }

      return value;
    },
    async resolveConflict() {
      const update = {};

      Object.keys(this.diff).forEach((key) => {
        update[key] = ({
          originValue: this.originVehicle[key],
          currentValue: this.currentVehicle[key],
        })[this.diff[key].resolve];
      });

      update.updated = this.originVehicle.updated;

      this.hide({ update });
      this.$notifyUserInteraction.success('Der Konflikt wurde aufgelöst, Sie können das Fahrzeug nun speichern.');
    },
  },
};
</script>
