<template>
    <q-btn
        flat
        size="22px"
        class="q-mr-lg profilBtn no-shadow"
        :color="profilColor"
        @mouseover.native="profilColor = 'primary'"
        @mouseout.native="profilColor = 'grey-5'"
        no-ripple
        icon="account_circle"
    >
        <q-popover class="popover">
            <q-list separator class="q-py-none" >
                <q-item class="q-py-md">
                    <q-item-side>
                        <div class="bg-primary drawer-account-avatar text-white q-pa-sm">
                          <template v-if="credentials">{{ credentials }}</template>
                          <template v-else><q-icon name="person" size="28px"></q-icon></template>
                        </div>
                    </q-item-side>
                    <q-item-main>
                    <span data-empty="Kein Name angegeben">{{ currentUser | formatName }}</span><br>
                    <small>{{ currentUser.email }}</small>
                    </q-item-main>
                </q-item>
                <q-item class="q-py-md q-px-lg" link to="/profile-settings">
                  <q-item-side>
                    <q-icon name="person" color="blue-grey-8" size="25px" />
                  </q-item-side>
                  <q-item-main>
                    <q-item-tile label text-color="grey-8"><small>Profil verwalten</small></q-item-tile>
                  </q-item-main>
                </q-item>
                <template v-if="$store.getters['auth/alDealerOwner']">
                  <q-item class="q-py-md q-px-lg" link to="/branch">
                    <q-item-side>
                        <q-icon name="business" color="blue-grey-8" size="25px" />
                    </q-item-side>
                    <q-item-main>
                        <q-item-tile label text-color="grey-8"><small>Filialen verwalten</small></q-item-tile>
                    </q-item-main>
                  </q-item>
                </template>
                <template v-if="$store.getters['auth/alBranchOwner']">
                  <q-item class="q-py-md q-px-lg" link to="/user">
                    <q-item-side>
                        <q-icon name="group" color="blue-grey-8" size="25px" />
                    </q-item-side>
                    <q-item-main>
                        <q-item-tile label text-color="grey-8"><small>Mitarbeiter verwalten</small></q-item-tile>
                    </q-item-main>
                  </q-item>
                </template>
                <q-item class="q-py-md q-px-lg" link @click.native="openFeedback()">
                    <q-item-side>
                        <q-icon name="feedback" color="blue-grey-8" size="25px" />
                    </q-item-side>
                    <q-item-main>
                        <q-item-tile label text-color="grey-8"><small>Feedback einreichen</small></q-item-tile>
                    </q-item-main>
                </q-item>
                <q-item class="q-py-md q-px-lg" link @click.native="openHelp()">
                    <q-item-side>
                        <q-icon name="help_outline" color="blue-grey-8" size="25px" />
                    </q-item-side>
                    <q-item-main>
                        <q-item-tile label text-color="grey-8"><small>Hilfe/FAQ</small></q-item-tile>
                    </q-item-main>
                </q-item>
                <q-item class="q-py-sm bg-grey-11" @click.native="logout()" v-if="$app.whitelabel !== 'eln'">
                    <div class="full-width">
                        <q-btn label="Abmelden" size="sm" class="text-grey-8 no-shadow float-right bg-white btn-logout" no-ripple />
                    </div>
                </q-item>
            </q-list>
        </q-popover>
    </q-btn>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      profilColor: 'grey-5',
    };
  },
  computed: {
    ...mapState('auth', ['currentUser', 'currentBranch']),
    credentials() {
      if (!this.currentUser) return '';
      return (this.currentUser.firstName ? this.currentUser.firstName.charAt(0) : '') +
            (this.currentUser.lastName ? this.currentUser.lastName.charAt(0) : '');
    },
  },
  mounted() {
    window._urq = window._urq || [];
    window._urq.push(['initSite', '46441490-e9a6-4451-99b6-a473d273e331']);
    const ur = document.createElement('script');
    ur.type = 'text/javascript';
    ur.async = true;
    ur.src = (document.location.protocol === 'https:' ? 'https://cdn.userreport.com/userreport.js' : 'http://cdn.userreport.com/userreport.js');
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ur, s);
  },
  methods: {
    openHelp() {
      window.open('https://help.autengo.com/de/knowledge/fahrzeug-manager');
    },
    openFeedback() {
      window._urq.push(['Feedback_Open']);
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style scoped>
.popover {
  min-width: 280px;
  z-index: 9999;
}
.drawer-account-avatar {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.btn-logout {
  border: 1px #ccc solid;
}
.btn-logout:hover {
  background-color: white!important;
  color: #707077!important
}
</style>
