import axios from 'axios';
import _ from 'lodash';

export const initialState = () => ({
  queryParams: {
    limit: 10,
    offset: 0,
  },
  customerList: [],
  pagination: {
    filteredCount: 0,
    totalCount: 0,
  },
  tableProperties: {
    serverPagination: {
      page: 1,
      rowsPerPage: 10, // change init limit too
      rowsNumber: null,
      sortBy: null,
      descending: false,
    },
    search: '',
  },
  loading: true,
  columns: [
    {
      name: 'typ',
      field: 'typ',
      required: true,
      label: 'Typ',
      align: 'left',
      sortable: true,
    },
    {
      name: 'Name',
      field: 'Name',
      label: 'Name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'companyName',
      field: 'companyName',
      label: 'Typ',
      align: 'left',
      sortable: true,
    },
    {
      name: 'streetAddress',
      field: 'streetAddress',
      label: 'Ort',
      align: 'left',
      sortable: true,
    },
    {
      name: 'zipCode',
      field: 'zipCode',
      label: 'Informationen',
      align: 'left',
      sortable: true,
    },
    {
      name: 'actions',
      field: 'actions',
      label: 'Aktionen',
      align: 'left',
      sortable: true,
    },
  ],

});

export const mutations = {
  SET_CUSTOMER_LIST(state, newValue) {
    state.customerList = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.pagination.filteredCount = filteredCount;
    state.pagination.totalCount = totalCount;
  },
  SET_CUSTOMER_PAGINATION_ROWS_NUMBER(state, newValue) {
    state.serverPagination.rowsNumber = newValue;
  },
  SET_CUSTOMER_PAGINATION(state, pagination) {
    // ToDo add limit and offset to queryParam
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.serverPagination = pagination;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

};

async function getAllCustomers(filter) {
  let urlParams = { limit: 10 };
  if (filter) {
    urlParams = Object.assign(urlParams, filter);
  }
  return axios.get('v2/contact', {
    params: urlParams,
  });
}

export const actions = {

  async getCustomerWithEmail({ commit }, params = {}) {
    const {
      data: {
        data: customerList,
        ...pagination
      },
    } = await getAllCustomers(_.merge(params, { filter: { email: { isNull: false } } }));

    commit('SET_CUSTOMER_LIST', customerList);
    commit('SET_PAGINATION', {
      filteredCount: pagination.recordsFiltered,
      totalCount: pagination.recordsTotal,
    });
    return customerList;
  },

  async getAllCustomers({ commit }, params = {}) {
    const {
      data: {
        data: customerList,
        ...pagination
      },
    } = await getAllCustomers(params);
    commit('SET_CUSTOMER_LIST', customerList);
    commit('SET_PAGINATION', {
      filteredCount: pagination.recordsFiltered,
      totalCount: pagination.recordsTotal,
    });
    return customerList;
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
