<template>
  <q-card
          flat
          class="full-height column relative-position"
          :class="{ 'light-dimmed no-shadow': currentBranch.mobileDeAuthorized }">
    <q-card-title>
      <div class="text-weight-medium flex items-center">
        <img src="@/assets/mobileAppLogo.png" height="25" />
        <div class="q-ml-xs">mobile.de Verbindung</div>
      </div>
      <span slot="subtitle">Verbinde Deinen mobile.de-Account, um Fahrzeuge auf der Plattform zu inserieren.</span>
    </q-card-title>
    <q-card-main class="q-pb-xs col">
      <q-input
               v-if="!currentBranch.mobileDeId"
               type="text"
               float-label="Kundennummer eingeben"
               v-model.trim="mobileDeId"
               class="input-field" />
    </q-card-main>
    <q-card-actions align="end" class="col-auto">
      <q-btn
             v-if="currentBranch.mobileDeAuthorized"
             color="positive"
             label="Verbunden"
             no-caps
             dense
             class="no-shadow no-pointer-events" />
      <q-btn
             v-else-if="currentBranch.mobileDeId"
             color="faded"
             label="Freischaltung beantragt"
             outline
             no-caps
             dense
             class="no-shadow no-pointer-events" />
      <q-btn
             v-else
             color="primary"
             outline
             no-caps
             dense
             @click="unlock"
             :disabled="!mobileDeId">
        <q-spinner class="on-left" v-if="isUnlocking" />Verbinden
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MobileDeSetup',
  data() {
    return {
      mobileDeId: '',
      isUnlocking: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentBranch']),
  },
  mounted() {
    if (this.currentBranch.mobileDeId) this.mobileDeId = this.currentBranch.mobileDeId;
  },
  methods: {
    async unlock() {
      const timeout = setTimeout(() => { this.isUnlocking = true; }, 1000);
      await this.$store.dispatch('branch/updateBranch', {
        mobileDeId: this.mobileDeId,
      });
      this.$store.dispatch('branch/requestMobileDeActivation');
      this.$notifyUserInteraction.success('Firmeninformationen gespeichert!');
      clearTimeout(timeout);
      this.isUnlocking = false;
    },
  },
};
</script>

