import axios from 'axios';

export const initialState = {
  dealerDetail: {},
  dealerDetailLoaded: false,
  dealerSaving: false,
  dealerSavingSuccess: false,
  dealerDeleteSuccess: false,
};

export const mutations = {
  SET_DETAIL(state, newValue) {
    state.dealerDetail = newValue;
    state.dealerDetailLoaded = true;
  },
  RESET_DEALER(state) {
    Object.keys(state).forEach((attr) => { state[attr] = initialState[attr]; });
  },
  SET_SAVING(state, newValue) {
    state.dealerSaving = newValue;
  },
  SET_SAVING_SUCCESS(state, newValue) {
    state.dealerSavingSuccess = newValue;
  },
  SET_DELETE_SUCCESS(state, newValue) {
    state.dealerDeleteSuccess = newValue;
  },
};

export const actions = {

  getDealerById({ commit, dispatch }, id) {
    return axios.get(`/v2/dealer/${id}`).then((res) => {
      dispatch('resetDealer');
      commit('SET_DETAIL', res.data.data);
    });
  },

  updateDealer({ commit }, { dealerId, update }) {
    commit('SET_SAVING', true);
    commit('SET_SAVING_SUCCESS', false);

    return axios.put(`/v2/dealer/${dealerId}`, update)
      .then((res) => {
        commit('SET_DETAIL', res.data.data);
        commit('SET_SAVING_SUCCESS', true);
        commit('SET_SAVING', false);
      })
      .catch(() => {
        commit('SET_SAVING_SUCCESS', false);
        commit('SET_SAVING', false);
      });
  },

  resetDealer({ commit }) {
    commit('RESET_DEALER');
  },

  removeDummyData() {
    return axios.delete('/v2/dealer/dummyData');
  },

  createDummyData() {
    return axios.post('/v2/dealer/dummyData');
  },

};


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
};
