import axios from 'axios';

export const initialState = () => ({
  organizationList: [],
});

export const mutations = {
  SET_ORGANIZATION_LIST(state, list) {
    state.organizationList = list;
  },
};

export const actions = {
  async getAllOrganizations({ commit }, data) {
    return axios.get('v2/contact/organization', data).then((res) => {
      commit('SET_ORGANIZATION_LIST', res.data.data);
      return res.data.data;
    });
  },
};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
