<template>
  <q-card
          flat
          class="full-height column relative-position"
          :class="{ 'light-dimmed no-shadow': currentBranch.newAs24Authorized }">
    <q-card-title>
      <div class="text-weight-medium flex items-center">
        <img src="@/assets/autoscout24_app_logo.png" height="25" />
        <div class="q-ml-xs">AutoScout24 Verbindung</div>
      </div>
      <span slot="subtitle">Verbinde Deinen AutoScout24-Account, um Fahrzeuge auf der Plattform zu inserieren.</span>
      <div slot="right" class="row items-center">
        <q-icon name="info" />
        <q-tooltip>
          Die Freischaltung kann bis zu 2 Werktage dauern. <br> Sie werden
          benachrichtigt sobald die Verbindung aktiv ist. <br> Sollten Sie bereits eine
          Software verwenden, <br> die mit mobile.de oder AutoScout24 verbunden ist,<br>
          wird die Verbindung getrennt.
        </q-tooltip>
      </div>
    </q-card-title>

    <q-card-main class="q-pb-xs col">
      <q-input
               v-if="!currentBranch.as24Id"
               type="text"
               float-label="Kundennummer eingeben"
               v-model.trim="as24Id"
               class="input-field" />
    </q-card-main>
    <q-card-actions align="end" class="col-auto">
      <q-btn
             v-if="currentBranch.newAs24Authorized"
             color="positive"
             label="Verbunden"
             no-caps
             dense
             class="no-shadow no-pointer-events" />
      <q-btn
             v-else-if="currentBranch.as24Id"
             color="faded"
             label="Freischaltung beantragt"
             outline
             no-caps
             dense
             class="no-shadow no-pointer-events" />
      <q-btn
             v-else
             color="primary"
             outline
             no-caps
             dense
             @click="unlock"
             :disabled="!as24Id || loading">
        <q-spinner class="on-left" v-if="loading" />Verbinden
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'As24Setup',
  data() {
    return {
      as24Id: '',
      loading: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentBranch']),
  },
  mounted() {
    if (this.currentBranch.as24Id) this.as24Id = this.currentBranch.as24Id;
  },
  methods: {
    async unlock() {
      this.loading = true;
      await this.$store.dispatch('branch/updateBranch', {
        as24Id: this.as24Id,
      });
      await this.$store.dispatch('branch/requestas24Activation');
      this.$notifyUserInteraction.success('Firmeninformationen gespeichert!');
      this.loading = false;
    },
  },
};
</script>
