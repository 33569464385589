<template>
  <div class="row full-width">
    <div
      v-if="!loadingEmail && emailMessageIsEmpty"
      class="text-faded text-italic q-subheading"
    >
      Diese E-Mail enthält keine Nachricht.
    </div>
    <q-spinner-dots v-if="loadingEmail" size="40px" color="primary" />
    <div ref="emailContainer" v-html="html" class="full-width email-content" />
  </div>
</template>

<script>
import _ from 'lodash';
import juice from 'juice';

export default {
  name: 'AEmail',
  props: {
    message: {
      type: Object,
      required: true,
    },
    sanitize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      html: '',
      loadingEmail: true,
    };
  },
  computed: {
    emailMessageIsEmpty() {
      if (!this.loadingEmail) {
        return this.html.trim() === '';
      }
      return false;
    },
  },
  methods: {
    async inlineAttachedImages(message) {
      const inlineImages = (this.message.attachments || []).filter(file => file.isInline);

      if (!inlineImages || inlineImages.length === 0) {
        return message;
      }

      // max 10 images to avoid rate limiting from nylas
      const inlineImagesPromises = inlineImages.slice(0, 10).map(async (file) => {
        const imgBase64 = await this.$store.dispatch('emailThreadDetail/getEmailFileBase64', {
          file,
          messageId: this.message.id,
        });

        const imgSrc = `cid:${file.contentId}`;
        return { imgSrc, base64: imgBase64.base64 };
      });

      const inlineImagesResults = await Promise.all(inlineImagesPromises);

      inlineImagesResults.forEach(({ imgSrc, base64 }) => {
        message = message.replace(new RegExp(_.escapeRegExp(imgSrc), 'g'), base64);
      });

      return message;
    },

  },

  // else will be the mail from our db which is already sanitized and has already inline images replaced by dam urls
  async mounted() {
    if (this.sanitize) {
      const inlineStyledBody = juice(this.message.body);
      const sanitizedBody = this.$sanitize(inlineStyledBody);
      const sanitized = await this.inlineAttachedImages(sanitizedBody);
      this.html = sanitized.replace(/&nbsp;/g, ' ');
    } else {
      this.html = this.message.body;
    }
    this.loadingEmail = false;
  },

  watch: {
    'message.attachments': {
      async handler() {
        const sanitized = await this.inlineAttachedImages(this.$sanitize(juice(this.message.body)));
        this.html = sanitized.replace(/&nbsp;/g, ' ');
      },
      deep: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .email-content pre {
  white-space: pre-wrap;
}
</style>
