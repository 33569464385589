<template>
  <div
      class="image relative-position inline-block h-full"
      :style="{width: width + 'px'}"
  >
    <div v-if="!image.loading" class="h-full">
      <img :src="image.ref ? $getFileUrl(image.ref, width) : image"
           @click="$emit('clicked', { image, index: imageIndex })"
           :style="{ maxHeight: maxHeight + 'px' }"
           @load="$emit('load')"
           :class="{ select }"
           class="h-full"
      >
      <q-btn v-if="removeAble"
             icon="close"
             round
             color="grey"
             class="remove-button "
             size="xs"
             @click="$emit('remove', { image, index: imageIndex })" />
    </div>
    <div v-else :style="{ height: maxHeight + 'px' }" class="flex justify-center items-center">
      <q-spinner size="3rem" color="primary" />
    </div>

    <div v-if="showTitleOverlay" class="overlay text-white">
      <div class="q-py-xs q-px-sm">
        <slot></slot>
      </div>
    </div>
    <q-icon v-if="select" name="check_circle" color="primary" class="absolute-top-right" size="1.3rem" />
  </div>
</template>

<script>
import { calculateWidth } from '@/plugins/util';

export default {
  name: 'AImage',
  props: {
    image: {
      required: true,
    },
    imageIndex: Number,
    animate: {
      type: Boolean,
      default: false,
    },
    showTitleOverlay: {
      type: Boolean,
      default: false,
    },
    removeAble: {
      type: Boolean,
      default: true,
    },
    // possible values: sm | md | lg
    size: {
      type: String,
      default: 'sm',
    },
    select: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maxHeight() {
      const heights = {
        sm: 100,
        md: 150,
        lg: 200,
        default: 100,
      };
      return heights[this.size] || heights.default;
    },
    width() {
      return calculateWidth(this.maxHeight, 4, 3);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import theme

$borderRadius=2px;

.h-full {
  height: 100%;
}

img {
  border-radius: $borderRadius;
  width: 100%;
  object-fit: cover;
}
.image {
  .remove-button {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 500;
    opacity: .4;
    font-size: 2.2rem;
  }
}
>>> .q-btn .q-icon {
  font-size: 2.2em;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  z-index: 499;
}
.select {
  outline: 3px solid $primary;
}
</style>
