import uuid from 'uuid';
import moment from 'moment';
import Store from '../index.js';

const INACTIVE_TIMEOUT = moment.duration(30, 'minutes');

// eslint-disable-next-line no-underscore-dangle
const _privateState = {
  socket: { send() {} },
  lastAction: moment(localStorage.lastAction || undefined),
};

if (!localStorage.sessionId) localStorage.sessionId = uuid();

const state = () => ({
  sessionId: localStorage.sessionId,
});

const mutations = {
  SET_SESSION_ID(state, id) {
    state.sessionId = id;
    localStorage.sessionId = id;
  },
};

const actions = {
  async init({ dispatch }, socket) {
    _privateState.socket = socket;
    const sessionId = await dispatch('ping');

    dispatch('createSession', sessionId);
    dispatch('trackAction', {
      sessionId,
      action: 'LOAD',
    });
  },
  async track({ dispatch }, action) {
    const sessionId = await dispatch('ping');

    if (action.data instanceof Error) {
      const { message, stack, ...data } = action.data;
      action.data = { message, stack, ...data };
    }

    dispatch('trackAction', {
      sessionId,
      ...action,
    });
  },

  // private functions
  async createSession({ rootState }, sessionId) {
    _privateState.socket.send('session', 'createSession', {
      sessionId,
      userId: rootState.auth.currentUser.ID,
      branchId: rootState.auth.currentBranch.ID,
      dealerId: rootState.auth.currentDealer.ID,
      language: window.navigator.language,
      platform: window.navigator.platform,
      userAgent: window.navigator.userAgent,
      screenResolution: {
        width: window.screen.width,
        height: window.screen.height,
      },
    });
  },
  async trackAction({ rootState }, action) {
    _privateState.socket.send('session', 'trackAction', {
      userId: rootState.auth.currentUser.ID,
      branchId: rootState.auth.currentBranch.ID,
      dealerId: rootState.auth.currentDealer.ID,
      ...action,
    });
  },
  ping({ commit, state, dispatch }) {
    let { sessionId } = state;
    if (moment().subtract(INACTIVE_TIMEOUT).isAfter(_privateState.lastAction)) {
      const oldSessionId = sessionId;
      sessionId = uuid();

      commit('SET_SESSION_ID', sessionId);
      dispatch('createSession', sessionId);
      dispatch('trackAction', {
        sessionId,
        action: 'RESET',
        data: {
          old: oldSessionId,
          new: sessionId,
        },
      });
    }
    _privateState.lastAction = moment();
    return sessionId;
  },
};

window.addEventListener('mousemove', () => {
  Store.dispatch('session/ping');
});

window.addEventListener('beforeunload', () => {
  localStorage.lastAction = _privateState.lastAction.toISOString();
  Store.dispatch('session/track', {
    action: 'UNLOAD',
  });
});

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
