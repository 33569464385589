import axios from 'axios';
import Vue from 'vue';

const notify = new Vue();

export const initialState = {
  branchDetail: {},
  branchDetailLoaded: false,
  nextInvoiceNumber: 1,
  plattform: {
    1: 'as24Import',
    2: 'mobileDeImport',
  },
  plattformNames: {
    1: 'Autoscout24',
    2: 'Mobile.de',
  },
  as24Import: {
    providerId: 1,
    isPublished: 1,
    progress: 0,
    actionStarted: false,
    loading: false,
    loadingMessage: '',
  },
  as24Connected: null,
};

export const mutations = {
  SET_DETAIL(state, newValue) {
    state.branchDetail = newValue;
    state.branchDetailLoaded = true;
  },
  RESET_BRANCH(state) {
    Object.keys(state).forEach((attr) => { state[attr] = initialState[attr]; });
  },
  SET_NEXT_INVOICE_NUMBER(state, nextInvoiceNumber) {
    state.nextInvoiceNumber = nextInvoiceNumber;
  },
  SET_SHOW_WELCOME_WINDOW(state, flag) {
    state.showWelcomeWindow = flag;
  },
  RESET_AS24IMPORT(state) {
    state.as24Import = initialState.as24Import;
  },
  SET_IMPORT_PROGRESS(state, { providerId, progress }) {
    const plattform = state.plattform[providerId];
    Object.keys(progress).forEach((key) => {
      state[plattform][key] = progress[key];
    });
  },
  SET_AS24_CONNECTION_STATUS(state, isConnected) {
    state.as24Connected = isConnected;
  },
};

export const actions = {

  async setDocumentParams({ commit, dispatch }, { branchId, params }) {
    await dispatch('checkBranchId', branchId);
    const res = await axios.put(`/v2/branch/${branchId}`, params);
    commit('SET_DETAIL', res.data.data);

    return res.data.data;
  },

  async updateBranch({ commit, rootState, dispatch }, data) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    const res = await axios.put(`/v2/branch/${rootState.auth.currentBranch.ID}`, data);
    commit('SET_DETAIL', res.data.data);
    commit('auth/SET_CURRENT_BRANCH', { ...rootState.auth.currentBranch, ...res.data.data }, { root: true });
    return res.data.data;
  },

  async updatePlattformCustomerNumbers({ dispatch }, branch) {
    await dispatch('checkBranchId', branch.ID);
    const platformCustomerNumbers = (({ mobileDeId, as24Id }) => ({ mobileDeId, as24Id }))(branch);

    await axios.put(`/v2/branch/${branch.ID}/platformCustomerNumbers`, platformCustomerNumbers);
  },

  async changeWelcomeWindowStep({ rootState, dispatch }, step) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    const branchId = rootState.auth.currentBranch.ID;
    await axios.post(`/v2/branch/${branchId}/welcomeWindow`, step);
  },

  async setupFacebook({ commit, dispatch }, { branchId, object }) {
    await dispatch('checkBranchId', branchId);
    return axios.put(`/v2/branch/${branchId}/facebook`, object)
      .then((res) => {
        commit('SET_DETAIL', res.data.data);
      });
  },

  resetBranch({ commit }) {
    commit('RESET_BRANCH');
  },

  async uploadLogo({ commit, rootState, dispatch }, { ID, logo }) {
    await dispatch('checkBranchId', ID);
    const data = new FormData();
    data.append('file', logo);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const branch = await axios.post(`/v2/branch/${ID}/logo`, data, { headers }).then(res => res.data.data);
    commit('auth/SET_CURRENT_BRANCH', { ...rootState.auth.currentBranch, logoRef: branch.logoRef }, { root: true });
  },

  async deleteLogo({ commit, rootState, dispatch }, id) {
    await dispatch('checkBranchId', id);
    const branch = await axios.delete(`/v2/branch/${id}/logo`).then(res => res.data.data);
    commit('auth/SET_CURRENT_BRANCH', { ...rootState.auth.currentBranch, logoRef: branch.logoRef }, { root: true });
  },

  async getNextInvoiceNumber({ commit, rootState, dispatch }) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    const nextInvoiceNumber = await axios.get(`/v2/branch/${rootState.auth.currentBranch.ID}/nextInvoiceNumber`).then(res => res.data.data);
    commit('SET_NEXT_INVOICE_NUMBER', nextInvoiceNumber);
  },

  async shouldShowWelcomeWindow({ commit, rootState, dispatch }) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    const { showWelcomeWindow } = await axios.get(`/v2/branch/${rootState.auth.currentBranch.ID}/welcomeWindow`).then(res => res.data);
    commit('SET_SHOW_WELCOME_WINDOW', showWelcomeWindow);
    return showWelcomeWindow;
  },

  async neverShowWelcomeWindowAgain({ commit, rootState, dispatch }) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    await axios.delete(`/v2/branch/${rootState.auth.currentBranch.ID}/welcomeWindow`);
    commit('SET_SHOW_WELCOME_WINDOW', false);
  },
  async startAs24Import({
    commit, state, rootState, rootGetters, dispatch,
  }) {
    await dispatch('checkBranchId', rootState.auth.currentBranch.ID);
    const socketId = rootGetters['socket/getSocketId'];

    let providerName;
    if (state.as24Import.providerId === 2) {
      providerName = 'mobile.de';
    } else {
      providerName = 'Autoscout24.de';
    }

    commit('SET_IMPORT_PROGRESS', {
      providerId: state.as24Import.providerId,
      progress: { actionStarted: true },
    });

    return axios.post(`/v2/branch/${rootState.auth.currentBranch.ID}/importAds`, { socketId })
      .then(() => {
        commit('SET_IMPORT_PROGRESS', {
          providerId: state.as24Import.providerId,
          progress: {
            loading: true,
            progress: 0,
            loadingMessage: `Starte Import von ${providerName}`,
          },
        });
      })
      .catch(() => {
        commit('SET_IMPORT_PROGRESS', {
          providerId: state.as24Import.providerId,
          progress: { loading: false },
        });
      });
  },

  handleImportResult({ dispatch, commit }, message) {
    let providerName;
    if (message.providerId === 2) { providerName = 'mobile.de'; } else { providerName = 'AutoScout24.de'; }
    if (message.status === 'success') {
      notify.$notifyUserInteraction.success(`Import von ${providerName} erfolgreich erstellt`);
      commit('SET_IMPORT_PROGRESS', {
        providerId: (message.providerId === 2 ? 2 : 1),
        progress: { loading: false },
      });
    }
    if (message.status === 'progress') dispatch('handleImportProgress', message);
    if (message.status === 'error') {
      dispatch('handleImportProgress', message);
      notify.$notifyUserInteraction.error(`Import von ${providerName} fehlgeschlagen`);
      commit('SET_IMPORT_PROGRESS', {
        providerId: (message.providerId === 2 ? 2 : 1),
        progress: { loading: false },
      });
    }
  },

  handleImportProgress({ commit }, message) {
    commit('SET_IMPORT_PROGRESS', message);
  },

  async requestMobileDeActivation({ rootState, dispatch }, branchId = null) {
    const branch = branchId || rootState.auth.currentBranch.ID;
    await dispatch('checkBranchId', branch);
    return axios.get(`/v2/branch/${branch}/activation/mobileDe`);
  },

  async requestas24Activation({ rootState, dispatch }, branchId = null) {
    const branch = branchId || rootState.auth.currentBranch.ID;
    await dispatch('checkBranchId', branch);
    return axios.get(`/v2/branch/${branch}/activation/as24`);
  },

  async refreshPlatformAuth({ dispatch }, { branchId, platform }) {
    await dispatch('checkBranchId', branchId);
    const { data } = await axios.get(`/v2/branch/${branchId}/refresh/${platform}`);

    const isPlatformAuthenticated = !!data;
    return isPlatformAuthenticated;
  },

  getAs24ConnectionStatus({ commit, rootState }) {
    if (rootState.auth.currentBranch.as24Id) {
      return axios.get(`/v2/branch/${rootState.auth.currentBranch.ID}/as24`)
        .then((res) => {
          const { isConnected } = res.data;
          commit('SET_AS24_CONNECTION_STATUS', isConnected);
          return isConnected;
        });
    }
    return null;
  },

  async checkBranchId({ rootGetters }, branchId) {
    if (branchId) return;

    if (
      rootGetters['auth/alDealerOwner']
    ) {
      notify.$notifyUserInteraction.error('Bitte wählen Sie eine Filiale aus!');
    } else {
      notify.$notifyUserInteraction.error('Es ist ein unerwarteter Fehler aufgetreten (Fehlercode: 22300)');
    }

    throw new Error('noBranchSet');
  },

};


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
};
