<template>
  <q-chips-input
      :value="inputModel"
      v-bind="$attrs"
      ref="chipsInput"
      @add="transformInput"
      @remove="remove"
      @input="resetSelection"
      hide-underline
      @blur="setOnBlur"
      chips-color="black"
      chips-bg-color="gray-14"
  >
    <q-autocomplete
        @search="search"
        @selected="setValue"
        :max-results="9999"
        :min-characters="openSuggestionsOnFocus ? 0 : 1"
        ref="autocomplete"
        @input="resetSelection"
    />
  </q-chips-input>
</template>

<script>
import _ from 'lodash';
import { getCustomerInitials, orderBy } from '@/plugins/util';
import { mapActions } from 'vuex';

export default {
  name: 'AMailChipsInput',
  props: {
    value: {
      required: true,
    },
    openSuggestionsOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSuggestions: [],
      currentSelection: null,
    };
  },
  computed: {
    selectedEmails() {
      return this.value.map(el => el.email); 
    },
    inputModel() {
      return this.value.map(el => (el.name ? `${el.name} <${el.email.trim()}>` : el.email.trim())); 
    },
  },
  methods: {
    ...mapActions('customerList', ['getCustomerWithEmail']),
    remove({ index }) {
      this.$emit('input', this.value.filter((el, i) => i !== index));
    },
    transformInput({ val }) {
      if (_.isString(val)) {
        this.$emit('input', [...this.value, { email: val, name: '' }]);
      }
    },
    resetSelection() {
      this.currentSelection = null;
    },
    async search(terms, done) {
      const customers = await this.getCustomerWithEmail({
        limit: 8,
        search: terms,
        filter: {
          email: {
            notIn: this.selectedEmails,
          },
        },
      });

      const newSuggestions = customers.map((customer) => {
        const { name } = customer;
        const initials = getCustomerInitials(customer);

        return {
          label: (name || '').trim() || 'Kein Name',
          sublabel: customer.email,
          icon: customer.scope === 'organization' ? 'domain' : 'person',
          value: customer.ID,
          preprocessed: {
            name: name.trim(),
            email: customer.email ? customer.email.trim() : '',
          },
          leftInverted: true,
          initials,
        };
      });

      if (!_.isEqual(this.currentSuggestions.map(el => el.value), newSuggestions.map(el => el.value))) {
        this.currentSuggestions = newSuggestions;
      }

      done(newSuggestions);
    },
    filterAutocomplete(terms, list) {
      this.currentSelection = null;
      const token = terms.toLowerCase();
      const newSuggestions = orderBy(
        list
          .filter(item => !this.selectedEmails.includes(item.preprocessed.email))
          .filter(item => !!_.values(item)
            .find(value => _.isString(value) && value.toLowerCase().match(new RegExp(token)))),
        'initials',
        'asc',
      );

      if (!_.isEqual(this.currentSuggestions.map(el => el.value), newSuggestions.map(el => el.value))) {
        this.currentSuggestions = newSuggestions;
      }

      return newSuggestions;
    },
    setValue(item, keyboardNavigation = false) {
      if (keyboardNavigation) {
        this.currentSelection = item;
        this.$refs.chipsInput.input = item.label;
      } else {
        this.currentSelection = null;
        this.currentSuggestions = [];
        this.$emit('input', [...this.value, item.preprocessed]);
      }
    },
    setOnBlur() {
      if (this.$refs.chipsInput.input !== '') {
        if (this.currentSelection) {
          this.$emit('input', [...this.value, this.currentSelection.preprocessed]);
          this.currentSelection = null;
        } else {
          this.$emit('input', [...this.value, { email: this.$refs.chipsInput.input, name: '' }]);
        }

        this.$refs.chipsInput.input = '';
      }

      this.currentSuggestions = [];
    },
  },
  watch: {
    currentSuggestions(suggestions) {
      if (suggestions.length === 1) {
        this.$refs.chipsInput.$el.querySelector('.q-input-target').dispatchEvent(new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          shiftKey: false,
          keyCode: 40,
        }));
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> q.chip {
  font-weight: 400;
}
</style>
