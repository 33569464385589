<template>
  <q-modal
    v-model="opened"
    class="relative-position"
    :class="!maximized ? 'q-pt-xs' : ''"
    :position="position"
    :no-backdrop-dismiss="noBackdropDismiss"
    :no-esc-dismiss="noEscDismiss"
    :maximized="maximized"
    :minimized="minimized"
    :content-classes="maximized ? 'full-height' : ''"
    :content-css="computedCss"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <q-modal-layout>
      <div
        class="row justify-between items-center bg-grey-1 q-pa-md header"
        :class="hasFixedTitle ? 'full-width fixed' : ''"
        slot="header"
      >
        <div class="q-title text-weight-regular text-ellipsis col">{{title}}</div>
        <q-icon
          v-if="!hideCloseButton"
          name="clear"
          color="grey"
          @click.native="opened = false; $emit('close')"
          class="cursor-pointer"
        />
        <div v-if="this.$slots.titleRight">
          <slot name="titleRight"/>
        </div>
      </div>
      <div
        class="q-pl-lg q-pb-lg q-pr-lg q-pt-md"
        :style="'margin-top:' + (upperSpacing || 0) + 'px'"
      >
        <slot name="content"/>
      </div>
      <template v-if="useQuasarFooter">
        <div slot="footer">
          <div class="q-px-xs">
            <hr class="q-hr q-mt-xs">
          </div>
          <div class="q-py-sm q-px-md">
            <slot name="footer" />
          </div>
        </div>
      </template>
      <div v-else class="q-pl-lg q-pb-lg q-pr-lg q-pt-none">
        <slot name="footer" />
      </div>
    </q-modal-layout>
  </q-modal>
</template>

<script>
export default {
  name: 'AModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    maximized: {
      type: Boolean,
      default: false,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
    noBackdropDismiss: {
      type: Boolean,
      default: true,
    },
    noEscDismiss: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    hasFixedTitle: {
      type: Boolean,
      default: false,
    },
    upperSpacing: {
      type: Number,
      default: 0,
    },
    css: {
      type: Object,
    },
    useQuasarFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    opened: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    computedCss() {
      return {
        ...this.css,
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.header {
  border-bottom: 1px solid #eee;
  z-index: 10000;

  .q-icon {
    cursor: pointer;
    transition: color 0.5s ease;

    &:hover {
      color: #000 !important;
    }
  }
}
>>> .q-layout-footer, >>> .q-layout-header {
  box-shadow: none;
}
</style>
