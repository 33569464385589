<template>
  <div>
    <q-carousel no-swipe arrows infinite autoplay>
      <q-carousel-slide class="row items-center justify-center">
        <div class="text-center q-mt-md q-mb-sm q-display-1 text-weight-bolder">Mehr Reichweite für Ihren Bestand</div>
        <div class="text-center q-mb-xl q-body-1 text-grey-6">Automatisierter Export zu AutoScout24 und mobile.de</div>
        <img :src="facebook" style="max-height: 300px;" />
        <div class="text-center q-mt-md q-mb-sm text-dark q-sublabel text-weight-bold">Mit Präsenz auf Deutschlands größten Portalen erreichen
          Sie potentielle Käufer ideal.
        <br> Die Verwaltung aller Inserate findet zeitsparend, zentral und automatisiert statt.</div>
      </q-carousel-slide>
      <q-carousel-slide class="row items-center justify-center">
        <div class="text-center q-mt-md q-mb-sm q-display-1 text-weight-bolder">Alle laufenden Verkaufsprozesse im Blick</div>
        <div class="text-center q-mb-xl q-body-1 text-grey-6">Intuitives Anfragen-Management (CRM) und vollwertige E-Mail
          Integration gehen Hand in Hand</div>
        <img :src="crm" style="max-height: 300px;" />
        <div class="text-center q-mt-md q-mb-sm text-dark q-sublabel text-weight-bold">Eingehende Anfragen werden automatisch strukturiert.
          Sie haben Ihre gesamte
        Kundenkommunikation im Blick und können auf Nachrichten direkt antworten.</div>
      </q-carousel-slide>
      <q-carousel-slide class="row items-center justify-center">
        <div class="text-center q-mt-md q-mb-sm q-display-1 text-weight-bolder">Ihr Business flexibel von überall managen</div>
        <div class="text-center q-mb-xl q-body-1 text-grey-6">Die autengo Mobile-App ist die perfekte Ergänzung zur Software für
          Ihren Geschäftserfolg</div>
        <img :src="mobileApp" style="max-height: 300px;" />
        <div class="text-center q-mt-md q-mb-sm text-dark q-sublabel text-weight-bold">Von der Fahrzeugaufnahme bis zum Inserat
          managen Sie ihren Bestand mobil von überall.
         <br>Alle Kontakte und Anfragen sind immer dabei. Arbeiten so flexibel wie Sie es wünschen.</div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import crm from '@/assets/onboarding/crm.png';
import facebook from '@/assets/onboarding/facebook.png';
import mobileApp from '@/assets/onboarding/mobile-app.png';

export default {
  name: 'intro',
  data() {
    return {
      crm,
      facebook,
      mobileApp,
    };
  },
};
</script>

