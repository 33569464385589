/* eslint-disable */
import modalFn from 'quasar-framework/src/utils/modal-fn.js';

export default {
  install({ $q, Vue }) {
    this.create = $q.modal = (modal, props = {}, resolver = null) => {
      // Make $store available inside modal
      Vue.prototype.$store = document.getElementById('app').__vue__.$store;

      // Use quasar modal fn
      return modalFn(modal, Vue)(props, resolver);
    };
  },
};
