import * as config from '../../config';
import qs from 'qs';

const isProd = import.meta.env.PROD;
const isDev = import.meta.env.DEV;
const whitelabel = import.meta.env.VUE_APP_WHITELABEL;
const app = config[whitelabel];

const appConfig = {
  install: (Vue) => {
    Vue.prototype.$app = {
      ...app,
      whitelabel,
      env: {
        production: isProd,
        development: isDev,
      },
    };
    Vue.prototype.$getFileUrl = (ref, width, options = {}) => {
      if (ref?.includes('://')) return ref;
      if (!ref) return `${import.meta.env.VUE_APP_DAM_URL}/file/noref`;
      if (width === 'raw') width = undefined;
      if (width) width = parseInt(width, 10);

      const { disposition } = options;

      // Use own dam solution
      if (ref.length === 36) {
        const params = qs.stringify({
          width,
          disposition,
        });

        const url = `${import.meta.env.VUE_APP_DAM_URL}/file/${ref}`;
        return params ? `${url}?${params}` : url;
      }

      // Use cloudinary
      const url = 'https://res.cloudinary.com/carpeero16/image/upload';
      return width ? `${url}/a_exif,w_${width}/${ref}.jpg` : `${url}/${ref}`;
    };
    Vue.prototype.$cloudinaryRef = {
      raw: 'https://res.cloudinary.com/carpeero16/image/upload/',
      w250: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,w_250/',
      h100: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,h_100/',
      h150: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,h_150/',
      h200: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,h_200/',
      w500: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,w_500,c_limit/',
      w1200: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,w_1200,c_limit/',
      w200h150: 'https://res.cloudinary.com/carpeero16/image/upload/a_exif,w_200,h_150,c_fill/',
    };
    Vue.prototype.$serverRef
      = (ref, name = '', resourceType) => `${import.meta.env.VUE_APP_API_URL}/v2/cloudinary/${ref}/${name}${resourceType ? `?resourceType=${resourceType}` : ''}`;
    Vue.prototype.$cloudinary = {
      excludedFileExtensions: [
        'action', 'apk', 'app', 'bat', 'bin', 'cmd', 'com', 'command', 'cpl', 'csh', 'exe', 'gadget', 'inf1', 'ins', 'inx', 'ipa', 'isu', 'job',
        'jse', 'ksh', 'lnk', 'msc', 'msi', 'msp', 'mst', 'osx', 'out', 'paf', 'pif', 'prg', 'ps1', 'reg', 'rgs', 'run', 'sct', 'shb', 'shs', 'u3p',
        'vb', 'vbe', 'vbs', 'vbscript', 'workflow', 'ws', 'wsf',
      ],
    };
    Vue.prototype.$downloadFile = function(url) {
      url = this.$getFileUrl(url, 'raw', {
        disposition: 'attachment',
      });

      const link = document.createElement('a');
      link.href = url;
      link.dispatchEvent(new MouseEvent('click'));
    };
    Vue.prototype.$openFile = function(url) {
      url = this.$getFileUrl(url, 'raw', {
        disposition: 'inline',
      });

      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    };
  },
};

export default appConfig;
export { appConfig, app as appVars };
