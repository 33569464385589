const docTypes = {
  pdf: {
    label: '.PDF',
    icon: 'far fa-file-pdf',
  },
  'application/pdf': {
    label: '.PDF',
    icon: 'far fa-file-pdf',
  },
  'image/jpeg': {
    label: '.JPEG',
    icon: 'far fa-file-image',
  },
  'image/png': {
    label: '.PNG',
    icon: 'far fa-file-image',
  },
  default: {
    label: 'N/A',
    icon: 'far fa-file',
  },
};
export default docTypes;
