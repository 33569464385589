import axios from 'axios';
import _ from 'lodash';
import { reactiveSet } from '@/plugins/util';

const initialState = () => ({
  documents: [],
  lastUploadedDocuments: [],
  document: null,
  documentUploadProgress: {},
});

export const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },

  APPEND_DOCUMENTS(state, documents) {
    state.documents = state.documents.concat(documents);
  },

  SET_LAST_UPLOADED_DOCUMENTS(state, lastUploadedDocuments) {
    state.lastUploadedDocuments = lastUploadedDocuments;
  },

  APPEND_LAST_UPLOADED_DOCUMENTS(state, documents) {
    state.lastUploadedDocuments = state.lastUploadedDocuments.concat(documents);
  },

  SET_DOCUMENT(state, document) {
    state.document = document;
  },

  SET_DOCUMENT_UPLOAD_PROGRESS(state, { id, progress }) {
    reactiveSet(state.documentUploadProgress, id, progress);
  },

  RESET_DOCUMENT_UPLOAD_PROGRESS(state, id) {
    state.documentUploadProgress = _.omit(state.documentUploadProgress, id);
  },

  UPDATE_DOCUMENT_IN_LISTS(state, document) {
    updateInList(state, document, 'documents');
    updateInList(state, document, 'lastUploadedDocuments');
  },

  REMOVE_DOCUMENT_FROM_LISTS(state, document) {
    updateInList(state, document, 'documents', true);
    updateInList(state, document, 'lastUploadedDocuments', true);
  },
};

function updateInList(state, document, listName, remove = false) {
  const documentIndex = state[listName].findIndex(doc => doc.ID === document.ID);
  if (documentIndex >= 0) {
    if (remove) {
      state[listName].splice(documentIndex, 1);
    } else {
      state[listName].splice(documentIndex, 1, document);
    }
  }
}

function getDocuments({
  params, category, excludeCategory, filter, limit, offset, search,
} = {}) {
  const urlParams = {
    search: search || '',
    limit: limit || 10000,
    offset: offset || 0,
    ...(category ? { category } : {}),
    ...(excludeCategory ? { excludeCategory } : {}),
  };
  if (params) {
    Object.assign(urlParams, params);
  }
  if (filter) {
    Object.assign(urlParams, { filter });
  }

  return axios.get('/v2/document', {
    params: urlParams,
  });
}

export const actions = {

  async getRawDocuments(storeUtils, { params }) {
    return (await getDocuments(params)).data;
  },

  getDocuments({ commit }, { params = {}, append = false } = {}) {
    return getDocuments(params)
      .then((documents) => {
        const { data, ...pagination } = documents.data;
        if (append) {
          commit('APPEND_DOCUMENTS', data);
        } else {
          commit('SET_DOCUMENTS', data);
        }

        return {
          documents: data,
          pagination,
        };
      });
  },

  getLastUploadedDocuments({ commit }, { params = {}, append = false } = {}) {
    const paramsForLastUploaded = {
      filter: {
        templateId:
          {
            isNull: true,
          },
      },
      limit: params.limit || 20,
    };
    return getDocuments(_.merge({}, params, paramsForLastUploaded))
      .then((documents) => {
        const { data, ...pagination } = documents.data;
        if (append) {
          commit('APPEND_LAST_UPLOADED_DOCUMENTS', data);
        } else {
          commit('SET_LAST_UPLOADED_DOCUMENTS', data);
        }

        return {
          documents: data,
          pagination,
        };
      });
  },

  getDocument({ commit }, id) {
    return axios.get(`/v2/document/${id}`).then((document) => {
      commit('SET_DOCUMENT', document.data.data);
    });
  },

  createDocument({ commit, dispatch }, document) {
    return axios.post('/v2/document', document).then((createdDocument) => {
      commit('SET_DOCUMENT', createdDocument.data.data);
      dispatch('documentTable/notifyTables', {}, { root: true });

      return createdDocument.data.data;
    });
  },

  async uploadDocument({ commit }, document) {
    const documentData = new FormData();
    Object.keys(document)
      .filter(documentKey => document[documentKey])
      .forEach(documentKey => documentData.append(documentKey, document[documentKey]));

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const { data } = await axios.post('/v2/document/upload', documentData, { headers });
    commit('SET_DOCUMENT', data.data);
    return data.data;
  },

  uploadDocuments({ commit, dispatch }, {
    vehicleId, customerId, organizationId, filesWithId,
  }) {
    return Promise.all(filesWithId.map(({ file, id }) => {
      const data = new FormData();
      data.append('file', file);

      if (vehicleId) {
        data.append('vehicleId', vehicleId);
      }
      if (customerId) {
        data.append('customerId', customerId);
      }
      if (organizationId) {
        data.append('organizationId', organizationId);
      }

      return axios
        .post(
          '/v2/document/upload',
          data,
          {
            headers:
              { 'Content-type': 'multipart/form-data' },
            onUploadProgress(progressEvent) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              commit('SET_DOCUMENT_UPLOAD_PROGRESS', { id, progress });
            },
          },
        )
        .then((createdDocument) => {
          commit('SET_DOCUMENT_UPLOAD_PROGRESS', { id, progress: -1 });
          return createdDocument.data.data;
        });
    }))
      .then((data) => {
        filesWithId.forEach(({ id }) => commit('RESET_DOCUMENT_UPLOAD_PROGRESS', id));
        dispatch('documentTable/notifyTables', {}, { root: true });
        return data;
      });
  },

  updateDocument({ commit }, document) {
    return axios.put(`/v2/document/${document.ID}`, document).then((updatedDocument) => {
      commit('SET_DOCUMENT', updatedDocument.data.data);
      commit('UPDATE_DOCUMENT_IN_LISTS', updatedDocument.data.data);
    });
  },

  deleteDocument({ commit, dispatch }, document) {
    return axios.delete(`/v2/document/${document.ID}`).then(() => {
      commit('SET_DOCUMENT', null);
      commit('REMOVE_DOCUMENT_FROM_LISTS', document);
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
    });
  },

  useAsTemplate({ dispatch, commit }, document) {
    return axios.put(`/v2/document/asTemplate/${document.ID}`).then((updatedDocument) => {
      commit('UPDATE_DOCUMENT_IN_LISTS', updatedDocument.data.data);
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
    });
  },

  removeFromTemplates({ dispatch, commit }, document) {
    return axios.delete(`/v2/document/asTemplate/${document.ID}`).then((updatedDocument) => {
      commit('UPDATE_DOCUMENT_IN_LISTS', updatedDocument.data.data);
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
    });
  },

  async fetchUnfilteredDocuments({ dispatch }, { limit, offset, search }) {
    return dispatch('getDocuments', {
      params: {
        limit,
        search,
        offset,
      },
      append: true,
    });
  },

  resetDocuments({ commit }) {
    commit('SET_DOCUMENTS', []);
  },

  resetLastUploadedDocuments({ commit }) {
    commit('SET_LAST_UPLOADED_DOCUMENTS', []);
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
