import axios from 'axios';
import moment from 'moment';
import Store from '../index.js';
import router from '../../router';

export const state = () => ({
  accessToken: getSavedState('auth.accessToken'),
  isAdmin: getSavedState('auth.isAdmin') || false,
  currentUser: getSavedState('auth.currentUser') || {},
  userLoading: false,
  userLoadingPromise: null,
  currentBranch: getSavedState('auth.currentBranch') || {},
  branchLoading: false,
  branchLoadingPromise: null,
  currentDealer: getSavedState('auth.currentDealer') || {},
  dealerLoading: false,
  dealerLoadingPromise: null,
  accessibleBranches: getSavedState('auth.accessibleBranches') || [],
  branchSiblings: getSavedState('auth.branchSiblings') || [],
  error: null,
});


export const mutations = {
  SET_ACCESS_TOKEN(state, newValue) {
    state.accessToken = newValue;
    saveState('auth.accessToken', newValue);
    setDefaultAuthHeaders(state);
  },
  SET_ADMIN(state, isAdmin) {
    state.isAdmin = isAdmin;
    saveState('auth.isAdmin', isAdmin);
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState('auth.currentUser', newValue);
  },
  SET_CURRENT_BRANCH(state, newValue) {
    state.currentBranch = newValue || {};
    saveState('auth.currentBranch', newValue);
    setDefaultParams(state);
  },
  SET_CURRENT_DEALER(state, newValue) {
    state.currentDealer = newValue;
    saveState('auth.currentDealer', newValue);
  },
  SET_ACCESSIBLE_BRANCHES(state, value) {
    state.accessibleBranches = value;
    saveState('auth.accessibleBranches', value);
  },
  SET_BRANCH_SIBLINGS(state, value) {
    state.branchSiblings = value;
    saveState('auth.branchSiblings', value);
  },
  VALIDATION_FAILED: (state, error) => {
    state.error = error;
  },
  SET_NYLAS_GRANT_STATUS: (state, status) => {
    state.currentBranch.nylasGrantStatus = status;
    saveState('auth.currentBranch', state.currentBranch);
  },
};

export const getters = {
  loggedIn: state => !!state.accessToken && !!state.currentUser.ID,
  accessToken: state => state.accessToken,
  isAdmin: (state, getters) => (
    getters.loggedIn
    && (
      state.isAdmin
      || getters.userRoles.some(role => role.KEY === 'ADMIN')
    )
  ),
  isSupport: (state, getters) => (
    getters.loggedIn
    && (
      state.isAdmin
      || getters.userRoles.some(role => role.KEY === 'SUPPORT')
    )
  ),
  isDealerOwner: (state, getters) => (
    getters.loggedIn && getters.userRoles.some(
      role => role.KEY === 'DEALER_OWNER'
      && role.dealerId === state.currentDealer.ID,
    )
  ),
  isBranchOwner: (state, getters) => (
    getters.loggedIn && getters.userRoles.some(
      role => role.KEY === 'BRANCH_OWNER'
      && role.branchId === state.currentBranch.ID,
    )
  ),
  isEmployee: (state, getters) => (
    getters.loggedIn && getters.userRoles.some(
      role => role.KEY === 'EMPLOYEE'
      && role.branchId === state.currentBranch.ID,
    )
  ),

  // al => at least
  alSupport: (state, getters) => getters.isAdmin || getters.isSupport,
  alDealerOwner: (state, getters) => getters.alSupport || getters.isDealerOwner,
  alBranchOwner: (state, getters) => getters.alDealerOwner || getters.isBranchOwner,
  alEmployee: (state, getters) => getters.alBranchOwner || getters.isEmployee,
  userRoles: state => state.currentUser.roles,

  hasAs24Account: state => !!state.currentBranch.as24Id,
  hasAs24NewApiAccount: state => !!state.currentBranch.newAs24Authorized,
  hasNylasAccount: state => !!state.currentBranch.isNylasAuthorized,
  nylasGrantStatus: state => state.currentBranch.nylasGrantStatus,
  // hasNylasV3Account can be deleted after migration and the banner is no longer needed
  hasNylasV3Account: state => !!state.currentBranch.isNylasV3Authorized,
  isPayingCustomer: state => state.currentUser.trialPeriodEnd === null,
  isTrialPeriodActive: state => state.currentUser.trialPeriodEnd && moment().isBefore(state.currentUser.trialPeriodEnd),
  hasMobileDeAccount: state => !!state.currentBranch.mobileDeId,
  isMobileDeAuthorized: state => !!state.currentBranch.mobileDeAuthorized,
  isAs24Authorized: state => !!state.currentBranch.newAs24Authorized,
  hasFacebookEnabled: state => state.currentBranch.isFBMarketplaceEnabled,
  hasAutoAdUpload: state => !!state.currentBranch.autoAdUpload,
  howManyPlattformsConnected: (state, getters) => {
    const plattforms = [getters.hasMobileDeAccount, getters.hasAs24Account, getters.hasFacebookEnabled];
    return plattforms.filter(Boolean).length;
  },
  hasPlattformsConnected: (state, getters) => (getters.hasAs24Account || getters.hasMobileDeAccount),
  hasLogo: state => !!state.currentBranch.logoRef,
  hasRecommendedBranchFieldsFilledAndLogo: (state, getters) => {
    const userFields = ['title', 'lastName', 'firstName'];
    const branchFields = ['zipCode', 'city', 'streetAddress', 'phoneNumber', 'name'];

    return (
      getters.loggedIn
      && userFields.every(field => state.currentUser[field])
      && branchFields.every(field => state.currentBranch[field])
      && getters.hasLogo
    );
  },
  profileProgress: (state, getters, rootState) => {
    if (rootState.branchSetting['onboarding.setup.done']) {
      return {
        completedSteps: 1,
        totalSteps: 1,
      };
    }

    const conditions = [
      getters.hasAs24Account,
      getters.hasNylasAccount,
      getters.hasMobileDeAccount,
      getters.hasRecommendedBranchFieldsFilledAndLogo,
    ];
    return {
      completedSteps: conditions.filter(Boolean).length,
      totalSteps: conditions.length,
    };
  },
  getBranchNylasAddress: state => state.currentBranch.nylasEmailAddress,
  hasCs24Account: state => !!state.currentBranch.hasCs24Account,
  accessibleBranches: state => state.accessibleBranches.filter(b => b.dealerId === state.currentDealer.ID),
};

export const actions = {

  init({ state }) {
    setDefaultAuthHeaders(state);
    setDefaultParams(state);
  },

  reset({ commit }) {
    commit('SET_ACCESS_TOKEN', null);
    commit('SET_ADMIN', false);
    commit('SET_CURRENT_USER', {});
    commit('SET_CURRENT_BRANCH', {});
    commit('SET_CURRENT_DEALER', {});
  },

  login({ commit, dispatch }, {
    token,
    email,
    password,
    otp,
  } = {}) {
    if (token) commit('SET_ACCESS_TOKEN', token);

    const apiRequest = token
      ? axios.get('/v2/auth/me')
      : axios.post('/v2/auth/login', { email, password, otp });

    return apiRequest.then((authResponse) => {
      if (authResponse.status === 202 && authResponse.data.needs === 'otp') {
        return 'otp';
      }

      const {
        accessToken,
        user,
        branch,
        dealer,
      } = authResponse.data;

      const isAdmin = user.roles.some(role => role.KEY === 'ADMIN');

      if (!token) commit('SET_ACCESS_TOKEN', accessToken);
      commit('SET_ADMIN', isAdmin);
      commit('SET_CURRENT_USER', user);
      commit('SET_CURRENT_BRANCH', branch);
      commit('SET_CURRENT_DEALER', dealer);
      commit('VALIDATION_FAILED', null);

      dispatch('getAdditionalBranchData');
      dispatch('getAccessibleBranches');
      dispatch('getBranchSiblings');

      if (isAdmin) {
        commit('userSetting/SET', { 'layout.darkMode': true }, { root: true });
      } else {
        commit('userSetting/SET', { 'layout.darkMode': false }, { root: true });
      }

      return user;
    }).catch((error) => {
      commit('VALIDATION_FAILED', error.response.data.errorLocalized);
      return null;
    });
  },

  async loginWithPasskey({ commit, dispatch }, { authenticationId, authentication }) {
    try {
      const { data } = await axios.post(`/v2/auth/login/passkey/${authenticationId}`, { authentication });

      const {
        accessToken,
        user,
        branch,
        dealer,
      } = data;

      const isAdmin = user.roles.some(role => role.KEY === 'ADMIN');

      commit('SET_ACCESS_TOKEN', accessToken);
      commit('SET_ADMIN', isAdmin);
      commit('SET_CURRENT_USER', user);
      commit('SET_CURRENT_BRANCH', branch);
      commit('SET_CURRENT_DEALER', dealer);
      commit('VALIDATION_FAILED', null);

      dispatch('getAdditionalBranchData');
      dispatch('getAccessibleBranches');
      dispatch('getBranchSiblings');

      if (isAdmin) {
        commit('userSetting/SET', { 'layout.darkMode': true }, { root: true });
      } else {
        commit('userSetting/SET', { 'layout.darkMode': false }, { root: true });
      }

      return user;
    } catch (error) {
      commit('VALIDATION_FAILED', error.response.data.errorLocalized);
      return null;
    }
  },

  async loginWithAlzuraOAuthCode({ commit, dispatch }, code ) {

    try {
      const { data } = await axios.post('/v2/auth/login/oauth/alzura', { code });

      const {
        accessToken,
        user,
        branch,
        dealer,
      } = data;

      const isAdmin = user.roles.some(role => role.KEY === 'ADMIN');

      commit('SET_ACCESS_TOKEN', accessToken);
      commit('SET_ADMIN', isAdmin);
      commit('SET_CURRENT_USER', user);
      commit('SET_CURRENT_BRANCH', branch);
      commit('SET_CURRENT_DEALER', dealer);
      commit('VALIDATION_FAILED', null);

      dispatch('getAdditionalBranchData');
      dispatch('getAccessibleBranches');
      dispatch('getBranchSiblings');

      if (isAdmin) {
        commit('userSetting/SET', { 'layout.darkMode': true }, { root: true });
      } else {
        commit('userSetting/SET', { 'layout.darkMode': false }, { root: true });
      }

      return user;
    } catch (error) {
      commit('VALIDATION_FAILED', error.response.data.errorLocalized);
      throw error;
    }
  },

  async logout({ commit, dispatch }) {
    commit('SET_ACCESS_TOKEN', null);

    await router.push({
      path: '/login',
    });

    dispatch('reset');

    // call all reset mutations of all stores from here to prevent users to see data from other previous logged in users
    commit('ads/RESET_ADS', null, { root: true });
    commit('branch/RESET_BRANCH', null, { root: true });
    commit('customer/RESET_CUSTOMER_DETAIL', null, { root: true });
    commit('customer/RESET_CUSTOMER', null, { root: true });
    commit('dealer/RESET_DEALER', null, { root: true });
    commit('financing/CLEAR', null, { root: true });
    commit('opportunity/CLEAN_OPPORTUNITY_DETAIL', null, { root: true });
    commit('subscription/RESET', null, { root: true });
    commit('vehicle/RESET_VEHICLE', null, { root: true });
    commit('vehicleList/RESET_VEHICLE_LIST', null, { root: true });
    commit('apiKey/RESET', null, { root: true });
  },

  getNewToken({ commit, dispatch, state }) {
    if (!state.accessToken) {
      router.push({
        path: '/login',
      });
      return Promise.resolve(null);
    }

    return axios.get('/v2/auth/refreshToken', { errorNotification: false })
      .then((response) => {
        const {
          accessToken,
          user,
          branch,
          dealer,
        } = response.data;

        commit('SET_ACCESS_TOKEN', accessToken);
        commit('SET_ADMIN', user.roles.includes('ADMIN'));
        commit('SET_CURRENT_USER', user);
        commit('SET_CURRENT_BRANCH', branch);
        commit('SET_CURRENT_DEALER', dealer);
        commit('VALIDATION_FAILED', null);

        dispatch('getAdditionalBranchData');
        dispatch('getAccessibleBranches');
        dispatch('getBranchSiblings');

        return accessToken;
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 400) {
          commit('VALIDATION_FAILED', 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.');
          dispatch('reset');
          router.push({
            path: '/login',
          });
        }
        return null;
      });
  },

  async forgotPassword(_, { email, otp }) {
    const res = await axios.post('/v2/user/forgotPassword', { email, otp });

    if (res.status === 202 && res.data.needs === 'otp') {
      return 'otp';
    }

    return true;
  },

  resetPassword(_, { resetKey, password }) {
    return axios.post('/v2/user/resetPassword', { resetKey, password });
  },

  createPassword({ state }, password) {
    const userId = state.currentUser.ID;
    return axios.post(`/v2/user/${userId}/createPassword`, { password });
  },

  reloadCurrentUser({ commit, state }, userId) {
    if (state.userLoading) return state.userLoadingPromise;

    state.userLoading = true;
    state.userLoadingPromise = axios.get(`/v2/user/${userId || state.currentUser.ID}`)
      .then((res) => {
        const user = res.data.data;
        commit('SET_CURRENT_USER', user);
        state.userLoading = false;
        return user;
      });

    return state.userLoadingPromise;
  },

  reloadCurrentBranch({ commit, state, dispatch }, branchId) {
    if (state.branchLoading) return state.branchLoadingPromise;

    state.branchLoading = true;
    state.branchLoadingPromise = axios.get(`/v2/branch/${branchId || state.currentBranch.ID}`)
      .then((res) => {
        const branch = res.data.data;
        commit('SET_CURRENT_BRANCH', branch);
        state.branchLoading = false;
        dispatch('getAdditionalBranchData');
        dispatch('getBranchSiblings');
        return branch;
      })
      .catch((err) => {
        state.branchLoading = false;
        throw err;
      });

    return state.branchLoadingPromise;
  },

  reloadCurrentDealer({ commit, state, dispatch }, dealerId) {
    if (state.dealerLoading) return state.dealerLoadingPromise;

    state.dealerLoading = true;
    state.dealerLoadingPromise = axios.get(`/v2/dealer/${dealerId || state.currentDealer.ID}`)
      .then((res) => {
        const dealer = res.data.data;
        commit('SET_CURRENT_DEALER', dealer);
        state.dealerLoading = false;
        dispatch('getAccessibleBranches');
        return dealer;
      });

    return state.dealerLoadingPromise;
  },

  getAdditionalBranchData({ dispatch }) {
    dispatch('ads/getSellerInfo', null, { root: true });
    dispatch('branchSetting/getAllSettings', null, { root: true });
    dispatch('subscription/getSubscriptions', true, { root: true });
  },

  async getAccessibleBranches({ state, commit }) {
    try {
      const dealerId = state.currentDealer.ID;
      const { data } = await axios.get(`v2/dealer/${dealerId}/accessible-branches`);
      commit('SET_ACCESSIBLE_BRANCHES', data.list);
    } catch (error) {
      console.error(error);
    }
  },

  async getBranchSiblings({ state, commit }) {
    try {
      const branchId = state.currentBranch.ID;
      const { data } = await axios.get(`v2/branch/${branchId}/siblings`);
      commit('SET_BRANCH_SIBLINGS', data.list);
    } catch (error) {
      console.error(error);
    }
  },
};

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function setDefaultAuthHeaders(state) {
  if (state.accessToken) {
    axios.defaults.headers.common.Authorization = `JWT ${state.accessToken}`;
  }
}

function setDefaultParams(state) {
  axios.defaults.params = {}; // init params first
  axios.defaults.params.branchId = state.currentBranch.ID;
}

window.addEventListener('beforeunload', () => {
  saveState('auth.accessToken', Store.state.auth.accessToken);
  saveState('auth.currentUser', Store.state.auth.currentUser);
  saveState('auth.currentBranch', Store.state.auth.currentBranch);
  saveState('auth.currentDealer', Store.state.auth.currentDealer);
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
