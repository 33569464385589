<template>
  <q-modal
    v-model="open"
    v-bind="$attrs"
  >

    <div class="row items-center no-wrap q-ma-md" v-if="headless === false">
      <div class="q-title ellipsis" style="flex: 1; width: 0px;">
        {{title}}
      </div>
      <q-btn icon="close" flat round dense @click="hide" />
    </div>

    <div class="q-ma-md">
      <slot name="body" />
    </div>

    <div class="q-ma-md" v-if="$scopedSlots.footer">
      <slot name="footer" />
    </div>

    <div class="q-ma-md row justify-end items-center btn-gutter-x-sm-right" v-if="$scopedSlots.actions">
      <slot name="actions" />
    </div>

  </q-modal>
</template>

<script>
import { modal } from '@/plugins/mixins';

export default {
  name: 'SimpleModal',
  props: {
    title: String,
    headless: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [

    /*
     * Provides
     *   properties:
     *     open (allows v-sync)
     *   methods:
     *     show
     *     hide
     *   events:
     *     @show
     *     @hide
     */
    modal,
  ],
};
</script>

<style lang="stylus" scoped>
.btn-gutter-x-sm-right >>> .q-btn {
  margin-left: 8px;
}
</style>
