import Vue from 'vue';

const initialState = () => ({
  activeTables: 0,
  documentsChanged: false,
});

export const mutations = {
  SET_DOCUMENTS_CHANGED(state, changed) {
    state.documentsChanged = changed;
  },

  SET_ACTIVE_TABLE(state, increase = true) {
    state.activeTables += (increase ? 1 : -1);
  },
};

export const actions = {

  notifyTables({ state, commit }) {
    if (state.activeTables > 0) {
      commit('SET_DOCUMENTS_CHANGED', true);
    }
  },

  async resetDocumentsChanged({ commit }) {
    // wait one tick to ensure that all active components are notified
    await Vue.nextTick();
    commit('SET_DOCUMENTS_CHANGED', false);
  },

  addActiveTable({ commit }) {
    commit('SET_ACTIVE_TABLE', true);
  },

  removeActiveTable({ commit }) {
    commit('SET_ACTIVE_TABLE', false);
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
