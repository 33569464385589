<template>
  <div class="abo-upgrade">
    <div v-if="showRemaining"
      class="text-italic q-mx-lg"
      :class="darkMode ? 'text-white' : 'text-dark'"
    >
      Ihre Testphase endet in <span class="q-body-2">{{remaining}} Tagen</span>
    </div>
     <q-btn  class="q-mr-lg no-shadow" outline size="md" color="positive" label="Jetzt Paket buchen" to="/subscribe" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'AboUpgrade',
  props: {
    showRemaining: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('auth', ['currentDealer']),
    darkMode() {
      return !!this.$store.state.userSetting['layout.darkMode'];
    },
    remaining() {
      const today = moment();
      const endDate = moment(this.currentDealer.trialPeriodEnd);
      return endDate.diff(today, 'days');
    },
  },
};
</script>

<style scoped>
.abo-upgrade {
  display: contents;
}
</style>
