<template>
  <q-layout-drawer
    v-model="$store.state.userSetting.notificationDrawerOpen"
    side="right"
    overlay
    :content-class="['drawer-right']"
  >
    <q-resize-observable @resize="onResize" />
    <div class="row q-pa-md">
      <div class="q-title col">Benachrichtigungen</div>
      <q-icon
        name="cancel"
        size="18px"
        @click.native="closeDrawer"
        class="cursor-pointer"
      />
    </div>
    <q-list highlight separator v-if="notifications.length > 0">
      <q-item multiline v-for="item in notifications" :key="item.ID">
        <q-item-main
          :label="item.title"
          label-lines="2"
          :sublabel="item.snippet"
          sublabel-lines="2"
        />
        <q-item-side right>
          <q-item-tile stamp>{{
            item.created | formatDateIntelligent
          }}</q-item-tile>
        </q-item-side>
      </q-item>
    </q-list>
    <div v-else class="q-px-md q-pt-xl">
      keine Benachrichtigungen vorhanden
    </div>
    <div @resize="onResize"></div>
  </q-layout-drawer>
</template>

<script>
import { mapState } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';


export default {
  name: 'NotificationDrawer',

  data() {
    return {
      ps: null,
    };
  },

  computed: {
    ...mapState('notifications', ['notifications']),
  },

  methods: {
    onResize() {
      this.ps.update();
    },
    closeDrawer() {
      this.$store.commit('userSetting/SET_LOCAL', { key: 'notificationDrawerOpen', value: false });
    },
  },

  mounted() {
    this.ps = new PerfectScrollbar('.q-layout-drawer-right', { wheelSpeed: 0.5 });
  },

};
</script>
<style src="perfect-scrollbar/css/perfect-scrollbar.css"/>
<style scoped lang="stylus">
@import theme
</style>
