import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins';
import './plugins/filter';
import './plugins/directive';
import VueAnalytics from 'vue-analytics';

import './styles/app.styl';

const isProd = import.meta.env.PROD;

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-64823840-2',
  router,
  autoTracking: {
    exception: true,
  },
  debug: {
    enabled: false,
    sendHitTask: isProd,
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// Reload page when new deployment
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});
