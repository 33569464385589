<template>
  <div class="full-height column">
    <!--q-slide-transition-->
    <q-list
      no-border
      link
      class="col column no-wrap"
      :class="{ 'dark-mode': darkMode, 'light-mode': !darkMode }"
    >
      <template v-if="$store.getters['auth/alSupport']">
        <branch-alert
          v-if="
            $store.getters['auth/alSupport'] &&
              !$store.getters['auth/isEmployee']
          "
          class="q-pl-sm"
        />

        <q-list-header>Administration</q-list-header>
        <!--
          <q-item to="/admin/dashboard" exact>
            <q-item-side icon="dashboard" />
            <q-item-main>Dashboard</q-item-main>
          </q-item>
          -->
        <q-item to="/admin/dealer" exact>
          <q-item-side icon="people_outline" />
          <q-item-main>Dealer</q-item-main>
        </q-item>
        <!--
          <q-item to="/admin/branch" exact>
            <q-item-side icon="trending_up" />
            <q-item-main>Analytics</q-item-main>
          </q-item>
          -->
        <q-item to="/inserate">
          <q-item-side icon="fas fa-ad" />
          <q-item-main>Inserate</q-item-main>
        </q-item>

        <q-list-header>App</q-list-header>
      </template>

      <branch-select
        v-if="$store.getters['auth/accessibleBranches'].length > 1"
        class="q-my-sm q-ml-sm text-weight-regular"
      />

      <q-item to="/" exact>
        <q-item-side icon="dashboard" />
        <q-item-main>Dashboard</q-item-main>
      </q-item>

      <!-- Fahrzeuge -->
      <q-collapsible
        ref="collapsible-vehicle"
        icon="directions_car"
        label="Fahrzeuge"
        group="menu"
        :value="$route.path.includes('inventory') || $route.path.includes('vehicle')"
        :header-class="{ 'router-link-active': $route.path.includes('vehicle') }"
      >
        <q-item to="/inventory/incoming" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Im Vorlauf" />
        </q-item>
        <q-item to="/inventory/inventory" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Im Bestand" />
        </q-item>
        <q-item to="/inventory/sold" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Verkauft" />
        </q-item>
        <q-item to="/inventory/customer" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Kundenfahrzeuge" />
        </q-item>
      </q-collapsible>

      <q-item to="/opportunity">
        <q-item-side icon="chat_bubble_outline" />
        <q-item-main>Anfragen</q-item-main>
        <q-item-side right v-if="unreadOpportunities > 0">
          <q-chip color="negative" dense class="q-caption">
            {{
              unreadOpportunities
            }}
          </q-chip>
        </q-item-side>
      </q-item>

      <q-item
        to="/contact"
        :class="{ 'router-link-active': $route.path.includes('organization') }"
      >
        <q-item-side icon="contacts" />
        <q-item-main>Kontakte</q-item-main>
      </q-item>

      <q-item
        v-if="$app.whitelabel === 'autengo'"
        to="/marketplace"
      >
        <q-item-side icon="shopping_cart" />
        <q-item-main>Marktplatz</q-item-main>
      </q-item>

      <q-collapsible
        ref="collapsible-docs"
        icon="description"
        label="Dokumente"
        group="menu"
        :value="$route.path.includes('document')"
        :header-class="{ 'router-link-active': $route.path.includes('document/') }"
      >
        <q-item to="/document#create" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Dokument erstellen" />
        </q-item>
        <q-item to="/document#myDocuments" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Meine Dokumente" />
        </q-item>
      </q-collapsible>

      <q-collapsible
        v-if="!isInvoiceV2Active"
        ref="collapsible-invoices"
        icon="receipt"
        label="Rechnungen"
        group="menu"
        :value="$route.path.includes('invoice')"
        :header-class="{ 'router-link-active': $route.path.includes('invoice/') }"
      >
        <q-item to="/invoice#createInvoice" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Rechnung erstellen" />
        </q-item>
        <q-item to="/invoice#existingInvoice" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Übersicht" />
        </q-item>
        <q-item to="/invoice#templates" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Rechnungsvorlagen" />
        </q-item>
      </q-collapsible>

      <q-item
        v-if="isInvoiceV2Active"
        to="/invoices"
        :class="{ 'router-link-active': $route.path.includes('invoice') }"
      >
        <q-item-side icon="receipt" />
        <q-item-main>Rechnungen</q-item-main>
      </q-item>

      <!-- E-Mail -->
      <q-item
        v-if="!hasNylasAccount"
        to="/email/inbox"
      >
        <q-item-side icon="email" />
        <q-item-main>E-Mail</q-item-main>
        <q-item-side right v-if="paywallEmail">
          <q-chip
            text-color="premium"
            color="grey-1"
            icon="offline_bolt"
            dense
            class="q-caption"
          >
            Premium
          </q-chip>
        </q-item-side>
      </q-item>
      <q-collapsible
        v-else
        ref="collapsible-email"
        icon="email"
        label="E-Mail"
        group="menu"
        :value="$route.path.includes('email')"
        :header-class="{
          'router-link-active': (
            $route.path.includes('email/')
            && !['/email/inbox', '/email/drafts', '/email/sent'].includes($route.fullPath)
          )
        }"
      >
        <q-item to="/email/inbox" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Eingang" />
          <q-item-side right>
            <q-chip
              color="negative"
              dense
              class="q-caption"
              v-if="unread > 0"
            >
              {{ unread }}
            </q-chip>
          </q-item-side>
        </q-item>
        <q-item to="/email/drafts" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Entwürfe" />
        </q-item>
        <q-item to="/email/sent" class="subitem" exact>
          <q-item-side />
          <q-item-main label="Gesendet" />
        </q-item>
      </q-collapsible>

      <!-- Other -->

      <!--
        <q-list-header>Verkaufskanal</q-list-header>
        <q-collapsible
          ref="collapsible-showroom"
          group="menu"
          :value="$route.path.includes('showroom')"
        >
          <template slot="header">
            <q-item-side icon="storefront" />
            <q-item-main label="Showroom" />
            <q-item-side right>
              <q-chip
                color="neutral"
                dense
                class="q-caption"
                >NEU</q-chip
              >
            </q-item-side>
          </template>

          <q-item to="/showroom/analytics" class="subitem" exact>
            <q-item-side />
            <q-item-main label="Analytics" />
          </q-item>
          <q-item to="/showroom/tos" class="subitem" exact>
            <q-item-side />
            <q-item-main label="Rechtliches" />
          </q-item>
          <q-item to="/showroom/design" class="subitem" exact>
            <q-item-side />
            <q-item-main label="Design & Branding" />
          </q-item>
          <q-item to="/showroom/settings" class="subitem" exact>
            <q-item-side />
            <q-item-main label="Einstellungen" />
          </q-item>
        </q-collapsible>
        -->

      <q-list-header>Support</q-list-header>
      <q-item tag="a" href="https://help.autengo.com/de/knowledge/fahrzeug-manager" target="_blank">
        <q-item-side icon="support" />
        <q-item-main>Hilfe Center</q-item-main>
      </q-item>

      <div class="col" style="min-height: 20px" />

      <q-item to="/settings" v-if="$store.getters['auth/alBranchOwner']">
        <q-item-side icon="settings" />
        <q-item-main>Einstellungen</q-item-main>
      </q-item>

      <q-resize-observable @resize="$emit('resize')" />
    </q-list>
    <!--/q-slide-transition-->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { settings, subscription } from '@/plugins/mixins';
import BranchAlert from '../BranchAlert.vue';
import BranchSelect from '@/components/branch/BranchSelect.vue';

export default {
  name: 'DrawerListItems',

  components: {
    BranchAlert,
    BranchSelect,
  },

  mixins: [
    subscription('ELN_PREMIUM', 'ELN_EMAIL'),
    settings('branchSetting', {
      'isInvoiceV2Active': 'invoice.v2.active',
    }),
  ],

  data() {
    return {
      showDrawer: true,
      miniWidth: 60,
      showAppLinks: false,
    };
  },

  computed: {
    ...mapState('auth', ['currentDealer', 'currentUser']),
    ...mapState('emailThreadList', ['unread']),
    ...mapState('opportunityList', ['unreadOpportunities']),
    ...mapGetters('auth', ['hasNylasAccount', 'isAdminOrSup']),
    darkMode() {
      return !!this.$store.state.userSetting['layout.darkMode'];
    },

    isVehicleDetail() {
      if (this.$route.name === 'vehicleDetail') return true;
      return false;
    },

    currentBranchChangedWatcher() {
      return (
        this.$store.getters['auth/alSupport']
        && !this.$store.getters['auth/isEmployee']
      );
    },

    paywallEmail() {
      return (
        this.$app.whitelabel === 'eln'
        && this.subscriptions
        && !this.isSubscripted
      );
    },
  },
  watch: {
    currentBranchChangedWatcher(val) {
      this.showAppLinks = val;
    },
    $route() {
      Object.values(this.$refs).forEach((collapsible) => {
        if (!collapsible) return;
        collapsible.showing = collapsible.value;
      });
    },
  },
  mounted() {
    if (this.hasNylasAccount) this.$store.dispatch('emailThreadList/getEmailThreadsUnreadCount');
    this.$store.dispatch('opportunityList/getUnreadCount');
  },
};
</script>

<style scoped lang="stylus">
@import theme

// dark mode
.dark-mode {
  & >>> .q-item,
  & >>> .q-item .q-item-side-left {
    color: white;
  }

  & >>> .q-list-header,
  & >>> .q-item.subitem,
  & >>> .q-item.subitem .q-item-side-left {
    color: #CACCCE;
  }
}

>>> .q-list-header {
  min-height: initial;
  padding-top: 20px;
  padding-bottom: 4px;
  flex-shrink: 0;
}

>>> .q-item:hover, .q-item-highlight:hover, .q-list-link > .q-item:hover, .q-item-link:hover {
  background-color: #EDEEEF !important;
  color: $primary !important;

  .q-item-side-left {
    color: $primary !important;
  }

  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    width: 3px;
    height: 100%;
    background-color: $primary;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

// general styling
>>> .q-item {
  margin-left: 8px;
  padding-left: 12px;
  padding-right: 5px;
  min-height: 28px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  color: #202223;
  flex-shrink: 0;

  .q-item-side-left {
    color: #202223;
  }
}

>>> a.q-item {
  text-decoration: none;
}

>>> .q-item.subitem {
  color: #6D7175;

  .q-item-side-left {
    color: #6D7175;
  }
}

>>> .q-item.router-link-active, .q-item:focus {
  background-color: #EDEEEF !important;
  color: $primary !important;
  /* transition: all .6s cubic-bezier(.5, .2, .2, 1.1); */
  transition: all 0.2s ease-in;
  transition-delay: 0.1s;

  .q-item-side-left {
    color: $primary !important;
  }

  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    width: 3px;
    height: 100%;
    background-color: $primary;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

>>> .q-item-side {
  display: flex;
  justify-content: center;
}

>>> .q-collapsible-sub-item {
  padding: 0;
}

>>> .q-collapsible .q-item-side.q-item-side-right:has(.q-collapsible-toggle-icon) {
  display: none;
}
</style>
