import axios from 'axios';

export const initialState = () => ({
  queryParams: {
    limit: 25,
    offset: 0,
  },
  search: '',
  filter: null,
  contactList: [],
  contactListCount: 0,
  serverPagination: {
    page: 1,
    rowsPerPage: 25, // change queryParams.limit too
    rowsNumber: null, // this is the total number of records/totalCount
    sortBy: null,
    descending: false,
  },
  dataCounts: {
    filteredCount: 0,
    totalCount: 0,
  },
  loading: true,
  columns: [
    {
      name: 'icon',
      label: '',
      sortable: false,
      align: 'left',
    },
    {
      name: 'typ',
      label: 'Typ',
      sortable: false,
      align: 'left',
    },
    {
      name: 'name',
      label: 'Name',
      sortable: false,
      align: 'left',
    },
    {
      name: 'address',
      label: 'Adresse',
      sortable: false,
      align: 'left',
    },
    {
      name: 'email',
      label: 'Email',
      sortable: false,
      align: 'left',
    },
  ],
});

export const mutations = {
  SET_CONTACT_LIST(state, newValue) {
    state.contactList = newValue;
  },
  SET_CONTACT_LIST_PAGINATION_ROWS_NUMBER(state, newValue) {
    state.serverPagination.rowsNumber = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.dataCounts.filteredCount = filteredCount;
    state.dataCounts.totalCount = totalCount;
  },
  SET_CONTACT_LIST_PAGINATION(state, pagination) {
    // ToDo add limit and offset to queryParam
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.serverPagination = pagination;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },

  SET_QUERY_PARAMS(state, queryParams) {
    state.queryParams = queryParams;
  },

  SET_FILTER(state, filter) {
    state.filter = filter;
  },

  SET_CONTACT_LIST_COUNT(state, count) {
    state.contactListCount = count;
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
  },
};

function clean(obj) {
  Object.keys(obj).forEach((propName) => {
    if (obj[propName] && typeof obj[propName] === 'object') clean(obj[propName]);
    else if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  });
  return obj;
}


async function getAllContacts(queryParams, search) {
  let urlParams = queryParams;

  urlParams = {
    ...queryParams,
    search,
  };
  return axios.get('v2/contact', {
    params: urlParams,
  });
}

async function getAllPersons(queryParams, search, queryFilter) {
  let urlParams = queryParams;
  let filter;

  if (queryFilter) {
    filter = clean(JSON.parse(JSON.stringify(queryFilter)));
  }

  urlParams = {
    ...queryParams,
    search,
    filter,
  };
  return axios.get('v2/contact/person', {
    params: urlParams,
  });
}

export const actions = {
  getContactList({ commit, state }) {
    commit('SET_LOADING', true);
    return getAllContacts(state.queryParams, state.search).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_CONTACT_LIST_PAGINATION_ROWS_NUMBER', res.data.totalCount);
      commit('SET_CONTACT_LIST', res.data.data);
      commit('SET_LOADING', false);
    });
  },

  getPersonList({ commit, state }) {
    commit('SET_LOADING', true);
    return getAllPersons(state.queryParams, state.search, state.filter).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_CONTACT_LIST_PAGINATION_ROWS_NUMBER', res.data.totalCount);
      commit('SET_CONTACT_LIST', res.data.data);
      commit('SET_LOADING', false);
    });
  },

  async getContactListCount({ commit }) {
    const { data: { data: count } } = await axios.get('v2/contact/count');
    commit('SET_CONTACT_LIST_COUNT', count);
  },

  setContactListPagination({ commit }, pagination) {
    commit('SET_CONTACT_LIST_PAGINATION', pagination);
  },

  setContactSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },

  setContactListQueryParams({ commit }, queryParams) {
    commit('SET_QUERY_PARAMS', queryParams);
  },

  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },

  resetContactList({ commit }) {
    commit('RESET');
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
