import axios from 'axios';
import { toOptions } from '@/plugins/util';


export const initialState = {
  vehicleRef: {},
  vehicleRefLoaded: false,

  elnMakeList: [],
  makeListLoading: true,
  modelListLoaded: false,
  elnModelList: [],

  distributorList: [],
  distributorListLoading: true,
};


export const mutations = {
  SET_MAKES(state, data) {
    state.elnMakeList = data.map(item => ({
      label: `${item.description} <span>(${item.count})</span>`,
      value: item.tag,
    })).sort((a, b) => a.label.localeCompare(b.label));
    state.makeListLoading = false;
  },
  SET_DISTRIBUTORS(state, data) {
    state.distributorList = data.map(item => ({
      label: `${item.company} <span>(${item.city})</span>`,
      value: item.id,
    })).sort((a, b) => a.label.localeCompare(b.label));
    state.distributorListLoading = false;
  },
  SET_MODELS(state, data) {
    state.elnModelList = data.map(item => ({
      label: `${item.description} <span>(${item.count})</span>`,
      value: item.tag,
    })).sort((a, b) => a.label.localeCompare(b.label));
    state.modelListLoaded = true;
  },
  RESET_MODELS(state) {
    state.modelListLoaded = false;
    state.elnModelList = [];
  },
  SET_REF(state, newValue) {
    state.vehicleRef = newValue;
    state.vehicleRefLoaded = true;
  },
};

export const getters = {
  colors: state => toOptions(state.vehicleRef.colors, 'statvalue', 'statkey'),
};

export const actions = {
  async getMakeList({ commit }) {
    const { data } = await axios.get('/v2/eln/reference/make');
    commit('SET_MAKES', data.data);
  },
  async getModelsForMake({ commit }, makeName) {
    commit('RESET_MODELS');
    const { data } = await axios.get(`/v2/eln/reference/make/${makeName}/models`);
    commit('SET_MODELS', data.data);
  },
  async getVehicleRef({ commit }) {
    const { data } = await axios.get('/v2/eln/reference/vehicle');
    commit('SET_REF', data.data);
  },
  async getDistributors({ commit }) {
    const { data } = await axios.get('/v2/eln/reference/dealer?type=distributor');
    commit('SET_DISTRIBUTORS', data.data);
  },
};

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
  getters,
};
