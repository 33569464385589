<template>
  <div>
    <div class="q-mt-md q-mb-sm text-primary q-title">Verbinden Sie sich mit den Autobörsen</div>
    <div
      class="text-faded q-caption"
    >Um Ihren Fahrzeugbestand automatisch zu importieren und künftig auch Fahrzeuge auf die Börsen zu übertragen, ist eine aktive Verbindung notwendig.</div>
    <div class="row justify-between q-my-md">
      <div class="col q-mr-sm">
        <as24-setup />
      </div>
      <div class="col q-ml-sm">
        <mobile-de-setup />
      </div>
    </div>

    <div class="row justify-between gutter-md">
      <div class="col">
        <div class="text-grey-5 q-caption">
          Hinweis: Die Freischaltung kann bis zu 2 Werktage dauern. Sie werden benachrichtigt sobald die Verbindung aktiv ist.
          Sollten Sie bereits eine Software verwenden, die mit mobile.de oder Autoscout24 verbunden ist, wird die Verbindung getrennt.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import As24Setup from '@/components/dashboard/NewAs24Setup.vue';
import MobileDeSetup from '@/components/dashboard/MobileDeSetup.vue';

export default {
  name: 'mobileAs24',
  components: {
    As24Setup,
    MobileDeSetup,
  },
  data() {
    return {
    };
  },
};
</script>
