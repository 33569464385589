import router from '../router';

const validOrigins = ['user', 'message'];
const defaultOrigin = 'user';
const defaultType = 'error';
const defaultMessage = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal!';

export default class RequestErrorNotification {
  message;
  origin;
  type;

  constructor({ message, origin, type } = {}) {
    this.message = message || defaultMessage;
    this.origin = origin || defaultOrigin;
    this.type = type || defaultType;
  }

  getMessageString(errorStatus) {
    if (typeof this.message === 'string') {
      return this.message;
    } else if (typeof this.message[errorStatus] === 'string') {
      return this.message[errorStatus];
    }

    return defaultMessage;
  }

  triggerNotification(Vue, errorStatus, messageString) {
    messageString = messageString || this.getMessageString(errorStatus);

    if (!validOrigins.includes(this.origin)) {
      this.origin = defaultOrigin;
    }

    if (this.origin === 'user') {
      this.triggerUserNotification(Vue, messageString);
    }
    if (this.origin === 'message') {
      this.triggerMessageNotification(Vue, messageString);
    }
  }

  triggerUserNotification(Vue, messageString) {
    Vue.prototype.$notifyUserInteraction.notify(this.type, messageString);
  }

  triggerMessageNotification(Vue, messageString) {
    Vue.prototype.$notifyMessage.notify(this.type, messageString);
  }

  static triggerErrorDialog(Vue, titleString, messageString, redirectTo) {
    const params = {
      title: titleString,
      message: messageString,
    };
    if (typeof redirectTo !== 'undefined') {
      Vue.prototype.$q.dialog(params).then(() => {
        router.push(redirectTo);
      });
    } else {
      Vue.prototype.$q.dialog(params);
    }
  }
}
