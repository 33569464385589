import { mapState } from 'vuex';
import _ from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import { mapObject } from './util';


/**
 * Allow using v-model from the outside and provide this property internal
 *
 * @property {string} name The name of the internal property
 * @returns {object} The mixin which can be used in a component
 */
export function vModel(name) {
  return {
    computed: {
      [name]: {
        get() {
          return this.$attrs.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
}

/**
 * Allow using .sync from the outside and provide that property internal
 *
 * @property {string} name The name of the property
 * @returns {object} The mixin which can be used in a component
 */
export function vSync(name) {
  return {
    computed: {
      [name]: {
        get() {
          return this.$attrs[name];
        },
        set(value) {
          this.$emit(`update:${name}`, value);
        },
      },
    },
  };
}

/**
 * Makes the usage of modals simpler
 *
 * Provides
 *   properties:
 *     open (allows .sync)
 *   methods:
 *     show
 *     hide
 *   events:
 *     show
 *     hide
 */
export const modal = {
  mixins: [
    vSync('open'),
  ],
  mounted() {
    const modal = this.$refs.modal || this.$children[0];
    this.$options.modal = modal;

    this.$options.modal.$on('show', $event => this.$emit('show', $event));
    this.$options.modal.$on('hide', ($event) => {
      this.$emit('cancel', $event);
      this.$emit('hide', $event);
    });
    this.$options.modal.$on('cancel', $event => this.$emit('cancel', $event));
  },
  methods: {
    show($event) {
      return this.$options.modal.show($event);
    },
    hide($event) {
      return this.$options.modal.hide($event);
    },
    ok($event) {
      this.$emit('ok', $event);
    },
    cancel($event) {
      this.$emit('cancel', $event);
    },
  },
};

export function subscription(...packages) {
  return {
    computed: {
      ...mapState('subscription', ['subscriptions']),
      isSubscripted() {
        if (!this.subscriptions) return false;
        return this.subscriptions.some(sub => packages.includes(sub.packageKey));
      },
    },
  };
}

export const scrollbar = (id, headerId) => ({
  data() {
    return {
      ps: null,
      headerElement: null,
      scrollElement: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // Apply PerfectScrollbar to the element with the provided ID
      this.scrollElement = document.getElementById(id);
      this.headerElement = document.getElementById(headerId);
      if (this.scrollElement) {
        this.ps = new PerfectScrollbar(this.scrollElement, { useBothWheelAxes: true });
        this.scrollElement.style.position = 'relative';

        this.scrollElement.addEventListener('scroll', this.handleScroll);
      }
    });
  },

  methods: {
    handleScroll() {
      if (!this.headerElement) return;

      if (this.ps.reach.y === 'start') {
        this.headerElement.style.boxShadow = 'none';
      } else {
        this.headerElement.style.boxShadow = '0px 4px 8px -3px rgba(17, 17, 17, 0.06)';
      }
    },
  },

  beforeDestroy() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
  },
});

export const documentEssentials = {
  methods: {
    async downloadDocument(doc) {
      doc.name = doc.ext ? `${doc.name}.${doc.ext}` : doc.name;
      // download.js is buggy
      const link = document.createElement('a');
      link.download = doc.name;
      const blob = await fetch(`${this.$serverRef(doc.pdfRef, encodeURIComponent(doc.name))}`).then(res => res.blob());
      const downloadUrl = URL.createObjectURL(blob);
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(`${this.$serverRef(doc.pdfRef, encodeURIComponent(doc.name))}`);
    },
  },
};

/**
 * Simple usage of userSetting store module.
 *
 * Provides getters and setters for each [localKey] from given mapping object.
 * Read from and writes into userSetting[settingsKey].
 *
 * Mapping object: {
 *   [localKey1]: [settingsKey1],
 *   [localKey2]: [settingsKey2],
 *   [localKey3]: [settingsKey3],
 * }
 *
 * Don't forget to add each [settingsKey] to DEFAULT_SETTINGS in the userSetting
 * store module if needed.
 *
 * @example
 *   mixins: [
 *     settings('userSetting', {
 *       'visibleColumns': 'dealerOverview.visibleColumns',
 *     }),
 *   ],
 */
export function settings(store, mapping) {
  const computed = mapObject(mapping, ([localKey, settingsKey]) => ([
    [localKey],
    {
      get() {
        return this.$store.state[store][settingsKey];
      },
      set(value) {
        this.$store.dispatch(`${store}/setSetting`, {
          [settingsKey]: value,
        });
      },
    },
  ]));

  return {
    computed: { ...computed },
  };
}

/**
 * Add data state changed tracking.
 *
 * Provides "dataChanged" flag to check if the data state changed.
 *
 * @property {Object} options  Use e. g. omit to exclude the tracking for some keys
 * @property {Number} interval Check interval in millis (default is 300)
 *
 * @example
 *   mixins: [
 *     trackStateChanges({
 *       omit: ['darkMode', 'fullscreen'],
 *     }),
 *   ],
 */
export function trackStateChanges(options = {}, interval = 300) {
  return {
    data: () => ({
      dataChanged: false,
    }),
    created() {
      this.$once('startTracking', this._startTracking);
      this.$on('resetTracking', this._resetTracking);
    },
    beforeDestroy() {
      this.$off('startTracking', this._startTracking);
      this.$off('resetTracking', this._resetTracking);
      this._stopTracking();
    },
    methods: {
      _resetTracking() {
        this.$options._clonedDataState = this._cloneDataState();
        this.dataChanged = false;
      },
      async _startTracking() {
        // Await data inserted into inputs
        await this.$nextTick();

        // Await input response back to data
        await this.$nextTick();

        // Because I can :D
        await this.$nextTick();

        this._resetTracking();
        this.$options._trackingInterval = setInterval(() => {
          this.dataChanged = this._checkDataStateChanged();
        }, interval);
      },
      _stopTracking() {
        clearInterval(this.$options._trackingInterval);
      },
      _cloneDataState() {
        let data = _.cloneDeep(this._data);

        // Omit irrelevant data
        data = _.omit(data, ['dataChanged', ...(options?.omit || [])]);

        return data;
      },
      _checkDataStateChanged() {
        const currentDataState = this._cloneDataState();

        function isEqual(a, b) {
          return _.isEqualWith(a, b, function(a, b) {
            // Changes between null, undefined and '' are no changes
            if (!a && !b) return true;

            // No return here to return undefined -> Normal equal fn getting used
          });
        }

        return !isEqual(currentDataState, this.$options._clonedDataState);
      },
    },
  };
}
