import axios from 'axios';

const state = () => ({
  list: [],
  cursor: null,
});

const mutations = {
  RESET(s) {
    Object.assign(s, state());
  },
  SET(state, { list, cursor }) {
    state.list = list;
    state.cursor = cursor;
  },
  ADD(state, entry) {
    state.list.unshift(entry);
  },
  UPDATE(state, entry) {
    const index = state.list.findIndex(k => k.ID === entry.ID);
    if (index > -1) state.list.splice(index, 1, entry);
  },
  PATCH(state, entry) {
    const index = state.list.findIndex(k => k.ID === entry.ID);
    if (index > -1) Object.assign(state.list[index], entry);
  },
  REMOVE(state, ID) {
    const index = state.list.findIndex(k => k.ID === ID);
    if (index > -1) state.list.splice(index, 1);
  },
};

const actions = {
  reset({ commit }) {
    commit('RESET');
  },
  async getList({ commit, state }, {
    filter,
    order,
    abortSignal,
  }) {
    let resetList = false;
    if (!state.cursor) resetList = true;

    const { data } = await axios.get('v2/invoices', {
      params: {
        filter,
        order,
        cursor: state.cursor,
      },
      signal: abortSignal,
    });

    if (abortSignal.aborted) return;

    const { list, cursor } = data;

    commit('SET', {
      list: resetList ? list : state.list.concat(list),
      cursor,
    });

    return !!cursor; // isMore?
  },
  async create({ commit }, invoice) {
    const { data } = await axios.post('v2/invoices', invoice);
    commit('ADD', data.invoice);
    return data.invoice;
  },
  async update({ commit }, invoice) {
    await axios.post(`v2/invoices/${invoice.ID}`, invoice);
    commit('UPDATE', invoice);
  },
  async get({ commit }, invoiceId) {
    const { data } = await axios.get(`v2/invoices/${invoiceId}`);
    commit('UPDATE', data.data);
    return data.data;
  },
  async saveDetails(_, invoiceDetails) {
    await axios.post(`v2/invoices/${invoiceDetails.invoiceId}/details`, invoiceDetails);
  },
  async getDetails({ commit }, invoiceId) {
    const { data } = await axios.get(`v2/invoices/${invoiceId}/details`);
    commit('UPDATE', data.invoice);
    return data;
  },
  async remove({ commit }, invoiceId) {
    await axios.delete(`v2/invoices/${invoiceId}`);
    commit('REMOVE', invoiceId);
  },
  async finalize({ commit }, invoiceId) {
    const { data } = await axios.patch(`v2/invoices/${invoiceId}/finalize`);
    commit('UPDATE', data.invoice);
    return data;
  },
  async updateStatus({ commit }, { invoiceId, status }) {
    await axios.patch(`v2/invoices/${invoiceId}/status`, { status });
    commit('PATCH', { ID: +invoiceId, status });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
