import moment from 'moment';
import prettysize from 'prettysize';
import mobileLogo from '@/assets/mobileAppLogo.png';
import as24Logo from '@/assets/as24AppLogo.png';
import autengoLogo from '@/assets/autengoLogo.png';
import _, { isPlainObject } from 'lodash';
import allIcons from './allIcons';
import docTypes from './docTypes';
import Vue from 'vue';

export function formatDate(value, format = 'DD.MM.YYYY') {
  if (!value) {
    return null;
  }
  return moment(value).format(format);
}

export function formatDateIntelligent(value) {
  if (!value) return '';
  if (moment().isSame(value, 'day')) return moment(value).format('HH:mm');
  if (moment().isSame(value, 'year')) return moment(value).format('DD.MM. HH:mm');
  return moment(value).format('DD.MM.YYYY HH:mm');
}

export function formatUnixDate(value, format = 'DD.MM.YYYY') {
  if (!value) {
    return null;
  }
  return moment.unix(value).format(format);
}

export function formatDateForEmail(value) {
  if (!value) {
    return null;
  }
  if (_.isNumber(value)) {
    const isCurrentDate = moment.unix(value).isSame(new Date(), 'day');
    const isCurrentYear = moment.unix(value).isSame(new Date(), 'year');

    if (isCurrentDate) return moment.unix(value).format('HH:mm');
    if (isCurrentYear) return moment.unix(value).format('DD. MMM');

    return moment.unix(value).format('DD.MM.YY');
  }

  const isCurrentDate = moment.utc(value).isSame(new Date(), 'day');
  const isCurrentYear = moment.utc(value).isSame(new Date(), 'year');

  if (isCurrentDate) return moment.utc(value).format('HH:mm');
  if (isCurrentYear) return moment.utc(value).format('DD. MMM');

  return moment.utc(value).format('DD.MM.YY');
}


export function formatDateForEmailThread(value) {
  if (!value) {
    return null;
  }
  return moment.unix(value).format('dd, DD.MM.YYYY HH:mm');
}

export function formatDateForEmailInfo(value) {
  if (_.isNumber(value)) {
    return `${moment.unix(value).format('DD.MM.YYYY HH:mm')} Uhr`;
  }

  return `${moment.utc(value).format('DD.MM.YYYY HH:mm')} Uhr`;
}

export function formatDateFirstReg(value) {
  if (!value) {
    return null;
  }
  return moment(String(value)).format('MM/YYYY');
}

export function formatNumberBeautiful(value, { decimalPlaces = 2, unit = null } = {}) {
  if (!value && value !== 0) return null;

  if (typeof value === 'string') {
    value = value.replaceAll('.', '').replaceAll(',', '.');
    value = parseFloat(value);
    if (isNaN(value)) return null;
  }

  const precision = 10 ** decimalPlaces;
  value = parseFloat(value) * precision;
  value = Math.round(value) / precision || 0;

  let [full, fraction = ''] = `${value}`.split('.');
  fraction = decimalPlaces ? `,${fraction.padEnd(decimalPlaces, '0')}` : '';
  full = full.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  unit = unit ? ` ${unit}` : '';
  return full + fraction + unit;
}

export function currency(value, decimalPlaces = 0, currencySymbol = '€') {
  if (!value && value !== 0) {
    return null;
  }
  const val = (value / 1).toFixed(decimalPlaces).replace('.', ',');
  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${currencySymbol}`;
}

export function currencyCentsToEuro(value, currencySymbol = '€') {
  if (!value && value !== 0) {
    return null;
  }
  let valString = value.toString();
  if (value === 0) valString = '000';
  const val = `${valString.slice(0, valString.length - 2) || '0'},${valString.slice(valString.length - 2, valString.length)}`;
  return `${val.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${currencySymbol}`;
}

export function currencyWith2DecimalPlaces(value) {
  return currency(value, 2);
}

export function decimal(value, decimalPlaces = 2) {
  if (typeof value !== 'number') value = 0;
  return value.toFixed(decimalPlaces).replace('.', ',');
}

export function commaDecimalPlaces(value) {
  return (value / 1).toFixed(2).replace('.', ',');
}

export function formatDateToNumDay(value) {
  if (!value) {
    return null;
  }
  return moment(String(value)).format('D');
}

export function formatDateToShortMonth(value) {
  if (!value) {
    return null;
  }
  moment.locale('de');
  return moment(String(value)).format('MMM');
}

export function formatDateToYear(value) {
  if (!value) {
    return null;
  }
  return moment(String(value)).format('YYYY');
}

export function formatTime(value) {
  if (!value) {
    return null;
  }
  return moment(String(value)).format('HH:mm');
}

export function standingDays(value) {
  if (!value) {
    return null;
  }
  return moment().diff(moment(String(value)), 'days');
}

export function numberWithPoints(value) {
  if (!value) {
    return '0';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatGender(value) {
  if (!value) {
    return null;
  }

  return value === 'Mr' ? 'Herr' : 'Frau';
}

export function formatName(obj, nodata = '') {
  if (!obj) return '';
  if (typeof obj === 'string') return obj;

  const name = [];

  // organization
  if (obj.companyName) {
    name.push(obj.companyName);
  }

  // person
  if (obj.titleOrDegree) {
    name.push(obj.titleOrDegree);
  }

  if (obj.firstName) {
    name.push(obj.firstName);
  }

  if (obj.lastName) {
    name.push(obj.lastName);
  }

  // In some cases the name is actually behind the name key
  if (!name.length && obj.name) {
    return obj.name;
  }

  return name.join(' ') || nodata;
}

export function getCustomerInitials(customer) {
  if (!customer) {
    return null;
  }

  const firstName = customer.firstName ? customer.firstName.trim() : null;
  const lastName = customer.lastName ? customer.lastName.trim() : null;
  const email = customer.email ? customer.email.trim() : null;

  if (firstName && lastName) {
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  }
  if (firstName && !lastName) {
    return (firstName.charAt(0) + firstName.charAt(1)).toUpperCase();
  }
  if (!firstName && lastName) {
    return (lastName.charAt(0) + lastName.charAt(1)).toUpperCase();
  }
  if (!firstName && !lastName && email) {
    return email.charAt(0).toUpperCase();
  }

  return '';
}

export function formatSource(value) {
  let source = '';
  if (!value) {
    return null;
  }
  switch (value) {
    case 'walkIn':
      source = 'Laufkundschaft';
      break;
    case 'mail':
      source = 'E-Mail';
      break;
    case 'platform':
      source = 'Plattform';
      break;
    case 'phone':
      source = 'Telefon';
      break;
    case 'offlineMarketing':
      source = 'Offline-Werbung';
      break;
    case 'onlineMarketing':
      source = 'Online-Werbung';
      break;
    default:
      source = '';
  }
  return source;
}

export function contactType(value) {
  let source = '';
  if (!value) {
    return null;
  }
  switch (value) {
    case 'customer':
      source = 'Kunde';
      break;
    case 'supplier':
      source = 'Lieferant';
      break;
    case 'partner':
      source = 'Partner';
      break;
    case 'prospect':
      source = 'Interessent';
      break;
    default:
      source = '';
  }
  return source;
}

export function iconForSource(value) {
  if (!value) {
    return null;
  } else if (value === 'as24') {
    return `<img class="source-logo"src="${as24Logo}">`;
  } else if (value === 'mobile') {
    return `<img class="source-logo" src="${mobileLogo}">`;
  }
  return `<img class="source-logo" src="${autengoLogo}">`;
}

export function translateVehicleState(state, vehicleStateList) {
  const relevantVehicleState = vehicleStateList.find(vehicleState => vehicleState.ecd === state);
  return relevantVehicleState ? relevantVehicleState.localized : state;
}

export function tFuel(state, vehicleFuelTypeList) {
  const relevantVehicleFuelType = vehicleFuelTypeList.find(fuelType => fuelType.ecd === state);
  return relevantVehicleFuelType ? relevantVehicleFuelType.localized : state;
}

export function tTaxRate(state, taxRateList) {
  if (state == null) return 'keine Angabe';
  const relevantTaxRate = taxRateList.find(item => item.ecd === state);
  return relevantTaxRate ? relevantTaxRate.localized : state;
}

export function tCondition(state, vehicleConditionTypeList) {
  const relevantVehicleConditionType = vehicleConditionTypeList.find(fuelType => fuelType.ecd === state);
  return relevantVehicleConditionType ? relevantVehicleConditionType.localized : state;
}

export function tTransmission(state, vehicleTransmissionTypeList) {
  const relevantVehicleTransmissionType = vehicleTransmissionTypeList.find(transmissionType => transmissionType.ecd === state);
  return relevantVehicleTransmissionType ? relevantVehicleTransmissionType.localized : state;
}

export function tVehicleType(state, vehicleTypeList) {
  const relevantVehicleState = vehicleTypeList.find(vehicleState => vehicleState.ecd === state);
  return relevantVehicleState ? relevantVehicleState.app : state;
}

export function tColor(state, colorList) {
  const relevantColor = colorList.find(vehicleState => vehicleState.ecd === state);
  return relevantColor ? relevantColor.app : state;
}

export function tEmission(state, emissionClassList) {
  const relevantEmission = emissionClassList.find(vehicleState => vehicleState.ecd === state);
  return relevantEmission ? relevantEmission.localized : state;
}

export function netPrice(price, taxRate) {
  if (!taxRate) taxRate = 0;
  return parseFloat(price) / ((parseFloat(taxRate) + 100) / 100);
}

export function grossPrice(price, taxRate) {
  if (!taxRate) taxRate = 0;
  return parseFloat(price) * ((parseFloat(taxRate) + 100) / 100);
}

export function toOptions(items, labelAttr, valueAttr, omit = []) {
  if (!items) return [];

  if (isPlainObject(items)) {
    items = Object.values(items);
  }

  items = items.map(item => ({
    label: item[labelAttr],
    value: valueAttr ? item[valueAttr] : item,
  }));

  if (omit.length) {
    items = items.filter(({ value }) => !omit.includes(value));
  }

  return items;
}

export function kwToPs(kw) {
  const i = kw * 1.36;
  return Math.round(Math.round(i * 100) / 100);
}

export function psToKw(ps) {
  const i = ps / 1.36;
  return Math.round(Math.round(i * 100) / 100);
}

export function isEmpty(object) {
  return Object.keys(object).length === 0;
}

export function simpleStar(value) {
  if (!value) return null;

  const starredValue = `${value} <span class="text-red">*</span>`;
  return starredValue;
}

export function star(value, model) {
  if (!model) return value;

  const starredValue = `${value} <span class="text-red">*</span>`;

  const validations = Object.keys(model);
  const isRequired = validations.includes('required');
  const conditionallyRequireds = validations.filter(validation => validation.match(/required.+/));
  const hasConditionallyRequireds = !!conditionallyRequireds.length;

  if (isRequired) {
    return starredValue;
  } else if (hasConditionallyRequireds) {
    const evaluatedConditions = conditionallyRequireds.map(conditionallyRequired => model.$params[conditionallyRequired].params.evaluateCondition());
    if (evaluatedConditions.reduce((result, evaluated) => result || evaluated)) {
      return starredValue;
    }
  }

  return value;
}

export function calculateWidth(height, ratioWidth, ratioHeight) {
  return (height / ratioHeight) * ratioWidth;
}

export function calculateHeight(width, ratioWidth, ratioHeight) {
  return (width / ratioWidth) * ratioHeight;
}

export function number(value) {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  }
  return value;
}

export function orderBy(values, field, by) {
  if (!values) {
    return null;
  }
  const sorted = values.sort((value1, value2) => {
    const fieldValue1 = _.get(value1, field);
    const fieldValue2 = _.get(value2, field);

    if (fieldValue1 < fieldValue2) return -1;
    if (fieldValue1 > fieldValue2) return 1;
    return 0;
  });
  return by === 'asc' ? sorted : sorted.reverse();
}

const cmToPxRatio = 37.795;
export function cmToPx(cm) {
  return cm * cmToPxRatio;
}

export function pxToCm(px) {
  return px / cmToPxRatio;
}

export function generateId() {
  return Math.round(Math.random() * Date.now()).toString();
}

export function translateBoolean(bool) {
  return bool ? 'Ja' : 'Nein';
}

export function getEmailOrEmailName(object) {
  if (object.name && object.name.length > 0) return `${object.name} <${object.email}>`;
  return object.email;
}

// this should be based on a ressource from backend
export function translateHistoryAction(value) {
  let source = '';
  if (!value) {
    return null;
  }
  switch (value) {
    case 'receive':
      source = 'empfangen';
      break;
    case 'create':
    case 'created':
      source = 'erstellt';
      break;
    case 'send':
      source = 'gesendet';
      break;
    case 'added':
      source = 'hinzugefügt';
      break;
    case 'set':
      source = 'aktualisiert';
      break;
    case 'unset':
      source = 'entfernt';
      break;
    default:
      source = value;
  }
  return source;
}

export function convertContactsToAddressPairs(contacts) {
  if (!contacts) return null;

  return contacts.map((pair) => {
    if (pair.email) {
      return pair;
    }
    return { name: '', email: pair };
  });
}

export function convertAddressPairsToContacts(pairs) {
  if (!pairs) return null;
  return pairs.map((pair) => {
    if (pair.name !== '') {
      return `${pair.name} <${pair.email}>`;
    }
    return pair.email;
  });
}

export function fullUserNameOrDefault(user) {
  if (!user) return 'autengo';
  if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
  if (user.firstName && !user.lastName) return `${user.firstName}`;
  if (!user.firstName && user.lastName && user.title) return `${formatGender(user.title)} ${user.lastName}`;
  if (!user.firstName && user.lastName) return `${user.lastName}`;
  return 'autengo';
}

export function formatFilesize(filesize) {
  return prettysize(filesize, false, false, 2);
}

export function emailAddressName(participant) {
  if (participant.name.length > 0) {
    return participant.name;
  }
  return participant.email.split('@')[0];
}

export function getProperFileType(fileType, withDot = false) {
  let result = '';
  if (withDot) result = '.';
  if (fileType !== null && !fileType.includes('/')) result += fileType.trim();
  if (fileType !== null && fileType.includes('/')) result += fileType.split('/')[1].trim();
  return result;
}

export function isEven(num) {
  return num % 2 === 0;
}
export function isOdd(num) {
  return num % 2 === 1;
}
export function translateDoctype(docType) {
  return docType.split('/').pop();
}

export function kwAndPs(kw) {
  if (!kw) kw = '0';
  const i = kw * 1.36;
  const ps = Math.round(Math.round(i * 100) / 100);

  return `${kw} kW (${ps} PS)`;
}

export function getAllIcons() {
  return allIcons;
}

export function getDocTypes() {
  return docTypes;
}

export function formatBytes(bytes, decimals) {
  if (bytes === 0) return { size: 0, unit: 'Bytes' };
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 0;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

export function reactiveSet(obj, path, value) {
  if (!_.isObject(obj) || !path) return;

  deepSet(obj, path.split('.'), value);

  function deepSet(curObj, properties, curValue) {
    if (properties.length === 1) {
      Vue.set(curObj, properties.pop(), curValue);
    } else {
      const property = properties.shift();
      if (!_.isObject(curObj) || _.isUndefined(curObj[property])) {
        Vue.set(curObj, property, {});
      }
      deepSet(curObj[property], properties, curValue);
    }
  }
}

export function getFileType(fileType, withDot) {
  const fname = getProperFileType(fileType, withDot);
  return fname.slice((Math.max(0, fname.lastIndexOf('.')) || 0) + (fname.lastIndexOf('.') >= 0 ? 1 : 0));
}

export function truncateWithEllipses(text, max) {
  if (max === undefined) max = 20;
  return text.substr(0, max - 1) + (text.length > max ? '&hellip;' : '');
}

export function getAttachmentIcon(filename) {
  let icon = 'far ';
  switch (getFileType(filename).toLowerCase()) {
    case 'doc':
    case 'docx':
      icon += 'fa-file-word';
      break;
    case 'xls':
    case 'xlsx':
      icon += 'fa-file-excel';
      break;
    case 'pdf':
    case 'application/pdf':
      icon += 'fa-file-pdf';
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/jpg':
    case 'image/svg+xml':
      icon += 'fa-file-image';
      break;
    default:
      icon += 'fa-file';
      break;
  }
  return icon;
}

export function vehicleName(vehicle) {
  if (!vehicle) return '';

  const name = [vehicle.makeName];

  if (vehicle.modelName && vehicle.modelName !== 'Andere') {
    name.push(vehicle.modelName);
  }

  if (vehicle.modelVersion) {
    name.push(vehicle.modelVersion);
  }

  return name.join(' ');
}

export function vehicleNameShort(vehicle) {
  if (!vehicle) return '';

  const name = [vehicle.makeName];

  if (vehicle.modelName && vehicle.modelName !== 'Andere') {
    name.push(vehicle.modelName);
  }

  if (vehicle.modelVersion) {
    name.push(`${vehicle.modelVersion.slice(0, 10)}`);
    if (vehicle.modelVersion.length > 10) name.push('...');
  }

  return name.join(' ');
}

export function vehicleNameWithId(vehicle) {
  if (!vehicle) return '';

  const name = [vehicle.makeName];

  if (vehicle.modelName && vehicle.modelName !== 'Andere') {
    name.push(vehicle.modelName);
  }

  name.push(`(${vehicle.internalId || vehicle.sellingId})`);

  return name.join(' ');
}

export function translateOpportunityState(value) {
  let source = '';
  if (!value) {
    return null;
  }
  switch (value) {
    case 'lost':
      source = 'verloren';
      break;
    case 'won':
      source = 'gewonnen';
      break;
    case 'rejected':
      source = 'abgelehnt';
      break;
    case 'new':
      source = 'offen';
      break;
    default:
      source = value;
  }
  return source;
}

export function toListString(obj, clue = ',') {
  return Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join(clue);
}

export function toUrlString(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

export function formatNumber(value) {
  if (typeof value !== 'number') return '';
  return value.toString().replace('.', ',');
}

export async function authorizeAs24() {
  const uri = 'https://api.autoscout24.com/auth/oauth/v2/authorize?';

  const uriParams = {
    response_type: 'code',
    client_id: '172ae16f-eafd-49dd-af19-e500650b2196',
    redirect_uri: encodeURIComponent('https://api.easycardealer.com/v1/autoscoutCallback'),
  };

  const windowFeatures = {
    toolbar: 'no',
    location: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 400,
    height: 600,
  };

  const as24AuthWindow = window.open(
    uri + toListString(uriParams, '&'),
    'targetWindow',
    toListString(windowFeatures),
  );

  if (!as24AuthWindow) {
    throw new Error('Can not open a pop-up');
  }

  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (as24AuthWindow.closed) {
        resolve();
        clearInterval(timer);
      }
    }, 500);
  });
}

export function parseKeysToIn(object, keys) {
  for (let i = 0; i < keys.length; i += 1) {
    object[keys[i]] = parseInt(object[keys[i]], 10);
  }
  return object;
}

export function clean(obj) {
  Object.keys(obj).forEach((propName) => {
    if (obj[propName] && typeof obj[propName] === 'object') {
      clean(obj[propName]);
    } else if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  });
  return obj;
}

export async function wait(millis) {
  if (moment.isDuration(millis)) {
    millis = millis.asMilliseconds();
  }
  return new Promise(resolve => setTimeout(resolve, millis));
}

export class Defer {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = (...args) => {
        this.resolved = true;
        this.settled = true;
        return resolve(...args);
      };
      this.reject = (error) => {
        this.rejected = true;
        this.settled = true;
        return reject(error);
      };
    });
  }
}

export function openModal({ component, ...props }, root) {
  const defer = new Defer();

  // Direct component input
  if (props.render) {
    component = props;
    props = {};
  }

  props.open = true;

  const node = document.createElement('div');
  document.body.appendChild(node);

  const vm = new Vue({
    el: node,
    parent: root,
    data() {
      return { props };
    },
    render: h => h(component, {
      ref: 'modal',
      props,
      on: {
        hide,
      },
    }),
    mounted() {
      this.$refs.modal.show();
    },
  });

  function hide($event) {
    defer.resolve($event);
    vm.$destroy();
  }

  return defer.promise;
}

export function mapObject(obj, mapFn) {
  return Object.fromEntries(Object.entries(obj).map(mapFn));
}

export function getForwardMailBody(message) {
  return `
            <br>
            <br>
            <hr id="old-mail">
            <div style="border-left: 1px solid grey; padding-left: 10px; margin-top: 10px; padding-top: 10px">
              <strong>Von:</strong> ${message.from.map(p => getEmailOrEmailName(p)).join(', ')}<br>
              <strong>Gesendet:</strong> ${formatDateForEmailInfo(message.date)}<br>
              <strong>An:</strong> ${message.to.map(p => getEmailOrEmailName(p)).join(', ')}<br>
              ${message.cc.length > 0 ? `<strong>Cc:</strong> ${message.cc.map(p => getEmailOrEmailName(p)).join(', ')}<br>` : ''}
              ${message.bcc.length > 0 ? `<strong>Bcc:</strong> ${message
                .bcc.map(p => getEmailOrEmailName(p)).join(', ')}<br>` : ''}
              <strong>Betreff:</strong> ${message.subject}<br>
              <br>
              ${message.body}
            </div>
          `;
}

export function encodeThreadId(threadId) {
  return btoa(threadId)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, ''); // Remove padding
}

export function decodeThreadId(encodedId) {
  return atob(
    encodedId
      .replace(/-/g, '+')
      .replace(/_/g, '/'),
  );
}

export function decodeHtmlEntities(text) {
  const entities = {
    '&nbsp;': ' ',
    '&#160;': ' ',
    '&lt;': '<',
    '&#60;': '<',
    '&gt;': '>',
    '&#62;': '>',
    '&amp;': '&',
    '&#38;': '&',
    '&quot;': '"',
    '&#34;': '"',
    '&apos;': '\'',
    '&#39;': '\'',
    '&cent;': '¢',
    '&#162;': '¢',
    '&pound;': '£',
    '&#163;': '£',
    '&yen;': '¥',
    '&#165;': '¥',
    '&euro;': '€',
    '&#8364;': '€',
    '&copy;': '©',
    '&#169;': '©',
    '&reg;': '®',
    '&#174;': '®',
  };

  return text.replace(/&[a-zA-Z0-9#]+;/g, match => entities[match] || match);
}

export function readPath(source, path) {
  if (!(source instanceof Object)) return undefined;

  path = path instanceof Array ? path : path.split('.');

  let key;
  while (path.length && !key) key = path.shift();

  return path.length ? readPath(source[key], path) : source[key];
}
