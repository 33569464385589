import axios from 'axios';
import { clean } from '@/plugins/util';


const initialState = () => ({
  list: [],
  loading: true,
  queryParams: {
    limit: 25,
    offset: 0,
  },
  pagination: {
    filteredCount: 0,
    totalCount: 0,
  },
  search: {
    freeText: '',
    requestStatus: [],
    isBookmarked: null,
    isRequested: null,
  },
});

export const mutations = {
  SET_LIST(state, newValue) {
    state.list = newValue;
  },
  SET_PAGINATION(state, newValue) {
    state.pagination = newValue;
  },
  SET_QUERY_PARAMS(state, { limit = 25, offset = 0 }) {
    state.queryParams = { limit, offset };
  },
  RESET_LIST(state) {
    state.list = [];
  },
  RESET_FILTER(state) {
    state.search = initialState().search;
  },
  RESET_FILTER_PROP(state, prop) {
    state.search[prop] = null;
  },
  SET_FILTER_KV(state, { key, value }) {
    if (Array.isArray(state.search[key])) {
      state.search[key] = [value];
    } else {
      state.search[key] = value;
    }
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
};


export const actions = {
  async requestOffer({ commit }, offer) {
    await axios.post('v2/eln/vehicle/offer', offer);
    commit('elnMarketList/UPDATE_VEHICLE', [offer.uid, {
      requestStatus: 'requested',
      isRequested: true,
    }], { root: true });
  },

  async getList({ state, commit }) {
    commit('SET_LOADING', true);

    const { data } = await axios.get('v2/eln/vehicle', {
      params: {
        search: clean(JSON.parse(JSON.stringify(state.search))),
        offset: state.queryParams.offset,
      },
    });

    commit('SET_LIST', data.data);
    commit('SET_PAGINATION', { totalCount: data.totalCount, filteredCount: data.filteredCount });

    commit('SET_LOADING', false);
  },

  setType({ commit }, type) {
    const key = type === 'offers' ? 'isRequested' : 'isBookmarked';
    const unsetKey = type === 'offers' ? 'isBookmarked' : 'isRequested';

    commit('SET_FILTER_KV', { key: unsetKey, value: null });
    commit('SET_FILTER_KV', { key, value: true });
  },

  resetList({ commit }) {
    commit('RESET_LIST');
  },

  async updateVehicle(_, vehicle) {
    await axios.put(`v2/eln/vehicle/${vehicle.ID}`, vehicle);
  },

  async createVehicle({ commit }, vehicle) {
    await axios.post('v2/eln/vehicle', vehicle);

    commit('elnMarketList/UPDATE_VEHICLE', [vehicle.uid, {
      isBookmarked: vehicle.isBookmarked,
    }], { root: true });
  },

  async getMakeModel(_, vehicle) {
    return axios.get('v2/eln/vehicle/makemodel', {
      params: {
        makeKey: vehicle.makeKey,
        modelKey: vehicle.modelKey,
      },

    });
  },

  async importVehicle({ commit }, vehicle) {
    const { data } = await axios.post(`v2/eln/vehicle/${vehicle.uid}/import`, vehicle);
    commit('elnMarketList/UPDATE_VEHICLE', [vehicle.uid, {
      vehicleId: data.data.ID,
      sellingId: data.data.sellingId,
    }], { root: true });
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
