import axios from 'axios';

const initialState = () => ({
  queryParams: {
    limit: 25,
    offset: 0,
  },
  vehicleList: [],
  pagination: {
    filteredCount: 0,
    totalCount: 0,
  },
  // this prop is for q-table only
  serverPagination: {
    page: 1,
    rowsPerPage: 25, // change queryParams.limit too
    rowsNumber: null, // this is the total number of records/totalCount
    sortBy: null,
    descending: false,
  },
  loading: true,
  search: '',
  order: {
    name: 'created',
    dir: 'DESC',
  },
  filter: {
    vehicleState: [],
    makeId: [],
    modelId: [],
    firstRegistration: {
      startDate: null,
      endDate: null,
    },
    price: {
      min: null,
      max: null,
    },
  },
  settings: {
    incoming: {
      listView: true,
      order: {
        name: 'created',
        dir: 'DESC',
      },
    },
    inventory: {
      listView: true,
      order: {
        name: 'created',
        dir: 'DESC',
      },
    },
    sold: {
      listView: true,
      order: {
        name: 'created',
        dir: 'DESC',
      },
    },
    customer: {
      listView: true,
      order: {
        name: 'created',
        dir: 'DESC',
      },
    },
  },
});

export const mutations = {
  SET_VEHICLE_LIST(state, newValue) {
    state.vehicleList = newValue;
  },
  RESET_VEHICLE_LIST(state) {
    state.vehicleList = [];
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.pagination.filteredCount = filteredCount;
    state.pagination.totalCount = totalCount;
    state.serverPagination.rowsNumber = totalCount;
  },
  SET_SERVER_PAGINATION(state, pagination) {
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.order.name = pagination.sortBy || 'created';
    state.order.dir = pagination.descending ? 'DESC' : 'ASC';
    state.serverPagination = pagination;
  },

  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },

  SET_ORDER(state, newValue) {
    state.order = newValue;
  },

  SET_FILTER_KV(state, { key, value }) {
    if (Array.isArray(state.filter[key])) {
      state.filter[key] = [value];
    } else {
      state.filter[key] = value;
    }
  },
  SET_LIST_VIEW(state, { key, value }) {
    state.settings[key].listView = value;
  },
  SET_LIST_QUERY_PARAMS(state, { key, value }) {
    state.queryParams[key] = value;
  },
};

function clean(obj) {
  Object.keys(obj).forEach((propName) => {
    if (obj[propName] && typeof obj[propName] === 'object') clean(obj[propName]);
    else if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  });
  return obj;
}

async function getAllVehicle(queryParams, queryfilter, search, order, branchId = null) {
  let urlParams = queryParams;
  let filter = queryfilter;
  if (filter) {
    filter = clean(JSON.parse(JSON.stringify(queryfilter)));
    urlParams = {
      ...queryParams,
      filter,
      search,
      order,
    };
  }
  if (branchId) urlParams.branchId = branchId;

  return axios.get('v2/vehicle', {
    params: urlParams,
  });
}

export const actions = {
  getVehicleList({ commit, state }, branchId = null) {
    commit('SET_LOADING', true);
    return getAllVehicle(state.queryParams, state.filter, state.search, state.order, branchId).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_VEHICLE_LIST', res.data.data);
      commit('SET_LOADING', false);
    });
  },

  setFilterKey({ commit }, { key, value }) {
    commit('SET_FILTER_KV', { key, value });
  },

  setInventoryType({ commit, state }, type) {
    commit('SET_FILTER_KV', { key: 'vehicleState', value: type });
    commit('SET_ORDER', state.settings[type].order);
  },

  resetVehicleList({ commit }) {
    commit('RESET_VEHICLE_LIST');
  },

  setListView({ commit }, { key, value }) {
    commit('SET_LIST_VIEW', { key, value });
  },
  setQueryParams({ commit }, { key, value }) {
    commit('SET_LIST_QUERY_PARAMS', { key, value });
  },
  setServerPagination({ commit }, pagination) {
    commit('SET_SERVER_PAGINATION', pagination);
  },
  setSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },

  async exportVehicles(_, params) {
    const { data } = await axios.get('v2/vehicle/export', {
      params,
    });

    return data;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
