import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

export const initialState = () => ({
  organizationDetail: {},
  organizationHistory: [],
  organizationVehicles: [],
  loadingHistory: false,
  organizationHistoryTab: 'all',
  actionTab: 'note',
  showAction: false,
  organizationOpportunities: {},
  focusMail: true,
  organizationPlanned: [],
  loadingPlanned: true,
  selectedActivityItem: {},
  organizationAttachmentFiles: [],
});

export const mutations = {
  SET_ORGANIZATION(state, organization) {
    state.organizationDetail = organization;
  },
  SET_HISTORY(state, history) {
    state.organizationHistory = history;
  },
  SET_LOADING_HISTORY(state, loading) {
    state.loadingHistory = loading;
  },
  SET_HISTORY_TAB(state, tab) {
    state.organizationHistoryTab = tab;
  },
  SET_SHOW_ACTION(state, newValue) {
    state.showAction = newValue;
  },
  SET_ACTION_TAB(state, newValue) {
    state.actionTab = newValue;
  },
  SET_OPPORTUNITIES(state, opportunities) {
    state.organizationOpportunities = opportunities;
  },
  SET_PLANNED(state, newValue) {
    state.organizationPlanned = newValue;
  },
  SET_LOADING_PLANNED(state, newValue) {
    state.loadingPlanned = newValue;
  },
  RESET_ACTIVITY(state) {
    state.selectedActivityItem = {};
  },
  SET_ATTACHMENT_FILES(state, files) {
    state.organizationAttachmentFiles = files;
  },
  APPEND_ATTACHMENT_FILES(state, organizationFiles) {
    state.organizationAttachmentFiles = state.organizationAttachmentFiles.concat(organizationFiles);
  },
  SET_VEHICLES(state, vehicles) {
    state.organizationVehicles = vehicles;
  },
  ADD_VEHICLE(state, vehicleId) {
    state.organizationVehicles.push(vehicleId);
  },
  REMOVE_VEHICLE(state, vehicleId) {
    const index = state.organizationVehicles.indexOf(vehicleId);
    if (index < 0) return;
    state.organizationVehicles.splice(index, 1);
  },
  SAVE_VEHICLE_DETAIL(state, vehicleDetail) {
    state[vehicleDetail.ID] = vehicleDetail;
  },
  SET_FOCUS_MAIL(state, focusMail) {
    state.focusMail = focusMail;
  },
  RESET(state) {
    const initial = initialState();
    Object.keys(state).forEach((attr) => {
      state[attr] = initial[attr]; 
    });
  },
};

export const getters = {

  groupedOrganizationHistoryItems: state => _.groupBy(state.organizationHistory, 'type'),

  organizationHistoryItemAmounts: (state, getters) => ({
    all: state.organizationHistory.length,
    ..._.mapValues(getters.groupedOrganizationHistoryItems, items => items.length),
  }),

  filteredOrganizationHistoryItems(state, getters) {
    if (state.organizationHistoryTab === 'all') {
      return state.organizationHistory;
    }
    return getters.groupedOrganizationHistoryItems[state.organizationHistoryTab];
  },

  organizationHistoryItems(state, getters) {
    return _.flow([
      data => _.groupBy(data, item => moment(item.created).format('MMMM YYYY')),
      data => _.map(data, (items, month) => ({ items, month })),
      // order items by month
      data => _.orderBy(data, [entry => parseInt(moment(entry.items[0].created).format('YYYYMM'), 10)], ['desc']),
      data => data.map(({ items, ...rest }) => ({ ...rest, items: _.orderBy(items, ['created'], ['desc']) })),
    ])(getters.filteredOrganizationHistoryItems);
  },

};

export const actions = {
  async createOrganization(storeUtils, data) {
    const result = await axios.post('v2/contact/organization', data);
    return result.data.data;
  },

  async getOrganizationById({ commit, dispatch }, id) {
    const { data: { data: organization } } = await axios.get(`/v2/contact/organization/${id}`);

    await dispatch('getContactPersonOpportunities', organization.contactPersons);

    commit('SET_ORGANIZATION', organization);

    return organization;
  },

  async getContactPersonOpportunities({ dispatch, commit }, contactPersons) {
    const opportunities = await Promise.all(contactPersons.map(mapContactPersonOpportunities));

    async function mapContactPersonOpportunities(contactPerson) {
      return {
        openDeals: await dispatch('contact/getOpenDealsByContactId', contactPerson.ID, { root: true }),
        pastDeals: await dispatch('contact/getPastDealsByContactId', contactPerson.ID, { root: true }),
      };
    }

    commit('SET_OPPORTUNITIES', opportunities.reduce((result, opportunity) => ({
      pastDeals: [...result.pastDeals, ...opportunity.pastDeals],
      openDeals: [...result.openDeals, ...opportunity.openDeals],
    }), { pastDeals: [], openDeals: [] }));
  },

  async updateOrganization({ commit }, organization) {
    const { data: { data } } = await axios.put(`/v2/contact/organization/${organization.ID}`, organization);
    commit('SET_ORGANIZATION', data);
  },

  async deleteOrganizationById({ dispatch }, id) {
    await axios.delete(`/v2/contact/organization/${id}`);
    dispatch('resetOrganizationDetail');
  },

  async getOrganizationHistory({ commit, state }) {
    if (!state.organizationDetail.ID) return;
    if (state.organizationHistory.length < 1) commit('SET_LOADING_HISTORY', true);

    const { data: organizationHistory } = (await axios.get(`/v2/contact/organization/${state.organizationDetail.ID}/activity/history`)).data;

    const contactHistories = (await Promise.all(state.organizationDetail.contactPersons.map(person => axios.get(`/v2/contact/${person.ID}/activity/history`))))
      .map(response => response.data.data);

    const opportunityHistories = (await Promise.all(state.organizationDetail.contactPersons.map(person => axios.get(`/v2/contact/${person.ID}/deals`))))
      .map(response => response.data.data.map(opportunity => opportunity.history));

    commit('SET_HISTORY', [
      ...organizationHistory,
      ..._.flatten(contactHistories),
      ..._.flattenDeep(opportunityHistories),
    ]);
    commit('SET_LOADING_HISTORY', false);
  },

  updateOrganizationHistoryTab({ commit }, tab) {
    commit('SET_HISTORY_TAB', tab);
  },

  async createOrganizationNote(storeUtils, { note, organizationId }) {
    return (await axios.post(`/v2/contact/organization/${organizationId}/notes`, note)).data.data;
  },

  async deleteOrganizationNote({ dispatch }, historyItem) {
    if (historyItem.opportunityId) {
      await dispatch('opportunityDetail/deleteOpportunityNote', {
        noteId: historyItem.noteId,
        opportunityId: historyItem.opportunityId,
      }, { root: true });
    } else if (historyItem.contactId) {
      await dispatch('contact/deleteContactNote', {
        noteId: historyItem.noteId,
        contactId: historyItem.contactId,
      }, { root: true });
    } else if (historyItem.organizationId) {
      await dispatch('deleteContactNote', {
        noteId: historyItem.noteId,
        organizationId: historyItem.organizationId,
      });
    }
    dispatch('getOrganizationHistory');
  },

  async deleteContactNote(storeUtils, { noteId, organizationId }) {
    await axios.delete(`/v2/contact/organization/${organizationId}/notes/${noteId}`);
  },

  async deleteOrganizationFile({ dispatch }, historyItem) {
    if (historyItem.opportunityId) {
      await dispatch('opportunityDetail/deleteOpportunityFile', {
        fileId: historyItem.fileId,
        opportunityId: historyItem.opportunityId,
      }, { root: true });
    } else if (historyItem.contactId) {
      await dispatch('contact/deleteContactFile', historyItem, { root: true });
    } else if (historyItem.organizationId) {
      await dispatch('document/deleteDocument', historyItem.file, { root: true });
    }
    dispatch('getOrganizationHistory');
  },

  showOrganizationAction({ commit }, show) {
    commit('SET_SHOW_ACTION', show);
  },

  updateActionTab({ commit }, tab) {
    commit('SET_ACTION_TAB', tab);
  },

  setNewMail({ commit }, newMail) {
    commit('SET_NEW_MAIL', newMail);
  },

  async getContactByHistoryItem({ dispatch }, historyItem) {
    if (historyItem.contactId) {
      return dispatch('contact/getContactById', historyItem.contactId);
    } else if (historyItem.opportunityId) {
      return (await dispatch('opportunityDetail/getOpportunityById', historyItem.opportunityId)).customer;
    }
    return null;
  },

  async getPlannedByOrganizationId({ commit, state }, organizationId) {
    if (state.organizationPlanned.length < 1) commit('SET_LOADING_PLANNED', true);
    const { data } = await axios.get(`/v2/contact/organization/${organizationId}/activity/planned`);
    commit('SET_PLANNED', data.data);
    commit('SET_LOADING_PLANNED', false);
  },

  async addOrganizationActivity({ dispatch }, { data, organizationId, mode }) {
    await axios.post(`/v2/contact/organization/${organizationId}/activity/${mode}`, data);
    if (mode === 'history') await dispatch('getOrganizationHistory');
    if (mode === 'planned') await dispatch('getPlannedByOrganizationId', organizationId);
  },

  async deleteOrganizationActivity({ dispatch }, { activityId, organizationId, mode }) {
    await axios.delete(`/v2/contact/${organizationId}/activity/${mode}/${activityId}`);
    await dispatch('getOrganizationHistory');
    await dispatch('getPlannedByOrganizationId', organizationId);
  },

  resetActivity({ commit }) {
    commit('RESET_ACTIVITY');
  },

  async fetchFilteredAttachmentFiles({ dispatch, commit, state }, { limit, offset, search }) {
    const contactPersonIds = state.organizationDetail.contactPersons && state.organizationDetail.contactPersons.length > 0
      ? state.organizationDetail.contactPersons.map(person => person.ID) : [];

    const { data, ...pagination } = await dispatch('document/getRawDocuments', {
      params: {
        category: 'Rechnung',
        excludeCategory: true,
        limit,
        search,
        offset,
        filter: {
          customerId: contactPersonIds,
        },
      },
    }, { root: true });
    commit('APPEND_ATTACHMENT_FILES', data);
    return { pagination, data };
  },

  async getVehiclesByOrganizationId({ commit }, organizationId) {
    const { data: { data: vehicles } } = await axios.get(`/v2/contact/organization/${organizationId}/vehicles`);
    commit('SET_VEHICLES', vehicles);
  },

  async addVehicleToOrganization({ commit }, { organizationId, vehicleId }) {
    const res = await axios.post(`/v2/contact/organization/${organizationId}/vehicles/${vehicleId}`);
    commit('ADD_VEHICLE', vehicleId);
    return res.data;
  },

  async removeVehicleFromOrganization({ commit }, { organizationId, vehicleId }) {
    await axios.delete(`/v2/contact/organization/${organizationId}/vehicles/${vehicleId}`);
    commit('REMOVE_VEHICLE', vehicleId);
  },

  async getVehicleDetailById({ commit, state }, vehicleId) {
    if (state.vehiclesDetails[vehicleId]) {
      return state.vehiclesDetails[vehicleId];
    }

    const { data: { data: vehicleDetail } } = await axios.get(`/v2/vehicle/id/${vehicleId}`);
    commit('SAVE_VEHICLE_DETAIL', vehicleDetail);
    return vehicleDetail;
  },

  resetOrganizationAttachmentFiles({ commit }) {
    commit('SET_ATTACHMENT_FILES', []);
  },

  setFocusMail({ commit }, focusMail) {
    commit('SET_FOCUS_MAIL', focusMail);
  },

  resetOrganizationDetail({ commit }) {
    commit('RESET');
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
