import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';

const notify = new Vue();

export const initialState = () => ({
  importResult: {
    couldNotMatch: [{}],
    matches: [{}],
    notSupported: [{}],
  },
  importProgress: {
    progress: 0,
    message: null,
  },
  mergeImportProgress: {
    progress: 0,
    message: null,
  },
  itemsToImport: [],
  importLogs: [],
  importDetailLog: [],
  assignResult: {
    adsToMatch: [],
    notSupported: [],
  },
});

export const mutations = {
  SET_IMPORT_RESULT(state, value) {
    state.importResult = value;
  },
  SET_IMPORT_LOG(state, value) {
    const groupedObject = _.groupBy(value, 'jobId');
    state.importLogs = Object.values(groupedObject);
  },
  SET_IMPORT_DETAIL_LOG(state, value) {
    state.importDetailLog = value;
  },
  RESET_IMPORT_RESULT(state) {
    const initial = initialState();
    state.importResult = initial.importResult;
  },
  SET_ITEMS_TO_IMPORT(state, value) {
    state.itemsToImport = value;
  },
  RESET_ITEMS_TO_IMPORT(state) {
    state.itemsToImport = [];
  },
  SET_IMPORT_PROGRESS(state, { progress, message }) {
    state.importProgress = { progress, message };
  },
  SET_MERGE_IMPORT_PROGRESS(state, { progress, message }) {
    state.mergeImportProgress = { progress, message };
  },
  SET_ASSIGN_RESULT(state, value) {
    state.assignResult = value;
  },
};

export const getters = {
  selctionStateItemToImport: (state) => {
    const couldNotMatchLength = state.importResult.couldNotMatch.length;
    const matchesLength = state.importResult.matches.length;
    const itemsToImportLength = state.itemsToImport.length;
    if (itemsToImportLength === 0) return false;
    if (couldNotMatchLength + matchesLength === itemsToImportLength) return true;
    return null;
  },
};

export const actions = {

  startImport({ commit, rootGetters }, payload) {
    commit('RESET_IMPORT_RESULT');
    payload.socketId = rootGetters['socket/getSocketId'];

    commit('SET_IMPORT_PROGRESS', { progress: 1, message: 'Gestartet' });
    return axios.post('/v2/inserate/import', payload);
  },

  fetchAds({ commit, rootGetters }, payload) {
    commit('RESET_IMPORT_RESULT');
    payload.socketId = rootGetters['socket/getSocketId'];

    commit('SET_IMPORT_PROGRESS', { progress: 1, message: 'Gestartet' });
    return axios.post('/v2/inserate/import/assign', payload);
  },

  async mergeAndImport({ commit, rootGetters, state }, branchId) {
    const payload = {};
    payload.data = state.itemsToImport;
    payload.socketId = rootGetters['socket/getSocketId'];
    payload.branchId = branchId;

    commit('SET_IMPORT_PROGRESS', { progress: 1, message: 'Gestartet' });
    await axios.put('/v2/inserate/import', payload);
    commit('RESET_IMPORT_RESULT');
  },

  async getImportLogs({ commit }, branchId) {
    const { data } = await axios.get(`/v2/inserate/import/logs/${branchId}`);
    commit('SET_IMPORT_LOG', data.data);
  },

  handleImportResult({ commit }, message) {
    console.log(message);
    if (message.status === 'success') {
      notify.$notifyUserInteraction.success('Import erfolgreich erstellt');

      if (!message.data.matches) {
        notify.$notifyUserInteraction.warning('Keine Fahrzeuge gefunden!');
      }

      commit('SET_IMPORT_RESULT', message.data);
      const itemsWhichCanBeImported = [];
      // push so we don't loose the object reference and can use array.some() etc.
      for (let i = 0; i < message.data.matches.length; i += 1) {
        itemsWhichCanBeImported.push(message.data.matches[i]);
      }
      commit('SET_ITEMS_TO_IMPORT', itemsWhichCanBeImported);
      commit('SET_IMPORT_PROGRESS', {
        progress: 100,
        message: 'Erfolgreich',
      });
    }

    if (message.status === 'error') {
      notify.$notifyUserInteraction.error('Import fehlgeschlagen');
      commit('SET_IMPORT_PROGRESS', {
        progress: 100,
        message: 'Fehlgeschlagen',
      });
    }
  },

  handleImportMergeResult({ commit }, message) {
    console.log(message);

    if (message.status === 'progress') {
      commit('SET_MERGE_IMPORT_PROGRESS', {
        progress: message.progress,
        message: message.data,
      });
    }

    if (message.status === 'success') {
      commit('SET_IMPORT_DETAIL_LOG', message.data);
      notify.$notifyUserInteraction.success('Import erfolgreich erstellt');
      commit('SET_MERGE_IMPORT_PROGRESS', { progress: 100, message: 'Erfolgreich' });
    }

    if (message.status === 'error') {
      notify.$notifyUserInteraction.error('Import fehlgeschlagen');
      commit('SET_MERGE_IMPORT_PROGRESS', {
        progress: 100,
        message: 'Fehlgeschlagen',
      });
    }
  },

  handleAssignResult({ commit }, message) {
    console.log(message);

    if (message.status === 'success') {
      commit('SET_ASSIGN_RESULT', message.data);
      notify.$notifyUserInteraction.success('Import erfolgreich erstellt');
      commit('SET_IMPORT_PROGRESS', { progress: 100, message: 'Erfolgreich' });
    }

    if (message.status === 'error') {
      notify.$notifyUserInteraction.error('Import fehlgeschlagen');
    }
  },

  moveCouldNotMatchToMatch({ state }, newlyMatchItem) {
    state.importResult.matches.push(newlyMatchItem);
    const { couldNotMatch } = state.importResult;

    state.importResult.couldNotMatch = couldNotMatch.filter((item) => {
      let isAs24;
      let isMobileDe;

      if (item.as24Ad.adData) {
        isAs24 = item.as24Ad.adData.as24Id === newlyMatchItem.as24Ad.adData.as24Id;
      }

      if (item.mobileDeAd.adData) {
        isMobileDe = item.mobileDeAd.adData.mobileDeId === newlyMatchItem.mobileDeAd.adData.mobileDeId;
      }
      if (isAs24 || isMobileDe) return false;
      return true;
    });
  },

  moveMatchToCouldNotMatch({ state }, matchItemToRemove) {
    let indexToRemove;
    state.importResult.matches.some((item, index) => {
      indexToRemove = index; return item === matchItemToRemove; 
    });
    state.importResult.matches.splice(indexToRemove, 1);

    state.importResult.couldNotMatch.push({ as24Ad: matchItemToRemove.as24Ad, mobileDeAd: {} });
    state.importResult.couldNotMatch.push({ as24Ad: {}, mobileDeAd: matchItemToRemove.mobileDeAd });
  },

  addItemToImport({ state }, addItem) {
    state.itemsToImport.push(addItem);
  },

  removeItemFromImport({ state }, removeItem) {
    let indexToRemove;
    state.itemsToImport.some((item, index) => {
      indexToRemove = index; return item === removeItem; 
    });
    state.itemsToImport.splice(indexToRemove, 1);
  },

  addAllToImport({ state }) {
    const itemsToImport = [...state.importResult.matches, ...state.importResult.couldNotMatch];
    state.itemsToImport = itemsToImport;
  },

  removeAllFromImport({ state }) {
    state.itemsToImport = [];
  },


};


export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
