import axios from 'axios';

export const initialState = () => ({
  customerList: [],
  customerNumList: [],
  customerIdList: [],
  customerDetail: {},
  customerHistory: [],
  isCustomer: true,
  vehiclesList: [],
  mailData: {},
  tableProperties: {
    serverPagination: {
      page: 1,
      rowsNumber: 5,
    },
    search: '',
  },
  bulkCreateList: [],
  importHistoryList: [],
  error: null,
  customerDocuments: [],
});

export const mutations = {
  SET_CUSTOMER_LIST(state, newValue) {
    state.customerList = newValue;
  },
  SET_CUSTOMER_NUM_LIST(state, newValue) {
    state.customerNumList = newValue;
  },
  SET_CUSTOMER_ID_LIST(state, newValue) {
    state.customerIdList = newValue;
  },
  SET_CUSTOMER_DETAIL(state, newValue) {
    state.customerDetail = newValue;
  },
  SET_CUSTOMER_HISTORY(state, newValue) {
    state.customerHistory = newValue;
  },
  RESET_CUSTOMER(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
  },
  RESET_CUSTOMER_DETAIL(state) {
    state.customerDetail = initialState().customerDetail;
  },
  DELETE_CUSTOMER_DETAIL(state) {
    state.customerDetail = [];
  },
  SET_CUSTOMER_VEHICLE_LIST(state, newValue) {
    state.vehiclesList = newValue;
  },
  SET_MAIL_DATA(state, newValue) {
    state.mailData = newValue;
  },
  SET_TABLE_PROPERTIES(state, newValue) {
    state.tableProperties = newValue;
  },
  BULK_CREATE_FAILED(state, error) {
    state.error = error;
  },
  SET_BULK_CREATE_LIST(state, newValue) {
    state.bulkCreateList = newValue;
  },
  SET_IMPORT_HISTORY_LIST(state, newValue) {
    state.importHistoryList = newValue;
  },
  SET_DOCS(state, docs) {
    state.customerDocuments = docs;
  },
};

function getAllCustomers(filter) {
  let urlParams = { limit: 25 };
  if (filter) {
    urlParams = Object.assign(urlParams, filter);
  }
  return axios.get('v2/customer', {
    params: urlParams,
  });
}

export const actions = {

  getAllCustomers({ commit } = {}, filter) {
    return getAllCustomers(filter)
      .then((res) => {
        commit('SET_CUSTOMER_LIST', res.data);
      });
  },

  getCustomerNumList({ commit }) {
    return axios.get('v2/customer/', { params: { limit: 25 } }).then((res) => {
      const arr = res.data.data;
      const customerNumList = [];
      for (let i = 0; i < arr.length; i += 1) {
        customerNumList.push(arr[i].customerNumber);
      }
      commit('SET_CUSTOMER_NUM_LIST', customerNumList);
    });
  },

  getCustomerById({ commit }, id) {
    return axios.get(`v2/customer/id/${id}`).then((res) => {
      commit('SET_CUSTOMER_DETAIL', res.data.data);
      commit('SET_CUSTOMER_HISTORY', res.data.data.history);
    });
  },

  async updateCustomer({ commit }, customer) {
    const { data: retrievedCustomer } = (await axios.put(`v2/customer/id/${customer.ID}`, customer)).data;
    commit('SET_CUSTOMER_DETAIL', retrievedCustomer);
  },

  getCustomerByCustomerNumber({ commit, dispatch }, id) {
    return axios.get(`v2/customer/${id}`).then((res) => {
      commit('SET_CUSTOMER_DETAIL', res.data.data);
      commit('SET_CUSTOMER_HISTORY', res.data.data.history);
      dispatch('getDocs', id);
    });
  },

  getCustomerVehicleList({ commit }, id) {
    return axios.get(`v2/customer/${id}/vehicle`).then((res) => {
      commit('SET_CUSTOMER_VEHICLE_LIST', res.data.data);
    });
  },

  deleteCustomer({ commit, dispatch }, { data, id }) {
    axios.delete(`v2/customer/${id}`, data).then(() => {
      commit('DELETE_CUSTOMER_DETAIL');
      dispatch('getAllCustomers');
    });
  },

  addVehicle({ dispatch }, { data, id }) {
    axios.post(`v2/customer/${id}/vehicle`, data).then(() => Promise.all([
      dispatch('getCustomerVehicleList', id),
      dispatch('getCustomerByCustomerNumber', id),
    ]));
  },

  deleteVehicle({ dispatch }, { data, id, vehicleId }) {
    axios.delete(`v2/customer/${id}/vehicle/${vehicleId}`, data).then(() => Promise.all([
      dispatch('getCustomerVehicleList', id),
      dispatch('getCustomerByCustomerNumber', id),
    ]));
  },

  addNote({ dispatch }, { data, customerNumber }) {
    axios.post(`v2/customer/${customerNumber}/note`, data)
      .then(() => {
        dispatch('getCustomerByCustomerNumber', customerNumber);
      });
  },

  sendMail({ commit, state, dispatch }, { data, id }) {
    commit('SET_MAIL_DATA', data);
    // Todo switch mail to v2
    axios.post('/v1/mail', state.mailData).then(() => dispatch('getCustomerByCustomerNumber', id));
  },

  saveCustomerEdits({ dispatch }, { data, id }) {
    axios.put(`v2/customer/${id}`, data)
      .catch((error) => {
        console.log(error);
      })
      .then(() => dispatch('getCustomerByCustomerNumber', id));
  },

  async addNewCustomer(storeUtils, data) {
    return axios.post('v2/contact', data).then(res => res.data.data);
  },

  bulkCreate({ commit } = {}, customerData) {
    commit('BULK_CREATE_FAILED', null);
    return axios.post('v2/customer/import', customerData).then((res) => {
      commit('SET_BULK_CREATE_LIST', res.data.data);
    }).catch((error) => {
      console.log(error);
      commit('BULK_CREATE_FAILED', error.response.data.errorLocalized);
      return null;
    });
  },

  getImportHistory({ commit } = {}) {
    return axios.get('v2/customer/importHistory').then((res) => {
      console.log('res: ', res);
      commit('SET_IMPORT_HISTORY_LIST', res.data.data);
    }).catch(() => null);
  },

  resetCustomer({ commit }) {
    commit('RESET_CUSTOMER');
  },

  resetCustomerDetail({ commit }) {
    commit('RESET_CUSTOMER_DETAIL');
  },

  // Customer Docs
  getDocs({ commit }, customerNumber) {
    return axios.get(`/v2/customer/${customerNumber}/documents/`).then((response) => {
      commit('SET_DOCS', response.data.data.data);
    });
  },

  deleteDoc({ dispatch }, { customerNumber, id }) {
    return axios.delete(`/v2/customer/${customerNumber}/documents/${id}`).then(() => {
      dispatch('getDocs', customerNumber);
    });
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
