const defaultPosition = 'top-right';
const defaultType = 'info';
const validInputTypes = [
  'error',
  'success',
  'info',
  'warning',
];
const inputTypeMapping = {
  error: 'negative',
  success: 'positive',
  info: 'info',
  warning: 'warning',
};

function triggerNotification(Vue, message, type, position, dismissable = false) {
  const options = {
    message,
    type,
    position: position || defaultPosition,
  };
  if (dismissable) {
    options.timeout = 30000;
    options.actions = [
      {
        icon: 'close',
        handler: () => { },
      },
    ];
  }
  Vue.prototype.$q.notify(options);
}

function notifications(Vue, position) {
  const notify = type => (message, dismissable) => triggerNotification(Vue, message, type, position, dismissable);


  return {
    info: notify('info'),
    warning: notify('warning'),
    success: notify('positive'),
    error: notify('negative'),
    notify: (type, message, dismissable) => {
      notify(validInputTypes.includes(type) ? inputTypeMapping[type] : defaultType)(message, dismissable);
    },
  };
}

export default {
  install: (Vue) => {
    Vue.prototype.$notifyUserInteraction = notifications(Vue, 'top-right');
    Vue.prototype.$notifyMessage = notifications(Vue, 'bottom-right');
  },
};
