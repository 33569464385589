import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';

const DEFAULT_SETTINGS = {
  'layout.showMiniDrawer': true,
  'layout.darkMode': false,
  'vehicle.duplicate.copyParts': ['images'],
  'vehicle.duplicate.noModal': false,
  'vehicle.layout.showOverview': true,
  'dealerOverview.visibleColumns': [
    'online',
    'name',
    'email',
    'vehicleCount',
    'trialPeriodEnd',
    'packages',
    'created',
  ],
};

export function state() {
  const savedSettings = Object.entries(localStorage)
    .map(([key, value]) => {
      if (!key.startsWith('user.setting.')) return null;
      try {
        return {
          key: key.replace('user.setting.', ''),
          value: JSON.parse(value),
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    })
    .filter(Boolean)
    .reduce((settings, { key, value }) => {
      settings[key] = value;
      return settings;
    }, {});

  if (localStorage['auth.isAdmin'] === 'true') {
    DEFAULT_SETTINGS['layout.darkMode'] = true;
  }

  return {
    ...DEFAULT_SETTINGS,
    ...savedSettings,
    notificationDrawerOpen: false,
  };
}

export const mutations = {
  RESET(state, obj) {
    obj = Object.assign({}, DEFAULT_SETTINGS, obj);
    Object.entries(obj).forEach(([key, value]) => {
      Vue.set(state, key, value);
      localStorage[`user.setting.${key}`] = JSON.stringify(value);
    });
  },
  SET(state, obj) {
    Object.entries(obj).forEach(([key, value]) => {
      Vue.set(state, key, value);
      localStorage[`user.setting.${key}`] = JSON.stringify(value);
    });
  },
  SET_LOCAL(state, { key, value }) {
    state[key] = value;
  },
};

export const actions = {
  async getAllSettings({ rootState, commit }) {
    const userId = rootState.auth.currentUser.ID;
    return axios.get(`/v2/user/${userId}/setting`)
      .then((res) => {
        commit('RESET', res.data.data);
      });
  },

  async getSetting({ rootState, commit }, key) {
    const userId = rootState.auth.currentUser.ID;
    return axios.get(`/v2/user/${userId}/setting/${key}`)
      .then((res) => {
        commit('SET', res.data.data);
      });
  },

  async setSetting({ rootState, commit }, setting) {
    // eslint-disable-next-line prefer-destructuring
    const [key, value] = Object.entries(setting)[0];

    if (
      !_.isBoolean(value)
      && !_.isNumber(value)
      && !_.isString(value)
      && !_.isArray(value)
    ) throw new Error('The type of value have to be one of {boolean|number|string|array}!');

    commit('SET', {
      [key]: value,
    });

    const userId = rootState.auth.currentUser.ID;
    return axios.post(`/v2/user/${userId}/setting/${key}`, { value });
  },

  async removeSetting({ rootState, commit }, key) {
    commit('SET', {
      [key]: DEFAULT_SETTINGS[key],
    });

    const userId = rootState.auth.currentUser.ID;
    return axios.delete(`/v2/user/${userId}/setting/${key}`);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
