import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { fullUserNameOrDefault, toOptions } from '@/plugins/util';

export const initialState = () => ({
  opportunityDetail: {},
  opportunityHistory: [],
  opportunityPlanned: [],
  opportunitySources: [],
  selectedActivityItem: {},
  loadingHistory: true,
  loadingPlanned: true,
  showAction: false,
  actionTab: 'note',
  historyTab: 'all',
  focusMail: true,
  opportunityAttachmentFiles: [],
});

export const mutations = {
  SET_OPPORTUNITY_DETAIL(state, newValue) {
    state.opportunityDetail = newValue;
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  SET_HISTORY(state, newValue) {
    state.opportunityHistory = newValue;
  },

  SET_SOURCES(state, newValue) {
    state.opportunitySources = newValue;
  },

  SET_LOADING_HISTORY(state, newValue) {
    state.loadingHistory = newValue;
  },

  SET_PLANNED(state, newValue) {
    state.opportunityPlanned = newValue;
  },

  SET_LOADING_PLANNED(state, newValue) {
    state.loadingPlanned = newValue;
  },

  SET_ACTIVITY(state, newValue) {
    state.selectedActivityItem = newValue;
  },

  RESET_ACTIVITY(state) {
    state.selectedActivityItem = {};
  },

  SET_SHOW_ACTION(state, newValue) {
    state.showAction = newValue;
  },

  SET_ACTION_TAB(state, newValue) {
    state.actionTab = newValue;
  },

  SET_HISTORY_TAB(state, tab) {
    state.historyTab = tab;
  },

  SET_FOCUS_MAIL(state, focusMail) {
    state.focusMail = focusMail;
  },

  SET_ATTACHMENT_FILES(state, files) {
    state.opportunityAttachmentFiles = files;
  },

  APPEND_ATTACHMENT_FILES(state, opportunityFiles) {
    state.opportunityAttachmentFiles = state.opportunityAttachmentFiles.concat(opportunityFiles);
  },
};

export const getters = {
  customerName: state => fullUserNameOrDefault(state.opportunityDetail.customer),
  customer: state => state.opportunityDetail.customer,

  groupedHistoryItems: state => _.groupBy(state.opportunityHistory, 'type'),

  historyItemAmounts: (state, getters) => ({
    all: state.opportunityHistory.length,
    ..._.mapValues(getters.groupedHistoryItems, items => items.length),
  }),

  filteredHistoryItems(state, getters) {
    if (state.historyTab === 'all') {
      return state.opportunityHistory;
    }
    return getters.groupedHistoryItems[state.historyTab];
  },

  historyItemsByMonth(state, getters) {
    return _.flow([
      data => _.groupBy(data, item => moment(item.created).format('MMMM YYYY')),
      data => _.map(data, (items, month) => ({ items, month })),
      data => _.orderBy(data, [entry => parseInt(moment(entry.items[0].created).format('YYYYMM'), 10)], ['desc']),
      data => data.map(({ items, ...rest }) => ({ ...rest, items: _.orderBy(items, ['created'], ['desc']) })),
    ])(getters.filteredHistoryItems);
  },
  opportunityId: (state) => {
    if (state.opportunityDetail.ID) return state.opportunityDetail.ID;
    return null;
  },
  opportunitySourcesAsOption: state => toOptions(state.opportunitySources, 'source', 'ID'),
  sourceName: (state) => {
    const sourceName = state.opportunitySources.find(source => source.ID === state.opportunityDetail.sourceType);
    if (sourceName) return sourceName.source;
    return '';
  },
};

function getFiles({
  params, filter, limit, offset, search, opportunityId,
} = {}) {
  const urlParams = {
    search: search || '',
    limit: limit || 10000,
    offset: offset || 0,
  };
  if (params) {
    Object.assign(urlParams, params);
  }
  if (filter) {
    Object.assign(urlParams, { filter });
  }

  return axios.get(`/v2/opportunity/${opportunityId}/files`, {
    params: urlParams,
  });
}

export const actions = {
  async fetchFilteredAttachmentFiles({ commit }, {
    limit, offset, search, opportunityId,
  }) {
    const { data: { data, ...pagination } } = await getFiles({
      limit,
      search,
      offset,
      opportunityId,
    });
    commit('APPEND_ATTACHMENT_FILES', data);
    return { pagination, data };
  },

  resetOpportunityAttachmentFiles({ commit }) {
    commit('SET_ATTACHMENT_FILES', []);
  },

  async createOpportunity({ commit }, data) {
    const { data: { data: newOpportunity } } = await axios.post('v2/opportunity', data);
    commit('SET_OPPORTUNITY_DETAIL', newOpportunity);
    return newOpportunity;
  },

  getOpportunityById({ commit, dispatch }, opportunityId) {
    return axios.get(`/v2/opportunity/${opportunityId}`).then((res) => {
      dispatch('getHistoryByOpportunityId', opportunityId);
      dispatch('getPlannedByOpportunityId', opportunityId);
      commit('SET_OPPORTUNITY_DETAIL', res.data.data);
    });
  },

  async updateOpportunity({ commit }, opportunity) {
    const { data } = await axios.put(`/v2/opportunity/${opportunity.ID}`, opportunity);
    commit('SET_OPPORTUNITY_DETAIL', data.data);
    return data.data;
  },

  async getPlannedByOpportunityId({ commit, state }, opportunityId) {
    if (state.opportunityPlanned.length < 1) commit('SET_LOADING_PLANNED', true);
    const { data } = await axios.get(`/v2/opportunity/${opportunityId}/activity/planned`);
    commit('SET_PLANNED', data.data);
    commit('SET_LOADING_PLANNED', false);
  },

  async getHistoryByOpportunityId({ commit, state }, opportunityId) {
    if (state.opportunityHistory.length < 1) commit('SET_LOADING_HISTORY', true);
    const { data } = await axios.get(`/v2/opportunity/${opportunityId}/activity/history`);
    commit('SET_HISTORY', data.data);
    commit('SET_LOADING_HISTORY', false);
  },

  async deleteOpportunity(storeUtils, opportunityId) {
    await axios.delete(`/v2/opportunity/${opportunityId}`);
  },

  // Notes
  async addOpportunityNote(storeUtils, { note, opportunityId }) {
    const {
      data: { data },
    } = await axios.post(`/v2/opportunity/${opportunityId}/notes`, note);
    return data;
  },

  async deleteOpportunityNote({ dispatch }, { noteId, opportunityId }) {
    await axios.delete(`/v2/opportunity/${opportunityId}/notes/${noteId}`);
    await dispatch('getHistoryByOpportunityId', opportunityId);
  },

  // Status
  async addOpportunityStatus(storeUtils, { payload, opportunityId }) {
    const {
      data: { data },
    } = await axios.post(`/v2/opportunity/${opportunityId}/status`, payload);
    return data;
  },

  // Activity
  async addOpportunityActivity({ dispatch }, { data, opportunityId, mode }) {
    await axios.post(`/v2/opportunity/${opportunityId}/activity/${mode}`, data);
    if (mode === 'history') await dispatch('getHistoryByOpportunityId', opportunityId);
    if (mode === 'planned') await dispatch('getPlannedByOpportunityId', opportunityId);
  },

  async deleteOpportunityActivity({ dispatch }, { activityId, opportunityId, mode }) {
    await axios.delete(`/v2/opportunity/${opportunityId}/activity/${mode}/${activityId}`);
    await dispatch('getHistoryByOpportunityId', opportunityId);
    await dispatch('getPlannedByOpportunityId', opportunityId);
  },

  // Source
  async addOpportunitySource({ dispatch }, source) {
    const { data } = await axios.post('/v2/opportunity/source/', source);
    dispatch('getOpportunitySources');
    return data;
  },
  async getOpportunitySources({ commit }) {
    const { data } = await axios.get('/v2/opportunity/source/');
    commit('SET_SOURCES', data);
    return data;
  },
  async getOpportunitySourcesCount(storeUtils, id) {
    const { data } = await axios.get(`/v2/opportunity/source/${id}`);
    return data.count;
  },
  async deleteOpportunitySourceById({ dispatch }, id) {
    const { data } = await axios.delete(`/v2/opportunity/source/${id}`);
    dispatch('getOpportunitySources');
    return data;
  },
  async changeOpportunitySourceById({ dispatch }, id) {
    const { data } = await axios.put(`/v2/opportunity/source/change/${id}`);
    dispatch('deleteOpportunitySourceById', id);
    return data;
  },
  async updateOpportunitySourceById({ dispatch }, data) {
    await axios.put(`/v2/opportunity/source/${data.ID}`, { source: data.source }).then(() => {
      dispatch('getOpportunitySources');
    });
  },

  // File
  async uploadFile(storeUtils, { filesWithId, opportunityId }) {
    const payload = new FormData();
    Array.from(filesWithId).forEach(({ file }) => {
      payload.append('files', file);
    });
    payload.append('opportunityId', opportunityId);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const { data } = await axios.post(`/v2/opportunity/${opportunityId}/files`, payload, { headers });
    return data.data;
  },

  async deleteOpportunityFile({ dispatch }, { fileId, opportunityId }) {
    await axios.delete(`/v2/opportunity/${opportunityId}/files/${fileId}`);
    await dispatch('getHistoryByOpportunityId', opportunityId);
  },

  resetOpportunityDetail({ commit }) {
    commit('RESET');
  },

  showOpportunityAction({ commit }, show) {
    commit('SET_SHOW_ACTION', show);
  },

  setActionTab({ commit }, tab) {
    commit('SET_ACTION_TAB', tab);
  },

  setActivityItem({ commit }, activity) {
    commit('SET_ACTIVITY', activity);
  },

  resetActivity({ commit }) {
    commit('RESET_ACTIVITY');
  },

  updateHistoryTab({ commit }, tab) {
    commit('SET_HISTORY_TAB', tab);
  },

  setNewMail({ commit }, newMail) {
    commit('SET_NEW_MAIL', newMail);
  },

  setFocusMail({ commit }, focusMail) {
    commit('SET_FOCUS_MAIL', focusMail);
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
};
