//
// This store is just for handling the generall socket events. Pls put the individual socket events in the store they belong to
//
export const state = {
  connect: false,
  socketId: null,
  jobIds: new Set(),
};

export const getters = {
  getSocketId: (state) => {
    const { socketId } = state;
    state.jobIds.add(socketId);
    return socketId;
  },
  isValidJobId: state => jobId => state.jobIds.has(jobId),
};

export const mutations = {
  SOCKET_CONNECT: (state) => {
    state.connect = true;
  },
  SOCKET_ID: (state, id) => {
    state.socketId = id;
  },
  SOCKET_DISCONNECT(state) {
    state.connect = false;
  },
};

export const actions = {

  onConnected({ commit }, socket) {
    commit('SOCKET_CONNECT');
    commit('SOCKET_ID', socket.id);
  },
  onDisconnected({ commit }) {
    commit('SOCKET_DISCONNECT');
    commit('SOCKET_ID', null);
  },
  isValidJobId({ getters }, jobId) {
    return getters.isValidJobId(jobId);
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
