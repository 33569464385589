<template>
  <a-modal
    v-model="isOpen"
    :css="{ width: '100vw', maxWidth: '1000px' }"
    :title="notification.title"
    :position="null"
    :noBackdropDismiss="false"
    @close="onClose"
  >
    <template slot="content">
      <div v-html="notification.description" />
    </template>
  </a-modal>
</template>

<script>


export default {
  name: 'NotificationsDialog',

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Object,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    onClose() {
      this.$store.dispatch('notifications/closeDialog', this.notification.ID);
      this.isOpen = false;
    },
  },

  created() {
    this.isOpen = this.open;
  },

};
</script>
