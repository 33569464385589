import axios from 'axios';
import { Notify } from 'quasar';


export const initialState = {
  notifications: [],
};

export const mutations = {
  SET_NOTIFICATIONS(state, newValue) {
    state.notifications = newValue;
  },
};

export const actions = {

  getAllNotifications({ commit } = {}) {
    return axios.get('/v2/notifications').then((res) => {
      commit('SET_NOTIFICATIONS', res.data.data);
    });
  },

  closeDialog({ dispatch }, ID) {
    return axios.put(`/v2/notifications/${ID}`, {
      showPopUp: false,
      isRead: true,
    }).then(() => {
      dispatch('getAllNotifications');
    });
  },

  handleNewNotification({ dispatch }, msg) {
    const notifyConf = {
      message: `${msg.title}`,
      detail: msg.snippet.substring(0, 50),
      timeout: 10000,
      position: 'bottom-right',
      color: 'grey-9',
      textColor: 'white',
    };

    dispatch('getAllNotifications');

    Notify.create(notifyConf);
  },

};


export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
