import axios from 'axios';


export const actions = {

  async uploadMedia(_, file) {
    const data = new FormData();
    data.append('file', file);

    const response = await axios.post('/v2/media/upload', data, {
      headers: { 'Content-type': 'multipart/form-data' },
    });

    return response.data;
  },

  async deleteMedia(_, uuid) {
    const response = await axios.delete(`/v2/media/${uuid}`);
    return response.data;
  },

};


export default {
  namespaced: true,
  actions,
};
