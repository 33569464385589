import axios from 'axios';
import Vue from 'vue';
import router from '../../router';
import { standingDays, vehicleNameShort } from '@/plugins/util';

const notify = new Vue();

const initialState = () => ({
  currentVehicle: null,
  plattform: {
    1: 'as24Ad',
    2: 'mobileDeAd',
    4: 'elnAd',
  },
  plattformNames: {
    1: 'Autoscout24',
    2: 'Mobile.de',
    4: 'ELN',
  },
  mobileDeStats: {
    loading: false,
  },
  as24AdStats: {
    loading: false,
  },
  mobileDeSellerInfo: {},
  mobileDeAd: {
    providerId: 2,
    redPencil: 0,
    topOfPage: 0,
    eyeCatcher: 0,
    pageOneAd: 0,
    adTurbo: 0,
    isPublished: 1,
    highlight1: '',
    highlight2: '',
    highlight3: '',
    progress: 0,
    actionStarted: false,
    loading: false,
    loadingMessage: '',
  },
  as24Ad: {
    providerId: 1,
    isPublished: 1,
    progress: 0,
    actionStarted: false,
    loading: false,
    loadingMessage: '',
  },
  elnAd: {
    providerId: 4,
    isPublished: 1,
    progress: 0,
    actionStarted: false,
    loading: false,
    loadingMessage: '',
  },
  facebookAd: {
    facebookReasons: [],
  },
  tempCounter: 0,
  adList: [],
  totalCount: 0,
  loading: true,
  directOffer: {
    offerText: '',
    validForDays: null,
  },
});

const KEYS_TO_REMOVE_CREATE = [
  'loading',
  'progress',
  'loadingMessage',
  'actionStarted',
  'priceRating',
];

const KEYS_TO_REMOVE = [
  ...KEYS_TO_REMOVE_CREATE,
  'providerName',
  'created',
  'vehicleId',
  'updated',
];

function removeKeys(obj, arr) {
  return Object.keys(obj)
    .filter(key => !arr.includes(key))
    .reduce((filteredObj, key) => {
      filteredObj[key] = obj[key];
      return filteredObj;
    }, {});
}

export const getters = {
  hasAds: (state) => {
    if (state.mobileDeAd.ID || state.as24Ad.ID || state.elnAd.ID) {
      return true;
    }
    return false;
  },
  howManyAds: (state) => {
    let count = 0;
    if (state.mobileDeAd.ID) count += 1;
    if (state.as24Ad.ID) count += 1;
    if (state.elnAd.ID) count += 1;
    return count;
  },
  mobileDePriceRatingPreview: (state, getters, rootState) => rootState.vehicle.vehicleMobileDePriceRating,

  // mobile.de seller rights
  canMakeDirectOffers: (state) => {
    if (
      state.mobileDeSellerInfo &&
      state.mobileDeSellerInfo.settings &&
      state.mobileDeSellerInfo.settings.directOffer.eligible &&
      !state.mobileDeSellerInfo.settings.directOffer.blocked &&
      state.mobileDeAd.ID &&
      state.mobileDeStats.parkings > 0
    ) {
      return true;
    }
    return false;
  },
  canShowVideoUrl: (state) => {
    if (
      state.mobileDeSellerInfo &&
      state.mobileDeSellerInfo.settings &&
      state.mobileDeSellerInfo.settings.videoUrl
    ) {
      return true;
    }
    return false;
  },
};

export const mutations = {
  SET_CURRENT_VEHICLE(state, vehicleId) {
    state.currentVehicle = vehicleId;
  },
  RESET_MOBLEDEAD(state) {
    state.mobileDeAd = initialState().mobileDeAd;
    state.mobileDeStats = initialState().mobileDeStats;
  },
  SET_AD_MOBILEDE_STATS(state, newValue) {
    state.mobileDeStats = { ...initialState().mobileDeStats, ...newValue };
  },
  SET_AD_AS24_STATS(state, newValue) {
    state.as24AdStats = { ...initialState().as24AdStats, ...newValue };
  },
  SET_SELLER_INFO(state, newValue) {
    state.mobileDeSellerInfo = newValue;
  },
  RESET_AS24AD(state) {
    state.as24Ad = initialState().as24Ad;
    state.as24AdStats = initialState().as24Ad.as24AdStats;
  },
  RESET_ELNAD(state) {
    state.elnAd = initialState().elnAd;
  },
  RESET_ADS(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      if (key !== 'mobileDeSellerInfo') {
        state[key] = initial[key];
      }
    });
  },
  SET_ALL_ADS(state, newValue) {
    const mobileDeAd = newValue.find(ad => ad.providerId === 2);
    const as24Ad = newValue.find(ad => ad.providerId === 1);
    const facebookAd = newValue.find(ad => ad.providerId === 3);
    const elnAd = newValue.find(ad => ad.providerId === 4);
    const initial = initialState();
    if (mobileDeAd) state.mobileDeAd = { ...initial.mobileDeAd, ...mobileDeAd };
    if (as24Ad) state.as24Ad = { ...initial.as24Ad, ...as24Ad };
    if (elnAd) state.elnAd = { ...initial.elnAd, ...elnAd };
    if (facebookAd) {
      facebookAd.facebookReasons = JSON.parse(facebookAd.facebookReasons);
      state.facebookAd = { ...initial.facebookAd, ...facebookAd };
    }
  },
  SET_SINGLE_AD(state, newValue) {
    if (newValue.providerId === 2) state.mobileDeAd = { ...newValue, ...state.mobileDeAd };
    if (newValue.providerId === 4) state.elnAd = { ...newValue, ...state.mobileDeAd };
  },
  SET_AD_PROGRESS(state, newValue) {
    const plattform = state.plattform[newValue.providerId];
    if (typeof newValue.progress.loading !== 'undefined') state[plattform].loading = newValue.progress.loading;
    if (typeof newValue.progress.progress !== 'undefined') state[plattform].progress = newValue.progress.progress;
    if (typeof newValue.progress.loadingMessage !== 'undefined') state[plattform].loadingMessage = newValue.progress.loadingMessage;
    if (typeof newValue.progress.actionStarted !== 'undefined') state[plattform].actionStarted = newValue.progress.actionStarted;
  },
  SET_TEMP_COUNTER(state) {
    state.tempCounter += 1;
  },
  RESET_TEMP_COUNTER(state) {
    state.tempCounter = 0;
  },
  SET_AD_LIST(state, newValue) {
    state.adList = newValue.data;
    state.totalCount = newValue.totalCount;
    state.loading = false;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_DIRECT_OFFER(state, newValue) {
    state.directOffer = newValue;
  },
};

export const actions = {
  handlePushResult({ dispatch, state, commit }, message) {
    if (message.status === 'success') {
      dispatch('vehicleList/getAllVehicles', null, { root: true });
      commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
      let providerName;
      if (message.providerId === 4) {
        providerName = 'ELN';
      } else if (message.providerId === 2) {
        providerName = 'mobile.de';
      } else {
        providerName = 'Autoscout24.de';
      }
      if (message.vehicle.sellingId === state.currentVehicle) dispatch('getAds', state.currentVehicle);
      notify.$notifyUserInteraction.success(`Inserat auf ${providerName} erfolgreich erstellt`);
    }
    if (message.status === 'progress') dispatch('handleProgress', message);
    if (message.status === 'error') dispatch('handleError', message);
  },

  handleUpdateResult({ dispatch, state }, message) {
    if (message.status === 'success') {
      let providerName;
      if (message.providerId === 4) {
        providerName = 'ELN';
      } else if (message.providerId === 2) {
        providerName = 'mobile.de';
      } else {
        providerName = 'Autoscout24.de';
      }
      if (message.vehicle.sellingId === state.currentVehicle) dispatch('getAds', state.currentVehicle);
      notify.$notifyUserInteraction.success(`Inserat auf ${providerName} erfolgreich geupdatet`);
    }
    if (message.status === 'progress') dispatch('handleProgress', message);
    if (message.status === 'error') dispatch('handleError', message);
    if (message.status === 'warning') dispatch('handleWarning', message);
  },

  handlePublicationResult({ dispatch, state }, message) {
    if (message.status === 'success') {
      dispatch('vehicleList/getAllVehicles', null, { root: true });
      let providerName;
      if (message.providerId === 4) {
        providerName = 'ELN';
      } else if (message.providerId === 2) {
        providerName = 'mobile.de';
      } else {
        providerName = 'Autoscout24.de';
      }
      if (message.vehicle.sellingId === state.currentVehicle) dispatch('getAds', state.currentVehicle);
      notify.$notifyUserInteraction.success(`Inserat auf ${providerName} erfolgreich geupdatet`);
    }
    if (message.status === 'progress') dispatch('handleProgress', message);
    if (message.status === 'error') dispatch('handleError', message);
    if (message.status === 'warning') dispatch('handleWarning', message);
  },

  handleRemoveResult({ commit, dispatch }, message) {
    if (message.status === 'success') {
      dispatch('vehicleList/getAllVehicles', null, { root: true });
      let providerName;
      if (message.providerId === 4) {
        commit('RESET_ELNAD');
        providerName = 'ELN';
      } else if (message.providerId === 2) {
        commit('RESET_MOBLEDEAD');
        providerName = 'mobile.de';
      } else {
        commit('RESET_AS24AD');
        providerName = 'Autoscout24.de';
      }
      if (message.operation === 8) providerName = 'Autengo';
      notify.$notifyUserInteraction.success(`Inserat auf ${providerName} erfolgreich gelöscht`);
    }
    if (message.status === 'progress') dispatch('handleProgress', message);
    if (message.status === 'error') dispatch('handleError', message);
    if (message.status === 'warning') dispatch('handleWarning', message);
  },

  handleImgResult({
    commit, dispatch, state, getters,
  }, message) {
    if (message.status === 'success') {
      let providerName;
      if (message.providerId === 4) {
        providerName = 'eln';
      } else if (message.providerId === 2) {
        providerName = 'mobile.de';
      } else {
        providerName = 'Autoscout24.de';
      }
      notify.$notifyUserInteraction.success(`Bilder auf ${providerName} erfolgreich geändert`);

      setTimeout(() => {
        commit('SET_AD_PROGRESS', { providerId: message.providerId, progress: { loading: false, actionStarted: false } });
      }, 2000); // show it 2 more sec

      commit('SET_TEMP_COUNTER');
      const adCount = getters.howManyAds;
      if (adCount === state.tempCounter) {
        commit('RESET_TEMP_COUNTER');
        commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
      }
    }
    if (message.status === 'progress') dispatch('handleProgress', message);
    if (message.status === 'error') {
      notify.$notifyUserInteraction.error(message.errors[0].text, true);
      commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
    }
  },

  handleProgress({ commit }, message) {
    commit('SET_AD_PROGRESS', message);
  },

  handleError({ commit }, message) {
    commit('SET_AD_PROGRESS', { providerId: message.providerId, progress: { loading: false, actionStarted: false } });
    commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
    console.log(message);
    if (message.providerId === 1) {
      notify.$notifyUserInteraction.error(`AutoScout24 Fehler: ${message.errors[0].message}`, true);
    } else {
      notify.$notifyUserInteraction.error(`mobile.de Fehler: ${message.errors[0].text}`, true);
    }
  },

  async handleWarning({ state, commit, dispatch }, message) {
    commit('SET_AD_PROGRESS', { providerId: message.providerId, progress: { loading: false, actionStarted: false } });
    commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
    try {
      await notify.$q.dialog({
        title: 'Warnung',
        message: `Die Anzeige zum Fahrzeug "${vehicleNameShort(message.vehicle)}"
          existiert nicht mehr auf ${state.plattformNames[message.providerId]}.
          Sie können die Anzeige aus Autengo löschen.`,
        ok: 'Ja, Anzeige aus Autengo löschen',
        cancel: 'Abbrechen',
      });
    } catch (err) {
      return;
    }
    const data = {
      providerId: message.providerId,
      ID: message.progress.adId,
    };
    dispatch('deleteAd', { data, forceDelete: true });
  },

  getAllAds({ commit }) {
    commit('SET_LOADING', true);
    return axios.get('/v2/inserate').then((res) => {
      commit('SET_AD_LIST', res.data);
    });
  },

  deleteAdAdmin(_, id) {
    return axios.delete(`/v2/inserate/${id}`);
  },

  getAds({ commit, dispatch }, id) {
    commit('SET_CURRENT_VEHICLE', id);
    return axios.get(`/v2/vehicle/${id}/ads`).then((res) => {
      commit('SET_ALL_ADS', res.data.data);
      dispatch('getAdStatistics', res.data.data);
      dispatch('getDirectOffer', res.data.data);
    });
  },

  getAdStatistics({ commit }, adArray) {
    const mobileDeAd = adArray.find(ad => ad.providerId === 2);
    const as24Ad = adArray.find(ad => ad.providerId === 1);

    if (mobileDeAd) {
      commit('SET_AD_MOBILEDE_STATS', { loading: true });
      axios.get(`/v2/inserate/${mobileDeAd.ID}/statistics`, { errorNotification: false }).then((res) => {
        commit('SET_AD_MOBILEDE_STATS', res.data.data);
      });
    }
    if (as24Ad) {
      // TODO: add as24 stats when they are available in listing-creation api
      /*       commit('SET_AD_AS24_STATS', { loading: true });
            axios.get(`/v2/inserate/${as24Ad.ID}/statistics`, { errorNotification: false }).then((res) => {
              commit('SET_AD_AS24_STATS', res.data.data);
            }); */
    }
  },


  async getDirectOffer({ commit }, adArray) {
    const mobileDeAd = adArray.find(ad => ad.providerId === 2);

    if (mobileDeAd) {
      const { data: { data } } = await axios.get(`/v2/inserate/${mobileDeAd.ID}/direct-offer`, { errorNotification: false });
      if (data) {
        const validForDays = standingDays(data.validUntil) * -1 + 1;
        data.validForDays = validForDays >= 0 ? validForDays : null;
        commit('SET_DIRECT_OFFER', data);
      }
      return data;
    }
    return null;
  },

  async createOrUpdateDirectOffer({ commit, state }, payload) {
    const { mobileDeAd } = state;

    if (mobileDeAd.ID) {
      const { data: { data } } = await axios.put(`/v2/inserate/${mobileDeAd.ID}/direct-offer`, payload);
      commit('SET_DIRECT_OFFER', data);
      return data;
    }
    return null;
  },

  updateAds({ state, dispatch }, sellingId) {
    if (state.mobileDeAd.ID) {
      dispatch('updateSingleAd', { adData: state.mobileDeAd, sellingId });
    }
    if (state.as24Ad.ID) {
      dispatch('updateSingleAd', { adData: state.as24Ad, sellingId });
    }
    if (state.elnAd.ID) {
      dispatch('updateSingleAd', { adData: state.elnAd, sellingId });
    }
  },

  updateSingleAd({ commit, rootGetters }, { adData, sellingId }) {
    adData = removeKeys(adData, KEYS_TO_REMOVE);

    let providerName;
    adData.socketId = rootGetters['socket/getSocketId'];
    if (adData.providerId === 4) {
      providerName = 'ELN';
    } else if (adData.providerId === 2) {
      providerName = 'mobile.de';
    } else {
      providerName = 'Autoscout24.de';
    }
    commit('SET_AD_PROGRESS', {
      providerId: adData.providerId,
      progress: { actionStarted: true },
    });
    return axios
      .put(`/v2/vehicle/${sellingId}/ads/${adData.ID}`, adData)
      .then(() => {
        commit('SET_AD_PROGRESS', {
          providerId: adData.providerId,
          progress: { loading: true, progress: 10, loadingMessage: `Starte Update der Anzeige auf ${providerName}` },
        });
      })
      .catch(() => {
        commit('SET_AD_PROGRESS', {
          providerId: adData.providerId,
          progress: { loading: false },
        });
      });
  },

  publishAd({ state, commit, rootGetters }, payload) {
    let providerName;
    const socketId = rootGetters['socket/getSocketId'];
    if (payload.providerId === 4) {
      providerName = 'ELN';
    } else if (payload.providerId === 2) {
      providerName = 'mobile.de';
    } else {
      providerName = 'Autoscout24.de';
    }
    commit('SET_AD_PROGRESS', {
      providerId: payload.providerId,
      progress: { actionStarted: true },
    });
    return axios
      .post(`/v2/vehicle/${state.currentVehicle}/ads/${payload.ID}/publication`, { socketId })
      .then(() => {
        commit('SET_AD_PROGRESS', {
          providerId: payload.providerId,
          progress: { loading: true, progress: 10, loadingMessage: `Starte Reservierung der Anzeige auf ${providerName}` },
        });
      })
      .catch(() => {
        commit('SET_AD_PROGRESS', {
          providerId: payload.providerId,
          progress: { loading: false },
        });
      });
  },

  unPublishAd({ state, commit, rootGetters }, payload) {
    let providerName;
    const socketId = rootGetters['socket/getSocketId'];
    if (payload.providerId === 4) {
      providerName = 'ELN';
    } else if (payload.providerId === 2) {
      providerName = 'mobile.de';
    } else {
      providerName = 'Autoscout24.de';
    }
    commit('SET_AD_PROGRESS', {
      providerId: payload.providerId,
      progress: { actionStarted: true },
    });
    return axios
      .delete(`/v2/vehicle/${state.currentVehicle}/ads/${payload.ID}/publication`, { data: { socketId } })
      .then(() => {
        commit('SET_AD_PROGRESS', {
          providerId: payload.providerId,
          progress: { loading: true, progress: 10, loadingMessage: `Starte Reservierung der Anzeige auf ${providerName}` },
        });
      })
      .catch(() => {
        commit('SET_AD_PROGRESS', {
          providerId: payload.providerId,
          progress: { loading: false },
        });
      });
  },

  deleteAd({
    commit, state, rootGetters, dispatch,
  }, { data, forceDelete = false }) {
    const providerName = forceDelete ? 'Autengo' : state.plattformNames[data.providerId];
    const socketId = rootGetters['socket/getSocketId'];

    const param = forceDelete ? `${data.ID}?force=true` : data.ID;
    commit('SET_AD_PROGRESS', {
      providerId: data.providerId,
      progress: { actionStarted: true },
    });
    return axios
      .delete(`/v2/vehicle/${state.currentVehicle}/ads/${param}`, { data: { socketId } })
      .then(() => {
        commit('SET_AD_PROGRESS', {
          providerId: data.providerId,
          progress: { loading: true, progress: 33, loadingMessage: `Lösche Anzeige auf ${providerName}` },
        });
        if (router.currentRoute.path.startsWith('/inventory')) {
          dispatch('vehicleListNew/getVehicleList', null, { root: true });
        }
      })
      .catch(() => {
        commit('SET_AD_PROGRESS', {
          providerId: data.providerId,
          progress: { loading: false },
        });
      });
  },

  createAd({ commit, state, rootGetters }, data) {
    commit('vehicle/SET_VEHICLE_IMAGES_LOADING', true, { root: true });

    data = removeKeys(data, KEYS_TO_REMOVE_CREATE);

    let providerName;
    data.socketId = rootGetters['socket/getSocketId'];
    if (data.providerId === 4) {
      providerName = 'ELN';
    } else if (data.providerId === 2) {
      providerName = 'mobile.de';
    } else {
      providerName = 'Autoscout24.de';
    }
    commit('SET_AD_PROGRESS', {
      providerId: data.providerId,
      progress: { actionStarted: true },
    });
    return axios
      .post(`/v2/vehicle/${state.currentVehicle}/ads`, data)
      .then((res) => {
        commit('SET_AD_PROGRESS', {
          providerId: data.providerId,
          progress: { loading: true, progress: 10, loadingMessage: `Starte Erstellung der Anzeige für ${providerName}` },
        });
        commit('SET_SINGLE_AD', res.data.data);
      })
      .catch(() => {
        commit('vehicle/SET_VEHICLE_IMAGES_LOADING', false, { root: true });
        commit('SET_AD_PROGRESS', {
          providerId: data.providerId,
          progress: { loading: false, actionStarted: false },
        });
      });
  },

  createOrUpdateAdsWithStoreData({ dispatch, state, rootGetters }) {
    const hasMobileDe = rootGetters['auth/isMobileDeAuthorized'];
    const hasAs24 = rootGetters['auth/isAs24Authorized'];
    const hasEln = notify.$app.whitelabel === 'eln';

    if (hasMobileDe) {
      const adData = state.mobileDeAd;

      if (!state.mobileDeAd.ID) {
        dispatch('createAd', adData);
      } else {
        dispatch('updateSingleAd', { adData, sellingId: state.currentVehicle });
      }
    }
    if (hasAs24) {
      const adData = state.as24Ad;
      if (!state.as24Ad.ID) {
        dispatch('createAd', adData);
      } else {
        dispatch('updateSingleAd', { adData, sellingId: state.currentVehicle });
      }
    }
    if (hasEln) {
      const adData = state.elnAd;
      if (!adData.ID) {
        dispatch('createAd', adData);
      } else {
        dispatch('updateSingleAd', { adData, sellingId: state.currentVehicle });
      }
    }
  },

  assignNewAd(_, { sellingId, oldAdId, adData }) {
    return axios.post(`/v2/vehicle/${sellingId}/ads/${oldAdId}/assignnew`, adData);
  },

  deleteAdsWithStoreData({ dispatch, state }) {
    if (state.mobileDeAd.ID) {
      const adData = state.mobileDeAd;
      dispatch('deleteAd', { data: adData });
    }
    if (state.as24Ad.ID) {
      const adData = state.as24Ad;
      dispatch('deleteAd', { data: adData });
    }
    if (state.elnAd.ID) {
      const adData = state.elnAd;
      dispatch('deleteAd', { data: adData });
    }
  },

  resetAds({ commit }) {
    return commit('RESET_ADS');
  },

  getSellerInfo({ commit, rootState }) {
    if (!rootState.auth.currentBranch.mobileDeAuthorized) {
      return commit('SET_SELLER_INFO', {});
    }
    return axios.get('/v2/inserate/sellerinfo').then((res) => {
      commit('SET_SELLER_INFO', res.data.data);
    });
  },

  async getRealPriceRating(rootState, sellingId) {
    const { data: { data } } = await axios.get(`/v2/vehicle/${sellingId}/price-rating`);
    return data;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
