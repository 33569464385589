import axios from 'axios';

export const initialState = () => ({
  queryParams: {
    limit: 20,
    offset: 0,
  },
  pagination: {
    filteredCount: 0,
    totalCount: 0,
  },
  order: {
    name: 'updated',
    dir: 'DESC',
  },
  groupBy: {
    name: null,
  },
  search: '',
  filter: {
    onlyUnread: null,
    onlyUnreplyed: null,
    state: ['new'],
  },
  opportunityList: [],
  loading: true,
  selection: [],
  unreadOpportunities: 0,
});

export const mutations = {
  SET_OPPORTUNITY_LIST(state, newValue) {
    state.opportunityList = newValue;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.pagination.filteredCount = filteredCount;
    state.pagination.totalCount = totalCount;
  },
  SET_FILTER_KV(state, { key, value }) {
    if (Array.isArray(state.filter[key])) {
      state.filter[key] = [value];
    } else {
      state.filter[key] = value;
    }
  },
  ADD_TO_SELECTION(state, newValue) {
    state.selection.push(newValue);
  },
  REMOVE_FROM_SELECTION(state, newValue) {
    state.selection = state.selection.filter(item => item.ID !== newValue.ID);
  },
  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
  },

  SET_UNREAD(state, newValue) {
    state.unreadOpportunities = newValue;
  },
  SET_LIST_QUERY_PARAMS(state, { key, value }) {
    state.queryParams[key] = value;
  },

};

function clean(obj) {
  Object.keys(obj).forEach((propName) => {
    if (obj[propName] && typeof obj[propName] === 'object') clean(obj[propName]);
    else if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  });
  return obj;
}

async function getAllOpportunites(queryParams, queryfilter, search, order, groupBy) {
  let urlParams = queryParams;
  let filter = queryfilter;
  if (filter) {
    filter = clean(JSON.parse(JSON.stringify(queryfilter)));
    groupBy = clean(JSON.parse(JSON.stringify(groupBy)));
    urlParams = {
      ...queryParams,
      filter,
      search,
      order,
      groupBy,
    };
  }
  return axios.get('v2/opportunity', {
    params: urlParams,
  });
}

export const actions = {

  getOpportunityList({ commit, state }) {
    commit('SET_LOADING', true);
    return getAllOpportunites(state.queryParams, state.filter, state.search, state.order, state.groupBy).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_OPPORTUNITY_LIST', res.data.data);
      commit('SET_LOADING', false);

      return res.data.data;
    });
  },

  setSearch({ commit }, terms) {
    commit('SET_SEARCH', terms);
  },

  resetOpportunityListStore({ commit }) {
    commit('RESET');
  },

  getUnreadCount({ commit }) {
    return axios.get('/v2/opportunity/count').then((res) => {
      commit('SET_UNREAD', res.data.data);
    });
  },

  setQueryParams({ commit }, { key, value }) {
    commit('SET_LIST_QUERY_PARAMS', { key, value });
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
