import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { linePlotOptions, barPlotOptions, piePlotOptions, baseChartOption, areaPlotOptions } from '../../plugins/apexChartOptions';
import { numberWithPoints } from '../../plugins/util';
import { appVars } from '../../plugins/appConfig';


const state = {
  ads: [],
  vehicles: {
    incoming: 0,
    inventory: 0,
    sold: 0,
  },
  opportunity: {
    new: 0,
    won: 0,
    rejected: 0,
    lost: 0,
  },
  standingDays: 0,
  inqueriesByDay: {
    series: [],
    chartOptions: barPlotOptions,
  },
  inqueriesByOrigin: {
    series: [],
    chartOptions: piePlotOptions,
  },
  trends: {
    series: [
      {
        name: 'Anfragen nach Tageszeit gesamt',
        data: [],
      },
    ],
    chartOptions: linePlotOptions,
  },
  priceHistory: {
    chartOptions: linePlotOptions,
  },
  inseratePerfomance: {
    loaded: false,
    as24: {
      series: [
        { name: 'Parkings', data: [] },
        { name: 'Impressions', data: [] },
        { name: 'Calls', data: [] },
        { name: 'Emails', data: [] },
      ],
      chartOptions: areaPlotOptions,
    },
    mobileDe: {
      series: [
        { name: 'Parkings', data: [] },
        { name: 'Impressions', data: [] },
        { name: 'Calls', data: [] },
        { name: 'Emails', data: [] },
      ],
      chartOptions: areaPlotOptions,
    },
  },
  inventoryAdPerformance: [],
  soldVehicles: {
    loaded: false,
    series: [
      {
        name: 'Verkaufte Fahrzeuge',
        data: [],
      },
    ],
    chartOptions: baseChartOption,
  },
  inventorySnapshot: {
    loaded: false,
    series: [
      {
        name: 'Fahrzeuge im Bestand',
        type: 'column',
        data: [],
      },
      {
        name: 'Fahrzeuge im Vorlauf',
        type: 'column',
        data: [],
      },
      {
        name: 'Kapitalbindung im Bestand',
        type: 'line',
        data: [],
      },
      {
        name: 'Kapitalbindung im Vorlauf',
        type: 'area',
        data: [],
      },
    ],
    chartOptions: baseChartOption,
  },
};

const getters = {
};

const actions = {
  async fetchAdsCount({ commit }, branchId) {
    const res = await axios.get(`/v2/insight/${branchId}/ad/count`);
    commit('setAdsCount', res.data);
  },
  async fetchVehiclesCount({ commit }, branchId) {
    const res = await axios.get(`/v2/insight/${branchId}/vehicle/state`);
    commit('setVehiclesCount', res.data);
  },
  async fetchOpportunity({ commit }, branchId) {
    const res = await axios.get(`/v2/insight/${branchId}/opportunity/state`);
    commit('setOpportunity', res.data);
  },
  async fetchStandingDays({ commit }, branchId) {
    const res = await axios.get(`/v2/insight/${branchId}/vehicle/standingDays`);
    commit('setStandingDays', res.data);
  },
  async fetchInseratePerfomance({ commit, state }, { branchId, params }) {
    state.inseratePerfomance.loaded = false;
    const res = await axios.get(`/v2/insight/${branchId}/ad/performance`, { params });
    commit('setInseratePerfomance', res.data);
  },
  async getOpportunitiesByDay({ commit }, { branchId, params }) {
    const { data } = await axios.get(`/v2/insight/${branchId}/opportunity/sourceType`, { params });
    commit('SET_OPPORTUNITIES_BY_DAY', data);
  },
  async fetchInqueriesByOrigin({ commit }, query) {
    const res = await axios.get(query);
    commit('setInqueriesByOrigin', res.data);
  },
  async fetchTrends({ commit }, query) {
    const res = await axios.get(query);
    commit('setTrends', res.data);
  },
  async fetchInventoryAdPerformance({ commit }, { branchId, params }) {
    const res = await axios.get(`/v2/insight/${branchId}/vehicle/performance`, { params });
    commit('setInventoryAdPerformance', res.data);
  },
  async fetchSoldVehicles({ commit, state }, branchId) {
    state.soldVehicles.loaded = false;
    const res = await axios.get(`/v2/insight/${branchId}/vehicle/sales`);
    commit('setSoldVehicles', res.data);
  },
  async fetchInventorySnapshot({ commit, state }, { branchId, params }) {
    state.inventorySnapshot.loaded = false;
    const res = await axios.get(`/v2/insight/${branchId}/vehicle/snapshot`, { params });
    commit('setInventorySnapshot', res.data);
  },
};

const mutations = {
  setAdsCount: (state, adsCount) => {
    const payload = [];
    Object.keys(adsCount.data).forEach(key => payload.push({
      name: key,
      val: adsCount.data[key],
    }));
    state.ads = payload;
  },
  setVehiclesCount: (state, vehiclesCount) => {
    const { incoming, inventory, sold } = vehiclesCount.data;
    state.vehicles.incoming = incoming || 0;
    state.vehicles.inventory = inventory || 0;
    state.vehicles.sold = sold || 0;
  },
  setOpportunity: (state, opportunityCount) => {
    const {
      new: opportunityCountNew,
      won,
      rejected,
      lost,
    } = opportunityCount.data;

    state.opportunity.new = opportunityCountNew || 0;
    state.opportunity.won = won || 0;
    state.opportunity.rejected = rejected || 0;
    state.opportunity.lost = lost || 0;
  },
  setStandingDays: (state, standingDaysCount) => {
    if (standingDaysCount.data) state.standingDays = standingDaysCount.data;
  },
  setInseratePerfomance: (state, inseratePerfomance) => {
    function generateChartDataForProvider(providerId) {
      const data = inseratePerfomance.data.filter(item => item.providerId === providerId);
      const chartOptions = Object.assign({}, areaPlotOptions);

      if (data.length <= 0) {
        return {
          options: chartOptions,
          series: [],
        };
      }

      const weeklyData = {};

      const latestEntriesByWeek = {};

      data.forEach((item) => {
        const week = `KW ${moment(item.created).isoWeek()}`;
        const { vehicleId } = item;

        if (!latestEntriesByWeek[week]) {
          latestEntriesByWeek[week] = {};
        }

        if (!latestEntriesByWeek[week][vehicleId]) {
          latestEntriesByWeek[week][vehicleId] = item;
        } else {
          const existingEntry = latestEntriesByWeek[week][vehicleId];
          if (new Date(item.created) > new Date(existingEntry.created)) {
            latestEntriesByWeek[week][vehicleId] = item;
          }
        }
      });

      Object.keys(latestEntriesByWeek).forEach((week) => {
        if (!weeklyData[week]) {
          weeklyData[week] = {
            parkings: 0,
            impressions: 0,
            calls: 0,
            emails: 0,
          };
        }

        Object.values(latestEntriesByWeek[week]).forEach((entry) => {
          weeklyData[week].parkings += entry.parkings;
          weeklyData[week].impressions += entry.impressions;
          weeklyData[week].calls += entry.calls;
          weeklyData[week].emails += entry.emails;
        });
      });

      const uniqueWeeks = Object.keys(weeklyData).sort();

      const series = [
        { name: 'Parkungen', data: [] },
        { name: 'Impressionen', data: [] },
        { name: 'Anrufe', data: [] },
        { name: 'E-Mails', data: [] },
      ];

      uniqueWeeks.forEach((week) => {
        series[0].data.push(weeklyData[week].parkings);
        series[1].data.push(weeklyData[week].impressions);
        series[2].data.push(weeklyData[week].calls);
        series[3].data.push(weeklyData[week].emails);
      });

      chartOptions.xaxis.categories = uniqueWeeks;
      chartOptions.grid = {
        padding: {
          left: 20,
          right: 20,
        },
      };

      return {
        options: chartOptions,
        series,
      };
    }

    const as24 = generateChartDataForProvider(1);
    const mobileDe = generateChartDataForProvider(2);

    state.inseratePerfomance.as24.series = as24.series;
    state.inseratePerfomance.as24.chartOptions = as24.options;

    state.inseratePerfomance.mobileDe.series = mobileDe.series;
    state.inseratePerfomance.mobileDe.chartOptions = mobileDe.options;

    state.inseratePerfomance.loaded = true;
  },
  setInventoryAdPerformance: (state, inventoryAdPerformance) => {
    if (!inventoryAdPerformance.data) return;

    const { data } = inventoryAdPerformance;

    const onlyDataWithAds = data.filter(item => item.totalImpressions > 0);

    onlyDataWithAds.forEach((item) => {
      const { totalImpressions, entryBefore } = item;
      if (!entryBefore) {
        item.trend = null;
        return;
      }
      const trend = (totalImpressions - entryBefore.impressionsYesterday) / entryBefore.impressionsYesterday * 100;
      item.trend = trend.toFixed(0);
    });

    state.inventoryAdPerformance = onlyDataWithAds;
  },
  setSoldVehicles: (state, soldVehicles) => {
    if (!soldVehicles.data) return;

    const categories = [];
    let data = new Array(12).fill(0);

    for (let i = 11; i >= 0; i--) {
      // we know api will return last 12 months data
      const month = moment().subtract(i, 'months');
      categories.push(month.format('MMM'));
    }

    soldVehicles.data.forEach((item) => {
      const targetMonth = moment(`${item.year}-${item.month}`, 'YYYY-M');
      const formattedMonth = targetMonth.format('MMM');

      const index = categories.indexOf(formattedMonth);

      if (index !== -1) {
        data[index] = item.sold_count;
      } else {
        console.log('Month not found in categories');
      }
    });

    state.soldVehicles.chartOptions.xaxis.axisTicks = {
      show: false,
    };

    state.soldVehicles.chartOptions.yaxis = {
      forceNiceScale: true,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    };

    if (Array.isArray(data) && data.every(item => item === 0)) {
      data = [];
    }

    state.soldVehicles.series[0].data = data;
    state.soldVehicles.chartOptions.xaxis.categories = categories;
    state.soldVehicles.loaded = true;
  },
  SET_OPPORTUNITIES_BY_DAY: (state, inqueriesByDay) => {
    const { 1: as24, 2: mobile } = inqueriesByDay.data || {};

    let weekDays;
    let frequency;
    const as24Series = new Array(7).fill(0);
    const mobileSeries = new Array(7).fill(0);

    if (as24) {
      weekDays = as24.map(e => new Date(e.created).getDay());
      frequency = _.countBy(weekDays);
      Object.keys(frequency).forEach((key) => {
        as24Series[key] = frequency[key];
      });
      delete inqueriesByDay.data['1'];
    }

    if (mobile) {
      weekDays = mobile.map(e => new Date(e.created).getDay());
      frequency = _.countBy(weekDays);
      Object.keys(frequency).forEach((key) => {
        mobileSeries[key] = frequency[key];
      });
      delete inqueriesByDay.data['2'];
    }

    const merged = [];
    Object.keys(inqueriesByDay.data).forEach(e => merged.push(...inqueriesByDay.data[e]));
    weekDays = merged.map(e => new Date(e.created).getDay());
    frequency = _.countBy(weekDays);
    const others = new Array(7).fill(0);
    Object.keys(frequency).forEach((key) => {
      others[key] = frequency[key];
    });

    state.inqueriesByDay.series = [
      {
        name: 'AutoScout24',
        data: as24Series,
      },
      {
        name: 'mobile.de',
        data: mobileSeries,
      },
      {
        name: 'Sonstige',
        data: others,
      },
    ];
  },
  setInqueriesByOrigin: (state, inqueriesByOrigin) => {
    let { 1: as24, 2: mobile } = inqueriesByOrigin.data || {};

    if (as24) delete inqueriesByOrigin.data['1'];
    else as24 = 0;

    if (mobile) delete inqueriesByOrigin.data['2'];
    else mobile = 0;

    let others = 0;
    Object.keys(inqueriesByOrigin.data).forEach((e) => { others += inqueriesByOrigin.data[e]; });

    state.inqueriesByOrigin.series = [as24, mobile, others];
  },
  setTrends: (state, trends) => {
    state.trends.chartOptions.xaxis.categories.splice(0, state.trends.chartOptions.xaxis.categories.length);
    state.trends.series[0].data.splice(0, state.trends.series[0].data.length);

    if (trends.data.length > 0) {
      const today = new Date().toJSON().slice(0, 10);
      let data = trends.data.map(e => today + e.created.substring(10));
      data = data.sort((a, b) => new Date(b) - new Date(a));
      data = data.map(e => `${e.substring(0, e.length - 10)}30`);
      data = data.reduce((acc, val) => acc.set(val, 1 + (acc.get(val) || 0)), new Map());

      data.forEach((val, key) => {
        state.trends.chartOptions.xaxis.categories.push(key);
        state.trends.series[0].data.push(val);
      });
    }
  },
  setInventorySnapshot: (state, inventorySnapshot) => {
    if (!inventorySnapshot.data) return;

    const { data } = inventorySnapshot;

    const labels = data.map(item => new Date(item.created).getTime());

    // Extract series data
    const fahrzeugeImBestand = data.map(item => item.inventoryCount);
    const fahrzeugeImVorlauf = data.map(item => item.incomingCount);
    const kapitalbindungImBestand = data.map(item => item.priceOfInventory);
    const kapitalbindungImVorlauf = data.map(item => item.priceOfIncoming);


    state.inventorySnapshot = {
      series: [
        {
          name: 'Fahrzeuge im Bestand',
          type: 'column',
          data: fahrzeugeImBestand,
        },
        {
          name: 'Fahrzeuge im Vorlauf',
          type: 'column',
          data: fahrzeugeImVorlauf,
        },
        {
          name: 'Kapitalbindung im Bestand',
          type: 'line',
          data: kapitalbindungImBestand,
        },
        {
          name: 'Kapitalbindung im Vorlauf',
          type: 'area',
          data: kapitalbindungImVorlauf,
        },
      ],
      chartOptions: {
        colors: [`${appVars.primary}`, '#00E396', '#FEB019', '#FF4560'],
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        labels,
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        noData: {
          text: 'Keine Daten verfügbar',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        yaxis: [
          {
            seriesName: 'Fahrzeuge im Bestand',
            min: 0,
          },
          {
            seriesName: 'Fahrzeuge im Bestand',
            min: 0,
          },
          {
            seriesName: 'Kapitalbindung im Bestand',
            min: 0,
            labels: {
              formatter(value) {
                return `${numberWithPoints(value)} €`;
              },
            },

          },
          {
            seriesName: 'Kapitalbindung im Bestand',
            min: 0,
            labels: {
              formatter(value) {
                return `${numberWithPoints(value)} €`;
              },
            },
          },
        ],
      },
    };

    state.inventorySnapshot.loaded = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
