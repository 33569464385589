import Vue from 'vue';
import {
  currency,
  formatDate,
  formatDateIntelligent,
  formatDateForEmail,
  formatDateFirstReg,
  formatDateToNumDay,
  formatDateToShortMonth,
  formatDateToYear,
  formatGender,
  formatSource,
  formatTime,
  grossPrice,
  iconForSource,
  kwToPs,
  psToKw,
  netPrice,
  numberWithPoints,
  standingDays,
  star,
  toOptions,
  translateVehicleState,
  tTransmission,
  tFuel,
  tCondition,
  tVehicleType,
  number,
  decimal,
  orderBy,
  simpleStar,
  getEmailOrEmailName,
  translateHistoryAction,
  fullUserNameOrDefault,
  formatFilesize,
  emailAddressName,
  getProperFileType,
  formatName,
  getCustomerInitials,
  translateDoctype,
  kwAndPs,
  tColor,
  tEmission,
  formatBytes,
  tTaxRate,
  getAttachmentIcon,
  truncateWithEllipses,
  vehicleName,
  vehicleNameShort,
  vehicleNameWithId,
  translateBoolean,
  translateOpportunityState,
  contactType,
  formatDateForEmailInfo,
  formatDateForEmailThread,
  formatNumber,
  commaDecimalPlaces,
  currencyWith2DecimalPlaces,
  formatUnixDate,
  currencyCentsToEuro,
  decodeHtmlEntities,
  formatNumberBeautiful,
} from './util';

Vue.filter('formatDate', formatDate);

Vue.filter('formatDateIntelligent', formatDateIntelligent);

Vue.filter('formatUnixDate', formatUnixDate);

Vue.filter('formatDateForEmail', formatDateForEmail);

Vue.filter('formatDateFirstReg', formatDateFirstReg);

Vue.filter('currency', currency);

Vue.filter('currencyWith2DecimalPlaces', currencyWith2DecimalPlaces);

Vue.filter('currencyCentsToEuro', currencyCentsToEuro);

Vue.filter('commaDecimalPlaces', commaDecimalPlaces);

Vue.filter('formatDateToNumDay', formatDateToNumDay);

Vue.filter('formatDateToShortMonth', formatDateToShortMonth);

Vue.filter('formatDateToYear', formatDateToYear);

Vue.filter('formatTime', formatTime);

Vue.filter('standingDays', standingDays);

Vue.filter('numberWithPoints', numberWithPoints);

Vue.filter('formatGender', formatGender);

Vue.filter('formatSource', formatSource);

Vue.filter('iconForSource', iconForSource);

Vue.filter('formatName', formatName);

Vue.filter('getCustomerInitials', getCustomerInitials);

Vue.filter('translateVehicleState', translateVehicleState);

Vue.filter('tTransmission', tTransmission);

Vue.filter('tFuel', tFuel);

Vue.filter('tTaxRate', tTaxRate);

Vue.filter('tCondition', tCondition);

Vue.filter('tVehicleType', tVehicleType);

Vue.filter('tColor', tColor);

Vue.filter('tEmission', tEmission);

Vue.filter('netPrice', netPrice);

Vue.filter('grossPrice', grossPrice);

Vue.filter('toOptions', toOptions);

Vue.filter('kwToPs', kwToPs);

Vue.filter('kwAndPs', kwAndPs);

Vue.filter('psToKw', psToKw);

Vue.filter('star', star);

Vue.filter('simpleStar', simpleStar);

Vue.filter('number', number);

Vue.filter('decimal', decimal);

Vue.filter('orderBy', orderBy);

Vue.filter('getEmailOrEmailName', getEmailOrEmailName);

Vue.filter('translateHistoryAction', translateHistoryAction);

Vue.filter('fullUserNameOrDefault', fullUserNameOrDefault);

Vue.filter('formatFilesize', formatFilesize);

Vue.filter('emailAddressName', emailAddressName);

Vue.filter('fileType', getProperFileType);

Vue.filter('translateDoctype', translateDoctype);

Vue.filter('formatBytes', formatBytes);

Vue.filter('translateOpportunityState', translateOpportunityState);

Vue.filter('attachmentIcon', getAttachmentIcon);

Vue.filter('truncateWithEllipses', truncateWithEllipses);

Vue.filter('vehicleName', vehicleName);

Vue.filter('vehicleNameShort', vehicleNameShort);

Vue.filter('vehicleNameWithId', vehicleNameWithId);

Vue.filter('translateBoolean', translateBoolean);

Vue.filter('contactType', contactType);

Vue.filter('formatDateForEmailInfo', formatDateForEmailInfo);

Vue.filter('formatDateForEmailThread', formatDateForEmailThread);

Vue.filter('formatNumber', formatNumber);

Vue.filter('decodeHtmlEntities', decodeHtmlEntities);

Vue.filter('formatNumberBeautiful', formatNumberBeautiful);
