import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

moment.locale('de');

export const initialState = () => ({
  contactDetail: {},
  contactHistory: [],
  contactPlanned: [],
  contactVehicles: [],
  vehiclesDetails: {},
  selectedActivityItem: {},
  loadingHistory: true,
  loadingPlanned: true,
  showAction: false,
  actionTab: 'note',
  contactHistoryTab: 'all',
  HISTORY_ICONS: {
    note: 'description',
    mail: 'mail',
    file: 'attachment',
  },
  PLAN_ICONS: {
    task: { name: 'assignment', label: 'Aufgabe' },
    mail: { name: 'mail', label: 'E-Mail' },
  },
  openDeals: [],
  pastDeals: [],
  contactFiles: [],
  contactAttachmentFiles: [], // used in attachment modal
  focusMail: true,
});

export const mutations = {
  SET_DETAIL(state, newValue) {
    state.contactDetail = newValue;
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]; 
    });
  },

  SET_HISTORY(state, newValue) {
    state.contactHistory = newValue;
  },

  SET_LOADING_HISTORY(state, newValue) {
    state.loadingHistory = newValue;
  },

  SET_PLANNED(state, newValue) {
    state.contactPlanned = newValue;
  },

  SET_LOADING_PLANNED(state, newValue) {
    state.loadingPlanned = newValue;
  },

  SET_ACTIVITY(state, newValue) {
    state.selectedActivityItem = newValue;
  },

  RESET_ACTIVITY(state) {
    state.selectedActivityItem = {};
  },

  SET_SHOW_ACTION(state, newValue) {
    state.showAction = newValue;
  },

  SET_ACTION_TAB(state, newValue) {
    state.actionTab = newValue;
  },

  SET_HISTORY_TAB(state, tab) {
    state.contactHistoryTab = tab;
  },

  SET_OPEN_DEALS(state, deals) {
    state.openDeals = deals;
  },

  SET_PAST_DEALS(state, deals) {
    state.pastDeals = deals;
  },

  SET_FILES(state, files) {
    state.contactFiles = files;
  },

  APPEND_FILES(state, contactFiles) {
    state.contactFiles = state.contactFiles.concat(contactFiles);
  },

  SET_ATTACHMENT_FILES(state, files) {
    state.contactAttachmentFiles = files;
  },

  APPEND_ATTACHMENT_FILES(state, contactFiles) {
    state.contactAttachmentFiles = state.contactAttachmentFiles.concat(contactFiles);
  },

  SET_FOCUS_MAIL(state, focusMail) {
    state.focusMail = focusMail;
  },

  SET_VEHICLES(state, vehicles) {
    state.contactVehicles = vehicles;
  },

  ADD_VEHICLE(state, vehicleId) {
    state.contactVehicles.push(vehicleId);
  },

  REMOVE_VEHICLE(state, vehicleId) {
    const index = state.contactVehicles.indexOf(vehicleId);
    if (index < 0) return;
    state.contactVehicles.splice(index, 1);
  },

  SAVE_VEHICLE_DETAIL(state, vehicleDetail) {
    state[vehicleDetail.ID] = vehicleDetail;
  },
};

export const getters = {

  groupedContactHistoryItems: state => _.groupBy(state.contactHistory, 'type'),

  contactHistoryItemAmounts: (state, getters) => ({
    all: state.contactHistory.length,
    ..._.mapValues(getters.groupedContactHistoryItems, items => items.length),
  }),

  filteredContactHistoryItems(state, getters) {
    if (state.contactHistoryTab === 'all') {
      return state.contactHistory;
    }
    return getters.groupedContactHistoryItems[state.contactHistoryTab];
  },

  contactHistoryItems(state, getters) {
    return _.flow([
      data => _.groupBy(data, item => moment(item.created).format('MMMM YYYY')),
      data => _.map(data, (items, month) => ({ items, month })),
      // order items by month
      data => _.orderBy(data, [entry => parseInt(moment(entry.items[0].created).format('YYYYMM'), 10)], ['desc']),
      data => data.map(({ items, ...rest }) => ({ ...rest, items: _.orderBy(items, ['created'], ['desc']) })),
    ])(getters.filteredContactHistoryItems);
  },

  contactId: (state) => {
    if (state.contactDetail.ID) return state.contactDetail.ID;
    return null;
  },

};

export const actions = {

  async deleteContactById({ dispatch }, contactId) {
    await axios.delete(`/v2/contact/${contactId}`);
    return dispatch('resetContactDetail');
  },

  getContactById({ commit }, id) {
    return axios.get(`v2/customer/id/${id}`).then((res) => {
      commit('SET_DETAIL', res.data.data);
    });
  },

  getContactOrOrga({ commit }, { id, scope }) {
    return axios.get(`v2/customer/id/${id}?scope=${scope}`).then((res) => {
      commit('SET_DETAIL', res.data.data);
    });
  },

  async getPlannedByContactId({ commit, state }, contactId) {
    if (state.contactPlanned.length < 1) commit('SET_LOADING_PLANNED', true);
    const { data } = await axios.get(`/v2/contact/${contactId}/activity/planned`);
    commit('SET_PLANNED', data.data);
    commit('SET_LOADING_PLANNED', false);
  },

  async getHistoryByContactId({ commit, state, dispatch }, contactId) {
    if (state.contactHistory.length < 1) commit('SET_LOADING_HISTORY', true);
    const { data: contactHistory } = (await axios.get(`/v2/contact/${contactId}/activity/history`)).data;
    await dispatch('getOpenDealsByContactId', contactId);
    await dispatch('getPastDealsByContactId', contactId);
    commit('SET_HISTORY', [
      ...contactHistory,
      ..._.flatMap(state.openDeals, deal => deal.history),
      ..._.flatMap(state.pastDeals, deal => deal.history),
    ]);
    commit('SET_LOADING_HISTORY', false);
  },

  // Notes
  async createContactNote(storeUtils, { note, contactId }) {
    return (await axios.post(`/v2/contact/${contactId}/notes`, note)).data.data;
  },

  async deleteContactNote({ dispatch }, { noteId, contactId }) {
    await axios.delete(`/v2/contact/${contactId}/notes/${noteId}`);
    dispatch('getHistoryByContactId', contactId);
  },

  // async deleteOpportunityNote({ dispatch }, { noteId, opportunityId }) {
  //   await axios.delete(`/v2/opportunity/${opportunityId}/notes/${noteId}`);
  //   await dispatch('getHistoryByOpportunityId', opportunityId);
  // },
  //
  // Activity
  async addContactActivity({ dispatch }, { data, contactId, mode }) {
    await axios.post(`/v2/contact/${contactId}/activity/${mode}`, data);
    if (mode === 'history') await dispatch('getHistoryByContactId', contactId);
    if (mode === 'planned') await dispatch('getPlannedByContactId', contactId);
  },

  async deleteContactActivity({ dispatch }, { activityId, contactId, mode }) {
    await axios.delete(`/v2/contact/${contactId}/activity/${mode}/${activityId}`);
    await dispatch('getHistoryByContactId', contactId);
    await dispatch('getPlannedByContactId', contactId);
  },

  // async uploadFile(storeUtils, { files, contactId }) {
  //   const payload = new FormData();
  //   Array.from(files).forEach((file) => {
  //     payload.append('files', file);
  //   });
  //   payload.append('contactId', contactId);
  //
  //   const headers = { 'Content-Type': 'multipart/form-data' };
  //
  //   const { data } = await axios.post(`/v2/contact/${contactId}/files`, payload, { headers });
  //   return data.data;
  // },

  // async deleteContactFile({ dispatch }, { fileId, contactId }) {
  //   await axios.delete(`/v2/contact/${contactId}/files/${fileId}`);
  //   await dispatch('getHistoryByContactId', contactId);
  // },


  resetContactDetail({ commit }) {
    commit('RESET');
  },

  showContactAction({ commit }, show) {
    commit('SET_SHOW_ACTION', show);
  },

  setActionTab({ commit }, tab) {
    commit('SET_ACTION_TAB', tab);
  },

  setActivityItem({ commit }, activity) {
    commit('SET_ACTIVITY', activity);
  },

  resetActivity({ commit }) {
    commit('RESET_ACTIVITY');
  },

  updateContactHistoryTab({ commit }, tab) {
    commit('SET_HISTORY_TAB', tab);
  },

  // Deals
  async getOpenDealsByContactId({ commit }, contactId) {
    const { data } = await axios.get(`/v2/contact/${contactId}/deals/open`);
    commit('SET_OPEN_DEALS', data.data);
    return data.data;
  },

  async getPastDealsByContactId({ commit }, contactId) {
    const { data } = await axios.get(`/v2/contact/${contactId}/deals/past`);
    commit('SET_PAST_DEALS', data.data);
    return data.data;
  },

  // Files
  async getFilesByContactId({ commit }, contactId) {
    const { data } = await axios.get(`/v2/contact/${contactId}/files`);
    commit('SET_FILES', data.data);
  },

  async deleteContactFile({ dispatch }, historyItem) {
    dispatch('document/deleteDocument', historyItem.file, { root: true });
  },

  setFocusMail({ commit }, focusMail) {
    commit('SET_FOCUS_MAIL', focusMail);
  },

  async getVehiclesByContactId({ commit }, contactId) {
    const { data: { data: vehicles } } = await axios.get(`/v2/contact/${contactId}/vehicles`);
    commit('SET_VEHICLES', vehicles);
  },

  async addVehicleToContact({ commit }, { contactId, vehicleId }) {
    const res = await axios.post(`/v2/contact/${contactId}/vehicles/${vehicleId}`);
    commit('ADD_VEHICLE', vehicleId);
    return res.data;
  },

  async removeVehicleFromContact({ commit }, { contactId, vehicleId }) {
    await axios.delete(`/v2/contact/${contactId}/vehicles/${vehicleId}`);
    commit('REMOVE_VEHICLE', vehicleId);
  },

  async getVehicleDetailById({ commit, state }, vehicleId) {
    if (state.vehiclesDetails[vehicleId]) {
      return state.vehiclesDetails[vehicleId];
    }

    const { data: { data: vehicleDetail } } = await axios.get(`/v2/vehicle/id/${vehicleId}`);
    commit('SAVE_VEHICLE_DETAIL', vehicleDetail);
    return vehicleDetail;
  },

  async deleteNoteByHistoryItem({ dispatch, state }, historyItem) {
    if (historyItem.opportunityId) {
      await dispatch('opportunityDetail/deleteOpportunityNote', {
        noteId: historyItem.noteId,
        opportunityId: historyItem.opportunityId,
      }, { root: true });
    } else if (historyItem.contactId) {
      await dispatch('deleteContactNote', {
        noteId: historyItem.noteId,
        contactId: historyItem.contactId,
      });
    }
    if (Object.keys(state.contactDetail).length > 0) {
      dispatch('getHistoryByContactId', state.contactDetail.ID);
    }
  },

  async deleteFileByHistoryItem({ dispatch, state }, historyItem) {
    if (historyItem.opportunityId) {
      await dispatch('deleteOpportunityFile', {
        fileId: historyItem.fileId,
        opportunityId: historyItem.opportunityId,
      }, { root: true });
    } else if (historyItem.contactId) {
      await dispatch('deleteContactFile', historyItem);
    }
    if (Object.keys(state.contactDetail).length > 0) {
      dispatch('getHistoryByContactId', state.contactDetail.ID);
    }
  },

  async fetchFilteredDocuments({ dispatch, commit }, {
    limit, offset, search, contactId,
  }) {
    const { data, ...pagination } = await dispatch('document/getRawDocuments', {
      params: {
        category: 'Rechnung',
        excludeCategory: true,
        limit,
        search,
        offset,
        filter: {
          customerId: contactId,
        },
      },
    }, { root: true });
    commit('APPEND_FILES', data);
    return { pagination, data };
  },

  async fetchFilteredAttachmentFiles({ dispatch, commit }, {
    limit, offset, search, contactId,
  }) {
    const { data, ...pagination } = await dispatch('document/getRawDocuments', {
      params: {
        category: 'Rechnung',
        excludeCategory: true,
        limit,
        search,
        offset,
        filter: {
          customerId: contactId,
        },
      },
    }, { root: true });
    commit('APPEND_ATTACHMENT_FILES', data);
    return { pagination, data };
  },

  resetContactFiles({ commit }) {
    commit('SET_FILES', []);
  },

  resetContactAttachmentFiles({ commit }) {
    commit('SET_ATTACHMENT_FILES', []);
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
