import axios from 'axios';
import Vue from 'vue';

export const state = () => ({
  queryParams: {
    page: 1,
    order: {
      name: 'tenderDateAcceptBidDeadline',
      dir: 'desc',
    },
  },
  filter: {
    manufacturer: '',
    model: '',
    bodytype: null,
    doors: null,
    mileageMin: null,
    mileageMax: null,
    registrationDateMin: null,
    registrationDateMax: null,
    fuelTypeId: null,
    transmissionId: null,
    location: '',
    statusId: '70',
    currentStatus: null,
    kilowattMin: null,
    kilowattMax: null,
    cubicCapacityMin: null,
    cubicCapacityMax: null,
    // tenderEndDateMin: null,
    // tenderEndDateMax: null,
    // tenderDateAcceptBidDeadlineMin: null,
    // tenderDateAcceptBidDeadlineMax: null,
    // currencyId: '',
  },
  list: [],
  serverPagination: {
    page: 1,
    rowsPerPage: 6, // change queryParams.limit too
    rowsNumber: null, // this is the total number of records/totalCount
    sortBy: 'tenderEndDate',
    descending: false,
  },
  loading: true,
  columns: [
    { name: 'manufacturer', label: 'Hersteller', sortable: true },
    { name: 'model', label: 'Modell', sortable: true },
    { name: 'mileage', label: 'Kilometerstand', sortable: true },
    { name: 'registrationDate', label: 'Erstzulassung', sortable: true },
    { name: 'location', label: 'Standort', sortable: true },
    { name: 'fuelType', label: 'Kraftstoff', sortable: true },
    { name: 'transmission', label: 'Getriebe', sortable: true },
    { name: 'kilowatt', label: 'Leistung (KW)', sortable: true },
    { name: 'cubicCapacity', label: 'Hubraum', sortable: true },
    { name: 'tenderEndDate', label: 'Ende der Gebotsrunde', sortable: true },
    { name: 'tenderDateAcceptBidDeadline', label: 'Gebotsabgabefrist', sortable: true },
    { name: 'currentStatus', label: 'Kategorie', sortable: true },
    { name: 'minBid', label: 'Mindestgebot', sortable: true },
    { name: 'highestBid', label: 'Höchstes Gebot', sortable: true },
    { name: 'currencyId', label: 'currencyId' },
  ],
  objectUrls: [],
});

export const mutations = {
  SET_LIST(state, newValue) {
    state.list = newValue;
  },
  ADD_LIST(state, entries) {
    state.list.push(...entries);
  },
  SET_LIST_PAGINATION(state, pagination) {
    state.queryParams.page = pagination.page;
    state.queryParams.order.name = pagination.sortBy || 'tenderDateAcceptBidDeadline';
    state.queryParams.order.dir = pagination.descending ? 'desc' : 'asc';
    state.serverPagination = pagination;
  },
  SET_QUERY_PARAMS(state, params) {
    Object.assign(state.queryParams, params);
  },
  SET_SERVER_PAGINATION(state, newValue) {
    Object.assign(state.serverPagination, newValue);
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_FILTER(state, filter) {
    Object.assign(state.filter, filter);
  },

  RESET_FILTER(currentState) {
    currentState.filter = state().filter;
  },

  SET_VISIBLE_COLUMNS(state, newValue) {
    state.visibleColumns = newValue;
  },

  ADD_OBJECT_URL(state, url) {
    state.objectUrls.push(url);
  },

  RESET_OBJECT_URLS(state) {
    state.objectUrls.forEach(url => URL.revokeObjectURL(url));
    state.objectUrls = [];
  },

  UPDATE_VEHICLE(state, [id, update]) {
    // id can be string or number
    // eslint-disable-next-line eqeqeq
    const vehicle = state.list.find(vehicle => vehicle.id == id);
    if (!vehicle) return;
    Object.entries(update).forEach(([key, value]) => {
      Vue.set(vehicle, key, value);
    });
  },
};

export const actions = {
  async getList({ commit, state }) {
    commit('SET_LOADING', true);

    const { data } = await axios.get('v2/marketplace', {
      params: {
        ...state.queryParams,
        limit: 6,
        filter: state.filter,
      },
    });

    commit('SET_SERVER_PAGINATION', {
      page: data.pagination.page,
      rowsPerPage: data.pagination.size,
      rowsNumber: data.totalCount,
    });
    commit('SET_LIST', data.data);
    commit('SET_LOADING', false);
  },

  async loadNext({ commit, state }) {
    if (
      state.serverPagination.rowsPerPage >= state.serverPagination.rowsNumber
    ) return;

    commit('SET_LOADING', true);

    commit('SET_QUERY_PARAMS', {
      page: state.queryParams.page + 1,
    });

    const { data } = await axios.get('v2/marketplace', {
      params: {
        ...state.queryParams,
        limit: 6,
        filter: state.filter,
      },
    });

    commit('SET_SERVER_PAGINATION', {
      rowsPerPage: state.serverPagination.rowsPerPage + 6,
    });
    commit('ADD_LIST', data.data);
    commit('SET_LOADING', false);
  },

  setListPagination({ commit }, pagination) {
    commit('SET_LIST_PAGINATION', pagination);
  },

  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },

  async getCarsale24Image({ commit }, { size, key }) {
    const response = await axios.get(`/v2/marketplace/carsale24/image/${size}/${key}`, {
      responseType: 'blob',
      loadingBar: false,
    });
    const url = URL.createObjectURL(response.data);
    commit('ADD_OBJECT_URL', url);
    return url;
  },

  async setBookmarked({ commit, state }, { id, value }) {
    commit('UPDATE_VEHICLE', [id, {
      bookmarked: value,
    }]);

    // id can be string or number
    // eslint-disable-next-line eqeqeq
    const vehicle = state.list.find(vehicle => vehicle.id == id);

    if (value) {
      commit('marketplaceFavList/ADD_LIST', [vehicle], { root: true });
    } else {
      commit('marketplaceFavList/REMOVE_LIST', vehicle.id, { root: true });
    }

    return axios.post('/v2/marketplace/carsale24/vehicle', vehicle);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
