import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

export const initialState = () => ({
  rabbitMq: {
    series: [{
      name: 'email.webhook',
      data: [],
    },
    {
      name: 'vehicle.push',
      data: [],
    }],
    categories: [],
  },
  usersOnline: [],
});

export const mutations = {
  SET_USERS_ONLINE(state, value) {
    state.usersOnline = value;
  },
  SET_RABBIT(state, value) {
    const initial = initialState();
    state.rabbitMq = initial.rabbitMq;

    const groupedObject = _.groupBy(value, 'created');
    const series = Object.values(groupedObject);
    series.forEach((item) => {
      item.forEach((data) => {
        const found = state.rabbitMq.series.find(x => x.name === data.queue);
        if (found) {
          found.data.push(data.messages);
        }
      });
    });

    const categories = Object.keys(groupedObject);

    state.rabbitMq.categories = categories.map(item => moment(item).format('HH:mm'));
  },
};

export const actions = {

  async getRabbitStats({ commit }) {
    const { data } = await axios.get('/v2/admin/system/rabbitmq');
    commit('SET_RABBIT', data.data);
  },

  async getOnlineUserCount({ commit }) {
    const { data } = await axios.get('/v2/admin/system/useronline');
    commit('SET_USERS_ONLINE', data.data);
  },

};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
