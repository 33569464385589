/*

This store is for the email-feature talking to our database first instead of nylas
Everything not going directly to nylas via backend proxy belongs here

 */

import axios from 'axios';
import { Notify } from 'quasar';
import router from '../../router';
import { convertContactsToAddressPairs } from '@/plugins/util';

export const initialState = () => ({
  loadingEmailMessages: true,
  selectedEmailMessage: null,
  emailMessages: [],
  emailThreadDetail: {},
  emailDataClone: {
    to: [],
    cc: [],
    bcc: [],
    from: [],
    subject: '',
    body: '',
    files: [],
  },
  emailData: {
    to: [],
    cc: [],
    bcc: [],
    from: [],
    subject: '',
    body: '',
    files: [],
  },
  isEmailMessageCreated: false,
  isEmailMessageBeingCreated: false,
});

export const mutations = {
  SET_EMAIL_MESSAGES(state, newValue) {
    state.emailMessages = newValue;
  },

  SET_THREAD_DETAIL(state, newValue) {
    state.emailThreadDetail = newValue;
  },

  SET_LOADING(state, newValue) {
    state.loadingEmailMessages = newValue;
  },

  SET_ALL_EMAILDATA(state, thread) {
    state.emailData = {
      ...thread,
      to: convertContactsToAddressPairs(thread.to || []),
      cc: convertContactsToAddressPairs(thread.cc),
      bcc: convertContactsToAddressPairs(thread.bcc),
    };

    state.emailDataClone = JSON.parse(JSON.stringify(state.emailData));
  },

  SET_EMAILDATA(state, { key, value }) {
    state.emailData[key] = value;
  },

  SET_IS_BEING_CREATED(state, isBeingCreated) {
    state.isEmailMessageBeingCreated = isBeingCreated;
  },

  SET_IS_CREATED(state, isCreated) {
    state.isEmailMessageCreated = isCreated;
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export const getters = {
  hasEmailDataChanged: state => JSON.stringify(state.emailDataClone) !== JSON.stringify(state.emailData),
};

export const actions = {
  async createEmptyEmailMessage({ commit, state }, defaults) {
    if (state.isEmailMessageCreated || state.isEmailMessageBeingCreated) {
      return;
    }
    commit('SET_IS_BEING_CREATED', true);
    const {
      data: { data },
    } = await axios.post('/v2/email/internal', { ...defaults });
    commit('SET_IS_BEING_CREATED', false);
    commit('SET_IS_CREATED', true);

    commit('SET_ALL_EMAILDATA', { ...data, files: [], ...defaults });
  },

  async createEmailMessage({ commit }, emailMessage) {
    const {
      data: { data },
    } = await axios.post('/v2/email/internal', emailMessage);
    commit('SET_ALL_EMAILDATA', data);
    return data;
  },

  async updateEmailMessage({ commit }, emailMessage) {
    const {
      data: { data },
    } = await axios.put(`/v2/email/internal/${emailMessage.ID}`, emailMessage);
    commit('SET_ALL_EMAILDATA', data);
  },

  async deleteEmailMessage(storeUtils, emailMessage) {
    if (!emailMessage.ID) return false;
    return axios.delete(`/v2/email/internal/${emailMessage.ID}`);
  },

  setEmailMessage({ commit }, emailMessage) {
    commit('SET_ALL_EMAILDATA', emailMessage);
    commit('SET_IS_CREATED', true);
  },

  setEmailData({ commit }, data) {
    commit('SET_EMAILDATA', data);
  },

  // Sending via nylas
  async sendNewMailMessage(storeUtils, payload) {
    return axios.post('/v2/email/internal/send', payload);
  },

  // CRUD Attachment
  deleteEmailMessageFile({ commit, state }, fileToBeDeleted) {
    const updatedFiles = state.emailData.files.filter(file => file.ID !== fileToBeDeleted.ID);
    axios.delete(`/v2/email/internal/attachment/${fileToBeDeleted.ID}`);

    commit('SET_EMAILDATA', { key: 'files', value: updatedFiles });
  },

  async uploadDocument({ dispatch, state }, document) {
    await dispatch('createEmptyEmailMessage');
    const { data } = await axios.post(`/v2/email/internal/attachment/document/${state.emailData.ID}`, document);
    return data.data;
  },

  async uploadAttachment(storeUtils, { files, mailId }) {
    const payload = new FormData();
    Array.from(files).forEach((file) => {
      payload.append('files', file);
    });
    payload.append('maildId', mailId);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const { data } = await axios.post('/v2/email/internal/attachment', payload, { headers });
    return data.data;
  },

  // socket handlers
  handleNewMail({ dispatch, rootGetters }, msg) {
    let notifyConf = {};
    const defaults = {
      message: `${msg.from[0].name || msg.from[0].email}: ${msg.subject.substring(0, 30)}`,
      detail: msg.snippet.substring(0, 50),
      timeout: 10000,
      position: 'bottom-right',
      color: 'grey-9',
      textColor: 'white',
    };

    if (msg.opportunityId) {
      notifyConf = {
        ...defaults,
        icon: 'people',
        actions: [
          {
            icon: 'open_in_new',
            handler: () => {
              router.push(`/opportunitymail/${msg.opportunityId}`);
            },
          },
        ],
      };

      const storeOppId = rootGetters['opportunityDetail/opportunityId'];

      if (storeOppId === msg.opportunityId) {
        dispatch('opportunityDetail/getHistoryByOpportunityId', storeOppId, { root: true });
      }

      dispatch('opportunityList/getUnreadCount', null, { root: true });
      dispatch('opportunityList/getOpportunityList', null, { root: true });
    } else if (msg.contactId) {
      notifyConf = {
        ...defaults,
        icon: 'people',
        actions: [
          {
            icon: 'open_in_new',
            handler: () => {
              router.push(`/contact/${msg.contactId}`);
            },
          },
        ],
      };

      const storeId = rootGetters['contact/contactId'];

      if (storeId === msg.contactId) {
        dispatch('contact/getHistoryByContactId', storeId, { root: true });
      }
    } else {
      notifyConf = {
        ...defaults,
        icon: 'email',
        actions: [
          {
            icon: 'open_in_new',
            handler: () => {
              router.push(`/email/detail/${msg.thread_id}`);
            },
          },
        ],
      };
    }

    if (router.currentRoute.path.startsWith('/email')) {
      dispatch('emailThreadList/getEmailThreadList', null, { root: true });
    }

    dispatch('emailThreadList/getEmailThreadsUnreadCount', null, { root: true });
    Notify.create(notifyConf);
  },

  // Store setter
  setEmailThreadDetail({ commit }, thread) {
    commit('SET_THREAD_DETAIL', thread);
  },

  setEmailMessages({ commit }, messages) {
    commit('SET_EMAIL_MESSAGES', messages);
  },

  setReplyToEmailData({ commit, state }, { replyToMyself }) {
    commit('SET_EMAILDATA', { key: 'body', value: '' });
    commit('SET_EMAILDATA', { key: 'files', value: [] });

    if (state.emailThreadDetail.ID) {
      commit('SET_EMAILDATA', { key: 'to', value: state.emailThreadDetail.from });
      commit('SET_EMAILDATA', { key: 'cc', value: state.emailThreadDetail.cc });
      commit('SET_EMAILDATA', { key: 'bcc', value: state.emailThreadDetail.bcc });
      commit('SET_EMAILDATA', { key: 'subject', value: state.emailThreadDetail.subject });
    }

    if (replyToMyself) {
      // in case we want to reply to a message we sent self.
      // Optimally we should filter our email, instead of reassign
      commit('SET_EMAILDATA', { key: 'from', value: state.emailData.to });
    }
  },

  async createReplyEmailMessage({ dispatch }, { thread, messages, replyToMyself }) {
    await dispatch('createEmptyEmailMessage');
    dispatch('setEmailThreadDetail', thread);
    dispatch('setEmailMessages', messages);
    dispatch('setReplyToEmailData', {
      replyToMyself,
    });
  },

  resetInternalEmail({ commit }) {
    commit('RESET');
    commit('SET_LOADING', true);
  },

  markMessageAsRead(storeUtils, messageId) {
    return axios.put(`/v2/email/messages/${encodeURIComponent(messageId)}/unread`, { unread: false }, { errorNotification: false });
  },

  markNylasMessageAsRead(storeUtils, messageId) {
    return axios.put(`/v2/email/internal/setread/${encodeURIComponent(messageId)}`, { unread: false });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
