
export default {
  install: (Vue) => {
    async function copySelfToClipboard(text) {
      try {
        const { state } = await navigator.permissions.query({ name: 'clipboard-write' });
        if (state === 'denied') throw new Error('Permission "clipboard-write" is denied!');

        await navigator.clipboard.writeText(text);
        Vue.prototype.$notifyUserInteraction.info('in Zwischenablage kopiert!');
      } catch (err) {
        console.error(err);
        Vue.prototype.$notifyUserInteraction.error('Konnte nicht kopiert werden!');
      }
    }
    Vue.prototype.$copy = copySelfToClipboard;
  },
};
