<template>
  <q-popover
      v-model="showPopover"
      class="q-body-1 q-px-md q-py-sm edit-popover"
      :anchor-click="show === null"
  >
    <q-field count>
      <q-input ref="input" v-model="valueModel" @keydown.enter="save(valueModel)" />
    </q-field>
    <div class="row justify-end no-wrap q-mt-sm gutter-x-sm">
      <div class="col-auto">
        <q-btn flat color="faded" label="Abbrechen" @click="cancel" />
      </div>
      <div class="col-auto">
        <q-btn color="primary" label="Speichern" class="no-shadow" @click="save(valueModel)"></q-btn>
      </div>
    </div>
  </q-popover>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'APopupEdit',
  props: {
    value: {
      type: String,
      required: true,
    },
    // the attribute defines if the popover is visible or not from external
    // if the attribute is not defined this component handles the visibility state on its own
    // by registering a click handler on its parent DOM element
    // use this attribute in combination with the .sync modifier
    show: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      showModel: false,
      valueModel: '',
    };
  },
  computed: {
    showPopover: {
      get() {
        return !_.isNull(this.show) ? this.show : this.showModel; 
      },
      set(val) {
        if (!_.isNull(this.show)) {
          this.$emit('update:show', val);
        } else {
          this.showModel = val;
        }
      },
    },
  },
  methods: {
    save(value) {
      this.$emit('input', value);
      this.$emit('save', value);
      this.showPopover = false;
    },
    cancel() {
      this.showPopover = false;
      this.$emit('cancel');
    },
  },
  watch: {
    async showPopover(show) {
      if (show) {
        this.valueModel = this.value;
        await this.$nextTick();
        const input = this.$refs.input.$el.querySelector('.q-input-target:not(.q-input-shadow)')
          || this.$refs.input.querySelector('input')
          || this.$refs.input.querySelector('textarea');

        if (input) {
          input.focus();
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.edit-popover {
  width: 350px;
}
</style>
