import axios from 'axios';
import { parseKeysToIn } from '@/plugins/util';
import router from '../../router';
import Vue from 'vue';

const notify = new Vue();

export const state = () => ({
  vehicleDetail: {},
  vehicleDetailLoaded: false,
  vehicleBids: [],
  vehicleTender: {},
  vehicleBoughtInfo: {},
  objectUrls: [],
});

export const mutations = {
  SET_DETAIL(state, detail) {
    if (state.vehicleDetailLoaded) {
      Object.assign(state.vehicleDetail, detail);
    } else {
      state.vehicleDetail = detail;
      state.vehicleDetailLoaded = true;
    }
  },
  SET_BIDS(state, bidData) {
    state.vehicleBids = bidData.bids || [];
    state.vehicleTender = parseKeysToIn(bidData.tender, ['minBid', 'highestBid', 'ownHighestBid']) || {};
  },
  ADD_OBJECT_URL(state, url) {
    state.objectUrls.push(url);
  },
  RESET(currentState) {
    currentState.objectUrls.forEach(url => URL.revokeObjectURL(url));
    Object.assign(currentState, state());
  },
  SET_BOOKMARK(state, bookmarked) {
    state.vehicleDetail.bookmarked = bookmarked;
  },
  SET_BOUGHT_INFO(state, boughtInfo) {
    state.vehicleBoughtInfo = boughtInfo;
  },
};

export const actions = {
  async getVehicleById({ commit }, id) {
    const { data } = await axios.get(`/v2/marketplace/carsale24/${id}`);
    commit('SET_DETAIL', data.data);
  },

  async getVehicleBids({ commit }, id) {
    const { data } = await axios.get(`/v2/marketplace/carsale24/${id}/bids`);
    commit('SET_BIDS', data.data);
  },

  async bidOnVehicle(_, { id, data }) {
    return axios.post(`/v2/marketplace/carsale24/${id}/bids`, data);
  },

  async getCarsale24Image({ commit }, { size, key }) {
    const response = await axios.get(`/v2/marketplace/carsale24/image/${size}/${key}`, {
      responseType: 'blob',
      loadingBar: false,
    });
    const url = URL.createObjectURL(response.data);
    commit('ADD_OBJECT_URL', url);
    return url;
  },

  async getCarsale24Document({ commit }, key) {
    const response = await axios.get(`/v2/marketplace/carsale24/document/${key}`, {
      responseType: 'blob',
    });
    const url = URL.createObjectURL(response.data);
    commit('ADD_OBJECT_URL', url);
    return url;
  },

  async registerCarsale24({ rootState, commit }, data) {
    await axios.post('/v2/marketplace/carsale24/register', data);
    commit('auth/SET_CURRENT_BRANCH', {
      ...rootState.auth.currentBranch,
      hasCs24Account: true,
    }, { root: true });
  },
  reset({ commit }) {
    commit('RESET');
  },

  async setBookmarked({ commit }, { vehicle, value }) {
    await axios.post('/v2/marketplace/carsale24/vehicle', {
      ...vehicle,
      pictureKey: vehicle.pictures[0],
      bookmarked: value,
    });
    commit('SET_BOOKMARK', value);
  },

  async getCarsale24BoughtInfo({ commit }, id) {
    const { data } = await axios.get(`/v2/marketplace/carsale24/${id}/bought`);
    commit('SET_BOUGHT_INFO', data.data);
  },

  async handleOutbidden({ dispatch }, msg) {
    if (router.currentRoute.params.vehicleId === msg.vehicleId) {
      await dispatch('getVehicleBids', msg.vehicleId);
    }
    notify.$notifyUserInteraction.warning('Sie wurden überboten');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
