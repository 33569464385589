import { isNil } from 'lodash';

export default {
  install: (Vue) => {
    Vue.prototype.$confirm = ({
      title, message, proceedLabel, proceedColor, cancelLabel, cancelColor, preventClose, zIndex,
    } = {}) => {
      const dialog = Vue.prototype.$q.dialog({
        title: title || 'Bestätigung erforderlich',
        message: message || 'Wollen Sie wirklich fortfahren?',
        ok: {
          noCaps: true,
          label: proceedLabel || 'Ja, fortfahren',
          color: proceedColor || 'negative',
        },
        cancel: {
          noCaps: true,
          label: cancelLabel || 'Abbrechen',
          color: cancelColor || 'faded',
        },
        preventClose: !isNil(preventClose) ? preventClose : false,
      });

      if (zIndex) {
        Vue.nextTick(() => {
          document.querySelector('.modal').style.zIndex = zIndex;
        });
      }

      return dialog;
    };
  },
};
