import axios from 'axios';

export const initialState = () => ({
  queryParams: {
    limit: 10,
    search: '',
    folder: 'inbox',
    page_token: null,
  },
  emailThreadList: [],
  unread: null,
  loading: true,
});

/* Cache emails for 10 min because of nylas being slow */
const THREAD_CACHE = {};

const cacheData = ({ folder, search }, page, data) => {
  const req = [folder, search].join(':');

  if (!THREAD_CACHE[req]) {
    THREAD_CACHE[req] = {
      timestamp: Date.now(),
    };
  }

  THREAD_CACHE[req][page] = data;
};

const getCache = ({ folder, search }, page) => {
  const req = [folder, search].join(':');

  if (!THREAD_CACHE[req]) return null;

  // 600 sec = 10 min
  if (Date.now() - THREAD_CACHE[req].timestamp > 1e3 * 600) {
    THREAD_CACHE[req] = null;
    return null;
  }

  return THREAD_CACHE[req][page];
};

const clearCache = ({ folder, search }) => {
  const req = [folder, search].join(':');
  THREAD_CACHE[req] = null;
};

export const mutations = {
  SET_THREAD_LIST(state, newValue) {
    state.emailThreadList = [...newValue];
  },
  ADD_TO_THREAD_LIST(state, moreValue) {
    state.emailThreadList.push(...moreValue);
  },
  CLEAR_CACHE(state) {
    clearCache(state.queryParams);
  },
  SET_UNREAD(state, newValue) {
    state.unread = newValue;
  },
  SET_PAGE_TOKEN(state, newValue) {
    state.queryParams.page_token = newValue;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  REMOVE_THREAD(state, threadId) {
    state.emailThreadList = state.emailThreadList.filter(thread => thread.id !== threadId);
  },
  MARK_THREAD_READ(state, threadId) {
    const thread = state.emailThreadList.find(t => t.id === threadId);
    if (thread) thread.unread = false;
  },
  SET_HAS_DRAFT_TO_THREAD(state, { threadId, hasDrafts = false }) {
    const thread = state.emailThreadList.find(t => t.id === threadId);
    if (thread) thread.hasDrafts = hasDrafts;
  },
};

export const actions = {

  async getEmailThreadList({ commit, state }, page = 1) {
    commit('SET_LOADING', true);

    if (page < 2) commit('SET_PAGE_TOKEN', null);

    const queryParams = { ...state.queryParams };
    let data = getCache(queryParams, page);
    if (!data) {
      const { data: resData } = await axios.get('/v2/email/threads', { params: queryParams });
      data = resData;
      cacheData(queryParams, page, data);

      if (
        queryParams.folder !== state.queryParams.folder
        || queryParams.search !== state.queryParams.search
      ) return 'canceled';
    } else if (queryParams.search) {
      // Fake search if search is cached
      await new Promise(resolve => setTimeout(resolve, 600));

      if (
        queryParams.folder !== state.queryParams.folder
        || queryParams.search !== state.queryParams.search
      ) return 'canceled';
    }

    commit('SET_PAGE_TOKEN', data.nextCursor);

    if (page < 2) {
      commit('SET_THREAD_LIST', data.data);
    } else {
      commit('ADD_TO_THREAD_LIST', data.data);
    }

    commit('SET_LOADING', false);

    if (!data.nextCursor) {
      return 'no-more';
    }

    return data.data;
  },

  getEmailThreadsUnreadCount({ commit, state }) {
    return axios.get('/v2/email/threads/unread', { params: state.queryParams }).then((res) => {
      commit('SET_UNREAD', res.data.data);
      return res.data.data;
    });
  },

  deleteEmailThread({ commit }, threadId) {
    return axios.delete(`/v2/email/threads/${threadId}`).then(() => {
      commit('REMOVE_THREAD', threadId);
    });
  },
};


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
