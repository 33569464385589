<template>
  <div id="app">
    <q-progress
      class="customLoadingBar"
      color="primary"
      height="2px"
      :indeterminate="true"
      v-show="showLoadingBar"
    />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showLoadingBarStatus: false,
      showLoadingBarRequest: false,
    };
  },
  computed: {
    connectionStatus() {
      return this.$store.state.connection.status;
    },
    connectionRequestOpen() {
      return this.$store.state.connection.requests.length > 0;
    },
    showLoadingBar() {
      return this.showLoadingBarStatus || this.showLoadingBarRequest;
    },
  },
  watch: {
    connectionStatus(status) {
      if (this.$options.showLoadingTimeoutStatus) {
        clearTimeout(this.$options.showLoadingTimeoutStatus);
      }

      if (status === 'hold') {
        this.$options.showLoadingTimeoutStatus = setTimeout(
          () => { this.showLoadingBarStatus = true; },
          1500,
        );
      } else {
        this.showLoadingBarStatus = false;
      }

      if (status === 'offline') {
        if (this.$options.dismissConnectionAlert) {
          this.$options.dismissConnectionAlert();
        }
        this.$options.dismissConnectionAlert = this.$q.notify({
          type: 'negative',
          position: 'top-right',
          timeout: 0,
          message: 'Die Verbindung wurde unterbrochen, bitte stellen Sie sicher, dass die Verbindung besteht und laden Sie die Seite erneut',
        });
      }

      if (status === 'online' && this.$options.dismissConnectionAlert) {
        this.$options.dismissConnectionAlert();
      }
    },
    connectionRequestOpen(val) {
      if (this.$options.showLoadingTimeoutRequest) {
        clearTimeout(this.$options.showLoadingTimeoutRequest);
      }

      if (val) {
        this.$options.showLoadingTimeoutRequest = setTimeout(
          () => { this.showLoadingBarRequest = true; },
          1500,
        );
      } else {
        this.showLoadingBarRequest = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.customLoadingBar {
  position: absolute;
  z-index: 2409;

  >>> .q-progress-track {
    display: none;
  }
}
</style>
