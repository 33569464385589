<template>
  <q-select
    v-model="currentBranchId"
    :options="branchOptions"
    :readonly="branchLoading"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'BranchSelect',
  computed: {
    ...mapState('auth', ['currentBranch', 'branchLoading']),
    ...mapGetters('auth', ['accessibleBranches']),
    currentBranchId: {
      get() {
        return this.currentBranch.ID;
      },
      set(branchId) {
        this.reloadCurrentBranch(branchId);
      },
    },
    branchOptions() {
      return this.accessibleBranches.map(branch => ({
        label: branch.name,
        value: branch.branchId,
      }));
    },
  },
  created() {
    this.getAccessibleBranches();
  },
  methods: {
    ...mapActions('auth', ['getAccessibleBranches', 'reloadCurrentBranch']),
  },
};
</script>
