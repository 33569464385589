import axios from 'axios';

export const state = () => ({
  queryParams: {
    limit: 25,
    offset: 0,
    order: {
      name: null,
      dir: null,
    },
  },
  search: '',
  branchList: [],
  branchListCount: 0,
  serverPagination: {
    page: 1,
    rowsPerPage: 25,
    rowsNumber: null,
    sortBy: null,
    descending: false,
  },
  dataCounts: {
    filteredCount: 0,
    totalCount: 0,
  },
  loading: true,
  columns: [
    {
      name: 'icon',
      label: '',
      sortable: false,
      align: 'left',
    },
    {
      name: 'name',
      label: 'Name',
      sortable: true,
      align: 'left',
    },
    {
      name: 'created',
      label: 'Erstellt',
      sortable: true,
      align: 'left',
    },
    {
      name: 'action',
      label: '',
      align: 'right',
    },
  ],
});

export const mutations = {
  SET_BRANCH_LIST(state, newValue) {
    state.branchList = newValue;
  },
  SET_BRANCH_LIST_PAGINATION_ROWS_NUMBER(state, newValue) {
    state.serverPagination.rowsNumber = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.dataCounts.filteredCount = filteredCount;
    state.dataCounts.totalCount = totalCount;
  },
  SET_BRANCH_LIST_PAGINATION(state, pagination) {
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.queryParams.order.name = pagination.sortBy || 'ID';
    state.queryParams.order.dir = pagination.sortBy && pagination.descending ? 'DESC' : 'ASC';
    state.serverPagination = pagination;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },

  SET_QUERY_PARAMS(state, queryParams) {
    state.queryParams = queryParams;
  },

  SET_BRANCH_LIST_COUNT(state, count) {
    state.branchListCount = count;
  },
};

export const actions = {
  getBranchList({ commit, state }) {
    commit('SET_LOADING', true);
    return axios.get('v2/branch', {
      params: {
        ...state.queryParams,
        search: state.search,
      },
    }).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_BRANCH_LIST_PAGINATION_ROWS_NUMBER', res.data.totalCount);
      commit('SET_BRANCH_LIST', res.data.data);
      commit('SET_LOADING', false);
    });
  },

  async getBranchListCount({ commit }) {
    const { data: { data: count } } = await axios.get('v2/branch/count');
    commit('SET_BRANCH_LIST_COUNT', count);
  },

  setBranchListPagination({ commit }, pagination) {
    commit('SET_BRANCH_LIST_PAGINATION', pagination);
  },

  setBranchSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },

  async addNewBranch(_, data) {
    const result = await axios.post('v2/branch', data);
    return result.data.data;
  },

  async removeBranch(_, branchId) {
    const { data } = await axios.delete(`v2/branch/${branchId}`);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
