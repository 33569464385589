import axios from 'axios';

export const state = () => ({
  queryParams: {
    limit: 25,
    offset: 0,
    order: {
      name: 'created',
      dir: 'DESC',
    },
  },
  search: '',
  filter: {
    packageKey: [],
    subscriptionStatus: [],
    stripeCustomerId: null,
    lastOnline: null,
  },
  dealerList: [],
  dealerListCount: [],
  serverPagination: {
    page: 1,
    rowsPerPage: 25, // change queryParams.limit too
    rowsNumber: null, // this is the total number of records/totalCount
    sortBy: 'created',
    descending: true,
  },
  dataCounts: {
    filteredCount: 0,
    totalCount: 0,
  },
  loading: true,
  selected: [],
  columns: [
    {
      name: 'ID',
      label: 'ID',
      sortable: true,
      align: 'left',
    },
    {
      name: 'online',
      label: 'Online',
      align: 'center',
    },
    {
      name: 'isActive',
      label: 'Zugriff',
      sortable: true,
      align: 'center',
    },
    {
      name: 'name',
      label: 'Firma',
      sortable: true,
      align: 'left',
    },
    {
      name: 'email',
      label: 'E-Mail',
      sortable: true,
      align: 'left',
    },
    {
      name: 'firstName',
      label: 'Vorname',
      sortable: true,
      align: 'left',
    },
    {
      name: 'lastName',
      label: 'Nachname',
      sortable: true,
      align: 'left',
    },
    {
      name: 'vehicleCount',
      label: 'Fahrzeuge',
      sortable: false,
      align: 'left',
    },
    {
      name: 'trialPeriodEnd',
      label: 'Testzeitraum',
      sortable: true,
      align: 'left',
    },
    {
      name: 'packages',
      label: 'Pakete',
      align: 'left',
    },
    {
      name: 'created',
      label: 'Erstellt',
      sortable: true,
      align: 'left',
    },
  ],
});

export const mutations = {
  SET_DEALER_LIST(state, newValue) {
    state.dealerList = newValue;
  },
  SET_LIST_PAGINATION_ROWS_NUMBER(state, newValue) {
    state.serverPagination.rowsNumber = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.dataCounts.filteredCount = filteredCount;
    state.dataCounts.totalCount = totalCount;
  },
  SET_LIST_PAGINATION(state, pagination) {
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.queryParams.order.name = pagination.sortBy || 'created';
    state.queryParams.order.dir = pagination.descending ? 'DESC' : 'ASC';
    state.serverPagination = pagination;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },

  SET_FILTER(state, filter) {
    Object.assign(state.filter, filter);
  },

  SET_QUERY_PARAMS(state, queryParams) {
    state.queryParams = queryParams;
  },

  SET_LIST_COUNT(state, count) {
    state.dealerListCount = count;
  },
  SET_SELECTION(state, data) {
    state.selected = data;
  },
};

export const actions = {
  async getDealerList({ commit, state }) {
    commit('SET_LOADING', true);

    const { data } = await axios.get('v2/dealer', {
      params: {
        ...state.queryParams,
        filter: state.filter,
        search: state.search,
      },
    });

    commit('SET_PAGINATION', { filteredCount: data.filteredCount, totalCount: data.totalCount });
    commit('SET_LIST_PAGINATION_ROWS_NUMBER', data.totalCount);
    commit('SET_DEALER_LIST', data.data);
    commit('SET_LOADING', false);
  },

  async getListCount({ commit }) {
    const { data: { data: count } } = await axios.get('v2/dealer/count');
    commit('SET_LIST_COUNT', count);
  },

  setListPagination({ commit }, pagination) {
    commit('SET_LIST_PAGINATION', pagination);
  },

  setSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },

  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
