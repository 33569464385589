<template>
  <div>

    <q-editor
      :value="value"
      :disable="disable"
      @input="$emit('input', $event)"
      max-height="400px"
      toolbar-flat
      :toolbar="[
            ['left', 'center', 'right', 'justify'],
            ['bold', 'italic', 'underline'],
            ['unordered', 'ordered', 'default_font'],
            [
              {
                icon: $q.icon.editor.fontSize,
                fixedIcon: true,
                list: 'no-icons',
                options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
              },
            ],
            ['undo', 'redo'],
            ['attachment_btn', 'signature_btn', 'template_btn']
          ]"
      class="mailEditor"
      ref="mailEditor"
    >
      <q-btn
              v-if="showMailButtons"
              slot="signature_btn"
              dense
              flat
              icon="gesture"
              @click.native="!disable ? saveCursorPosition($event) : () => {}"
              :disable="disable"
      >
        <q-tooltip>Signaturen</q-tooltip>
        <q-popover v-if="!disable">
          <q-list separator link>
            <q-item v-for="(signature, index) in signatures" v-close-overlay :key="index">
              <q-item-main
                      class="q-caption"
                      @click.native="insertSignature(signature.ID)"
              >{{ signature.name }}</q-item-main>
              <q-item-side right>
                <q-item-tile
                        @click.native="rowClickSignatures(signature)"
                        icon="edit"
                        style="font-size: 16px"
                        color="faded"
                />
              </q-item-side>
            </q-item>
          </q-list>
          <q-list separator link>
            <q-item
                    class="q-caption"
                    v-close-overlay
                    @click.native="openSignatureEditor(true)"
            >Als Signatur speichern</q-item>
            <q-item
                    class="q-caption"
                    v-close-overlay
                    @click.native="openSignatureEditor(false)"
            >Leere Signatur</q-item>
            <q-item
                    class="q-caption"
                    v-close-overlay
                    @click.native="openSignatureList()"
            >Signaturen bearbeiten</q-item>
          </q-list>
        </q-popover>
      </q-btn>
      <q-btn
        v-if="showMailButtons"
        slot="template_btn"
        dense
        flat
        icon="import_contacts"
        @click.native="!disable ? saveCursorPosition($event) : () => {}"
        :disable="disable"
      >
        <q-tooltip>Vorlagen</q-tooltip>
        <q-popover v-if="!disable">
          <q-list separator link>
            <q-item v-for="(template, index) in templates" v-close-overlay :key="index">
              <q-item-main
                class="q-caption"
                @click.native="insertTemplate(template.ID)"
              >{{ template.name }}</q-item-main>
              <q-item-side right>
                <q-item-tile
                  @click.native="rowClickTemplates(template)"
                  icon="edit"
                  style="font-size: 16px"
                  color="faded"
                />
              </q-item-side>
            </q-item>
          </q-list>
          <q-list separator link>
            <q-item
              class="q-caption"
              v-close-overlay
              @click.native="openTemplateEditor(true)"
            >Als Vorlage speichern</q-item>
            <q-item
              class="q-caption"
              v-close-overlay
              @click.native="openTemplateEditor(false)"
            >Leere Vorlage</q-item>
            <q-item
              class="q-caption"
              v-close-overlay
              @click.native="openTemplateList()"
            >Vorlagen bearbeiten</q-item>
          </q-list>
        </q-popover>
      </q-btn>
    </q-editor>

    <q-modal
      v-model="templateEditorOpened"
      class="q-pa-lg"
      :content-css="{maxWidth: '80vw', minHeight: 'auto'}"
    >
      <q-modal-layout>
        <q-toolbar slot="header">
          <q-btn flat round dense v-close-overlay icon="keyboard_arrow_left"/>
          <q-toolbar-title>Vorlage bearbeiten</q-toolbar-title>
        </q-toolbar>
      </q-modal-layout>
      <div class="layout-padding">
        <div class="row gutter-md">
          <div class="col">
            <div class="modal-form">
              <q-input placeholder="Vorlagenname eingeben" v-model="templateName" maxlength="80"/>
              <br>
              <q-editor
                :value="templateEditorHTML"
                @input="templateEditorHTML = $event"
                class="full-width"
                :toolbar="[
            ['left', 'center', 'right', 'justify'],
            ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
            ['unordered', 'ordered', 'outdent', 'indent'],
            ['token', 'hr', 'link'],
            [
              {
                label: $q.i18n.editor.fontSize,
                icon: $q.icon.editor.fontSize,
                fixedLabel: true,
                fixedIcon: true,
                list: 'no-icons',
                options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
              },
            ],
            ['undo', 'redo'],
            ['save']
          ]"
                ref="mailTemplateEditor"
              ></q-editor>
              <q-checkbox
                class="q-caption q-pt-sm q-ml-xs"
                color="primary"
                v-model="templateBranchAllowed"
                label="Für komplette Firma freigeben"
              />
              <hr>
              <div align="end">
                <q-btn
                  flat
                  color="light"
                  label="Abbrechen"
                  @click="templateEditorOpened = false"
                  class="q-mr-sm"
                />
                <q-btn
                  rounded
                  label="Speichern"
                  @click="storeTemplate()"
                  :disabled="isDisabledTemplateEditor()"
                  color="primary no-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-modal>

    <q-modal
      v-model="templateListOpened"
      class
      :content-css="{maxWidth: '50vw', minHeight: 'auto'}"
    >
      <q-modal-layout>
        <q-toolbar slot="header">
          <q-btn flat round dense v-close-overlay icon="keyboard_arrow_left"/>
          <q-toolbar-title>Vorlagen</q-toolbar-title>
        </q-toolbar>
      </q-modal-layout>
      <div class="layout-padding">
        <div class="row gutter-md">
          <div class="col">
            <q-table
              title="Vorlagen"
              :data="templates"
              :columns="templateListColumns"
              row-key="ID"
              selection="multiple"
              :selected.sync="selectedTemplates"
              class="no-shadow"
              dense
            >
              <q-tr
                slot="body"
                slot-scope="props"
                :props="props"
                @click.native="rowClickTemplates(props.row)"
                class="cursor-pointer"
              >
                <q-td auto-width>
                  <q-checkbox color="primary" v-model="props.selected"/>
                </q-td>
                <q-td v-for="col in props.cols" :key="col.name" :props="props">{{ col.value }}</q-td>
              </q-tr>
            </q-table>
            <br>
            <div align="end">
              <q-btn
                flat
                color="light"
                label="Abbrechen"
                @click="templateListOpened = false"
                class="q-mr-sm"
              />
              <q-btn
                rounded
                label="Löschen"
                @click="confirmDeleteTemplates()"
                :disabled="isDisabledDeleteTemplates()"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </q-modal>

    <q-modal
            v-model="signatureEditorOpened"
            class="q-pa-lg"
            :content-css="{maxWidth: '80vw', minHeight: 'auto'}"
    >
      <q-modal-layout>
        <q-toolbar slot="header">
          <q-btn flat round dense v-close-overlay icon="keyboard_arrow_left"/>
          <q-toolbar-title>Signatur bearbeiten</q-toolbar-title>
        </q-toolbar>
      </q-modal-layout>
      <div class="layout-padding">
        <div class="row gutter-md">
          <div class="col">
            <div class="modal-form">
              <q-input placeholder="Signaturname eingeben" v-model="signatureName" maxlength="80"/>
              <br>
              <q-editor
                      :value="signatureEditorHTML"
                      @input="signatureEditorHTML = $event"
                      class="full-width"
                      :toolbar="[
            ['left', 'center', 'right', 'justify'],
            ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
            ['unordered', 'ordered', 'outdent', 'indent'],
            ['token', 'hr', 'link'],
            [
              {
                label: $q.i18n.editor.fontSize,
                icon: $q.icon.editor.fontSize,
                fixedLabel: true,
                fixedIcon: true,
                list: 'no-icons',
                options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
              },
            ],
            ['undo', 'redo'],
            ['save'],
            ['image_btn']
          ]"
                      ref="mailSignatureEditor"
              >
                <q-btn slot="image_btn" icon="photo" flat dense @click="insertImage"/>
              </q-editor>
              <hr>
              <div align="end">
                <q-btn
                        flat
                        color="light"
                        label="Abbrechen"
                        @click="signatureEditorOpened = false"
                        class="q-mr-sm"
                />
                <q-btn
                        rounded
                        label="Speichern"
                        @click="storeSignature()"
                        :disabled="isDisabledSignatureEditor()"
                        color="primary no-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-modal>

    <q-modal
            v-model="signatureListOpened"
            class
            :content-css="{maxWidth: '50vw', minHeight: 'auto'}"
    >
      <q-modal-layout>
        <q-toolbar slot="header">
          <q-btn flat round dense v-close-overlay icon="keyboard_arrow_left"/>
          <q-toolbar-title>Vorlagen</q-toolbar-title>
        </q-toolbar>
      </q-modal-layout>
      <div class="layout-padding">
        <div class="row gutter-md">
          <div class="col">
            <q-table
                    title="Signaturen"
                    :data="signatures"
                    :columns="signatureListColumns"
                    row-key="ID"
                    selection="multiple"
                    :selected.sync="selectedSignatures"
                    class="no-shadow"
                    dense
            >
              <q-tr
                      slot="body"
                      slot-scope="props"
                      :props="props"
                      @click.native="rowClickSignatures(props.row)"
                      class="cursor-pointer"
              >
                <q-td auto-width>
                  <q-checkbox color="primary" v-model="props.selected"/>
                </q-td>
                <q-td v-for="col in props.cols" :key="col.name" :props="props">{{ col.value }}</q-td>
              </q-tr>
            </q-table>
            <br>
            <div align="end">
              <q-btn
                      flat
                      color="light"
                      label="Abbrechen"
                      @click="signatureListOpened = false"
                      class="q-mr-sm"
              />
              <q-btn
                      rounded
                      label="Löschen"
                      @click="confirmDeleteSignatures()"
                      :disabled="isDisabledDeleteSignatures()"
                      color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </q-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AEditor',

  props: {
    value: {
      required: false,
    },
    showMailButtons: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
    },
  },

  data() {
    return {
      cursorPosition: 0,
      cursorPositionData: {},
      templateEditorHTML: '',
      templateEditorOpened: false,
      templateBranchAllowed: false,
      templateName: '',
      templateId: null,
      templateListOpened: false,
      selectedTemplates: [],
      templateListColumns: [
        {
          name: 'name',
          required: true,
          label: 'Name',
          align: 'left',
          field: 'name',
          sortable: true,
        },
        {
          name: 'created',
          required: true,
          label: 'Erstellt',
          align: 'left',
          field: 'created',
          sortable: true,
          format(value) {
            return new Date(value).toLocaleString();
          },
        },
        {
          name: 'scope',
          required: true,
          label: 'Sichtbarkeit',
          align: 'left',
          field: 'scope',
          sortable: true,
          format(value) {
            return value === 'user' ? 'Nutzer' : 'Firma';
          },
        },
      ],
      signatureEditorHTML: '',
      signatureEditorOpened: false,
      signatureName: '',
      signatureId: null,
      signatureListOpened: false,
      selectedSignatures: [],
      signatureListColumns: [
        {
          name: 'name',
          required: true,
          label: 'Name',
          align: 'left',
          field: 'name',
          sortable: true,
        },
        {
          name: 'created',
          required: true,
          label: 'Erstellt',
          align: 'left',
          field: 'created',
          sortable: true,
          format(value) {
            return new Date(value).toLocaleString();
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('emailThreadDetail', ['templates', 'signatures']),
  },
  mounted() {
    this.loadTemplatesAndSignatures();
  },
  methods: {
    insertImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.png, .jpg';

      input.onchange = () => {
        const files = Array.from(input.files);
        const file = files[0]; // eslint-disable-line

        if (file.size > 1048576) { // 1MB
          this.$notifyUserInteraction.error('Bild darf 1MB nicht überschreiten');
          return;
        }

        const reader = new FileReader();
        let dataUrl = '';
        reader.onloadend = () => {
          dataUrl = reader.result;

          this.$refs.mailSignatureEditor.focus();
          document.execCommand('insertHTML', true, `<div><img src="${dataUrl}" /></div>`);
        };
        reader.readAsDataURL(file);
      };
      input.click();
    },

    loadTemplatesAndSignatures() {
      this.$store.dispatch('emailThreadDetail/loadTemplates');
      this.$store.dispatch('emailThreadDetail/loadSignatures');
    },
    async storeTemplate() {
      this.$store
        .dispatch('emailThreadDetail/storeTemplate', {
          html: this.templateEditorHTML,
          name: this.templateName,
          scope: this.templateBranchAllowed ? 'branch' : 'user',
          id: this.templateId,
        })
        .then(() => {
          this.resetTemplateEditor();
          this.loadTemplatesAndSignatures();
          this.$notifyUserInteraction.success('E-Mail Vorlage gespeichert.');
        });
    },
    getSelectedTemplateIds() {
      return this.selectedTemplates.map(selected => selected.ID);
    },
    confirmDeleteTemplates() {
      if (this.isDisabledDeleteTemplates()) return;
      const selectedTemplateIds = this.getSelectedTemplateIds();
      this.$q
        .dialog({
          title: 'Vorlagen löschen',
          message: 'Wollen Sie die ausgewählten Vorlagen wirklich löschen?',
          ok: 'Ja',
          cancel: 'Nein',
        })
        .then(() => {
          this.$store
            .dispatch('emailThreadDetail/deleteTemplates', {
              templateIds: selectedTemplateIds,
            })
            .then(() => {
              this.templateListOpened = false;
              this.selectedTemplates = [];
              this.loadTemplates();
            });
        })
        .catch(() => {
          // do nothing
        });
    },
    isDisabledTemplateEditor() {
      return this.templateName === '';
    },
    resetTemplateEditor() {
      this.templateEditorHTML = '';
      this.templateName = '';
      this.templateId = null;
      this.templateBranchAllowed = false;
      this.templateEditorOpened = false;
    },
    isDisabledDeleteTemplates() {
      return this.selectedTemplates.length === 0;
    },
    rowClickTemplates(row) {
      this.$store
        .dispatch('emailThreadDetail/loadTemplate', row.ID)
        .then((data) => {
          this.openTemplateEditor(false, data.data.data);
        });
    },
    openTemplateList() {
      this.templateListOpened = true;
    },
    openTemplateEditor(
      loadHTMLFromEditor = false,
      templateFromDatabase = null,
    ) {
      this.resetTemplateEditor();
      let templateHTML = '';
      let branchAllowed = false;
      let templateName = '';
      if (loadHTMLFromEditor) {
        const el = this.$refs.mailEditor.$el.lastChild;
        templateHTML = el.innerHTML;
      }
      if (templateFromDatabase !== null) {
        templateHTML = templateFromDatabase.html;
        branchAllowed = templateFromDatabase.scope === 'branch';
        templateName = templateFromDatabase.name;
        this.templateId = templateFromDatabase.ID;
      }
      this.templateEditorHTML = templateHTML;
      this.templateBranchAllowed = branchAllowed;
      this.templateName = templateName;
      this.templateEditorOpened = true;
      this.templateListOpened = false;
    },

    async storeSignature() {
      this.$store
        .dispatch('emailThreadDetail/storeSignature', {
          html: this.signatureEditorHTML,
          name: this.signatureName,
          id: this.signatureId,
        })
        .then(() => {
          this.resetSignatureEditor();
          this.loadTemplatesAndSignatures();
          this.$notifyUserInteraction.success('E-Mail Signatur gespeichert.');
        });
    },
    getSelectedSignatureIds() {
      return this.selectedSignatures.map(selected => selected.ID);
    },
    confirmDeleteSignatures() {
      if (this.isDisabledDeleteSignatures()) return;
      const selectedSignatureIds = this.getSelectedSignatureIds();
      this.$q
        .dialog({
          title: 'Signatur löschen',
          message: 'Wollen Sie die ausgewählten Signaturen wirklich löschen?',
          ok: 'Ja',
          cancel: 'Nein',
        })
        .then(() => {
          this.$store
            .dispatch('emailThreadDetail/deleteSignatures', {
              signatureIds: selectedSignatureIds,
            })
            .then(() => {
              this.signatureListOpened = false;
              this.selectedSignatures = [];
              this.loadSignatures();
            });
        })
        .catch(() => {
          // do nothing
        });
    },
    isDisabledSignatureEditor() {
      return this.signatureName === '';
    },
    resetSignatureEditor() {
      this.signatureEditorHTML = '';
      this.signatureName = '';
      this.signatureId = null;
      this.signatureEditorOpened = false;
    },
    isDisabledDeleteSignatures() {
      return this.selectedSignatures.length === 0;
    },
    rowClickSignatures(row) {
      this.$store
        .dispatch('emailThreadDetail/loadSignature', row.ID)
        .then((data) => {
          this.openSignatureEditor(false, data.data.data);
        });
    },
    openSignatureList() {
      this.signatureListOpened = true;
    },
    openSignatureEditor(
      loadHTMLFromEditor = false,
      signatureFromDatabase = null,
    ) {
      this.resetSignatureEditor();
      let signatureHTML = '';
      let signatureName = '';
      if (loadHTMLFromEditor) {
        const el = this.$refs.mailEditor.$el.lastChild;
        signatureHTML = el.innerHTML;
      }
      if (signatureFromDatabase !== null) {
        signatureHTML = signatureFromDatabase.html;
        signatureName = signatureFromDatabase.name;
        this.signatureId = signatureFromDatabase.ID;
      }
      this.signatureEditorHTML = signatureHTML;
      this.signatureName = signatureName;
      this.signatureEditorOpened = true;
      this.signatureListOpened = false;
    },

    getNodeIndex(n) {
      let i = 0;
      while ((n = n.previousSibling)) i += 1; // eslint-disable-line
      return i;
    },
    saveCursorPosition() {
      this.$refs.mailEditor.focus();
      const range = window.getSelection().getRangeAt(0);
      let sC = range.startContainer;
      let eC = range.endContainer;
      const A = [];
      const B = [];
      const el = this.$refs.mailEditor.$el.lastChild;
      while (sC !== el) {
        A.push(this.getNodeIndex(sC));
        sC = sC.parentNode;
      }
      while (eC !== el) {
        B.push(this.getNodeIndex(eC));
        eC = eC.parentNode;
      }

      this.cursorPositionData = {
        sC: A,
        sO: range.startOffset,
        eC: B,
        eO: range.endOffset,
      };
    },
    insertTemplate(id) {
      this.$store
        .dispatch('emailThreadDetail/loadTemplate', id)
        .then((templateData) => {
          this.restoreCursorPositionAndInsert(templateData.data.data.html);
        });
    },
    insertSignature(id) {
      this.$store
        .dispatch('emailThreadDetail/loadSignature', id)
        .then((signatureData) => {
          this.setCaretToEnd();
          this.saveCursorPosition();
          this.restoreCursorPositionAndInsert(signatureData.data.data.html);
        });
    },
    setCaretToEnd() {
      this.$refs.mailEditor.focus();
      const el = this.$refs.mailEditor.$el.lastChild;
      const blockquote = el.querySelector('#old-mail');
      const target = blockquote ? blockquote.previousElementSibling : el.lastElementChild;
      const range = document.createRange();
      const sel = window.getSelection();

      if (target) {
        range.setStartAfter(target);
        range.setEndAfter(target);
      } else {
        range.selectNodeContents(el);
      }

      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus();
      range.detach(); // optimization
    },
    restoreCursorPositionAndInsert(html) {
      this.$refs.mailEditor.focus();
      const el = this.$refs.mailEditor.$el.lastChild;
      const sel = window.getSelection();
      const range = sel.getRangeAt(0);
      const nel = document.createElement('div');
      const frag = document.createDocumentFragment();
      let node;
      let x;
      let C;
      let sC = el;
      let eC = el;

      C = this.cursorPositionData.sC;
      x = C.length - 1;
      while (x >= 0) {
        sC = sC.childNodes[C[x]];
        x -= 1;
      }
      C = this.cursorPositionData.eC;
      x = C.length - 1;
      while (x >= 0) {
        eC = eC.childNodes[C[x]];
        x -= 1;
      }
      range.setStart(sC, this.cursorPositionData.sO);
      range.setEnd(eC, this.cursorPositionData.eO);
      nel.innerHTML = html;
      node = nel.firstChild;
      while (node) {
        frag.appendChild(node);
        node = nel.firstChild;
      }
      frag.appendChild(document.createElement('br'));
      frag.appendChild(document.createElement('br'));
      frag.appendChild(document.createElement('br'));
      range.insertNode(frag);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    },
    storeCursorPosition() {
      const el = this.$refs.mailEditor.$el.lastChild;
      let caretOffset = 0;
      try {
        if (typeof window.getSelection !== 'undefined') {
          const range = window.getSelection().getRangeAt(0);
          const selected = range.toString().length;
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(el);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length - selected;
        }
      } catch (err) {
        // do nothing
      }
      this.cursorPosition = caretOffset;
    },
    getAllTextnodes(el) {
      let n;
      const a = [];
      const walk = document.createTreeWalker(
        el,
        NodeFilter.SHOW_TEXT,
        null,
        false,
      );
      while ((n = walk.nextNode())) a.push(n); // eslint-disable-line
      return a;
    },
    getCaretData(el, position) {
      let node;
      const nodes = this.getAllTextnodes(el);
      for (let n = 0; n < nodes.length; n += 1) {
        if (position > nodes[n].nodeValue.length && nodes[n + 1]) {
          // remove amount from the position, go to next node
          position -= nodes[n].nodeValue.length;
        } else {
          node = nodes[n];
          break;
        }
      }
      // you'll need the node and the position (offset) to set the caret
      return { node, position };
    },
    setCaretPositionAndInsert(d, insertData) {
      const sel = window.getSelection();
      const range = document.createRange();
      range.setStart(d.node, d.position);
      range.collapse(true);
      range.insertNode(range.createContextualFragment(insertData));
      sel.removeAllRanges();
    },
    focus() {
      this.$refs.mailEditor.$el.lastElementChild.focus();
    },
  },
};
</script>
<style scoped lang="stylus">
.q-editor {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: none;

  &.disabled {
    border: none;
  }
}

>>> .q-editor-content {
  background: unset;
}

>>> .q-editor-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  background: unset !important;
}

>>> .q-editor-toolbar-padding div:last-child {
  margin-left: auto;
}

>>> .q-editor-toolbar-padding div:last-child::before {
  background: white;
}

>>> .q-editor-content:empty:not(:focus):before {
  content: 'Bitte Text eingeben...';
  font-style: italic;
  color: grey;
}

>>> .q-editor-content {
  background: #e3e3e336;
}
</style>
