import axios from 'axios';
import saveAs from 'file-saver';

const initialState = () => ({
  financingProducts: [],
  financingProductUrl: null,
  financeCheckStatus: null,
});

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.financingProducts = products;
  },
  SET_FINANCE_CHECK_STATUS(state, financeCheckStatus) {
    state.financeCheckStatus = financeCheckStatus;
  },
  SET_PRODUCT_URL(state, productUrl) {
    state.financingProductUrl = productUrl;
  },
  CLEAR(state) {
    const { financingProducts, financingProductUrl } = initialState();
    state.financingProducts = financingProducts;
    state.financingProductUrl = financingProductUrl;
  },
};

export const actions = {
  getProducts({ commit, rootState }, params) {
    return axios.get('/v1/financing/product', {
      params: { ...params, branchId: rootState.auth.currentBranch.ID },
    }).then((products) => {
      commit('SET_PRODUCTS', products.data.data);
      commit('SET_FINANCE_CHECK_STATUS', products.data.financeCheckStatus);
    });
  },

  getProductUrl({ commit }, params) {
    return axios.post(`/v1/financing/product/${params.id}/traversal`, {
      term: params.queryTerm,
      amount: parseFloat(params.queryAmount),
    }).then((response) => {
      commit('SET_PRODUCT_URL', response.data.data.traversalUrl);
    });
  },

  clear({ commit }) {
    commit('CLEAR');
  },

  createContract() {
    return axios.post('/v1/financing/contract/', null, { responseType: 'arraybuffer' }).then((response) => {
      saveAs(new Blob([response.data], { type: 'application/pdf' }), 'Kooperationsvertrag-Finanzcheck.pdf');
    });
  },

  createAdv() {
    return axios.get('/v1/financing/contract/document/adv/', { responseType: 'arraybuffer' }).then((response) => {
      saveAs(new Blob([response.data], { type: 'application/pdf' }), 'ADV-Finanzcheck.pdf');
    });
  },

  uploadBusinessRegisterFile: createUploadAction('/v1/financing/contract/document/tradingRegister'),
  uploadPersonalId: createUploadAction('/v1/financing/contract/document/id'),
  uploadSignedContract: createUploadAction('/v1/financing/contract/document/signed'),
  uploadSignedAdv: createUploadAction('/v1/financing/contract/document/adv'),

  async updateContract({ dispatch, rootState, commit }, {
    businessRegisterFile, personalId, signedContract, signedAdv,
  }) {
    await Promise.all([
      dispatch('uploadBusinessRegisterFile', businessRegisterFile),
      dispatch('uploadPersonalId', personalId),
      dispatch('uploadSignedContract', signedContract),
      dispatch('uploadSignedAdv', signedAdv),
    ]);

    await axios.put('/v1/financing/contract', { branchId: rootState.auth.currentBranch.ID, status: 'pending' });
    commit('SET_FINANCE_CHECK_STATUS', 'pending');
  },
};

function createUploadAction(url) {
  return (_, file) => {
    const data = new FormData();

    data.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios.post(url, data, { headers });
  };
}


export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
