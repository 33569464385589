import axios from 'axios';
import moment from 'moment';
import { Defer } from '@/plugins/util';

const state = () => ({
  subscriptions: null,
  paymentMethods: null,
  billing: null,
  invoices: null,
  vinUsage: Infinity,
  settings: {
    sendInvoiceEmail: true,
    invoiceEmail: '',
  },
});

const getters = {
  mainPackages: () => [
    // Very old but still active
    'FM_BUSINESS_MONTH',

    // Old but still active
    'FM_GOLD_MONTH',
    'FM_GOLD_YEAR',
    'FM_SILVER_MONTH',
    'FM_SILVER_YEAR',

    // Current
    'AM_STARTER_MONTH',
    'AM_STARTER_YEAR',
    'AM_PREMIUM_MONTH',
    'AM_PREMIUM_YEAR',
    'AM_PROFESSIONAL_MONTH',
    'AM_PROFESSIONAL_YEAR',
  ],
  notSubscriptedToMainPackage: (state, getters) => state.subscriptions && state.subscriptions.every(sub => !getters.mainPackages.includes(sub.packageKey)),
};

const mutations = {
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  ADD_SUBSCRIPTION(state, subscription) {
    state.subscriptions.push(subscription);
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_BILLING(state, billing) {
    if (!state.billing) {
      state.billing = billing;
    } else {
      Object.assign(state.billing, billing);
    }
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
  SET_VIN_USAGE(state, vinUsage) {
    state.vinUsage = vinUsage;
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  RESET(state) {
    state.subscriptions = null;
    state.paymentMethods = null;
    state.billing = null;
    state.invoices = null;
    state.vinUsage = Infinity;
  },
};

let subscriptionsLoading = false;
let paymentMethodsLoading = false;
let billingLoading = false;
let invoicesLoading = false;
let vinUsageLoading = false;

const actions = {

  async getSubscriptions({ state, commit }, force) {
    // Skip data request if already loading
    if (subscriptionsLoading) return subscriptionsLoading.promise;

    // Skip data request if already loaded
    if (state.subscriptions && !force) return state.subscriptions;

    subscriptionsLoading = new Defer();
    const { data: res } = await axios.get('/v2/subscription');
    commit('SET_SUBSCRIPTIONS', res.list);
    subscriptionsLoading.resolve(res.list);
    subscriptionsLoading = false;

    return res.list;
  },

  async getSubscriptionsFull() {
    const { data: res } = await axios.get('/v2/subscription/full');
    return res.list;
  },

  async createSubscription({ commit }, subscription) {
    subscriptionsLoading = true;
    const { data: res } = await axios.post('/v2/subscription', subscription);
    commit('ADD_SUBSCRIPTION', res.data);
    subscriptionsLoading = false;
  },

  async getPaymentMethods({ state, commit }, force) {
    // Skip data request if already loading
    if (paymentMethodsLoading) return;

    // Skip data request if already loaded
    if (state.paymentMethods && !force) return;

    paymentMethodsLoading = true;
    const { data: res } = await axios.get('/v2/subscription/payment-methods');
    commit('SET_PAYMENT_METHODS', res.list);
    paymentMethodsLoading = false;
  },

  async createSetupIntent() {
    const { data } = await axios.get('/v2/subscription/billing/setup');
    return data.setupIntent;
  },

  async saveSetupIntent(_, { paymentMethod, setupIntent }) {
    await axios.post('/v2/subscription/billing/setup', { paymentMethod, setupIntent });
  },

  async changeDefaultPaymentMethod(_, { paymentMethod }) {
    return axios.put('/v2/subscription/billing/payment-method', {
      paymentMethod,
    });
  },

  async removePaymentMethod(_, { paymentMethod }) {
    return axios.delete(`/v2/subscription/billing/payment-method/${paymentMethod}`);
  },

  async changeSubscriptionPaymentMethod(_, { subscriptionId, paymentMethod }) {
    return axios.post(`/v2/subscription/${subscriptionId}/payment-method`, {
      paymentMethod,
    });
  },

  async getBilling({ state, commit }, force) {
    // Skip data request if already loading
    if (billingLoading) return;

    // Skip data request if already loaded
    if (state.billing && !force) return;

    billingLoading = true;
    const { data: res } = await axios.get('/v2/subscription/billing');
    commit('SET_BILLING', res.data);
    billingLoading = false;
  },

  async setBilling({ commit }, data) {
    await axios.post('/v2/subscription/billing', data);
    commit('SET_BILLING', data);
  },

  async getInvoices({ state, commit }, force) {
    // Skip data request if already loading
    if (invoicesLoading) return;

    // Skip data request if already loaded
    if (state.invoices && !force) return;

    invoicesLoading = true;
    const { data: res } = await axios.get('/v2/subscription/invoices');
    commit('SET_INVOICES', res.list.filter(invoice => invoice.total > 0));
    invoicesLoading = false;
  },

  async getUsage({ state, commit }, force) {
    // Skip data request if already loading
    if (vinUsageLoading) return;

    // Skip data request if already loaded
    if (state.vinUsage !== Infinity && !force) return;

    const params = {
      filter: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().startOf('month').add(1, 'months').format('YYYY-MM-DD'),
      },
    };

    vinUsageLoading = true;

    const { data } = await axios.get('/v2/subscription/usage', { params });
    const { vinUsage } = data.data;

    commit('SET_VIN_USAGE', vinUsage);
    vinUsageLoading = false;
  },

  async pay(_, invoice) {
    await axios.get(`/v2/subscription/pay/${invoice}`);
  },

  async cancel(_, { subscriptionId }) {
    const { data: res } = await axios.delete(`/v2/subscription/${subscriptionId}`);
    return res.data;
  },

  async getSettings({ commit }) {
    const { data } = await axios.get('/v2/subscription/settings');
    commit('SET_SETTINGS', data.settings);
  },

  async saveSettings({ state }) {
    await axios.post('/v2/subscription/settings', state.settings);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
