import axios from 'axios';

const initialState = () => ({
  user: {},
});

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  UPDATE_USER(state, update) {
    Object.assign(state.user, update);
  },
};

export const actions = {

  savePassword({ rootState }, password) {
    return axios.post(`/v2/user/${rootState.auth.currentUser.ID}/changePassword`, password, { errorNotification: false });
  },

  async updateUser({ state, rootState, commit }, data) {
    const userId = state.user.ID || rootState.auth.currentUser.ID;
    const res = await axios.put(`/v2/user/${userId}`, data);
    if (state.user.ID) commit('UPDATE_USER', res.data.data);
  },

  async getUserById({ commit }, id) {
    const { data } = await axios.get(`/v2/user/${id}`);
    commit('SET_USER', data.data);
  },

  async getUserInfoById({ commit }, id) {
    const { data } = await axios.get(`/v2/user/${id}/info`);
    commit('SET_USER', data.user);
  },

  async resetPassword({ state }) {
    await axios.get(`/v2/user/${state.user.ID}/newPassword`);
  },

  async setInactive({ state, commit }) {
    await axios.put(`/v2/user/${state.user.ID}/inactive`);
    commit('UPDATE_USER', { isActive: false });
  },

  async setActive({ state, commit }) {
    await axios.put(`/v2/user/${state.user.ID}/active`);
    commit('UPDATE_USER', { isActive: true });
  },

  async deleteUser({ state }) {
    await axios.delete(`/v2/user/${state.user.ID}`);
  },

  async addUserRoles({ state, commit }, roles) {
    const { data } = await axios.post(`/v2/user/${state.user.ID}/role`, roles);
    commit('UPDATE_USER', { roles: data.roles });
  },

  async removeUserRole({ state, commit }, roleKey) {
    const { data } = await axios.delete(`/v2/user/${state.user.ID}/role/${roleKey}`);
    commit('UPDATE_USER', { roles: data.roles });
  },
};

export default {
  state: initialState(),
  mutations,
  namespaced: true,
  actions,
};
