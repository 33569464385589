import Vue from 'vue';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import { formatNumberBeautiful } from './util';

Vue.directive('empty', {
  bind(el, { value }) {
    el.dataset.empty = value;
    if (el.innerHTML.trim() === '') el.innerHTML = '';
  },
});

Vue.directive('mask', mask);

Vue.directive('hover', {
  bind(el, binding) {
    if (!binding.value) {
      return;
    } else if (_.isString(binding.value)) {
      binding.value = binding.value.split(' ');
    } else if (_.isObject(binding.value)) {
      if (!binding.value.add && !binding.value.remove) return;
      if (_.isString(binding.value.add)) {
        binding.value.add = binding.value.add.split(' ');
      }
      if (_.isString(binding.value.remove)) {
        binding.value.remove = binding.value.remove.split(' ');
      }
    }

    el.addEventListener('mouseover', () => {
      if (_.isArray(binding.value)) {
        el.classList.add(...binding.value);
        return;
      }

      if (binding.value.add) {
        el.classList.add(...binding.value.add);
      }
      if (binding.value.remove) {
        el.classList.remove(...binding.value.remove);
      }
    });

    el.addEventListener('mouseout', () => {
      if (_.isArray(binding.value)) {
        el.classList.remove(...binding.value);
        return;
      }

      if (binding.value.add) {
        el.classList.remove(...binding.value.add);
      }
      if (binding.value.remove) {
        el.classList.add(...binding.value.remove);
      }
    });
  },
});

Vue.directive('smallCaps', {
  update(el, { value }) {
    const text = value && value.trim() || '';
    const words = text.split(' ');

    let innerHTML = '';
    words.forEach((word) => {
      if (!word.length) return;

      const formattedWord = `<span style="font-size: 110%;">${word[0]}</span>${word.slice(1)}`;
      innerHTML += `${formattedWord} `;
    });

    el.innerHTML = innerHTML;
  },
});

Vue.directive('number', {
  bind(el, { value: options }) {
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      const els = el.getElementsByTagName('input');
      if (els.length !== 1) {
        throw new Error(`v-number directive requires 1 input, found ${els.length}`);
      } else {
        [el] = els;
      }
    }

    function dispatchInputEvent() {
      const newEvent = new InputEvent('input', { bubbles: true, cancelable: true });
      newEvent.creator = 'vMoney';
      el.dispatchEvent(newEvent);
    }

    el.addEventListener('input', function(event) {
      if (
        event.creator === 'vMoney'
        || event.data == null
        || !el.value
        || event.data === '-' && el.value === '-'
      ) return;

      event.stopPropagation();
      event.stopImmediatePropagation();

      // Keep cursor at same position as before formatting
      let cursor = el.selectionEnd;

      const isNegativeZero = el.value === '-0' || el.value === `-0,,${''.padEnd(options.decimalPlaces, '0')}` || el.value === `-0,0${''.padEnd(options.decimalPlaces, '0')}` || el.value === `-0,${''.padEnd(options.decimalPlaces, '0')}`;
      el.value = `${isNegativeZero ? '-' : ''}${formatNumberBeautiful(el.value, options)}`;

      // Find new cursor position
      for (let i = cursor - 1; i < el.value.length; i++) {
        if (el.value.charAt(i) === event.data) {
          cursor = i + 1;
          break;
        }
      }

      // Set correct cursor position
      if (el === document.activeElement) {
        el.setSelectionRange(cursor, cursor);
        setTimeout(function () {
          el.setSelectionRange(cursor, cursor);
        }, 0);
      }

      dispatchInputEvent();
    });

    el.addEventListener('blur', function() {
      const newVal = formatNumberBeautiful(el.value, options);
      if (el.value && newVal !== el.value) {
        el.value = newVal;
        dispatchInputEvent();
      }
    });
  },
});
