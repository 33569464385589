<template>
  <q-uploader ref="uploader" v-bind="$attrs" @add="acceptFiles($event)" />
</template>

<script>

export default {
  name: 'AUploader',
  components: {},
  props: ['extensions'],
  methods: {
    acceptFiles(files) {
      if (this.extensions) {
        const extensions = this.extensions.split(',').map(extension => extension.trim());
        const filesArray = Array.from(files);
        const validFiles = filesArray.filter(file => extensions.includes(file.name.match(/\.[a-zA-Z0-9]+$/)[0]));
        if (validFiles.length !== files.length) {
          const invalidFile = filesArray.find(file => !extensions.includes(file.name.match(/\.[a-zA-Z0-9]+$/)[0]));
          this.$notifyUserInteraction.error(`Ungültiges Dateiformat: ${invalidFile.name}`);
        }

        if (validFiles.length !== 0) {
          this.$emit('add', validFiles);
        } else {
          this.$refs.uploader.reset();
        }
      } else {
        this.$emit('add', files);
      }
    },
    upload() {
      this.$refs.uploader.upload();
    },
  },
};
</script>
