import axios from 'axios';
import qs from 'qs';
import store from '@/store';
import router from '@/router';

axios.defaults.baseURL = import.meta.env.VUE_APP_API_URL;
axios.defaults.headers.common['X-Authorization'] = import.meta.env.VUE_APP_XAUTH;
axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'indices' });

let authTokenRequest;

function resetAuthTokenRequest() {
  authTokenRequest = null;
}
// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = store.dispatch('auth/getNewToken');
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

export default {
  install: (Vue) => {
    Vue.prototype.$axios = axios;
    Vue.prototype.$axios.interceptors.request.use((request) => {
      // Save url explicit because axios change url value
      request.requestURL = request.url;
      store.commit('connection/ADD_REQUEST', request.requestURL);

      if (request.loadingBar === false) {
        store.commit('connection/REMOVE_REQUEST', request.requestURL);
      }

      return request;
    });
    Vue.prototype.$axios.interceptors.response.use((response) => {
      store.commit('connection/SET_STATUS', 'online');
      store.commit('connection/REMOVE_REQUEST', response.config.requestURL);

      if (response.config.method === 'get') return response;

      let body;

      try {
        body = JSON.parse(response.config.data || null);
      } catch (_) {
        body = null;
      }

      const data = {
        method: response.config.method,
        url: response.config.url.replace(response.config.baseURL, '/'),
        req: {
          data: body,
        },
        res: {
          status: response.status,
          data: response.data,
        },
      };

      store.dispatch('session/track', {
        action: 'API',
        data,
      });
      return response;
    }, (error) => {
      store.commit('connection/REMOVE_REQUEST', error.config.requestURL);

      if (error.code === 'ERR_CANCELED') {
        return Promise.reject(error);
      }

      // axios sadly throws only a generic "Network error" Error when request
      // is disrupted by network problems like connection lost. No error code
      // or error data is permitted. So check for existing error with undefined
      // error.response to get the network errors
      if (error && error.response === undefined) {
        if (!error.config.noNetwork) {
          store.commit('connection/SET_STATUS', 'hold');
          error.config.noNetwork = {
            retries: 15,
            lastRetry: null,
          };
        }
        if (error.config.noNetwork.retries) {
          const wait = error.config.noNetwork.lastRetry ? 1000 : 0;
          error.config.noNetwork.retries -= 1;
          error.config.noNetwork.lastRetry = true;
          return new Promise(res => setTimeout(res, wait))
            .then(() => axios(error.config));
        }
        store.commit('connection/SET_STATUS', 'offline');
        return new Promise(res => setTimeout(res, 5000))
          .then(() => axios(error.config));
      }

      store.commit('connection/SET_STATUS', 'online');

      if (error.response.data.error === 'accessExpiredError') {
        return router.push('/payment');
      }

      if (error.response.data.error === 'userInactiveError') {
        // return router.push('/notactive');
      }

      if (
        error.response.status === 401
        && error.response.data === 'Unauthorized'
        && (error.config.retried === undefined || error.config.retried === false)
      ) {
        return getAuthToken().then((accessToken) => {
          error.config.retried = true;
          error.config.headers.Authorization = `JWT ${accessToken}`;
          return axios(error.config);
        }).then((res) => {
          store.dispatch('auth/reloadCurrentDealer');
          return res;
        });
      }

      return Promise.reject(error);
    });
  },
};
