import axios from 'axios';

const state = () => ({
  list: [],
});

const mutations = {
  RESET(s) {
    Object.assign(s, state());
  },
  SET(state, list) {
    state.list = list;
  },
  ADD(state, key) {
    state.list.push(key);
  },
  REMOVE(state, uuid) {
    const index = state.list.findIndex(k => k.uuid === uuid);
    state.list.splice(index, 1);
  },
};

const actions = {
  reset({ commit }) {
    commit('RESET');
  },
  async create({ commit }, { uuid, name, registration }) {
    const { data } = await axios.post(`v2/security/passkey/${uuid}`, { name, registration });
    commit('ADD', data.key);
  },
  async get({ commit }) {
    const { data } = await axios.get('v2/security/passkeys');
    commit('SET', data.list);
  },
  async remove({ commit }, payload) {
    await axios.post(`v2/security/passkey/remove`, payload);
    commit('REMOVE', payload.uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
