export const state = () => ({
  planType: 'year',
  packagePrices: {
    silver: {
      month: 45.00,
      year: 37.5, // price per month for yearly plan
    },
    gold: {
      setUpFee: 199,
      month: 119,
      year: 89, // price per month for yearly plan
    },
  },
  selectedPackage: 'gold',
});

export const mutations = {
  SET_PLAN_TYPE(state, newValue) {
    state.planType = newValue;
  },
  SET_SELECTED_PACKAGE(state, newValue) {
    state.selectedPackage = newValue;
  },
};

export const actions = {
  updateSelectedPackage({ commit }, selectedPackage) {
    commit('SET_SELECTED_PACKAGE', selectedPackage);
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
