import axios from 'axios';
import moment from 'moment';

export const initialState = () => ({
  dealerDetail: {},
  dealerOwner: {},
  dealerBranches: [],
  subscriptions: [],
  invoicesLoading: true,
  invoices: [],
  sessions: [],
  vinCountLoading: true,
  vinCount: 0,
});

export const mutations = {

  SET_DEALER_DETAIL(state, newValue) {
    state.dealerDetail = newValue;
  },

  UPDATE_DEALER_DETAIL(state, update) {
    Object.assign(state.dealerDetail, update);
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  SET_DEALER_BRANCHES(state, branches) {
    state.dealerBranches = branches;
  },

  SET_DEALER_OWNER(state, owner) {
    state.dealerOwner = owner;
  },

  UPDATE_DEALER_OWNER(state, update) {
    Object.assign(state.dealerOwner, update);
  },

  UPDATE_BRANCH(state, [branchId, update]) {
    const branch = state.dealerBranches.find(b => b.ID === branchId);
    Object.assign(branch, update);
  },

  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
  SET_INVOICES_LOADING(state, invoicesLoading) {
    state.invoicesLoading = invoicesLoading;
  },
  SET_VINCOUNT(state, count) {
    state.vinCount = count;
  },
  SET_VINCOUNT_LOADING(state, loading) {
    state.vinCountLoading = loading;
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },
};

export const getters = {
  paidCustomerSince(state) {
    const subs = state.subscriptions
      .map(sub => (sub.active ? sub.created : null))
      .filter(Boolean);

    if (!subs.length) return 'kein zahlender Kunde';

    subs.sort((a, b) => a - b);
    return `${moment(subs[0] * 1000).format('DD.MM.YYYY HH:mm')} Uhr`;
  },
  sessions(state) {
    return state.sessions.map(session => ({
      ...session,
      duration: moment(session.sessionEnd).diff(session.sessionStart),
    }));
  },
};

export const actions = {
  async getDealerById({ commit }, dealerId) {
    const { data } = await axios.get(`/v2/dealer/${dealerId}/full`);
    commit('SET_DEALER_DETAIL', data.data);
  },

  async updateDealer({ commit, state }, update) {
    await axios.put(`/v2/dealer/${state.dealerDetail.ID}`, update);
    commit('UPDATE_DEALER_DETAIL', update);
  },

  async deleteDealer({ commit }, dealerId) {
    await axios.delete(`/v2/dealer/${dealerId}`);
    commit('RESET');
  },

  async getDealerBranches({ commit }, dealerId) {
    const { data } = await axios.get(`/v2/dealer/${dealerId}/branches`);
    commit('SET_DEALER_BRANCHES', data.data);
  },

  async getDealerOwner({ commit }, dealerId) {
    const { data } = await axios.get(`/v2/dealer/${dealerId}/owner`);
    commit('SET_DEALER_OWNER', data.data);
  },

  async updateOwner({ commit, state }, update) {
    await axios.put(`/v2/user/${state.dealerOwner.ID}`, update);
    commit('UPDATE_DEALER_OWNER', update);
  },

  async updateBranch({ commit }, [branchId, update]) {
    const res = await axios.put(`/v2/branch/${branchId}`, update);
    commit('UPDATE_BRANCH', [branchId, res.data.data]);
    return res.data.data;
  },

  async cleanVehicles({ commit }, dealerId) {
    await axios.delete(`/v2/dealer/${dealerId}/vehicles`);
    commit('UPDATE_DEALER_DETAIL', {
      vehicleCount: 0,
    });
  },

  async cleanAssets({ commit }, dealerId) {
    await axios.delete(`/v2/dealer/${dealerId}/clean`);
    commit('UPDATE_DEALER_DETAIL', {
      isCleaned: true,
      vehicleCount: 0,
    });
  },

  async setActive({ commit }, dealerId) {
    await axios.put(`/v2/dealer/${dealerId}/active`);
    commit('UPDATE_DEALER_DETAIL', {
      isActive: true,
    });
  },

  async setInactive({ commit }, dealerId) {
    await axios.put(`/v2/dealer/${dealerId}/inactive`);
    commit('UPDATE_DEALER_DETAIL', {
      isActive: false,
    });
  },

  async setBranchActive({ commit }, branchId) {
    await axios.put(`/v2/branch/${branchId}/active`);
    commit('UPDATE_BRANCH', [branchId, { isActive: 1 }]);
  },

  async updateTrialPeriodEnd({ commit }, [dealerId, update]) {
    await axios.put(`/v2/dealer/${dealerId}/trialPeriodEnd`, update);
    commit('UPDATE_DEALER_DETAIL', update);
  },

  async getStripeInvoices({ commit }, dealerId) {
    commit('SET_INVOICES_LOADING', true);
    const { data } = await axios.get(`/v2/subscription/${dealerId}/invoices`);
    commit('SET_INVOICES', data.data);
    commit('SET_INVOICES_LOADING', false);
    return data.data;
  },
  async getVinCount({ commit }, dealerId) {
    commit('SET_VINCOUNT_LOADING', true);
    const { data } = await axios.get(`/v2/subscription/${dealerId}/vincount`);
    commit('SET_VINCOUNT', data.data);
    commit('SET_VINCOUNT_LOADING', false);
    return data.data;
  },
  async getSessions({ commit }, dealerId) {
    const { data } = await axios.get(`/v2/session/dealer/${dealerId}/sessions`);
    commit('SET_SESSIONS', data.data);
    return data.data;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
