import axios from 'axios';

const initialState = () => ({
  categories: [],
  countsByCategory: {
    templates: 0,
    documents: 0,
  },
});

export const mutations = {
  SET_DOCUMENT_TEMPLATE_CATEGORIES(state, categories) {
    state.categories = categories;
  },

  SET_COUNT_BY_CATEGORY(state, { type, count }) {
    state.countsByCategory[type] = count;
  },
};

let fetchPromise = null;

export const actions = {
  async getDocumentCategories({ commit, state }, { forceReload = false } = {}) {
    if (!forceReload && fetchPromise) {
      return fetchPromise;
    }
    if (!forceReload && state.categories.length > 0) {
      return state.categories;
    }
    fetchPromise = axios.get('/v2/documentCategory')
      .then((res) => {
        fetchPromise = null;
        commit('SET_DOCUMENT_TEMPLATE_CATEGORIES', res.data.data);
        return res.data.data;
      });

    return fetchPromise;
  },

  createDocumentCategory({ dispatch }, category) {
    return axios.post('/v2/documentCategory/', { ...category, scope: 'user' }).then((createdCategory) => {
      dispatch('getDocumentCategories', { forceReload: true });
      dispatch('documentTable/notifyTables', {}, { root: true });
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
      return createdCategory.data.data;
    });
  },

  updateDocumentCategory({ dispatch }, category) {
    return axios.put(`/v2/documentCategory/${category.ID}`, { ...category, scope: 'user' }).then((updatedCategory) => {
      dispatch('getDocumentCategories', { forceReload: true });
      dispatch('documentTable/notifyTables', {}, { root: true });
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
      return updatedCategory.data.data;
    });
  },

  deleteDocumentCategory({ dispatch }, category) {
    return axios.delete(`/v2/documentCategory/${category.ID}`)
      .then(() => {
        dispatch('getDocumentCategories', { forceReload: true });
        dispatch('documentTable/notifyTables', {}, { root: true });
        dispatch('documentTemplateTable/notifyTables', {}, { root: true });
      });
  },

  getCountsByCategory({ dispatch }, categoryId) {
    return Promise.all([
      dispatch('getDocumentsCount', categoryId),
      dispatch('getTemplatesCount', categoryId),
    ]);
  },

  getDocumentsCount({ commit }, categoryId) {
    return axios.get('/v2/document/count', {
      params: { filter: { categoryId } },
    })
      .then(count => commit('SET_COUNT_BY_CATEGORY', { type: 'documents', count: count.data.data }));
  },

  getTemplatesCount({ commit }, categoryId) {
    return axios.get('/v2/documentTemplate/count', {
      params: { filter: { categoryId } },
    })
      .then(count => commit('SET_COUNT_BY_CATEGORY', { type: 'templates', count: count.data.data }));
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
