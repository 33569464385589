import axios from 'axios';

export const state = () => ({
  queryParams: {
    limit: 25,
    offset: 0,
    order: {
      name: null,
      dir: null,
    },
  },
  search: '',
  userList: [],
  userListCount: 0,
  serverPagination: {
    page: 1,
    rowsPerPage: 25,
    rowsNumber: null,
    sortBy: null,
    descending: false,
  },
  dataCounts: {
    filteredCount: 0,
    totalCount: 0,
  },
  loading: true,
  columns: [
    {
      name: 'icon',
      label: '',
      sortable: false,
      align: 'left',
    },
    {
      name: 'email',
      label: 'E-Mail',
      sortable: true,
      align: 'left',
    },
    {
      name: 'lastName',
      label: 'Name',
      sortable: true,
      align: 'left',
    },
    {
      name: 'firstName',
      label: 'Vorname',
      sortable: true,
      align: 'left',
    },
    {
      name: 'active',
      label: 'Status',
      sortable: false,
      align: 'left',
    },
  ],
});

export const mutations = {
  SET_USER_LIST(state, newValue) {
    state.userList = newValue;
  },
  SET_USER_LIST_PAGINATION_ROWS_NUMBER(state, newValue) {
    state.serverPagination.rowsNumber = newValue;
  },
  SET_PAGINATION(state, { filteredCount, totalCount }) {
    state.dataCounts.filteredCount = filteredCount;
    state.dataCounts.totalCount = totalCount;
  },
  SET_USER_LIST_PAGINATION(state, pagination) {
    state.queryParams.limit = pagination.rowsPerPage;
    state.queryParams.offset = pagination.rowsPerPage * (pagination.page - 1);
    state.queryParams.order.name = pagination.sortBy || 'ID';
    state.queryParams.order.dir = pagination.sortBy && pagination.descending ? 'DESC' : 'ASC';
    state.serverPagination = pagination;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },

  SET_SEARCH(state, newValue) {
    state.search = newValue;
  },

  SET_QUERY_PARAMS(state, queryParams) {
    state.queryParams = queryParams;
  },

  SET_USER_LIST_COUNT(state, count) {
    state.userListCount = count;
  },
};

export const actions = {
  getUserList({ commit, state }) {
    commit('SET_LOADING', true);
    return axios.get('v2/user', {
      params: {
        ...state.queryParams,
        search: state.search,
      },
    }).then((res) => {
      commit('SET_PAGINATION', { filteredCount: res.data.filteredCount, totalCount: res.data.totalCount });
      commit('SET_USER_LIST_PAGINATION_ROWS_NUMBER', res.data.totalCount);
      commit('SET_USER_LIST', res.data.data);
      commit('SET_LOADING', false);
    });
  },

  async getUserListCount({ commit }) {
    const { data: { data: count } } = await axios.get('v2/user/count');
    commit('SET_USER_LIST_COUNT', count);
  },

  setUserListPagination({ commit }, pagination) {
    commit('SET_USER_LIST_PAGINATION', pagination);
  },

  setUserSearch({ commit }, search) {
    commit('SET_SEARCH', search);
  },

  async addNewUser(_, data) {
    const result = await axios.post('v2/user', data);
    return result.data.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
