<template>
  <q-layout-drawer
    :value="showDrawer"
    :width="width"
    :content-class="[
      darkMode ? 'dark-bg' : 'bg-white',
      'no-shadow',
      'text-weight-medium',
      'q-pr-sm',
    ]"
    side="left"
    :id="darkMode ? 'dark-drawer' : null"
  >
    <q-resize-observable @resize="onResize" />
    <drawer-list-items @resize="onResize"/>
    <div slot="mini">
      <drawer-list-items @resize="onResize"/>
    </div>
  </q-layout-drawer>
</template>

<script>
import DrawerListItems from './DrawerListItems';
import PerfectScrollbar from 'perfect-scrollbar';


export default {
  name: 'DrawerWrapper',
  components: { DrawerListItems },

  data() {
    return {
      width: 240,
      ps: null,
    };
  },
  methods: {
    onResize() {
      this.ps.update();
    },
  },
  computed: {
    darkMode() { return !!this.$store.state.userSetting['layout.darkMode']; },
    showDrawer() { return !!this.$store.state.userSetting['layout.showMiniDrawer']; },
  },
  mounted() {
    this.ps = new PerfectScrollbar('.q-layout-drawer', { wheelSpeed: 0.5 });
  },
};
</script>
<style src="perfect-scrollbar/css/perfect-scrollbar.css"/>
<style scoped lang="stylus">
@import theme

#dark-drawer >>> ::-webkit-scrollbar {
  display: none;
}

#dark-drawer:hover >>> ::-webkit-scrollbar {
  display: block;
}

#dark-drawer >>> ::-webkit-scrollbar {
  width: 10px;
  background-color: $dark-bg;
}

/* Track */
#dark-drawer >>> ::-webkit-scrollbar-track {
  background: $dark-bg;
}

/* Handle */
#dark-drawer >>> ::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
#dark-drawer >>> ::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>
