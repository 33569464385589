import { appVars } from './appConfig';

export const linePlotOptions = {
  colors: [`${appVars.primary}`],

  chart: {
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    type: 'datetime',
    categories: [],
    tooltip: {
      enabled: false,
    },
  },
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};

export const areaPlotOptions = {
  colors: [`${appVars.primary}`, '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],

  chart: {
    type: 'area',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    type: 'category',
    categories: [],
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    show: false,
  },
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};

export const barPlotOptions = {
  colors: ['#f5f200', '#ff6610', '#1877f2', `${appVars.primary}`],
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
  },
  tooltip: {
    x: {
      formatter: (value) => {
        switch (value) {
          case 'Mo': return 'Montag';
          case 'Di': return 'Dienstag';
          case 'Mi': return 'Mittwoch';
          case 'Do': return 'Donnerstag';
          case 'Fr': return 'Freitag';
          case 'Sa': return 'Samstag';
          case 'So': return 'Sonntag';
          default: return undefined;
        }
      },
    },
    y: {
      formatter: value => value,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: 'top',
  },
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};

export const piePlotOptions = {
  chart: {
    toolbar: {
      show: false,
      tools: {
        download: true,
      },
    },
  },
  colors: ['#f5f200', '#ff6610', '#1877f2', `${appVars.primary}`],
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'top',
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200,
      },
      legend: {
        position: 'bottom',
      },
    },
  }],
  labels: ['AutoScout24', 'mobile.de', 'Sonstige'],
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};

export const gaugeChartOptions = {
  chart: {
    offsetX: -40,
    sparkline: {
      enabled: true,
    },
  },
  colors: [`${appVars.primary}`],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,

      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -20,
          fontSize: '24px',
          fontWeight: 600,
        },
      },
      hollow: {
        size: '60%',
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  stroke: {
    lineCap: 'round',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};

export const baseChartOption = {
  colors: [`${appVars.primary}`, '#ff6610', '#1877f2', '#f5f200'],
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: [],
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: 'top',
  },
  plotOptions: {
    bar: {
      columnWidth: '35%',
    },
  },
  noData: {
    text: 'Keine Daten verfügbar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
};
