<template>
  <create-acc-animation/>
</template>

<script>
import CreateAccAnimation from '@/components/dashboard/CreateAccAnimation.vue';

export default {
  name: 'AccountInit',
  components: {
    CreateAccAnimation,
  },

  created() {
    this.$store.dispatch('dealer/createDummyData');
  },
};
</script>
