import axios from 'axios';

const initialState = () => ({
  vehicleList: {
    data: [],
  },
  vehiclePriceRange: {},
  vehicleListFilterOptions: {
    makeId: [],
    modelId: [],
    vehicleState: ['sold', 'inventory', 'incoming', 'customer'],
    firstRegistration: {
      startDate: null,
      endDate: null,
    },
    price: {
      min: null,
      max: null,
    },
  },
  vehicleListFilterOptionsResetted: true,
  vehicleListPagination: {
    page: 1,
    rowsNumber: 20,
    sortBy: null,
    descending: false,
  },
  vehicleListSearch: '',
  vehicleListFilterAndPagination: {},
});

export const mutations = {
  SET_VEHICLES(state, newValue) {
    state.vehicleList = newValue;
    state.vehicleListPagination.rowsNumber = newValue.totalCount;
  },
  SET_PRICE_RANGE(state, newValue) {
    state.vehiclePriceRange = newValue;
    state.vehicleListFilterOptions.price = newValue;
  },
  RESET_VEHICLE_LIST(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
  },
  SET_VEHICLE_LIST_FILTER_OPTIONS(state, filterOptions) {
    state.vehicleListFilterOptions = filterOptions;
    state.vehicleListFilterOptionsResetted = false;
  },
  RESET_VEHICLE_LIST_FILTER_OPTIONS(state) {
    state.vehicleListFilterOptions = initialState().vehicleListFilterOptions;
    state.vehicleListFilterOptionsResetted = true;
  },
  SET_VEHICLE_LIST_PAGINATION(state, pagination) {
    state.vehicleListPagination = pagination;
  },
  SET_VEHICLE_LIST_SEARCH(state, search) {
    state.vehicleListSearch = search;
  },
  SET_VEHICLE_LIST_FILTER_AND_PAGINATION(state, filter) {
    state.vehicleListFilterAndPagination = filter;
  },
};

function getAllVehicles(filter) {
  let urlParams = { limit: 25 };
  if (filter) {
    urlParams = Object.assign(urlParams, filter);
  }
  return axios.get('v2/vehicle', {
    params: urlParams,
  });
}

export const actions = {

  getAllVehiclesPlain(store, filter) {
    return getAllVehicles(filter);
  },

  getAllVehicles({ commit, state } = {}, filter) {
    if (filter !== null) {
      commit('SET_VEHICLE_LIST_FILTER_AND_PAGINATION', filter);
    }
    if (filter === null) {
      filter = state.vehicleListFilterAndPagination;
    }
    return getAllVehicles(filter)
      .then((res) => {
        commit('SET_VEHICLES', res.data);
      });
  },

  getVehiclePriceRange({ commit }) {
    function filter(dir) {
      return {
        limit: 1,
        order: {
          name: 'price',
          dir,
        },
      };
    }

    Promise.all([getAllVehicles(filter('DESC')), getAllVehicles(filter('ASC'))]).then(([descVehicles, ascVehicles]) => {
      const vehicleMaxPrice = (descVehicles.data.data[0] || {}).price || 0; // there can be vehicles with a price of `null`
      const vehicleMinPrice = (ascVehicles.data.data[0] || {}).price || 0;

      commit('SET_PRICE_RANGE', {
        max: vehicleMaxPrice,
        min: vehicleMinPrice,
      });
    });
  },

  setVehicleListFilterOptions({ commit }, filterOptions) {
    commit('SET_VEHICLE_LIST_FILTER_OPTIONS', filterOptions);
  },

  resetVehicleListFilterOptions({ commit }) {
    commit('RESET_VEHICLE_LIST_FILTER_OPTIONS');
  },

  setVehicleListPagination({ commit }, pagination) {
    commit('SET_VEHICLE_LIST_PAGINATION', pagination);
  },

  setVehicleListSearch({ commit }, search) {
    commit('SET_VEHICLE_LIST_SEARCH', search);
  },

  resetVehicleList({ commit }) {
    commit('RESET_VEHICLE_LIST');
  },

};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
