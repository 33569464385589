<template>
  <q-layout-footer>
    <q-toolbar color="warning" @click.native="$router.replace(`/admin/dealer/${currentDealer.ID}`)">
      <q-toolbar-title class="text-center cursor-pointer">
        {{ currentBranch.name || dealerOwner.email || `Händler: #${currentDealer.ID}` }}
        <span slot="subtitle" v-if="currentDealer.name !== currentBranch.name">
          {{ currentDealer.name }}
        </span>
      </q-toolbar-title>
      <q-btn flat round dense icon="clear" @click.stop="reset" />
    </q-toolbar>
  </q-layout-footer>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapState('dealerDetail', ['dealerOwner']),
    ...mapState('auth', ['currentDealer', 'currentBranch', 'currentUser']),
  },
  methods: {
    ...mapActions('auth', ['reloadCurrentBranch', 'reloadCurrentDealer']),
    reset() {
      // Reset store modules which will not automatically update
      this.$store.commit('subscription/RESET');

      const dealerId = this.currentDealer.ID;
      this.reloadCurrentBranch(this.currentUser.branchId);
      this.reloadCurrentDealer(this.currentUser.dealerId);

      if (+this.$route.params.dealerId === dealerId) return;
      this.$router.push(`/admin/dealer/${dealerId}`);
    },
  },
};
</script>
