const de = {
  vehicle: 'Fahrzeug',

  equipment: 'Ausstattung',
  description: 'Inseratsbeschreibung',

  makeId: 'Marke',
  modelId: 'Modell',
  modelVersion: 'Modellvariante',
  vehicleTypeId: 'Fahrzeugart',
  trimLine: 'Ausstattungslinie',
  modelRange: 'Baureihe',
  hsn: 'HSN',
  tsn: 'TSN',
  vehicleIdentificationNumber: 'Fahrzeug-Identifikationsnummer (FIN)',
  internalId: 'Interne Nummer',
  licensePlate: 'Kennzeichen',

  doorCount: 'Anzahl Türen',
  slidingDoor: 'Schiebetür',
  seatCount: 'Anzahl Sitzplätze',
  colorId: 'Außenfarbe',
  manufacturerColorName: 'Herstellerfarbbezeichnung',
  isMetallicColor: 'Metallic',
  interiorType: 'Innenausstattung',
  interiorColorId: 'Farbe der Innenausstattung',
  climatisationType: 'Klimaanlage',
  airbagType: 'Airbags',

  tyreBrand: 'Marke der Reifen',
  wheelSize: 'Größe der Felgen',
  tyreTreadL: 'Profiltiefe VL',
  tyreTreadR: 'Profiltiefe VR',
  tyreTreadBL: 'Profiltiefe HL',
  tyreTreadBR: 'Profiltiefe HR',

  conditionType: 'Gebrauchsstatus',
  kilometers: 'Kilometerstand',
  firstRegistration: 'Erstzulassung',
  constructionDate: 'Produktionsdatum',
  previousOwnerCount: 'Fahrzeughalter',
  deliveryDate: 'Auslieferungsdatum',
  deliveryPeriod: 'Verfügbarkeit',
  countryId: 'Länderversion',
  nextInspection: 'Nächste Hauptuntersuchung (HU/AU)',
  lastService: 'Letzte Inspektion',
  hadAccidentDamage: 'Unfallfahrzeug',
  warrantyMonths: 'Garantie',
  lastCamBeltService: 'Letzter Zahnriemenwechsel',
  isInspectionNew: 'HU/AU neu',
  hasSparewheel: 'Ersatzrad',
  isDrivable: 'Fahrtauglich',
  isNonSmoker: 'Nichtraucherfahrzeug',
  hasFullServiceHistory: 'Scheckheftgepflegt',
  isDamaged: 'Beschädigtes Fahrzeug',

  driveType: 'Antriebsart',
  transmissionType: 'Getriebe',
  powerKw: 'Leistung (kW)',
  gears: 'Anzahl Gänge',
  cylinders: 'Anzahl Zylinder',
  cubicCapacity: 'Hubraum',
  weight: 'Leergewicht',

  fuelType: 'Kraftstoff',
  fuelDetailType: 'Energieträger/Kraftstoff',
  liquidCombined: 'Kraftstoffverbrauch kombiniert',
  co2: 'CO2 Emissionen kombiniert',
  liquidUrban: 'Kraftstoffverbrauch innerorts',
  energyEfficiency: 'Energieeffizienzklasse',
  liquidExtraUrban: 'Kraftstoffverbrauch außerorts',
  electricConsumption: 'Stromverbrauch',
  emissionClass: 'Schadstoffklasse',
  pollutionBadge: 'Feinstaubplakette',
  isEnvkvCompliant: 'Daten nach ENVKV',
  particulateFilter: 'Partikelfilter',
  isE10Enabled: 'E10 geeignet',
  isBioDieselSuitable: 'Biodiesel geeignet',
  isVegetableOilSuitable: 'Pflanzenöl geeignet',
  isPluginHybrid: 'Plugin-Hybrid',

  price: 'Inseratspreis Brutto',
  dealerPrice: 'Händlerpreis Brutto',
  taxRate: 'MwSt.',
  isPriceNegotiable: 'Verhandlungsbasis',
  priceExport: 'Preis Export',
  priceYard: 'Preis Platz',
  recommendedRetailPrice: 'UVP',
  priceOverpass: 'Überführung',
};

export default {
  de,
};
