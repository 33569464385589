import routes from './routes';
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Router = new VueRouter({
  routes,
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 300); // wait for route transition (Default.vue)
      });
    }
    // this should be the case if only query param updates occur
    if (to.path === from.path && to.hash === from.hash) {
      return {
        x: window.pageXOffset,
        y: window.pageYOffset,
      };
    }

    if (to.hash.startsWith('#ns_')) {
      return {
        x: window.pageXOffset,
        y: window.pageYOffset,
      };
    }

    // Tab nav -> no scroll
    if (
      to.matched.length === 3
      && from.matched.length === 3
      && to.matched[1].path === from.matched[1].path
    ) {
      return false;
    }

    return { x: 0, y: 0 };
  },
});

Router.beforeEach((to, from, next) => {
  store.dispatch('session/track', {
    action: 'ROUTE',
    data: {
      from: {
        path: from.fullPath,
        view: from.name,
        params: from.params,
      },
      to: {
        path: to.fullPath,
        view: to.name,
        params: to.params,
      },
    },
  });

  if (to.matched.some(record => record.meta.checkAuth)) {
    const authed = store.getters['auth/loggedIn'];
    if (!authed) {
      return next(`/login?next=${encodeURIComponent(to.fullPath)}`);
    }
  }

  if (to.matched.some(record => record.meta.isAdmin)) {
    const isAdmin = store.getters['auth/alSupport'];
    if (!isAdmin) {
      return next(false);
    }
  }

  if (to.matched.some(record => record.meta.isBranchOwner)) {
    const isBranchOwner = store.getters['auth/alBranchOwner'];
    if (!isBranchOwner) {
      return next(false);
    }
  }

  return next();
});

export default Router;
