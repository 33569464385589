<template>
  <div class="sticky">
    <q-alert
      class="cursor-pointer"
      color="warning"
      :message="currentBranch.name || dealerOwner.email || `Händler: #${currentDealer.ID}`"
      :detail="currentDealer.name !== currentBranch.name ? currentDealer.name : null"
      :actions="[{ icon: 'clear', handler: reset }]"
      @click.native="navigateToDealer"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapState('dealerDetail', ['dealerOwner']),
    ...mapState('auth', ['currentDealer', 'currentBranch', 'currentUser']),
  },
  methods: {
    ...mapActions('auth', ['reloadCurrentBranch', 'reloadCurrentDealer']),
    async reset() {
      // Reset store modules which will not automatically update
      this.$store.commit('subscription/RESET');

      const dealerId = this.currentDealer.ID;
      await this.reloadCurrentBranch(this.currentUser.branchId);
      await this.reloadCurrentDealer(this.currentUser.dealerId);

      if (+this.$route.params.dealerId === dealerId) return;
      this.$router.push(`/admin/dealer/${dealerId}`);
    },
    navigateToDealer() {
      if (+this.$route.params.dealerId === this.currentDealer.ID) return;
      this.$router.push(`/admin/dealer/${this.currentDealer.ID}`);
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 8px;
  z-index: 99;
}
</style>
