<template>
  <q-field
    :warning="!!internalWarning"
    :warning-label="internalWarning || ''"
    :error="!!internalError || error"
    :error-label="internalError || errorLabel"
  >
    <q-input
      class="col datetime-input"
      v-model="qValueText"
      v-bind="optionsText"
      v-mask="mask"
      @focus="focused = true"
      @blur="handleBlur"
    />
    <q-datetime
      class="datetime-selector"
      v-model="qValueDate"
      v-bind="optionsDate"
      no-parent-field
      ref="qDatetime"
      @blur="$emit('blur', $event)"
    />
  </q-field>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ADatetime',
  props: {
    value: {
      required: true,
    },
    setEndOf: {
      type: String,
      default: '',
    },
    useUtc: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorLabel: {
      type: String,
    },
  },
  data() {
    return {
      internalValue: '',
      focused: false,
    };
  },
  watch: {
    value(value) {
      if (value === 'Invalid date') return;
      this.internalValue = this.dateToText(value);
    },
  },
  mounted() {
    this.internalValue = this.dateToText(this.value);
  },
  computed: {
    format() {
      switch (this.$attrs && this.$attrs.type) {
        case 'datetime': return 'DD.MM.YYYY HH:mm';
        case 'time': return 'HH:mm';
        default: return 'DD.MM.YYYY';
      }
    },
    formatLocalized() {
      switch (this.$attrs && this.$attrs.type) {
        case 'datetime': return 'TT.MM.JJJJ HH:mm';
        case 'time': return 'HH:mm';
        default: return 'TT.MM.JJJJ';
      }
    },
    mask() {
      return this.format.replace(/\w/g, '#');
    },
    isValid() {
      if (!this.internalValue) return true;

      const value = moment(this.internalValue.trim(), this.format);
      return value.isValid()
        && this.internalValue.trim().length === this.format.length;
    },
    internalWarning() {
      if (!this.internalValue) return false;
      if (!this.isValid) return 'Kein gültiges Datum';

      return false;
    },
    internalError() {
      if (!this.internalValue) return false;
      if (!this.focused && !this.isValid) return 'Kein gültiges Datum';
      if (!this.isValid) return false;

      if (!this.$attrs.min && !this.$attrs.max) return false;

      const min = this.$attrs.min
        ? moment(this.$attrs.min).startOf('day')
        : null;

      const max = this.$attrs.max
        ? moment(this.$attrs.max)
        : null;

      const value = moment(this.internalValue.trim(), this.format);

      if (min && !value.isSameOrAfter(min)) return `Eingabe muss nach dem "${min.format(this.format)}" liegen!`;
      if (max && value.isAfter(max)) return `Eingabe muss vor dem "${max.format(this.format)}" liegen!`;

      return false;
    },
    qValueText: {
      get() {
        return this.internalValue;
      },
      set(input) {
        this.internalValue = input.replace(/[^\d.]/g, '');

        if (!input) {
          this.emitValue(null);
          return;
        }

        const value = moment(this.internalValue, this.format);

        if (value.isValid() && this.internalValue.length === this.format.length) {
          this.emitValue(value);
        }
      },
    },
    qValueDate: {
      get() {
        const value = moment(this.value);
        return value.isValid() ? this.value : '';
      },
      set(value) {
        this.emitValue(moment(value));
      },
    },
    optionsDate() {
      return {
        defaultView: 'day',
        format: this.format,
        ...this.$attrs,
        clearable: false,
        after: [{
          icon: 'calendar_today',
          handler: () => {
            this.$refs.qDatetime.show();
          },
        }],
      };
    },
    optionsText() {
      return {
        placeholder: this.formatLocalized,
        ...this.$attrs,
        type: 'text',
      };
    },
  },
  methods: {
    dateToText(date) {
      if (!date) return '';

      const value = moment(date);
      return value.isValid() ? value.format(this.format) : date;
    },
    handleBlur(event) {
      this.focused = false;
      if (!this.isValid) this.$emit('input', 'Invalid date'); // Emit an invalid date
      this.$emit('blur', event);
    },
    emitValue(value) {
      if (!value) return this.$emit('input', value);

      if (moment.isMoment(value)) {
        if (this.useUtc) value.utc(true);
        if (this.setEndOf) value.endOf(this.setEndOf);
        return this.$emit('input', value.toISOString());
      }

      return this.$emit('input', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .q-field-content {
  display: flex;
  flex-wrap: wrap;
}

>>> .q-field-bottom {
  width: 100%;
}

.datetime-selector {
  flex: 0;
  padding-left: 3px !important;
  padding-right: 7px !important;
  min-width: initial;

  .filter & {
    padding-bottom: 0;
  }

  >>> .q-if-inner {
    display: none;
  }

  >>> .q-if-control:last-child {
    display: none;
  }
}

.datetime-input,
.datetime-input:after {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.datetime-selector,
.datetime-selector:after {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.datetime-selector {
  border-left: none !important;
}
</style>
