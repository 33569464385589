<template>
    <q-layout view="hHh lpr lFf">
        <q-page-container>
            <q-page class="flex flex-center">
                <router-view />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
  name: 'LayoutMinimal',
};
</script>

<style>
</style>
