<template>
  <div>
    <div class="q-mt-md q-mb-sm text-primary q-title">Vervollständigen Sie ihr Profil</div>
    <div class="q-mt-md q-mb-sm q-caption text-faded">
      Bitte achten Sie auf korrekte Schreibweise, da Ihre Firmendaten auch
      zur Erstellung von Dokumenten und für die Inserate auf Autobörsen benötigt werden.
    </div>

    <div class="row items-center gutter-md dropzone-container q-mt-xs">
      <div class="col-6 text-center">
        <div v-if="logoPending">
          <q-spinner color="primary" size="3rem" />
        </div>
        <div v-else>
          <div v-if="currentBranch.logoRef" class="logo-preview">
            <a-galery
              class="q-mt-md"
              v-model="logoGallery"
              :animate="true"
              :show-navigation="false"
              :remove-able="true"
              @remove="deleteLogo"
              size="lg"
              @load="logoPending = false"
            />
          </div>
          <div v-else>
            <div class="dropzone-container">
              <vue-drop-zone
                id="vehicleImages"
                :options="dropzoneOptions"
                :includeStyling="false"
                :useCustomSlot="true"
                @vdropzone-files-added="uploadFiles($event)"
                @vdropzone-file-added="removeFileFromDropzone($event)"
                class="dropzone full-height flex justify-center"
                ref="dropzone"
                slot="lastNotDraggableElement"
              >
                <div class="column items-center">
                  <div class="col-auto">
                    <img :src="undrawUpload" class="upload-image q-mb-md" />
                  </div>
                  <div
                    class="col-auto q-caption text-weight-medium text-faded q-mb-lg"
                  >Ziehen Sie Ihr Logo hier rein oder klicken Sie zum Hochladen</div>
                </div>
              </vue-drop-zone>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="q-body-2">Logo hochladen</div>
        <div class="q-caption">(empfohlen)</div>
      </div>
    </div>
    <div class="row gutter-md q-mt-md">
      <div class="col-6 row gutter-sm">
        <div class="col-12">
          <div class="q-caption text-grey-5 text-weight-medium">Kontakt</div>
        </div>

        <div class="col-6">
          <q-field :error="$v.currentUser.title.$error">
            <q-select
              v-model="$v.currentUser.title.$model"
              float-label="Anrede*"
              :options="titleOptions"
              clearable
            ></q-select>
          </q-field>
        </div>

        <div class="col-6"/>

        <div class="col-6">
          <q-field :error="$v.currentUser.firstName.$error">
            <q-input
              class="no-shadow"
              type="text"
              float-label="Vorname*"
              v-model="$v.currentUser.firstName.$model"
            />
          </q-field>
        </div>
        <div class="col-6">
          <q-field :error="$v.currentUser.lastName.$error">
            <q-input
              class="no-shadow"
              type="text"
              float-label="Nachname*"
              v-model="$v.currentUser.lastName.$model"
            />
          </q-field>
        </div>
        <div class="col-6">
          <q-field :error="$v.currentBranch.phoneNumber.$error">
            <q-input
              class="no-shadow"
              type="text"
              float-label="Telefonnummer*"
              v-model="$v.currentBranch.phoneNumber.$model"
            />
          </q-field>
        </div>
      </div>

      <div class="col-6 row gutter-sm">
        <div class="col-12">
          <div class="q-caption text-faded text-grey-5 text-weight-medium">Anschrift</div>
        </div>

        <q-field :error="$v.currentBranch.name.$error" class="col-12">
          <q-input type="text" float-label="Firma*" v-model="$v.currentBranch.name.$model" />
        </q-field>

        <q-field :error="$v.currentBranch.streetAddress.$error" class="col-12">
          <q-input
            class="no-shadow"
            type="text"
            float-label="Adresse*"
            v-model="$v.currentBranch.streetAddress.$model"
          />
        </q-field>
        <div class="row gutter-sm">
          <div class="col-6">
            <q-field :error="$v.currentBranch.zipCode.$error">
              <q-input
                class="no-shadow"
                type="text"
                float-label="Postleitzahl*"
                v-model="$v.currentBranch.zipCode.$model"
              />
            </q-field>
          </div>
          <div class="col-6">
            <q-field :error="$v.currentBranch.city.$error">
              <q-input
                class="no-shadow"
                type="text"
                float-label="Stadt*"
                v-model="$v.currentBranch.city.$model"
              />
            </q-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AGalery from '@/components/base/AGallery';
import { required } from 'vuelidate/lib/validators';
import undrawUpload from '@/assets/undraw_upload.svg';

export default {
  name: 'firstSteps',
  components: {
    AGalery,
  },
  data() {
    return {
      titleOptions: [
        {
          value: 'Mr',
          label: 'Herr',
        },
        {
          value: 'Mrs',
          label: 'Frau',
        },
      ],
      dropzoneOptions: {
        url: 'dummy',
        maxFiles: 1,
        autoProcessQueue: false,
        uploadMultiple: false,
        paramName: 'images',
        createImageThumbnails: false,
      },
      logoPending: false,
      undrawUpload,
    };
  },
  validations: {
    currentUser: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      title: {
        required,
      },
    },
    currentBranch: {
      name: {
        required,
      },
      streetAddress: {
        required,
      },
      zipCode: {
        required,
      },
      city: {
        required,
      },
      phoneNumber: {
        required,
      },
    },
  },
  methods: {
    // eslint-disable-next-line
    async updateContact() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store.dispatch('dealer/updateDealer', {
          dealerId: this.currentDealer.ID,
          update: {
            name: this.currentBranch.name,
          },
        });

        await this.$store.dispatch('branch/updateBranch', {
          name: this.currentBranch.name,
          phoneNumber: this.currentBranch.phoneNumber,
          streetAddress: this.currentBranch.streetAddress,
          zipCode: this.currentBranch.zipCode,
          city: this.currentBranch.city,
        });

        await this.$store.dispatch('user/updateUser', {
          title: this.currentUser.title,
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
        });

        // Preemptively set billing address to speed up the payment process
        await this.$store.dispatch('subscription/setBilling', {
          company: this.currentBranch.name,
          streetAddress: this.currentBranch.streetAddress,
          zipCode: this.currentBranch.zipCode,
          city: this.currentBranch.city,
        });

        this.$store.dispatch('auth/reloadCurrentUser');
        this.$store.dispatch('auth/reloadCurrentBranch');

        return true;
      }
      return null;
    },
    removeFileFromDropzone(file) {
      this.$refs.dropzone.removeFile(file);
    },
    async uploadFiles(files) {
      this.$refs.dropzone.removeAllFiles();
      const filesArray = Array.from(files);
      this.logoPending = true;
      await this.$store.dispatch('branch/uploadLogo', {
        ID: this.currentBranch.ID,
        logo: filesArray[0],
      });
      this.logoPending = false;
    },
    async deleteLogo() {
      this.logoPending = true;
      await this.$store.dispatch('branch/deleteLogo', this.currentBranch.ID);
      this.logoPending = false;
    },
  },
  computed: {
    ...mapState('auth', ['currentUser', 'currentBranch', 'currentDealer']),
    logoGallery() {
      return [
        {
          ref: this.currentBranch.logoRef,
        },
      ];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import theme

.dropzone-container {
  height: 200px;
}

.dropzone {
  border: dashed $primary 1.5px;
  border-radius: 15px;
  background: rgba($primary, 0.1);

  &:hover {
    background: rgba($primary, 0.3);
  }
}

.upload-image {
  width: 60%;
  max-width: 150px;
}
</style>
