<template>
  <div>
    <q-tooltip v-if="customTooltip" anchor="top left">{{
      customTooltip
    }}</q-tooltip>
    <q-input
      ref="input"
      v-bind="$attrs"
      v-model="qValue"
      @keydown="allowedKey($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'AInput',
  props: {
    value: {
      required: true,
    },
    max: Number,
    min: Number,
    withSeparator: false,
    actAsNumber: false,
    customTooltip: false,
  },
  data() {
    return {
      lastValueWithValidLength: null,
    };
  },
  computed: {
    qValue: {
      get() {
        if (this.withSeparator === true) {
          return this.format(this.value);
        }
        return this.value;
      },
      set(val) {
        if (this.withSeparator === true) {
          this.$emit('input', this.returnValue(val));
        } else {
          this.setValue(val);
        }
      },
    },
  },
  methods: {
    setValue(val) {
      if ((this.$attrs.type && this.$attrs.type === 'number') || this.actAsNumber === true) {
        if (!Number.isNaN(this.$attrs.step) && this.$attrs.step === 1 && `${val}`.match(/\./)) {
          this.$emit('input', `${val}`.replace(/\.[0-9]+/, ''));
          this.updateInputValue();
        } else if (!Number.isNaN(this.$attrs.step) && this.$attrs.step === 0.1 && `${val}`.match(/\./)) {
          this.$emit('input', val.toFixed(1));
          this.updateInputValue();
        } else if (!Number.isNaN(this.min) && val < this.min) {
          if (val === null) {
            this.$emit('input', null);
          } else {
            this.$emit('input', this.min);
          }
          this.updateInputValue();
        } else if (!Number.isNaN(this.max) && val > this.max) {
          this.$emit('input', this.max);
          this.updateInputValue();
        } else {
          this.$emit('input', val);
        }
      } else {
        this.$emit('input', val);
      }
    },
    updateInputValue() {
      this.$nextTick(() => {
        if (this.$el) {
          this.$refs.input.$refs.input.value = this.qValue;
        }
      });
    },
    format(val) {
      if (val) {
        if (Number.isNaN(parseFloat(val))) {
          return null;
        }
        return this.unformat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return val;
    },
    unformat(val) {
      if (val) {
        if (Number.isNaN(parseFloat(val))) {
          return null;
        }
        return val.toString().replace(/\./g, '');
      }
      return val;
    },
    returnValue(newVal) {
      const newNumber = parseFloat(this.unformat(newVal));
      if (Number.isNaN(newNumber)) {
        return null;
      }

      return newNumber;
    },
    allowedKey(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (
        // Allow: backspace, delete, tab, enter
        [8, 9, 13, 46, 190, 188].includes(charCode) ||
        // Allow: Ctrl+A
        (charCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: Ctrl+C
        (charCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: Ctrl+X
        (charCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: Ctrl+V
        (charCode === 86 && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: home, end, left, right
        (charCode >= 35 && charCode <= 39) ||
        // Allow: numbers
        (charCode >= 48 && charCode <= 57) ||
        // Allow: numbers on num pad
        (charCode >= 96 && charCode <= 105)
      ) {
        return true;
      }
      event.preventDefault();
      return false;
    },
  },
  mounted() {
    if (this.withSeparator === true) {
      this.qValue = this.format(this.value);
    }
  },
};
</script>

<style scoped>
</style>
