import axios from 'axios';
import { reactiveSet } from '@/plugins/util';

const initialState = () => ({
  userSettings: {
    inventory: {
      incoming: {
        listView: 'normal',
      },
      inventory: {
        listView: 'normal',
      },
      sold: {
        listView: 'normal',
      },
      customer: {
        listView: 'normal',
      },
    },
    opportunity: {
      listView: true,
    },
  },
  defaultValueKeys: [],
  appNavigationSettings: {},
});

export const mutations = {
  SET_USER_SETTING(state, [key, userSetting]) {
    reactiveSet(state.userSettings, key, userSetting ? userSetting.value : null);
  },
  SET_APP_NAVIGATION_SETTINGS(state, settings) {
    state.appNavigationSettings = settings;
  },
  SET_DEFAULT_VALUE_KEYS(state, defaultValueKeys) {
    state.defaultValueKeys = defaultValueKeys;
  },
};

export const actions = {

/*   getUserSetting({ commit, state }, { defaultValue, key, forceReload = false }) {
    if (forceReload || !_.has(state.userSettings, key)) {
      return axios.get(`/v2/user/setting/${key}`)
        .then((response) => {
          commit('SET_USER_SETTING', [key, response.data.data || { value: defaultValue }]);
          if (_.isNil(response.data.data)) {
            commit('SET_USER_SETTING', [key, { value: defaultValue }]);
            commit('SET_DEFAULT_VALUE_KEYS', [...state.defaultValueKeys, key]);
          }
        });
    }
    return _.get(state.userSettings, key);
  }, */

  getUserSetting({ commit }, key) {
    return axios.get(`/v2/user/setting/${key}`).then((response) => {
      if (response.data.data) commit('SET_USER_SETTING', [key, response.data.data]);
    });
  },


  setUserSetting({ state, commit, ...storeUtils }, userSetting) {
    const preprocessedSetting = {
      ...userSetting,
      value: JSON.stringify(userSetting.value),
    };

    let setPromise;
    if (!state.defaultValueKeys.includes(userSetting.key)) {
      setPromise = updateUserSetting({ state, commit, ...storeUtils }, preprocessedSetting);
    } else {
      setPromise = createUserSetting({ state, commit, ...storeUtils }, preprocessedSetting);
      commit('SET_DEFAULT_VALUE_KEYS', state.defaultValueKeys.filter(key => key !== userSetting.key));
    }

    commit('SET_USER_SETTING', [userSetting.key, userSetting]);
    return setPromise;
  },

  setAppNavigationSettings({ commit }, settings) {
    commit('SET_APP_NAVIGATION_SETTINGS', settings);
  },

};

function createUserSetting({ dispatch }, userSetting) {
  return axios.post('/v2/user/setting', userSetting).then(() => dispatch('getUserSetting', userSetting.key));
}

function updateUserSetting({ dispatch }, userSetting) {
  return axios.put(`/v2/user/setting/${userSetting.key}`, userSetting).then((res) => {
    if (!res.data.data) return createUserSetting({ dispatch }, userSetting);
    return dispatch('getUserSetting', userSetting.key);
  });
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
