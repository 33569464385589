export function state() {
  return {
    status: 'online',

    // Holds open requests
    requests: [],
  };
}

export const mutations = {
  SET_STATUS(state, status) {
    state.status = status;
  },
  ADD_REQUEST(state, url) {
    state.requests.push(url);
  },
  REMOVE_REQUEST(state, url) {
    const i = state.requests.indexOf(url);
    if (i < 0) return;
    state.requests.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
