import axios from 'axios';

export const initialState = {
  dashboardData: [],
  contactsCount: 0,
};

export const mutations = {
  SET_DASHBOARD_DATA(state, newValue) {
    state.dashboardData = newValue;
  },
  SET_FEED(state, newValue) {
    state.elnFeed = newValue;
  },
  SET_CONTACTS_COUNT(state, count) {
    state.contactsCount = count;
  },
  RESET_DASHBOARD(state) {
    Object.keys(state).forEach((attr) => {
      state[attr] = initialState[attr]; 
    });
  },
};

export const actions = {

  getDashboardData({ commit } = {}) {
    return axios.get('/v1/dashboard').then((res) => {
      commit('SET_DASHBOARD_DATA', res.data.data);
    });
  },

  get({ commit } = {}) {
    return axios.get('/v1/dashboard').then((res) => {
      commit('SET_DASHBOARD_DATA', res.data.data);
    });
  },

  async getContactsCount({ commit, dispatch, rootState }) {
    await dispatch('contactList/getContactListCount', null, { root: true });
    commit('SET_CONTACTS_COUNT', rootState.contactList.contactListCount);
    await dispatch('contactList/resetContactList', null, { root: true });
  },

  resetDashboard({ commit }) {
    commit('RESET_DASHBOARD');
  },

};


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
};
