function creoleParseFromHTML(Vue, toParse) {
  // save from null
  if (toParse === null) toParse = '';
  const html = toParse;
  // remove unneeded whitespace
  toParse = toParse.replace(/(\r\n|\n|\r)/gm, '');

  // parse &nbsp
  toParse = toParse.replace(/&nbsp;/g, ' ');

  // parse single special chars like asterisk which are normally used as creole markup
  toParse = toParse.replace(/\*/g, '&#42;');

  // parse bold
  toParse = toParse.replace(/<strong>/g, '**');
  toParse = toParse.replace(/<\/strong>/g, '**');
  toParse = toParse.replace(/<b>/g, '**');
  toParse = toParse.replace(/<\/b>/g, '**');

  // parse italic
  toParse = toParse.replace(/<em>/g, '');
  toParse = toParse.replace(/<\/em>/g, '');
  toParse = toParse.replace(/<i>/g, '');
  toParse = toParse.replace(/<\/i>/g, '');

  // parse horizontal rulers
  toParse = toParse.replace(/<hr>/g, '----');
  toParse = toParse.replace(/<hr\/>/g, '----');
  toParse = toParse.replace(/<hr \/>/g, '----');

  // parse unordered lists
  toParse = toParse.replace(/<ul>/g, '');
  toParse = toParse.replace(/<li>/g, '* ');
  toParse = toParse.replace(/<\/li>/g, '\\\\');
  toParse = toParse.replace(/<\/ul>/g, '\\\\');

  // parse paragraph
  toParse = toParse.replace(/<p>/g, '');
  toParse = toParse.replace(/<\/p>/g, '');

  // remove spans
  toParse = toParse.replace(/<span(?:.|\n)*?>/g, '');
  toParse = toParse.replace(/<\/span>/g, '');

  // special case for br directly followed by div
  toParse = toParse.replace(/<br><\/div>/g, '\\\\');
  toParse = toParse.replace(/<br\/><\/div>/g, '\\\\');
  toParse = toParse.replace(/<br \/><\/div>/g, '\\\\');
  toParse = toParse.replace(/<div(?:.|\n)*?>/g, '');
  toParse = toParse.replace(/<\/div>/g, '\\\\');
  toParse = toParse.replace(/<br\/>/g, '\\\\');
  toParse = toParse.replace(/<br \/>/g, '\\\\');
  toParse = toParse.replace(/<br>/g, '\\\\');

  // remove all remaining html tags
  toParse = toParse.replace(/<(?:.|\n)*?>/g, '');
  toParse = toParse.replace(/<\/(?:.|\n)*?>/g, '');
  // our editor seems to add a br to the ending of every text, remove it for creole parsing
  if (toParse.substring(toParse.length - 2, toParse.length) === '\\\\') {
    toParse = toParse.substring(0, toParse.length - 2);
  }
  // filter out latin 0080−00FF Latin-1 Supplement.
  // 0100−017F Latin Extended-A.
  // 0180−024F Latin Extended-B.
  // for counting like on AS24
  const specialChars = (toParse.match(/[\u0080-\u024F]/g) || []).length;

  return {
    originalHtml: html,
    creole: toParse,
    // subtract the special chars and add them mulpiplied with 4, because AS24 seems to treat them as
    // some kind of unicode encoding
    // count: toParse.length,
    count: (toParse.length - specialChars) + (specialChars * 4),
  };
}

function creoleParseToHTML(Vue, toParse) {
  // toParse = toParse.replace(/~\*/g, '~~+');
  toParse = toParse.replace(/~\*/g, '&#42;');
  toParse = toParse.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);
  toParse = toParse.replace(/\/\/(.*?)\/\//g, (match, p1) => `<em>${p1}</em>`);
  toParse = toParse.replace(/\*{1} (.*?)\\{1,4}/g, (match, p1) => `<li>${p1}</li>`);
  // toParse = toParse.replace(/^(?!~).*\*{1} (.*?)\\{1,4}$/g, (match, p1) => `<li>${p1}</li>`);
  // toParse = toParse.replace(/~~\+/g, '*');
  toParse = toParse.replace(/\s*(<li>.*?<\/li>\s*)+/g, match => `<ul>${match}</ul>`);

  toParse = toParse.replace(/\\\\/g, '<br />');
  toParse = toParse.replace(/----/g, '<hr />');
  return toParse;
}

function parseFromHTML(Vue) {
  const parse = value => creoleParseFromHTML(Vue, value);

  return {
    parse: value => parse(value),
  };
}

function parseToHTML(Vue) {
  const parse = value => creoleParseToHTML(Vue, value);

  return {
    parse: value => parse(value),
  };
}

export default {
  install: (Vue) => {
    Vue.prototype.$creoleParseFromHTML = parseFromHTML(Vue);
    Vue.prototype.$creoleParseToHTML = parseToHTML(Vue);
  },
};
