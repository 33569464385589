<template>
  <div>
    <viewer v-if="isViewerOpenedOnce"
            hidden
            @inited="viewerInited"
            :options="viewerOptions"
            :images="images"
            ref="viewer"
    >
      <div class="row">
        <div class="col-2"> PREV </div>
        <img
            class="col-8"
            v-for="(image, index) in images"
            :src="image.ref ? $getFileUrl(image.ref, '1200') : image"
            :key="index"
        >
        <div class="col-2"> NEXT </div>
      </div>
    </viewer>

    <div v-if="isViewerOpened && showNavigation">
      <div
          class="viewer-left-arrow viewer-arrow flex items-center justify-center"
          @click="previous()"
      >
        <q-icon name="chevron_left" />
      </div>
      <div
          class="viewer-right-arrow viewer-arrow flex items-center justify-center"
          @click="next()"
      >
        <q-icon name="chevron_right" />
      </div>
      <q-btn size="lg" round class="viewer-close-button no-shadow" color="black" icon="clear" @click="hide()"/>
    </div>

    <draggable
        v-model="images"
        class="row gutter-sm"
        @start="$emit('startDragging', $event)"
        @sort="$emit('sort', $event)"
        :options="{
              ghostClass: 'dragged-image',
              filter: '.not-draggable',
              scrollSensitivity: 200,
              scrollSpeed: 20,
              animation: animate ? 150 : 0,
            }"
    >
      <div v-for="(image, index) in images" :key="index">
        <a-image
                 @clicked="openViewer($event)"
                 @remove="$emit('remove', $event)"
                 :image="image"
                 :image-index="index"
                 :show-title-overlay="showTitleOverlayForFirstImage ? index === 0 : false"
                 :size="size"
                 :remove-able="removeAble"
                 @load="$emit('load', image)"
        >
          <slot name="imageTitleOverlay"></slot>
        </a-image>
      </div>
      <div class="not-draggable">
        <slot name="lastNotDraggableElement"></slot>
      </div>
    </draggable>
  </div>
</template>

<script>
import { calculateWidth } from '@/plugins/util';
import draggable from 'vuedraggable';
import downloadjs from 'downloadjs';

export default {
  name: 'AGallery',
  components: {
    draggable,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    removeAble: {
      type: Boolean,
      default: true,
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
    showTitleOverlayForFirstImage: {
      type: Boolean,
      default: false,
    },
    // possible values: sm | md | lg
    size: {
      type: String,
      default: 'sm',
    },
  },
  data() {
    return {
      isViewerOpenedOnce: false,
      viewer: null,
      clickedImageIndex: 0,
      viewerOptions: {
        inline: false,
        button: false,
        navbar: this.showNavigation,
        title: false,
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 4,
          prev: 4,
          play: {
            show: 4,
            size: 'large',
          },
          next: 4,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4,
          download: () => {
            this.download();
          },
        },
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: false,
        keyboard: true,
        minZoomRatio: 1,
      },
    };
  },
  computed: {
    isViewerOpened() {
      if (this.viewer) {
        return this.viewer.isShown;
      }
      return false;
    },
    images: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    maxHeight() {
      if (this.size === 'md') {
        return 150;
      } else if (this.size === 'lg') {
        return 200;
      }

      return 100;
    },
    width() {
      return calculateWidth(this.maxHeight, 4, 3);
    },
  },
  methods: {
    openViewer({ index }) {
      if (index === 0 || index) {
        this.clickedImageIndex = index;
      }
      if (this.expandable) {
        if (!this.isViewerOpenedOnce) {
          this.isViewerOpenedOnce = true;
        }
        if (this.viewer) {
          this.viewer.show();
          if (index === 0 || index) {
            this.viewer.view(this.clickedImageIndex);
          }
        }
      }
    },
    download() {
      if (this.viewer) {
        downloadjs(this.viewer.image.currentSrc);
      }
    },
    next() {
      if (this.viewer) {
        this.viewer.next();
      }
    },
    previous() {
      if (this.viewer) {
        this.viewer.prev();
      }
    },
    hide() {
      if (this.viewer) {
        this.viewer.hide();
      }
    },
    viewerInited(viewer) {
      if (!this.viewer) {
        viewer.show();
        viewer.view(this.clickedImageIndex);
      }

      this.viewer = viewer;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import theme

.viewer-arrow {
  position: fixed;
  top: 0;
  z-index: 2020;
  font-size: 100px;
  width: 15%
  height: 100%;

  &:hover {
    cursor: pointer;
    background: rgba($secondary, .2);
  }
  color: rgba(white, .8);
}
.viewer-left-arrow {
  left: 0;
}
.viewer-right-arrow {
  right: 0;
}
.viewer-close-button {
  z-index: 2025;
  position: fixed;
  top: -12px;
  right: -12px;
}
</style>
