<template>
  <div class="column bg-white center text-bold items-center">
    <div class="q-display-1 text-weight-bold">Geschafft!</div>
    <div class="q-subheading text-weight-bold">Ihr Benutzerkonto wird für Sie eingerichtet…</div>
    <div ref='lavContainer'></div>
    <q-progress stripe indeterminate/>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import * as animationData from '@/assets/data.json';

export default {
  name: 'CreateAccAnimation',
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        clearCanvas: false,
        progressiveLoad: false,
        hideOnTransparent: true,
        scaleMode: 'noScale',
        className: 'lottie',
      },
    });
    this.$emit('animCreated', this.anim);
    this.$refs.lavContainer.children[0].style.cssText =
      'height: auto; width: calc(100% - 128px); margin-right: 64px; margin-top: 0.5rem;';
  },
};
</script>
