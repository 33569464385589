import RequestErrorNotification from '../store/requestErrorNotification';
import get from 'lodash.get';
import router from '@/router';

/*
To use this plugin simply add an attribute "errorNotification" to the config of your axios request.
Configuration of a basic axios request:
axios.get(`/v2/vehicle/123`, { config object });

Example 1 (turn off notifications):
{ errorNotification: false } <= config object of axios

Example 2 (custom notification):
const customErrorNotification = new RequestErrorNotification({
  message: 'Custom Notification Text',
  type: 'info', (<= 'info', 'error', 'success', or 'warning', default: 'error')
  origin: 'message' (<= 'message' or 'user', 'user' for top-right and 'message' for bottom-right notification, default: 'user')
});
{ errorNotification: customErrorNotification } <= config object of axios

Example 3 (custom notification message object):
const customErrorNotification = new RequestErrorNotification({
  message: {
    401: 'You shall not pass!!!'
    500: 'Random error just to annoy...'
  }
});
{ errorNotification: customErrorNotification } <= config object of axios
 */

export default {
  install: (Vue) => {
    Vue.prototype.$axios.interceptors.response.use(null, (error) => {
      if (error.code === 'ERR_CANCELED') {
        return Promise.reject(error);
      }

      const errorNotification = typeof error.config.errorNotification === 'undefined'
        ? true : error.config.errorNotification;

      if (errorNotification) {
        error.handled = true;
        let errorMessage = get(error, 'response.data.errorLocalized');

        if (errorMessage === 'Falsche Eingabe') errorMessage = 'Die Eingabe konnte nicht validiert werden!';

        if (errorNotification instanceof RequestErrorNotification) {
          errorNotification.triggerNotification(Vue, error.response.status, errorMessage);
        } else {
          // special handling for some errors, first is refreshAs24Error
          switch (error.response.data.error) {
            case 'refreshAs24Error':
              (new RequestErrorNotification()).triggerErrorDialog(
                Vue,
                'Ihre Authorisierung für Autoscout24 ist abgelaufen', errorMessage, '/settings#plattform-settings',
              );
              break;
            case 'maxVinInTrialPeriod':
              router.push('/maxvin');
              break;
            case 'saveConflict':
              (new RequestErrorNotification()).triggerNotification(Vue, error.response.status, 'Beim Speichern ist ein Konflikt aufgetreten!');
              break;
            default:
              (new RequestErrorNotification()).triggerNotification(Vue, error.response.status, errorMessage);
              break;
          }
        }
      }

      return Promise.reject(error);
    });
  },
};
