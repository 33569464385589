<template>
  <div>
    <q-btn
      flat
      size="22px"
      class="no-shadow animated shake"
      color="grey-5"
      no-ripple
      icon="notifications"
      @click="setDrawer"
    >
     <!--  <q-chip color="negative" floating dense class="q-caption">1</q-chip> -->
    </q-btn>
  </div>
</template>

<script>

export default {
  name: 'NotificationButton',

  data: () => ({
    isOpen: false,
  }),

  methods: {
    setDrawer() {
      const isOpen = this.$store.state.userSetting.notificationDrawerOpen;
      this.$store.commit('userSetting/SET_LOCAL', { key: 'notificationDrawerOpen', value: !isOpen });
    },
  },

};
</script>
<style scoped lang="stylus">
>>> .q-chip-floating {
  border-radius: 50%;
  top: 0.4em;
  right: 0.9em;
}
</style>

