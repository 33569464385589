import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { reactiveSet } from '@/plugins/util';
import TemplateEventBus from '@/components/document/templateEventBus';

const initialState = () => ({
  documentInvoiceTemplates: [],
  documentNonInvoiceTemplates: [],
  documentTemplate: {
    layout: {
      meta: {
        showHeader: false,
        showFooter: false,
      },
      content: [],
      background: {
        header: null,
        footer: null,
      },
    },
    name: 'Leere Vorlage',
    scope: 'user',
  },
  documentAgbTemplate: null,
  documentTemplateElement: null,
  hoveredDocumentTemplateElementId: null,
  vehicleList: {
    data: [],
  },
  containerToBeUpdated: null,
  documentTemplateContainer: null,
  documentTemplateGrid: null,
  backgroundEditMode: false,
  documentTemplateContainers: null,
  invoiceDocumentCategory: 'Rechnung',
  invoiceElements: null,
  invoiceNumberCount: 0,
  vehicleImageMapping: [],
  isZoomed: false,
});

export const mutations = {
  SET_TEMPLATES(state, { templates, storeTarget }) {
    state[storeTarget] = templates;
  },

  SET_TEMPLATE(state, template) {
    if (!_.has(template, 'layout.meta.showHeader')) {
      reactiveSet(template, 'layout.meta.showHeader', initialState().documentTemplate.layout.meta.showHeader);
    }
    if (!_.has(template, 'layout.meta.showFooter')) {
      reactiveSet(template, 'layout.meta.showFooter', initialState().documentTemplate.layout.meta.showFooter);
    }
    if (!_.has(template, 'layout.background')) {
      reactiveSet(template, 'layout.background', initialState().documentTemplate.layout.background);
    }
    state.documentTemplate = template;
  },

  RESET_TEMPLATE(state) {
    state.documentTemplate = initialState().documentTemplate;
  },

  SET_TEMPLATE_ELEMENT(state, templateElement) {
    state.documentTemplateElement = templateElement;
  },

  SET_HOVERED_TEMPLATE_ELEMENT_ID(state, templateElementId) {
    state.hoveredDocumentTemplateElementId = templateElementId;
  },

  DELETE_TEMPLATE_ELEMENT_FROM_LIST(state, { selectedContainer, templateElementIndex }) {
    selectedContainer.content.splice(templateElementIndex, 1);
  },

  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]; 
    });
  },

  SET_VEHICLE_LIST(state, vehicleList) {
    state.vehicleList = vehicleList;
  },

  SET_CONTAINER_TO_BE_UPDATED(state, container) {
    state.containerToBeUpdated = container;
  },

  SET_TEMPLATE_CONTAINER(state, container) {
    state.documentTemplateContainer = container;
  },

  SET_TEMPLATE_GRID(state, grid) {
    state.documentTemplateGrid = grid;
  },

  SET_TEMPLATE_CONTAINERS(state, containers) {
    state.documentTemplateContainers = containers;
  },

  SET_BACKGROUND_EDIT_MODE(state, editMode) {
    state.backgroundEditMode = editMode;
  },

  SET_INVOICE_ELEMENTS(state, elements) {
    Vue.set(state, 'invoiceElements', Object.assign(state.invoiceElements || {}, elements));
  },

  UNSET_INVOICE_ELEMENTS(state) {
    state.invoiceElements = null;
  },

  INCREMENT_INVOICE_NUMBER_COUNT(state) {
    state.invoiceNumberCount += 1;
  },

  DECREMENT_INVOICE_NUMBER_COUNT(state) {
    state.invoiceNumberCount -= 1;
  },

  UPSERT_VEHICLE_IMAGE_MAPPING(state, mapping) {
    const { mappingFieldId, ...otherMappingFields } = mapping;
    const existingMapping = state.vehicleImageMapping.find(imageMapping => imageMapping.mappingFieldId === mappingFieldId);
    if (existingMapping) {
      Object.assign(existingMapping, otherMappingFields);
    } else {
      state.vehicleImageMapping.push(mapping);
    }
  },

  DELETE_VEHICLE_IMAGE_MAPPING(state, mappingFieldId) {
    state.vehicleImageMapping = state.vehicleImageMapping.filter(mapping => mapping.mappingFieldId !== mappingFieldId);
  },

  SET_IS_ZOOMED(state, isZoomed) {
    state.isZoomed = isZoomed;
  },

  SET_AGB_TEMPLATE(state, template) {
    state.documentAgbTemplate = template;
  },
};

function getTemplates({ commit }, { category = null, excludeCategory = false, storeTarget }) {
  return axios.get('/v2/documentTemplate', category ? {
    params: { category, excludeCategory },
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' }); 
    },
  } : undefined).then((templates) => {
    commit('SET_TEMPLATES', { templates: templates.data.data, storeTarget });
  });
}

export const actions = {
  getNonInvoiceTemplates({ state, ...storeUtils }, { forceReload = false } = {}) {
    const storeTarget = 'documentNonInvoiceTemplates';
    if (!forceReload && state[storeTarget].length > 0) {
      return state[storeTarget];
    }
    return getTemplates({ state, ...storeUtils }, { category: state.invoiceDocumentCategory, excludeCategory: true, storeTarget });
  },

  getInvoiceTemplates({ state, ...storeUtils }, { forceReload = false } = {}) {
    const storeTarget = 'documentInvoiceTemplates';
    if (!forceReload && state[storeTarget].length > 0) {
      return state[storeTarget];
    }
    return getTemplates({ state, ...storeUtils }, { category: state.invoiceDocumentCategory, storeTarget: 'documentInvoiceTemplates' });
  },

  getTemplate({ commit }, id) {
    return axios.get(`/v2/documentTemplate/${id}`).then((template) => {
      commit('SET_TEMPLATE', template.data.data);
    });
  },

  getAgbTemplate({ commit }, id) {
    return axios.get(`/v2/documentTemplate/${id}`).then((template) => {
      commit('SET_AGB_TEMPLATE', template.data.data);
    });
  },

  createTemplate({ commit, dispatch }, template) {
    return axios.post('/v2/documentTemplate/', { ...template, scope: 'user' }).then((createdTemplate) => {
      commit('SET_TEMPLATE', createdTemplate.data.data);
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
    });
  },

  updateTemplate({ commit, dispatch }, template) {
    return axios.put(`/v2/documentTemplate/${template.ID}`, template).then((updatedTemplate) => {
      commit('SET_TEMPLATE', updatedTemplate.data.data);
      TemplateEventBus.$emit('document-template-save');
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
      return updatedTemplate.data.data;
    });
  },

  updateTemplates({ dispatch }, { templateIds, changes }) {
    return axios.put('/v2/documentTemplate/', { templateIds, changes })
      .then((data) => {
        dispatch('documentTemplateTable/notifyTables', {}, { root: true });
        return data;
      });
  },

  deleteTemplate({ commit, dispatch }, template) {
    return axios.delete(`/v2/documentTemplate/${template.ID}`).then(() => {
      commit('RESET');
      dispatch('documentTemplateTable/notifyTables', {}, { root: true });
    });
  },

  resetTemplate({ commit }) {
    commit('RESET_TEMPLATE');
  },

  updateTemplateElement({ commit }, templateElement) {
    commit('SET_TEMPLATE_ELEMENT', templateElement);
    if (templateElement && templateElement.id) {
      commit('SET_HOVERED_TEMPLATE_ELEMENT_ID', templateElement.id);
    } else {
      commit('SET_HOVERED_TEMPLATE_ELEMENT_ID', null);
    }
  },

  updateHoveredTemplateElementId({ commit }, templateElementId) {
    commit('SET_HOVERED_TEMPLATE_ELEMENT_ID', templateElementId);
  },

  deleteTemplateElement({ state, commit, dispatch }) {
    const selectedContainer = state.documentTemplateContainer;
    if (selectedContainer && state.documentTemplateElement) {
      const templateElementIndex = selectedContainer.content.findIndex(item => item.id === state.documentTemplateElement.id);
      commit('DELETE_TEMPLATE_ELEMENT_FROM_LIST', {
        selectedContainer,
        templateElementIndex,
      });

      if (selectedContainer.content.length > 0) {
        const contentToSelect
          = selectedContainer.content[templateElementIndex - 1]
          || selectedContainer.content[templateElementIndex];
        dispatch('updateTemplateElement', contentToSelect);
      } else {
        dispatch('updateTemplateElement', null);
      }
    }
  },

  reset({ commit }) {
    commit('RESET');
  },

  getAllVehicles({ dispatch, commit }) {
    dispatch('vehicleList/getAllVehiclesPlain', { limit: 1000 }, { root: true }).then((vehicleList) => {
      commit('SET_VEHICLE_LIST', vehicleList.data);
    });
  },

  setContainerToBeUpdated({ commit }, container) {
    commit('SET_CONTAINER_TO_BE_UPDATED', container);
  },

  updateTemplateContainer({ commit, dispatch, state }, container) {
    commit('SET_TEMPLATE_CONTAINER', container);

    if (state.documentTemplateElement && !container.content.find(content => content.id === state.documentTemplateElement.id)) {
      dispatch('updateTemplateElement', null);
    }
  },

  resetSelections({ dispatch }) {
    dispatch('updateTemplateElement', null);
    dispatch('updateTemplateContainer', null);
    dispatch('updateTemplateGrid', null);
    dispatch('updateTemplateContainers', null);
  },

  updateTemplateGrid({ commit }, grid) {
    commit('SET_TEMPLATE_GRID', grid);
  },

  setBackgroundEditMode({ commit }, editMode) {
    commit('SET_BACKGROUND_EDIT_MODE', editMode);
  },

  updateTemplateContainers({ commit }, containers) {
    commit('SET_TEMPLATE_CONTAINERS', containers);
  },

  deleteInvoice({ commit }) {
    commit('UNSET_INVOICE_ELEMENTS');
  },

  setInvoice({ commit }, elements) {
    commit('SET_INVOICE_ELEMENTS', elements);
  },

  incrementInvoiceNumberCount({ commit }) {
    commit('INCREMENT_INVOICE_NUMBER_COUNT');
  },

  decrementInvoiceNumberCount({ commit }) {
    commit('DECREMENT_INVOICE_NUMBER_COUNT');
  },

  upsertVehicleImageMapping({ commit }, mapping) {
    commit('UPSERT_VEHICLE_IMAGE_MAPPING', mapping);
  },

  deleteVehicleImageMapping({ commit }, mappingFieldId) {
    commit('DELETE_VEHICLE_IMAGE_MAPPING', mappingFieldId);
  },

  searchByName(context, { name, filter }) {
    return axios.post('/v2/documentTemplate/search', { name, filter })
      .then(data => data.data.data);
  },

};

const getters = {
  hasVehicleInvoicePosition: state => !!_.get(state.invoiceElements, 'positions', []).find(elem => elem.isVehicleInvoicePosition),
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
