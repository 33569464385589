<template>
  <div class="column">
    <div
        class="col-auto full-width q-mb-md bg-white a-card-border round-borders"
        v-for="(entry, index) in data"
        :key="entry[uniqueIdentifier]"
        @mouseover="hoveredDocumentId = entry[uniqueIdentifier]"
        @mouseleave="hoveredDocumentId = null"
    >
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-auto flex cursor-pointer" @click="$emit('clicked', entry)">
              <slot
                  name="image"
                  :entry="entry"
                  :data="data"
                  :index="index"
                  :hoveredDocumentId="hoveredDocumentId"
              >
              </slot>
            </div>
            <div class="col">
              <div class="q-py-sm q-px-md">
                <div class="q-title text-dark q-pb-lg cursor-pointer" @click="$emit('clicked', entry)">
                  <slot
                      name="title"
                      :entry="entry"
                      :data="data"
                      :index="index"
                      :hoveredDocumentId="hoveredDocumentId"
                  ></slot>
                </div>
                <div class="q-pa-xs">

                  <div class="row gutter-x-md gutter-y-sm text-faded">

                    <slot
                        name="main"
                        :entry="entry"
                        :data="data"
                        :index="index"
                        :hoveredDocumentId="hoveredDocumentId"
                    ></slot>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto flex justify-end items-center q-pr-sm">
          <div class="row">
            <slot
                name="right"
                :entry="entry"
                :data="data"
                :index="index"
                :hoveredDocumentId="hoveredDocumentId"
            >
            </slot>
          </div>
        </div>

      </div>

      <q-context-menu>
        <slot
            name="context-menu"
            :entry="entry"
            :data="data"
            :index="index"
            :hoveredDocumentId="hoveredDocumentId"
        ></slot>
      </q-context-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ACards',
  props: {
    data: {
      type: Array,
      required: true,
    },
    uniqueIdentifier: {
      type: String,
      default: 'ID',
    },
  },
  data() {
    return {
      hoveredDocumentId: null,
    };
  },
};
</script>

<style lang="stylus" scoped>
.a-card-border {
  border: 1px solid #e3e3e3;
  overflow: hidden;
}
</style>
