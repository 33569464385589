import axios from 'axios';
import { toOptions } from '@/plugins/util';
import _ from 'lodash';

const initialState = () => ({
  progressTypes: [],
  progressDefaults: [],
});

export const mutations = {
  SET_PROGRESS_TYPES(state, categories) {
    state.progressTypes = categories;
  },
  SET_PROGRESS_DEFAULTS(state, defaults) {
    state.progressDefaults = defaults;
  },
};

let fetchPromise = null;

export const actions = {
  async getOpportunityProgressTypes({ commit, state }, { forceReload = false } = {}) {
    if (!forceReload && fetchPromise) {
      return fetchPromise;
    }
    if (!forceReload && state.progressTypes.length > 0) {
      return state.progressTypes;
    }
    fetchPromise = axios.get('/v2/opportunity/progress')
      .then((res) => {
        fetchPromise = null;
        commit('SET_PROGRESS_TYPES', res.data.data);
        return res.data.data;
      });

    return fetchPromise;
  },
  async createOpportunityProgressType({ dispatch }, { progressType, defaults }) {
    const createdCategory = await axios.post('/v2/opportunity/progress', { ...progressType, scope: 'user', defaults });
    dispatch('getOpportunityProgressTypes', { forceReload: true });
    return createdCategory.data.data;
  },

  async updateOpportunityProgressType({ dispatch }, { progressType, defaults, overwrite }) {
    const createdCategory = await axios.put(
      `/v2/opportunity/progress/${progressType.ID}${overwrite ? '/overwrite' : ''}`,
      { ...progressType, scope: 'user', defaults },
    );
    dispatch('getOpportunityProgressTypes', { forceReload: true });
    return createdCategory.data.data;
  },

  async deleteOpportunityProgressType({ dispatch }, progressType) {
    await axios.delete(`/v2/opportunity/progress/${progressType.ID}`);
    dispatch('getOpportunityProgressTypes', { forceReload: true });
  },

  // ProgressType Defaults
  async getProgressDefaults({ commit }) {
    const progressDefaults = await axios.get('/v2/opportunity/progress/defaults');
    commit('SET_PROGRESS_DEFAULTS', progressDefaults.data.data);
    return progressDefaults.data.data;
  },

};

function getDefaultSourceLabels(progressType) {
  return _.get(progressType, 'defaults', [])
    .map(defaultMapping => defaultMapping.source)
    .join(', ');
}

function hasDefaultsSet(progressType) {
  return _.get(progressType, 'defaults', []).length > 0;
}

export const getters = {
  progressTypesOptions: state => toOptions(state.progressTypes, 'label').map((option) => {
    if (option.value.scope === 'system') {
      option.sublabel = 'Systemprozesstyp';
    }
    if (hasDefaultsSet(option.value)) {
      option.rightIcon = 'star';
      const sublabel = `Standard für ${getDefaultSourceLabels(option.value)}`;

      if (option.sublabel) {
        option.sublabel = `${option.sublabel} | ${sublabel}`;
      } else {
        option.sublabel = sublabel;
      }
    }
    return option;
  }),
  progressTypesOptionsWithId: (state, getters) => getters.progressTypesOptions.map(option => ({ ...option, value: option.value.ID })),
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
};
