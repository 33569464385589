<template>

  <q-infinite-scroll
      :handler="loadMoreDocuments"
      v-bind="$attrs"
      ref="infiniteScroll"
  >
    <slot></slot>
    <!--
      slot="message" for DOM element to display (in this example
      a dots spinner) when loading additional content
    -->
    <div class="flex justify-center" slot="message">
      <q-spinner-dots :size="40"></q-spinner-dots>
    </div>
  </q-infinite-scroll>
</template>

<script>
export default {
  name: 'AInfiniteScroll',
  props: {
    fetch: {
      type: Function,
      required: true,
    },
    batchSize: {
      type: Number,
      default: 15,
    },
  },
  methods: {
    async loadMoreDocuments(step, done) {
      const allLoaded = await this.fetch({
        limit: this.batchSize,
        offset: (step - 1) * this.batchSize,
      });

      done(allLoaded);
      this.$emit('fetch');
    },
    reset() {
      this.$refs.infiniteScroll.reset();
      this.$refs.infiniteScroll.resume();
      this.$refs.infiniteScroll.loadMore();
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
