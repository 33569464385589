<template>
  <q-input
    ref="autocomplete"
    v-model="inputModel"
    @clear="() => { setValue(resetValue); $emit('clear'); }"
    @input="onInput($event); $emit('input', $event)"
    v-bind="$attrs"
    type="search"
    autocomplete="off"
  >
    <q-autocomplete
      v-if="search"
      @search="search"
      :value-field="sortField"
      @selected="(item, keyboard) => setValue(item[valueField], keyboard, item)"
      :max-results="maxResults"
      v-bind="$attrs"
    ></q-autocomplete>
    <q-autocomplete
      v-else
      :static-data="{ field: sortField, list: data }"
      :filter="(terms, { list }) => filterAutocomplete(terms, list)"
      :value-field="sortField"
      @selected="(item, keyboard) => setValue(item[valueField], keyboard, item)"
      :max-results="maxResults"
      v-bind="$attrs"
    ></q-autocomplete>
  </q-input>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AAutocompleteInput',
  props: {
    value: {
      required: true,
    },
    data: {
      type: Array,
    },
    search: {
      type: Function,
    },
    sortField: {
      type: String,
      default: 'label',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    resetValue: {
      type: String,
      default: null,
    },
    maxResults: {
      type: Number,
      default: 9999,
    },
    displayValue: {
      type: String,
      default: '',
    },
    sanitize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputModel: '',
    };
  },
  methods: {
    filterAutocomplete(terms, list) {
      const token = terms.toLowerCase();
      return list
        .filter(item => !!_.values(item)
          .find(value => _.isString(value) && value.toLowerCase().match(new RegExp(token))));
    },
    setValue(val, keyboard = false, item) {
      this.$emit('fullItem', item);
      this.$emit('input', val);
      if (keyboard) return;
      this.$emit('selected', val);
      if (this.resetValue !== val) this.$refs.autocomplete.blur();
    },
    onInput(newVal) {
      if (this.sanitize) this.inputModel = this.$sanitize(this.inputModel, { ALLOWED_TAGS: [] });
      return newVal === '' ? this.setValue(this.resetValue) : undefined;
    },
  },
  mounted() {
    if (this.search && this.data) {
      throw new Error('Please provide only one of the following props: search, data');
    } else if (!this.search && !this.data) {
      throw new Error('Please provide at least one of the following props: search, data');
    }
    this.inputModel = this.displayValue;
  },
  watch: {
    value(newVal) {
      if (newVal === this.resetValue) {
        this.inputModel = '';
      }
    },
    displayValue(newDisplayValue) {
      if (newDisplayValue || newDisplayValue === '') {
        this.inputModel = newDisplayValue;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .q-item-image {
  width: 114px;
  height: 72px;
  object-fit: cover;
}
</style>
