import io from 'socket.io-client';
import Vue from 'vue';


let socket = null;

const createSocket = (token, store) => {
  // validates if the message should be handle within this tab. Should be used for user specific messages
  function validate(handler) {
    return async (message) => {
      const isValidJobId = await store.dispatch('socket/isValidJobId', message.jobId);
      if (message && isValidJobId) {
        handler(message);
      }
    };
  }

  try {
    if (socket) socket.close();

    if (!token) return;

    socket = io(process.env.VUE_APP_API_URL, {
      query: {
        authorization: `JWT ${token}`,
      },
      reconnection: true,
    });

    socket.on('connect', () => {
      store.dispatch('socket/onConnected', socket);
      store.dispatch('session/init', socket);
      console.log('socket connected', socket.id);
    });

    socket.on('disconnect', () => {
      store.dispatch('socket/onDisconnected');
      console.log('socket disconnected');
    });

    // socket events
    socket.on('vehicle_remove_result', validate((message) => {
      store.dispatch('ads/handleRemoveResult', message);
    }));
    socket.on('vehicle_push_result', validate((message) => {
      store.dispatch('ads/handlePushResult', message);
    }));
    socket.on('vehicle_update_result', validate((message) => {
      store.dispatch('ads/handleUpdateResult', message);
    }));
    socket.on('vehicle_img_result', validate((message) => {
      store.dispatch('ads/handleImgResult', message);
    }));
    socket.on('vehicle_publication_result', validate((message) => {
      store.dispatch('ads/handlePublicationResult', message);
    }));
    socket.on('branch_import', validate((message) => {
      store.dispatch('inserateImporter/handleImportResult', message);
    }));
    socket.on('branch_merge_import', validate((message) => {
      store.dispatch('inserateImporter/handleImportMergeResult', message);
    }));
    socket.on('branch_assign_inserate', ((message) => {
      store.dispatch('inserateImporter/handleAssignResult', message);
    }));
    socket.on('new_mail', (message) => {
      store.dispatch('internalEmail/handleNewMail', message);
    });
    socket.on('marketplace_outbidden', (message) => {
      store.dispatch('marketplaceDetail/handleOutbidden', message);
    });
    socket.on('email_account_invalid', (message) => {
      store.commit('auth/SET_NYLAS_GRANT_STATUS', message.status);
    });

    // Send errors to backend via socket
    const handleError = (...args) => {
      args = args.map((arg) => {
        if (arg instanceof Error) {
          if (arg.name === 'ChunkLoadError') {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
          return {
            message: arg.message,
            stack: arg.stack,
            ...arg,
          };
        }
        return arg;
      });

      if (socket && socket.connected) {
        socket.send('FRONTEND_ERROR', args);
      }
    };

    const consoleError = console.error;
    console.error = (...args) => {
      handleError(...args);
      consoleError(...args);
    };

    window.addEventListener('error', (event) => {
      handleError(event.error);
    });

    Vue.prototype.$socket = socket;
  } catch (error) {
    console.error(error);
    socket = null;
  }
};


export default async (store) => {
  const accessToken = localStorage.getItem('auth.accessToken');
  createSocket(JSON.parse(accessToken), store);

  store.subscribe(({ type, payload }) => {
    if (type !== 'auth/SET_ACCESS_TOKEN') return;
    console.log('new token, creating socket');
    createSocket(payload, store);
  });
};
