import axios from 'axios';
import debounce from 'lodash.debounce';
import { Notify } from 'quasar';
import router from '../../router';

import { openModal } from '@/plugins/util';
import VehicleDiffModal from '@/components/vehicle/vehicle-detail/VehicleDiffModal';
import { get } from 'lodash';

const initialState = () => ({
  vehicleDetail: {},
  vehicleDetailClone: {},
  vehicleChanged: false,
  vehicleDetailLoaded: false,
  vehicleImagLoading: false,
  vehicleOpportunities: [],
  createdVehicleSellingId: null,
  vehiclePriceEstimation: {},
  vehiclePurchase: {
    taxRate: 0,
  },
  vehiclePurchaseAvailable: false,
  vehicleInvoice: {
    taxRate: 0,
  },
  vehicleInvoiceAvailable: false,
  vehicleServices: [],
  vehicleImages: [],
  vehicleImagesBeforeDummy: [],
  vehicleNotes: [],
  vehicleDocuments: [],
  vehicleToDos: [],
  vehicleToDoTemplates: [],
  vehicleMobileDePriceRatingLoading: true,
  vehicleMobileDePriceRating: null,
  vehiclePriceHistory: [],
  vehicleDatData: {},
  vehicleContacts: [],
});

export const mutations = {
  SET_STATE(state, value) {
    Object.assign(state, value);
  },
  SET_DETAIL(state, newValue) {
    state.vehicleDetail = newValue;
    state.vehicleDetailLoaded = true;
    state.vehicleDetailClone = JSON.parse(JSON.stringify(newValue));
    state.vehicleChanged = false;
  },
  UPDATE_DETAIL(state, newValue) {
    state.vehicleDetail = newValue;
  },
  RESET_VEHICLE(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_PURCHASE(state, newValue) {
    state.vehiclePurchase = newValue;
    state.vehiclePurchaseAvailable = true;
  },
  RESET_PURCHASE(state) {
    state.vehiclePurchase = initialState().vehiclePurchase;
    state.vehiclePurchaseAvailable = false;
  },
  SET_INVOICE(state, newValue) {
    state.vehicleInvoice = newValue;
    state.vehicleInvoiceAvailable = true;
  },
  RESET_INVOICE(state, userId) {
    state.vehicleInvoice = initialState().vehicleInvoice;
    state.vehicleInvoice.sellerId = userId;
    state.vehicleInvoiceAvailable = false;
  },
  SET_SAVING(state, newValue) {
    state.vehicleSaving = newValue;
  },
  SET_SAVING_SUCCESS(state, newValue) {
    state.vehicleSavingSuccess = newValue;
  },
  SET_OPPORTUNITIES(state, newValue) {
    state.vehicleOpportunities = newValue;
  },
  SET_CREATED_VEHICLE_SELLING_ID(state, newValue) {
    state.createdVehicleSellingId = newValue.sellingId;
  },
  SET_VEHICLE_PRICE_ESTIMATION(state, newValue) {
    state.vehiclePriceEstimation = newValue;
  },
  SET_VEHICLE_DAMAGES(state, damages) {
    // v1 api returns full cloudinary url instead of only the reference -> quickfix
    state.vehicleDetail.damages = damages.map(damage => ({ ...damage, ref: damage.ref ? damage.ref.split('/').pop() : damage.ref }));
  },
  DELETE_VEHICLE_DAMAGE(state, deletedDamage) {
    state.vehicleDetail.damages = state.vehicleDetail.damages.filter(damage => damage.ID !== deletedDamage.ID);
  },
  SET_VEHICLE_SERVICES(state, services) {
    state.vehicleServices = services;
  },
  SET_VEHICLE_IMAGES(state, newValue) {
    state.vehicleImages = newValue;
  },
  ADD_VEHICLE_IMAGE_DUMMYS(state, newValues) {
    state.vehicleImagesBeforeDummy = JSON.parse(JSON.stringify(state.vehicleImages));
    state.vehicleImages = state.vehicleImages.concat(newValues.map(() => ({ loading: true })));
  },
  ADD_VEHICLE_IMAGES(state, newValues) {
    state.vehicleImages = state.vehicleImages.filter(image => !image.loading).concat(newValues);
  },
  REORDER_VEHICLE_IMAGES(state, newValue) {
    state.vehicleImages = newValue;
  },
  DELETE_VEHICLE_IMAGE(state, deletedImageId) {
    state.vehicleImages = state.vehicleImages.filter(image => image.ID !== deletedImageId);
  },
  SET_VEHICLE_IMAGES_LOADING(state, loadingState) {
    state.vehicleImagLoading = loadingState;
  },
  SET_COVER_IMAGE(state, imgObj) {
    state.vehicleDetail.coverImage = imgObj;
  },
  SET_NOTES(state, notes) {
    state.vehicleNotes = notes;
  },
  SET_TODOS(state, toDos) {
    state.vehicleToDos = toDos;
  },
  SET_TODO_TEMPLATES(state, toDos) {
    state.vehicleToDoTemplates = toDos;
  },
  SET_DOCS(state, docs) {
    state.vehicleDocuments = docs;
  },
  SET_VEHICLE_MOBILE_DE_PRICE_RATING_LOADING(state, isLoading) {
    state.vehicleMobileDePriceRatingLoading = isLoading;
  },
  SET_VEHICLE_MOBILE_DE_PRICE_RATING(state, rating) {
    state.vehicleMobileDePriceRating = rating;
  },
  SET_VEHICLE_PRICE_HISTORY(state, history) {
    state.vehiclePriceHistory = history.filter(e => e.price !== null);
  },
  SET_VEHICLE_DAT_DATA(state, datData) {
    state.vehicleDatData = datData;
  },
  SET_CONTACTS(state, contacts) {
    state.vehicleContacts = contacts;
  },
};

export const getters = {
  isDemo: (state) => {
    if (state.vehicleDetail.isDummy) return true;
    return false;
  },
  showAdTab: (state) => {
    if (!state.vehicleDetail.isEln) return true;
    if (state.vehicleDetail.elnAllowsExport) return true;
    return false;
  },
  sellingId: (state) => {
    if (state.vehicleDetail.sellingId) return state.vehicleDetail.sellingId;
    return null;
  },
};

function removeKey(arr, key) {
  const index = arr.indexOf(key);
  return index > -1 ? arr.splice(index, 1) : arr;
}

const getVehicleImagesDebounced = debounce(getVehicleImages, 1500);

export const actions = {

  getPurchaseForVehicle({ commit }, id) {
    return axios.get(`/v2/purchase/vehicle/${id}`, { errorNotification: false })
      .then((res) => {
        const { data } = res.data;
        if (data) commit('SET_PURCHASE', data);
        else commit('RESET_PURCHASE');
      })
      .catch(() => {
        commit('RESET_PURCHASE');
      });
  },

  getInvoiceForVehicle({ commit, rootState }, id) {
    return axios.get(`/v2/invoice/vehicle/${id}`, { errorNotification: false })
      .then((res) => {
        const { data } = res.data;
        if (data) commit('SET_INVOICE', data);
        else commit('RESET_INVOICE', rootState.auth.currentUser.ID);
      })
      .catch(() => {
        commit('RESET_INVOICE', rootState.auth.currentUser.ID);
      });
  },

  setVehicleDetailFromDataTable({ dispatch, commit }, vehicleData) {
    commit('SET_DOCS', vehicleData.documents);
    commit('SET_VEHICLE_DAMAGES', vehicleData.damages);
    commit('SET_VEHICLE_IMAGES', [vehicleData.coverImage]);
    const vehicleDataClone = JSON.parse(JSON.stringify(vehicleData));
    dispatch('getAdditionalVehicleData', { vehicleData: vehicleDataClone, sellingId: vehicleData.sellingId });
  },

  async getVehicleById({ commit, dispatch }, id) {
    const { data } = await axios.get(`/v2/vehicle/id/${id}`);
    commit('SET_DETAIL', data.data);
    dispatch('getVehicleMobileDePriceRating');
    return data.data;
  },

  // TODO: use getAdditionalVehicleData
  getVehicleBySellingId({ commit, dispatch, state }, sellingId) {
    if (sellingId === state.vehicleDetailClone.sellingId) {
      return state.vehicleDetailClone;
    }
    return axios.get(`/v2/vehicle/${sellingId}`)
      .then((res) => {
        dispatch('resetVehicle');
        dispatch('getVehicleImages', sellingId);
        dispatch('ads/getAds', sellingId, { root: true });
        dispatch('getOpportunities', sellingId);
        dispatch('getDocs', sellingId);
        dispatch('vehicleMeta/getModelsForMake', res.data.data.makeId, { root: true });
        dispatch('getPurchaseForVehicle', res.data.data.ID);
        dispatch('getInvoiceForVehicle', res.data.data.ID);
        commit('SET_DETAIL', res.data.data);
        dispatch('getVehicleMobileDePriceRating');
        dispatch('getVehiclePriceHistory', sellingId);

        return res.data.data;
      });
  },

  getAdditionalVehicleData({ commit, dispatch }, { vehicleData, sellingId }) {
    // because of binding a clone must be created
    // because of dispatch behavior the clone can not be created in the calling function for dispatch does match the clone to undefined for not known reasons
    // vehicleData = JSON.parse(JSON.stringify(vehicleData));
    delete vehicleData.opportunitiesCount;
    delete vehicleData.__index;
    dispatch('ads/getAds', sellingId, { root: true });
    dispatch('getOpportunities', sellingId);
    dispatch('getDocs', sellingId);
    dispatch('getVehicleImages', sellingId);
    dispatch('vehicleMeta/getModelsForMake', vehicleData.makeId, { root: true });
    dispatch('getPurchaseForVehicle', vehicleData.ID);
    dispatch('getInvoiceForVehicle', vehicleData.ID);
    commit('SET_DETAIL', vehicleData);
    dispatch('getVehicleMobileDePriceRating');
    dispatch('getVehiclePriceHistory', sellingId);
  },

  clearModelsForMake({ commit }) {
    commit('SET_MODELS', []);
  },

  deleteVehicle({ dispatch }, { sellingId, skipListUpdate = false }) {
    return axios.delete(`/v2/vehicle/${sellingId}`)
      .then(() => {
        if (skipListUpdate) return;
        dispatch('resetVehicle');
        dispatch('vehicleList/getAllVehicles', null, { root: true });
      });
  },

  async saveVehicleDetail({ commit, dispatch, state }, vehicleDetail) {
    try {
      const res = await axios.put(`/v2/vehicle/${vehicleDetail.sellingId}`, vehicleDetail);
      const hasPriceChanged = vehicleDetail.price !== state.vehicleDetailClone.price;
      if (hasPriceChanged && vehicleDetail.price >= 0) {
        dispatch('updateVehiclePriceHistory', { sellingId: vehicleDetail.sellingId, price: vehicleDetail.price });
      }
      commit('SET_VEHICLE_PRICE_ESTIMATION', {});
      commit('SET_DETAIL', res.data.data);
      dispatch('getVehicleMobileDePriceRating');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const { update } = await openModal({
          component: VehicleDiffModal,
          originVehicle: error.response.data.vehicle,
          originalVehicle: state.vehicleDetailClone,
          currentVehicle: vehicleDetail,
        }, router.app);

        if (update) {
          commit('UPDATE_DETAIL', Object.assign({}, vehicleDetail, update));
        }
      }
      throw error;
    }
  },

  async updateVehiclePriceHistory({ dispatch }, { sellingId, price }) {
    await axios.put(`/v2/vehicle/${sellingId}/price-history`, { price });
    return dispatch('getVehiclePriceHistory', sellingId);
  },

  async getVehiclePriceHistory({ commit }, sellingId) {
    const { data } = await axios.get(`/v2/vehicle/${sellingId}/price-history`);
    commit('SET_VEHICLE_PRICE_HISTORY', data.data);
  },

  saveVehiclePurchase({ commit, state }, { vehicleId, vehiclePurchase }) {
    const keys = Object.keys(vehiclePurchase);
    removeKey(keys, 'taxRate');

    if (state.vehiclePurchaseAvailable) {
      // update
      return axios.put(`/v2/purchase/vehicle/${vehicleId}`, vehiclePurchase)
        .then((res) => {
          commit('SET_PURCHASE', res.data.data);
        });
    }
    // create or do nothing
    if (keys.length > 0) {
      return axios.post(`/v2/purchase/vehicle/${vehicleId}`, vehiclePurchase)
        .then((res) => {
          commit('SET_PURCHASE', res.data.data);
        });
    }
    return '';
  },

  saveVehicleInvoice({ commit, state }, { vehicleId, vehicleInvoice }) {
    const keys = Object.keys(vehicleInvoice);
    removeKey(keys, 'taxRate');
    removeKey(keys, 'sellerId');

    if (state.vehicleInvoiceAvailable) {
      // update
      return axios.put(`/v2/invoice/vehicle/${vehicleId}`, vehicleInvoice)
        .then((res) => {
          commit('SET_INVOICE', res.data.data);
        });
    }
    // create or do nothing
    if (keys.length > 0) {
      return axios.post(`/v2/invoice/vehicle/${vehicleId}`, vehicleInvoice)
        .then((res) => {
          commit('SET_INVOICE', res.data.data);
        });
    }
    return '';
  },

  resetVehicle({ commit, dispatch }) {
    commit('RESET_VEHICLE');
    dispatch('ads/resetAds', undefined, { root: true });
  },

  getOpportunities({ commit }, id) {
    return axios.get(`/v2/vehicle/${id}/opportunity`).then((res) => {
      commit('SET_OPPORTUNITIES', res.data);
    });
  },

  createVehicleByVin({ commit, rootState, dispatch }, data) {
    data.modelId += '';
    return axios.post('/v2/vehicle', { ...data, branchId: rootState.auth.currentBranch.ID }).then((res) => {
      commit('SET_CREATED_VEHICLE_SELLING_ID', res.data.data);
      dispatch('vehicleList/getAllVehicles', null, { root: true });
    });
  },

  createVehicle({ commit, rootState, dispatch }, data) {
    return axios.post('/v2/vehicle', { ...data, branchId: rootState.auth.currentBranch.ID }).then((res) => {
      commit('SET_CREATED_VEHICLE_SELLING_ID', res.data.data);
      dispatch('vehicleList/getAllVehicles', null, { root: true });
    });
  },

  getPriceEstimation({ commit }, id) {
    return axios.get(`/v1/vehicle/${id}/priceEstimation`, { errorNotification: false })
      .then((res) => {
        commit('SET_VEHICLE_PRICE_ESTIMATION', res.data.data);
        return res.data.data;
      });
  },

  createVehicleDamage({ commit }, { vehicleSellingId, damageImage, damage }) {
    const data = new FormData();
    Object.keys(damage)
      .filter(damageKey => damageKey !== 'file')
      .forEach(damageKey => data.append(damageKey, damage[damageKey]));

    if (damageImage) {
      data.append('file', damageImage, damageImage.name);
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios.post(`/v2/vehicle/${vehicleSellingId}/damage`, data, { headers })
      .then((res) => {
        commit('SET_VEHICLE_DAMAGES', res.data.damages);
      });
  },

  async updateVehicleDamage({ commit }, { vehicleSellingId, damageImage, damage }) {
    const data = new FormData();
    Object.keys(damage)
      .filter(damageKey => damageKey !== 'file')
      .forEach(damageKey => data.append(damageKey, damage[damageKey]));

    if (damageImage) {
      data.append('file', damageImage, damageImage.name);
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios.put(`/v2/vehicle/${vehicleSellingId}/damage/${damage.ID}`, data, { headers })
      .then((res) => {
        commit('SET_VEHICLE_DAMAGES', res.data.damages);
      });
    /*     await deleteVehicleDamage({ vehicleSellingId, damage });
    return dispatch('createVehicleDamage', { vehicleSellingId, damageImage, damage }); */

    // put route does not support image uploading
    // return axios.put(`/v1/vehicle/${vehicleSellingId}/damage/${damage.ID}`, damage);
  },

  deleteVehicleDamage({ commit }, { vehicleSellingId, damage }) {
    return deleteVehicleDamage({ vehicleSellingId, damage })
      .then(() => {
        commit('DELETE_VEHICLE_DAMAGE', damage);
      });
  },

  async checkForChanges({ state, commit }) {
    let changed = false;
    if (state.vehicleDetail.sellingId) {
      changed = JSON.stringify(extractAttrForComparison(state.vehicleDetailClone)) !== JSON.stringify(extractAttrForComparison(state.vehicleDetail));
    }
    commit('SET_STATE', { vehicleChanged: changed });
    return changed;
  },

  createVehicleImageDummys({ commit }, images) {
    commit('ADD_VEHICLE_IMAGE_DUMMYS', images);
  },

  checkIfInternalIdExits(_, internalId) {
    return axios.get(`/v2/vehicle/internalId/${internalId}`, { errorNotification: false });
  },

  // Vehicle Dat Data for DataTab
  getVehicleDatData({ commit, state }, vehicleSellingId) {
    if (
      vehicleSellingId === state.vehicleDetailClone.sellingId
      && Object.keys(state.vehicleDatData).length > 0
    ) {
      return state.vehicleDatData;
    }
    return axios.get(`/v2/vehicle/${vehicleSellingId}/dat`)
      .then((res) => {
        commit('SET_VEHICLE_DAT_DATA', res.data.data.data);
      });
  },

  getVehicleDatDocument(_, vehicleSellingId) {
    return axios.get(`/v2/vehicle/${vehicleSellingId}/dat-document`);
  },

  // Vehicle Services
  getVehicleServices({ commit }, vehicleSellingId) {
    return axios.get(`/v1/vehicle/${vehicleSellingId}/service`)
      .then((res) => {
        commit('SET_VEHICLE_SERVICES', res.data.data);
      });
  },

  createVehicleService({ dispatch }, { vehicleSellingId, service }) {
    return axios.post(`/v1/vehicle/${vehicleSellingId}/service`, service)
      .then(() => dispatch('getVehicleServices', vehicleSellingId));
  },

  updateVehicleService({ dispatch }, { vehicleSellingId, service }) {
    return axios.put(`/v1/vehicle/${vehicleSellingId}/service/${service.ID}`, service)
      .then(() => dispatch('getVehicleServices', vehicleSellingId));
  },

  deleteVehicleService({ dispatch }, { vehicleSellingId, service }) {
    return axios.delete(`/v1/vehicle/${vehicleSellingId}/service/${service.ID}`)
      .then(() => {
        dispatch('getVehicleServices', vehicleSellingId);
      });
  },


  // Vehicle Images
  getVehicleImages(storeUtils, vehicleSellingId) {
    return getVehicleImages(storeUtils, vehicleSellingId);
  },

  async createVehicleImages({ commit, rootGetters, state }, { vehicleSellingId, images = [] }) {
    commit('SET_VEHICLE_IMAGES_LOADING', true);
    const data = new FormData();
    images.forEach((image) => {
      data.append('images', image);
    });
    const socketId = rootGetters['socket/getSocketId'];
    data.append('socketId', socketId);

    return axios.post(`/v2/vehicle/${vehicleSellingId}/images`, data, { headers: { 'Content-type': 'multipart/form-data' } })
      .then((res) => {
        commit('ADD_VEHICLE_IMAGES', res.data.data.filter(image => !image.error));
        if (!rootGetters['ads/hasAds']) setVehicleImagesLoadingTimeout(commit, false);
        return res.data.data;
      }).catch(() => {
        commit('SET_VEHICLE_IMAGES_LOADING', false);
        state.vehicleImages = JSON.parse(JSON.stringify(state.vehicleImagesBeforeDummy));
        return false;
      });
  },

  async sortVehicleImage({ commit, rootGetters }, { vehicleSellingId, imageId, sortOrder }) {
    commit('SET_VEHICLE_IMAGES_LOADING', true);
    const socketId = rootGetters['socket/getSocketId'];

    return axios.put(`/v2/vehicle/${vehicleSellingId}/images/${imageId}/sort`, { sortOrder, socketId })
      .then((res) => {
        commit('SET_VEHICLE_IMAGES', res.data.data);
        if (!rootGetters['ads/hasAds']) setVehicleImagesLoadingTimeout(commit, false);
        return res.data.data;
      });
  },

  reorderVehicleImagesLocally({ commit }, newImagesOrder) {
    commit('REORDER_VEHICLE_IMAGES', newImagesOrder);
  },

  deleteVehicleImage({ commit, rootGetters, ...storeUtils }, { vehicleSellingId, imageId, skipAds }) {
    commit('SET_VEHICLE_IMAGES_LOADING', true);
    commit('DELETE_VEHICLE_IMAGE', imageId);
    const socketId = rootGetters['socket/getSocketId'];

    skipAds = skipAds ? '?skipAds' : '';

    return axios.delete(`/v2/vehicle/${vehicleSellingId}/images/${imageId}${skipAds}`, { data: { socketId } })
      .then(async () => {
        getVehicleImagesDebounced.cancel();
        getVehicleImagesDebounced({ commit, ...storeUtils }, vehicleSellingId);
        if (!rootGetters['ads/hasAds']) setVehicleImagesLoadingTimeout(commit, false);
        return true;
      });
  },

  async updateVehicleImage({ commit, rootGetters, ...storeUtils }, {
    vehicleSellingId, imageId, updates, skipAds,
  }) {
    commit('SET_VEHICLE_IMAGES_LOADING', true);

    const socketId = rootGetters['socket/getSocketId'];
    updates.socketId = socketId;

    skipAds = skipAds ? '?skipAds' : '';

    return axios.put(`/v2/vehicle/${vehicleSellingId}/images/${imageId}${skipAds}`, updates)
      .then(async () => {
        getVehicleImagesDebounced.cancel();
        getVehicleImagesDebounced({ commit, ...storeUtils }, vehicleSellingId);
        if (!rootGetters['ads/hasAds']) setVehicleImagesLoadingTimeout(commit, false);
        return true;
      });
  },

  async removeBackground(_, { sellingId, imageId }) {
    try {
      const response = await axios.post(`/v2/vehicle/${sellingId}/images/${imageId}/removebackground`, {}, { errorNotification: false });
      // If the request is successful, return the data
      return [response.data, null];
    } catch (error) {
      // If there's an error, return it
      return [null, error];
    }
  },

  async uploadOwnBackground(_, { images = [] }) {
    const data = new FormData();
    images.forEach((image) => {
      data.append('images', image);
    });

    const response = await axios.post('/v2/media/backgroundimage', data, {
      headers: { 'Content-type': 'multipart/form-data' },
    });

    return response.data;
  },


  // Vehicle Notes
  getNotes({ commit }, vehicleSellingId) {
    return axios.get(`/v1/vehicle/${vehicleSellingId}/note/`).then((response) => {
      commit('SET_NOTES', response.data.data);
    });
  },

  createNote({ dispatch }, { vehicleSellingId, note }) {
    return axios.post(`/v1/vehicle/${vehicleSellingId}/note/`, note).then(() => {
      dispatch('getNotes', vehicleSellingId);
    });
  },

  deleteNote({ dispatch }, { vehicleSellingId, note }) {
    return axios.delete(`/v1/vehicle/${vehicleSellingId}/note/${note.ID}`).then(() => {
      dispatch('getNotes', vehicleSellingId);
    });
  },

  // Vehicle ToDos
  getToDos({ commit }, vehicleId) {
    return axios.get(`/v2/vehicle/${vehicleId}/todo`).then((response) => {
      commit('SET_TODOS', response.data.data);
    });
  },

  async createToDo({ dispatch }, { vehicleId, payload }) {
    return axios.post(`/v2/vehicle/${vehicleId}/todo`, payload).then(() => {
      dispatch('getToDos', vehicleId);
    });
  },

  updateToDo({ dispatch }, { vehicleId, todo }) {
    return axios.put(`/v2/vehicle/${vehicleId}/todo/${todo.ID}`, todo).then(() => {
      dispatch('getToDos', vehicleId);
    });
  },

  deleteToDo({ dispatch }, { vehicleId, todo }) {
    return axios.delete(`/v2/vehicle/${vehicleId}/todo/${todo.ID}`).then(() => {
      dispatch('getToDos', vehicleId);
    });
  },

  createToDoTemplate(storeUtils, payload) {
    return axios.post('/v2/vehicle/todo/templates', payload);
  },

  getToDoTemplates({ commit }) {
    return axios.get('/v2/vehicle/todo/templates').then((response) => {
      commit('SET_TODO_TEMPLATES', response.data.data);
    });
  },
  async deleteToDoTemplate({ dispatch }, id) {
    await axios.delete(`/v2/vehicle/todo/templates/${id}`);
    dispatch('getToDoTemplates');
  },


  // Vehicle Docs
  getDocs({ commit }, vehicleSellingId) {
    return axios.get(`/v2/vehicle/${vehicleSellingId}/documents/`).then((response) => {
      commit('SET_DOCS', response.data.data);
    });
  },

  deleteDoc({ dispatch }, { vehicleSellingId, id }) {
    return axios.delete(`/v2/vehicle/${vehicleSellingId}/documents/${id}`).then(() => {
      dispatch('getDocs', vehicleSellingId);
    });
  },

  getVehicleMobileDePriceRating({ commit, state }, vehicleSellingId) {
    if (!vehicleSellingId) {
      vehicleSellingId = state.vehicleDetail.sellingId;
    }

    if (!vehicleSellingId) {
      return;
    }

    commit('SET_VEHICLE_MOBILE_DE_PRICE_RATING_LOADING', true);

    axios.post(`/v2/vehicle/${vehicleSellingId}/price-rating`).then((res) => {
      commit('SET_VEHICLE_MOBILE_DE_PRICE_RATING', res.data.data);
      commit('SET_VEHICLE_MOBILE_DE_PRICE_RATING_LOADING', false);
    });
  },

  async duplicateVehicle({ state }, copyParts) {
    const dismissInfo = Notify.create({
      message: 'Das Fahrzeug wird dupliziert...',
      type: 'info',
      timeout: 0,
      position: 'top-right',
      actions: [{
        icon: 'close',
      }],
    });

    const { data } = await axios.post(`/v2/vehicle/${state.vehicleDetail.sellingId}/duplicate`, copyParts).catch(dismissInfo);

    dismissInfo();
    Notify.create({
      message: 'Das Fahrzeug wurde erfolgreich dupliziert!',
      type: 'positive',
      timeout: 0,
      position: 'top-right',
      actions: [{
        label: 'öffnen',
        handler: () => {
          router.push(`/vehicle/${data.data.sellingId}`);
        },
      }, {
        icon: 'close',
      }],
    });
  },

  async getContacts({ state, commit }) {
    const { sellingId } = state.vehicleDetail;
    const { data } = await axios.get(`/v2/vehicle/${sellingId}/contacts`);
    commit('SET_CONTACTS', data.list);
  },
};

function deleteVehicleDamage({ vehicleSellingId, damage }) {
  return axios.delete(`/v2/vehicle/${vehicleSellingId}/damage/${damage.ID}`);
}

function getVehicleImages({ commit }, vehicleSellingId) {
  return axios.get(`/v2/vehicle/${vehicleSellingId}/images`)
    .then((res) => {
      commit('SET_VEHICLE_IMAGES', res.data.data);
      return res.data.data;
    });
}

function extractAttrForComparison(vehicleDetail) {
  const {
    coverImage, damages, documents, ads,
    ...attrForComparison
  } = vehicleDetail;
  return attrForComparison;
}

function setVehicleImagesLoadingTimeout(commit, isLoading) {
  setTimeout(() => {
    commit('SET_VEHICLE_IMAGES_LOADING', isLoading);
  }, 500);
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
