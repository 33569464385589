import axios from 'axios';

const state = () => ({
  list: [],
});

const mutations = {
  RESET(s) {
    Object.assign(s, state());
  },
  SET(state, list) {
    state.list = list;
  },
  ADD(state, key) {
    state.list.push(key);
  },
  REMOVE(state, uuid) {
    const index = state.list.findIndex(k => k.uuid === uuid);
    state.list.splice(index, 1);
  },
  UPDATE(state, key) {
    const index = state.list.findIndex(k => k.uuid === key.uuid);
    state.list[index] = key;
  },
};

const actions = {
  reset({ commit }) {
    commit('RESET');
  },
  async create({ commit }, payload) {
    const { data } = await axios.post('v2/api-key', payload);
    commit('ADD', data.key);
  },
  async get({ commit }) {
    const { data } = await axios.get('v2/api-key');
    commit('SET', data.list);
  },
  async remove({ commit }, payload) {
    await axios.post('v2/api-key/remove', payload);
    commit('REMOVE', payload.uuid);
  },
  async getToken({ commit, state }, payload) {
    const key = state.list.find(k => k.uuid === payload.uuid);
    if (key.token) return key.token;

    const { data } = await axios.post('v2/api-key/token', payload);
    commit('UPDATE', { ...key, token: data.token });

    return data.token;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
