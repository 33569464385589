<template>
  <div v-if="hasNylasAccount && !hasNylasV3Account">
    <q-alert
      v-if="show"
      icon="priority_high"
      color="white"
      text-color="text-color"
      :actions="[
        { label: 'Verbinden', handler: signIn },
        { icon: 'close', handler: () => { show = false } }
      ]">
      Umstellung bei Ihrer E-Mail-Anbindung: Am <strong>1. Dezember</strong> wird die E-Mail-Synchronisierung
      umgestellt. Für einen reibungslosen Übergang bitten wir Sie sich bis dahin mit Ihrem E-Mail-Konto erneut zu
      verbinden. Mit der Umstellung ändert sich für Sie nichts.
    </q-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmailChangeBanner',

  computed: {
    ...mapGetters('auth', ['hasNylasAccount', 'getBranchNylasAddress', 'hasNylasV3Account']),
  },

  data() {
    return {
      show: true,
    };
  },

  methods: {
    signIn() {
      const params = {
        client_id: 'd5e1b7ce-6b53-435a-b708-9ad4d2d8851c',
        response_type: 'code',
        access_type: 'online',
        redirect_uri: process.env.VUE_APP_NYLAS_REDIRECT_URI,
        login_hint: this.getBranchNylasAddress,
        prompt: 'detect,select_provider',
      };

      const queryString = new URLSearchParams(params).toString();
      const url = `https://api.eu.nylas.com/v3/connect/auth?${queryString}`;

      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.banner {
  background-color: #f0f0f0 !important;
  color: #000;
  border: 1px solid #000;
}
</style>
