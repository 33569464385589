import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import connection from './modules/connection';
import dashboard from './modules/dashboard';
import vehicle from './modules/vehicle';
import vehicleMeta from './modules/vehicleMeta';
import vehicleList from './modules/vehicleList';
import vehicleListNew from './modules/vehicleListNew';
import opportunity from './modules/opportunity';
import opportunityProgress from './modules/opportunityProgress';
import customer from './modules/customer';
import customerList from './modules/customerList';
import branch from './modules/branch';
import user from './modules/user';
import dealer from './modules/dealer';// this should be under account-management
import dealerDetail from './modules/dealerDetail';
import dealerList from './modules/dealerList';
import subscription from './modules/subscription';
import checkout from './modules/checkout';
import socket from './modules/socket';
import session from './modules/session';
import ads from './modules/ads';
import financing from './modules/financing';
import documentTemplate from './modules/documentTemplate';
import document from './modules/document';
import emailThreadDetail from './modules/emailThreadDetail';
import emailThreadList from './modules/emailThreadList';
import internalEmail from './modules/internalEmail';
import opportunityList from './modules/opportunityList';
import opportunityDetail from './modules/opportunityDetail';
import documentCategory from './modules/documentCategory';
import documentTable from './modules/documentTable';
import documentTemplateTable from './modules/documentTemplateTable';
import setting from './modules/setting';
import contact from './modules/contact';
import contactList from './modules/contactList';
import organizationDetail from './modules/organizationDetail';
import organizationList from './modules/organizationList';
import inserateImporter from './modules/inserateImporter';
import insight from './modules/insight';
import branchSetting from './modules/branchSetting';
import branchList from './modules/branchList';
import userSetting from './modules/userSetting';
import userList from './modules/userList';
import system from './modules/system';
import marketplaceList from './modules/marketplaceList';
import marketplaceFavList from './modules/marketplaceFavList';
import marketplaceBidList from './modules/marketplaceBidList';
import marketplaceBoughtList from './modules/marketplaceBoughtList';
import marketplaceDetail from './modules/marketplaceDetail';
import notifications from './modules/notifications';
import elnMarketList from './modules/elnMarketList';
import elnMarketMeta from './modules/elnMarketMeta';
import elnMarketDetail from './modules/elnMarketDetail';
import elnVehicle from './modules/elnVehicle';
import apiKey from './modules/apiKey';
import passkey from './modules/passkey';
import media from './modules/media';
import invoices from './modules/invoices';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    connection,
    dashboard,
    vehicle,
    customer,
    customerList,
    vehicleMeta,
    vehicleList,
    opportunity, // old store
    opportunityProgress,
    internalEmail,
    opportunityList,
    opportunityDetail,
    vehicleListNew,
    branch,
    user,
    dealer,
    dealerDetail,
    dealerList,
    subscription,
    checkout,
    socket,
    session,
    ads,
    financing,
    documentTemplate,
    document,
    emailThreadDetail,
    emailThreadList,
    documentCategory,
    documentTable,
    documentTemplateTable,
    setting,
    contact,
    contactList,
    organizationDetail,
    organizationList,
    inserateImporter,
    insight,
    branchSetting,
    branchList,
    userSetting,
    userList,
    system,
    marketplaceList,
    marketplaceFavList,
    marketplaceDetail,
    marketplaceBidList,
    marketplaceBoughtList,
    notifications,
    elnMarketList,
    elnMarketMeta,
    elnMarketDetail,
    elnVehicle,
    apiKey,
    passkey,
    media,
    invoices,
  },
  strict: false,
});

store.dispatch('auth/init');

export default store;
