<template>
  <q-layout-header class="a-layout-header">
    <q-toolbar :color="darkMode ? 'darkmode' : 'white'">
      <q-btn
        :color="darkMode ? 'darkmode' : 'black'"
        flat
        dense
        round
        aria-label="Menu"
        @click="showDrawer = !showDrawer"
      >
        <q-icon name="menu" />
      </q-btn>
      <q-toolbar-title class="col-auto row items-center">
        <router-link to="/" class="row items-center">
          <img
            height="30"
            :src="darkMode ? $app.logoLight : $app.logo"
            class="cursor-pointer"
          />
        </router-link>
        <div
          v-small-caps="currentBranch.name"
          class="q-body-2 text-dark uppercase q-ml-md"
          style="word-spacing: 2px;"
        />
      </q-toolbar-title>

      <div class="col flex justify-center q-px-lg" v-if="$store.getters['auth/alSupport']">
        <a-autocomplete-input
          ref="dealerSearch"
          v-model="search"
          :search="fetchDealers"
          clearable
          no-parent-field
          hide-underline
          :min-characters="0"
          :max-results="20"
          :debounce="600"
          class="filter q-body-1 full-width"
          style="max-width: 400px"
          :class="darkMode ? 'dark-mode-input' : null"
          placeholder="Firmenname, Email-Adresse, etc."
          @selected="onDealerSelect"
        />
      </div>
      <div v-else class="col"></div>

      <div
        class="row items-center q-caption text-italic text-faded"
        v-show="mobileDeAd.loading || as24Ad.loading || elnAd.loading"
      >
        <div v-if="mobileDeAd.loading">{{mobileDeAd.loadingMessage}}</div>
        <span v-if="as24Ad.loading && mobileDeAd.loading" class>, &nbsp;</span>
        <div v-if="as24Ad.loading">{{as24Ad.loadingMessage}}</div>
        <span v-if="elnAd.loading && (mobileDeAd.loading && !as24Ad.loading || as24Ad.loading)" class>, &nbsp;</span>
        <div v-if="elnAd.loading">{{elnAd.loadingMessage}}</div>
        <q-spinner color="primary" :size="30" class="q-ml-sm" />
      </div>
      <abo-upgrade
        :showRemaining="true"
        v-if="currentDealer.trialPeriodEnd !== null"
      />
      <q-toggle
        v-if="$store.getters['auth/alSupport']"
        v-model="darkMode"
        label="Dark Mode"
        left-label
        :class="darkMode ? 'text-white' : 'text-black'"
      />
      <notification-button v-if="$app.whitelabel === 'eln'"/>
      <settings-popover />
    </q-toolbar>
  </q-layout-header>
</template>

<script>
import SettingsPopover from '@/components/base/SettingsPopover';
import AboUpgrade from '@/components/base/AboUpgrade';
import NotificationButton from '@/layouts/layout-components/notification/NotificationButton';

import { mapState, mapActions } from 'vuex';
import { settings } from '@/plugins/mixins';
import { formatName } from '@/plugins/util';

export default {
  name: 'LayoutToolbar',
  components: {
    SettingsPopover,
    AboUpgrade,
    NotificationButton,
  },
  data() {
    return {
      search: null,
    };
  },
  mixins: [
    settings('userSetting', {
      showDrawer: 'layout.showMiniDrawer',
      darkMode: 'layout.darkMode',
    }),
  ],
  computed: {
    ...mapState('auth', ['currentDealer', 'currentBranch']),
    ...mapState('ads', ['mobileDeAd', 'as24Ad', 'elnAd']),
    ...mapState('dealerList', ['dealerList']),
  },

  methods: {
    ...mapActions('dealerList', ['getDealerList']),
    async fetchDealers(searchTerms, done) {
      if (!searchTerms) {
        this.$store.commit('dealerList/SET_SEARCH', searchTerms);
        return done();
      }
      this.$store.commit('dealerList/SET_SEARCH', searchTerms);
      await this.getDealerList();
      return done(this.preprocessDealer(this.dealerList));
    },
    preprocessDealer(dealerList = []) {
      return dealerList.map(dealer => ({
        label: `${dealer.name}`,
        value: dealer.ID,
        sublabel: `${formatName(dealer)} (${dealer.email})`,
        letter: (dealer.name && dealer.name[0]) || (dealer.email && dealer.email[0]),
      }));
    },
    onDealerSelect(dealerId) {
      if (!dealerId) return;

      this.$store.commit('dealerList/SET_SEARCH', '');
      this.search = null;

      if (+this.$route.params.dealerId === dealerId) return;
      this.$router.push(`/admin/dealer/${dealerId}`);
    },
  },

};
</script>

<style scoped lang="stylus">
@import theme

.a-layout-header {
  box-shadow: 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
  height: 64px;
}

.filter {
  border: 2px solid $faded;
  border-radius: 4px;
  padding: 5px;
  padding-left: 8px;
}

.dark-mode-input >>> input{
  color: white;
}

.hard-center {
  position: absolute;
}
</style>
