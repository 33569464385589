<template>
  <a-modal
    class="a-first-login-modal"
    v-model="opened"
    :css="{ width: '100vw', maxWidth: '800px' }"
    title
    use-quasar-footer
    :hideCloseButton="!$store.getters['auth/alSupport']"
    :noEscDismiss="!$store.getters['auth/alSupport']"
  >
    <template slot="content">
      <intro v-if="step === 0" />
      <firststeps ref="firststeps" v-if="step === 1" />
      <mobile-as24 v-if="step === 2" />
      <account-Init v-if="step === 3" />
    </template>

    <template slot="footer">
      <div class="row justify-center" v-if="step === 0">
        <div class="q-ma-md">
          <q-btn
            label="Jetzt loslegen!"
            color="primary"
            class="no-shadow q-ml-sm"
            @click="changeStep(1)"
            no-caps
          />
        </div>
      </div>
      <div class="row justify-center" v-if="step === 1">
        <div class="q-ma-md">
          <q-btn
            label="Zurück"
            color="primary"
            class="no-shadow"
            @click="changeStep(0)"
            no-caps
            outline
          />
          <q-btn
            label="Weiter"
            color="primary"
            class="no-shadow q-ml-sm"
            no-caps
            @click="validateForm()"
          />
        </div>
      </div>

      <div class="row justify-center" v-if="step > 1 && step < 3">
        <div class="q-ma-md">
          <q-btn
            label="Zurück"
            color="primary"
            class="no-shadow q-ml-sm"
            outline
            no-caps
            @click="changeStep(step - 1)"
          />
          <q-btn
            label="Weiter"
            color="primary"
            class="no-shadow q-ml-sm"
            no-caps
            @click="changeStep(step + 1)"
          />
        </div>
      </div>

    </template>
  </a-modal>
</template>

<script>
import Intro from '@/components/dashboard/onboarding/steps/Intro';
import Firststeps from '@/components/dashboard/onboarding/steps/FirstSteps';
import MobileAs24 from '@/components/dashboard/onboarding/steps/MobileAs24';
import AccountInit from '@/components/dashboard/onboarding/steps/AccountInit';
import { mapState, mapActions } from 'vuex';


export default {
  name: 'WelcomeModal',
  components: {
    Intro,
    Firststeps,
    MobileAs24,
    AccountInit,
  },
  data() {
    return {
      opened: false,
      step: 0,
    };
  },
  mounted() {
    this.step = this.currentBranch.welcomeWindowStep;
  },
  computed: {
    ...mapState('auth', ['currentBranch']),
  },
  methods: {
    ...mapActions('branch', [
      'shouldShowWelcomeWindow',
      'neverShowWelcomeWindowAgain',
    ]),
    async validateForm() {
      const result = await this.$refs.firststeps.updateContact();
      if (result) this.changeStep(2);
    },
    changeStep(step) {
      this.step = step;
      this.$store.dispatch('branch/changeWelcomeWindowStep', { step });
    },
    close() {
      this.opened = false;
      this.neverShowWelcomeWindowAgain();
    },
  },
  async created() {
    this.opened = await this.shouldShowWelcomeWindow();
  },

  watch: {
    step(val) {
      // during that 8 secounds DummyData is created - should be enough
      if (val === 3) {
        setTimeout(() => {
          this.close();
        }, 8000);
      }
    },
  },
};
</script>
