import axios from 'axios';
import router from '../../router';

export const initialState = {
  opportunityList: [],
  opportunityDetail: {
    firstName: '',
    lastName: '',
    requestText: '',
    sourceTag: 'Autengo',
  },
  isCreatePage: false,
  mailsList: [],
  tableProperties: {
    serverPagination: {
      page: 1,
      rowsNumber: 5,
      sortBy: null,
      descending: false,
    },
    search: '',
  },
};

export const getters = {
  hasOpportunityLinked: (state) => {
    if (state.opportunityList.data) {
      const exists = Object.keys(state.opportunityList.data).some(i => state.opportunityList.data[i].isDummy === 0);
      return exists;
    }
    return false;
  },
};

export const mutations = {
  SET_OPPORTUNITY_LIST(state, newValue) {
    state.opportunityList = newValue;
  },
  SET_OPPORTUNITY_DETAIL(state, newValue) {
    state.opportunityDetail = newValue;
  },
  CLEAN_OPPORTUNITY_DETAIL(state, preservedAttributes) {
    state.opportunityDetail = {
      firstName: '',
      lastName: '',
      requestText: '',
      sourceTag: 'Autengo',
      ...(preservedAttributes || {}),
    };
  },
  SET_MAILS_LIST(state, newValue) {
    state.mailsList = newValue;
  },
  SET_IS_CREATE_PAGE(state, newValue) {
    state.isCreatePage = newValue;
  },
};

function getAllOpportunities(filter) {
  let urlParams = { limit: 10000 };
  if (filter) {
    urlParams = Object.assign(urlParams, filter);
  }
  return axios.get('v1/opportunity', {
    params: urlParams,
  });
}

export const actions = {

  getOpportunityList({ commit } = {}, filter) {
    getAllOpportunities(filter)
      .then((res) => {
        commit('SET_OPPORTUNITY_LIST', res.data);
      });
  },

  getOpportunityById({ commit }, id) {
    return axios.get(`/v1/opportunity/${id}`).then((res) => {
      commit('SET_OPPORTUNITY_DETAIL', res.data.data);
      const list = [];
      const { history } = res.data.data;
      for (let i = 0; i < history.length; i += 1) {
        if (history[i].type === 'emailSent') {
          list.push(history[i]);
        }
      }
      commit('SET_MAILS_LIST', list);
    });
  },

  editOpportunity({ dispatch }, { data, id }) {
    return axios.put(`/v1/opportunity/${id}`, data).then(() => {
      dispatch('getOpportunityById', id);
    });
  },

  addNote({ dispatch }, { data, id }) {
    axios.post(`v1/opportunity/${id}/note`, data)
      .then(() => {
        dispatch('getOpportunityById', id);
      });
  },

  addCustomer(storeUtils, { data, id }) {
    axios.post(`v1/customer/${id}/customerNumber`, data)
      .then((res) => {
        router.push(`/customer/${res.data.data.customerNumber}`);
      });
  },

  createOpportunity({ dispatch }, data) {
    axios.post('v1/opportunity', data).then((res) => {
      dispatch('getOpportunityById', res.data.data.ID);
      router.push(`/opportunity/${res.data.data.ID}`);
    });
  },

  deleteOpportunity({ commit }, { data, id }) {
    axios.delete(`v1/opportunity/${id}`, data).then(() => {
      commit('CLEAN_OPPORTUNITY_DETAIL');
    });
  },

  sendMail({ dispatch }, { data }) {
    return axios.post('/v1/mail', data).then(() => {
      dispatch('getOpportunityById', data.opportunityId);
    });
  },

};


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  actions,
  mutations,
  getters,
};
