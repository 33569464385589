import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';

const DEFAULT_SETTINGS = {
  'onboarding.setup.done': false,
  'facebook.lead.email': true,
  'marketplace.cs24.email.outbidden': true,
  'invoice.v2.active': false,
};

export function state() {
  const savedSettings = Object.entries(localStorage)
    .map(([key, value]) => {
      if (!key.startsWith('branch.setting.')) return null;
      try {
        return {
          key: key.replace('branch.setting.', ''),
          value: JSON.parse(value),
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    })
    .filter(Boolean)
    .reduce((settings, { key, value }) => {
      settings[key] = value;
      return settings;
    }, {});

  return {
    ...DEFAULT_SETTINGS,
    ...savedSettings,
  };
}

export const mutations = {
  RESET(state, obj) {
    obj = Object.assign({}, DEFAULT_SETTINGS, obj);
    Object.entries(obj).forEach(([key, value]) => {
      Vue.set(state, key, value);
      localStorage[`branch.setting.${key}`] = JSON.stringify(value);
    });
  },
  SET(state, obj) {
    Object.entries(obj).forEach(([key, value]) => {
      Vue.set(state, key, value);
      localStorage[`branch.setting.${key}`] = JSON.stringify(value);
    });
  },
};

export const actions = {
  async getAllSettings({ rootState, commit }) {
    const branchId = rootState.auth.currentBranch.ID;
    return axios.get(`/v2/branch/${branchId}/setting`)
      .then((res) => {
        commit('RESET', res.data.data);
      });
  },

  async getSetting({ rootState, commit }, key) {
    const branchId = rootState.auth.currentBranch.ID;
    return axios.get(`/v2/branch/${branchId}/setting/${key}`)
      .then((res) => {
        commit('SET', res.data.data);
      });
  },

  async setSetting({ rootState, commit }, setting) {
    const [key, value] = Object.entries(setting).shift();

    if (
      !_.isBoolean(value)
      && !_.isNumber(value)
      && !_.isString(value)
      && !_.isArray(value)
    ) throw new Error('The type of value have to be one of {boolean|number|string|array}!');

    commit('SET', {
      [key]: value,
    });

    const branchId = rootState.auth.currentBranch.ID;
    return axios.post(`/v2/branch/${branchId}/setting/${key}`, { value });
  },

  async removeSetting({ rootState, commit }, key) {
    commit('SET', {
      [key]: DEFAULT_SETTINGS[key],
    });

    const branchId = rootState.auth.currentBranch.ID;
    return axios.delete(`/v2/branch/${branchId}/setting/${key}`);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
