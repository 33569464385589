<template>
  <div class="a-avatar" :style="style">
    <div
      class="a-avatar__content row flex-center overflow-hidden"
      :class="contentClass"
      :style="contentStyle"
    >
      <q-icon v-if="icon" :name="icon" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AAvatar',
  props: {
    size: String,
    fontSize: String,

    color: String,
    textColor: String,

    icon: String,
    src: String,
    square: Boolean,
    rounded: Boolean,
  },
  computed: {
    contentClass() {
      return {
        [`bg-${this.color}`]: this.color,
        [`text-${this.textColor} q-chip--colored`]: this.textColor,
        'a-avatar__content--square': this.square,
        'a-avatar__content--rounded': this.rounded,
      };
    },
    style() {
      if (!this.size) return {};
      return { fontSize: this.size };
    },
    contentStyle() {
      const contentStyle = {};
      if (this.fontSize) contentStyle.fontSize = this.fontSize;
      if (this.src) contentStyle.background = `url(${this.src}) center center / cover`;
      return contentStyle;
    },
  },
};
</script>

<style lang="stylus" scoped>
.a-avatar {
  vertical-align: middle;
  display: inline-block;
  position: relative;

  border-radius: 50%;

  font-size: 48px;
  height: 1em;
  width: 1em;

  &__content {
    font-size: .5em;
    line-height: .5em;
  }

  &__content, img {
    border-radius: inherit;
    height: inherit;
    width: inherit;
  }
}

.a-avatar__content--square {
  border-radius: 0;
}
.a-avatar__content--rounded {
  border-radius: 3px;
}
</style>
