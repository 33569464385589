<template>
  <q-layout view="hHh LpR lFf" class="bg-white">
    <layout-toolbar />
    <email-change-banner2 class="email-change-banner"/>
    <layout-drawer />
    <notification-drawer />
    <q-page-container :key="currentBranch.ID">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </q-page-container>
    <!--layout-footer
      v-if="
        $store.getters['auth/alSupport'] &&
        !$store.getters['auth/isEmployee']
      "
    /-->
    <a-welcome-modal />
    <notifications-dialog
      v-if="openNotificationsDialog"
      :open="openNotificationsDialog"
      :notification="notifications[0]"
    />
  </q-layout>
</template>

<script>
import LayoutToolbar from './layout-components/Toolbar';
import LayoutDrawer from './layout-components/drawer/DrawerWrapper';
import NotificationDrawer from './layout-components/drawer/NotificationDrawer';
import LayoutFooter from './layout-components/Footer';
import AWelcomeModal from '@/components/dashboard/onboarding/Onboarding';
import NotificationsDialog from '@/components/base/NotificationsDialog';
import EmailChangeBanner2 from '@/components/email/EmailChangeBanner2.vue';

import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LayoutDefault',
  components: {
    LayoutToolbar,
    LayoutDrawer,
    LayoutFooter,
    AWelcomeModal,
    NotificationsDialog,
    NotificationDrawer,
    EmailChangeBanner2,
  },
  data() {
    return {
      miniState: false,
      leftDrawerOpen: true,
      miniWidth: 60,
      width: 200,
    };
  },
  computed: {
    ...mapState('auth', ['currentBranch']),
    ...mapGetters('auth', [
      'alSupport',
      'isTrialPeriodActive',
    ]),
    ...mapGetters('subscription', [
      'notSubscriptedToMainPackage',
    ]),
    ...mapState('notifications', ['notifications']),
    openNotificationsDialog() {
      if (this.notifications.length === 0) return false;
      if (this.notifications.some(n => n.showPopUp === 1)) return true;

      return false;
    },
    forwardToPaymentPage() {
      if (this.alSupport) return false;
      if (this.isTrialPeriodActive) return false;
      return this.notSubscriptedToMainPackage;
    },
  },
  watch: {
    forwardToPaymentPage: {
      immediate: true,
      handler(forwardToPaymentPage) {
        if (forwardToPaymentPage) this.$router.replace('/payment');
      },
    },
  },
  created() {
    this.$store.dispatch('userSetting/getAllSettings');
    this.$store.dispatch('auth/reloadCurrentBranch');
    this.$store.dispatch('notifications/getAllNotifications');
  },
};
</script>

<style scoped lang="stylus">
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
.email-change-banner {
  max-width: 1000px;
  margin-top: 5px
  position: fixed;
  z-index : 9999;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
}
</style>
